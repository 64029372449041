import i18next from "i18next";
import _ from "lodash";
import { toast } from "react-toastify";
import { AppThunk } from "store/store";
import { adminClientRolesActions } from "store/reducers/Admin/ClientRole/adminClientRolesSlice";
import { RoleClientDetails, RoleClientUpdate } from "backend/ApiAdminDefinition/data-contracts";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiAdminDefinition from "backend/apiAdminDefinition";
import apiErrorModel from "backend/utils/apiErrorModel";


/**
 * Update a role
 * @param role 
 * @returns 
 */
export const updateRoleThunk = (role: RoleClientUpdate, callbacks: ThrunkApiCallBacks<RoleClientDetails>): AppThunk => {
    return async (dispatch, getState) => {

        const selectedClient = getState().userProfil.currentTenant;

        if(!selectedClient) {
            toast.warn(i18next.t("Impossible de modifier une permission car aucun client n'est sélectionné"))
            return;
        }

        dispatch(adminClientRolesActions.setLoadingEdit(true));


        const oldRole =  _.clone(getState().adminClientRole.roles.find(m => m.id == role.id));

        // save new permission state before api call for refresh UI
        dispatch(adminClientRolesActions.updateRole(role));

        //const result = await apiAdmin.forClient(selectedClient.id).role.update(role);
        apiAdminDefinition.roleClient.clientRolePut(role.id, role)
        .then((result) => {
            dispatch(adminClientRolesActions.updateRole(result.data));
            callbacks.onSuccess(result.data);
        })
        .catch((errors: apiErrorModel) =>  {
            callbacks.onError(errors);
            // if api request failed, restore old role state
            if(oldRole) dispatch(adminClientRolesActions.updateRole(oldRole));
        })
        .finally(() => dispatch(adminClientRolesActions.setLoadingEdit(false)));

    }
}
