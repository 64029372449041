/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, OffMeshLinkCreate, OffMeshLinkDetails, OffMeshLinkUpdate, Operation } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MapOffMeshLink<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags MapOffMeshLink
     * @name OffmeshlinksList
     * @summary [🤵 OR 🔑] Get all off mesh link. [🔒: map_read].
     * @request GET:/map/{mapId}/offmeshlinks
     * @secure
     */
    offmeshlinksList = (mapId: string, params: RequestParams = {}) =>
        this.request<OffMeshLinkDetails[], void>({
            path: `/map/${mapId}/offmeshlinks`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapOffMeshLink
     * @name OffmeshlinksPost
     * @summary [🤵 OR 🔑] Create several off mesh links. [🔒: map_create].
     * @request POST:/map/{mapId}/offmeshlinks
     * @secure
     */
    offmeshlinksPost = (mapId: string, data: OffMeshLinkCreate[], params: RequestParams = {}) =>
        this.request<OffMeshLinkDetails[], void>({
            path: `/map/${mapId}/offmeshlinks`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapOffMeshLink
     * @name OffmeshlinksPut
     * @summary [🤵 OR 🔑] Update all fields of several map offmesh link. [🔒: map_update].
     * @request PUT:/map/{mapId}/offmeshlinks
     * @secure
     */
    offmeshlinksPut = (mapId: string, data: OffMeshLinkUpdate[], params: RequestParams = {}) =>
        this.request<OffMeshLinkDetails[], ApiErrorsResponse | void>({
            path: `/map/${mapId}/offmeshlinks`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapOffMeshLink
     * @name OffmeshlinksDelete
     * @summary [🤵 OR 🔑] [🔒: map_update].
     * @request DELETE:/map/{mapId}/offmeshlinks
     * @secure
     */
    offmeshlinksDelete = (mapId: string, data: string[], params: RequestParams = {}) =>
        this.request<any, ApiErrorsResponse | void>({
            path: `/map/${mapId}/offmeshlinks`,
            method: 'DELETE',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        })
    /**
     * No description
     *
     * @tags MapOffMeshLink
     * @name OffmeshlinkPost
     * @summary [🤵 OR 🔑] Create new off mesh link. [🔒: map_create].
     * @request POST:/map/{mapId}/offmeshlink
     * @secure
     */
    offmeshlinkPost = (mapId: string, data: OffMeshLinkCreate, params: RequestParams = {}) =>
        this.request<OffMeshLinkDetails, void>({
            path: `/map/${mapId}/offmeshlink`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapOffMeshLink
     * @name OffmeshlinkPut
     * @summary [🤵 OR 🔑] Update all field of a off mesh links. [🔒: map_update].
     * @request PUT:/map/{mapId}/offmeshlink/{offmeshlinkId}
     * @secure
     */
    offmeshlinkPut = (mapId: string, offmeshlinkId: string, data: OffMeshLinkUpdate, params: RequestParams = {}) =>
        this.request<OffMeshLinkDetails, void>({
            path: `/map/${mapId}/offmeshlink/${offmeshlinkId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapOffMeshLink
     * @name OffmeshlinkPatch
     * @summary [🤵 OR 🔑] Update specific field of a off mesh link. [🔒: map_update].
     * @request PATCH:/map/{mapId}/offmeshlink/{offmeshlinkId}
     * @secure
     */
    offmeshlinkPatch = (mapId: string, offmeshlinkId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<OffMeshLinkDetails, void>({
            path: `/map/${mapId}/offmeshlink/${offmeshlinkId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapOffMeshLink
     * @name OffmeshlinkDelete
     * @summary [🤵 OR 🔑] Delete an off mesh link. [🔒: map_update].
     * @request DELETE:/map/{mapId}/offmeshlink/{offmeshlinkId}
     * @secure
     */
    offmeshlinkDelete = (mapId: string, offmeshlinkId: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/map/${mapId}/offmeshlink/${offmeshlinkId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
