import { AppThunk } from "store/store"
import { auditActions } from "store/reducers/Audit/auditSlice";
import { SelectAuditDataThunk } from "./SelectAuditDataThunk";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";
import _ from "lodash";
import { AuditRawFileDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import dayjs from "dayjs";


/**
 * 
 * @param mapId 
 * @param from 
 * @param to 
 * @param auditRawFileId Id of the audit raw file to load heatmap
 * @returns 
 */
export const LoadAuditHeatMap = (mapId: string, auditRawFile: AuditRawFileDetails): AppThunk => {
    return async (dispatch, getState) => {

        try{

            dispatch(auditActions.setHeatmapData({
                state: "waitingLoad",
                scaleOriginal: [],
                scaleOptimized: []
            }))

            // Loading is set to false in the view after unity sdk preload heatmap data
            dispatch(auditActions.setLoadingHeatmap(true));

            /*
            const from = dayjs(auditRawFile.dateFrom).startOf('day').toDate();
            const to = dayjs(auditRawFile.dateTo).endOf('day').toDate();

            const params = {
                from: from.toISOString(),
                to: to.toISOString()
            }


            

            apiBackofficeDefinition.heatmap.generate
            .generateScaleFromPaths(mapId, {...params, category: `${auditRawFile.id}_Original`})
            .then((scales) => {
                dispatch(auditActions.setHeatmapData({
                    state: "loaded",
                    scale: scales.data
                }))
            }).catch((error) => {
                dispatch(auditActions.setHeatmapData({
                    state: "error",
                    scale: []
                }))
            })*/
        }
        catch(error: any)
        {
            /*if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des données d'audit"))*/
            console.error("[LoadAuditHeatMap] error", error)
        }
        finally
        {
            //dispatch(auditActions.setLoadingHeatmap(false));
        }
    }
}