import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FloorDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

export interface IAdminClientMapFloorStore {
  floors: FloorDetails[];
  loadingList: boolean;
  loadingForm: boolean;
}

const initialState: IAdminClientMapFloorStore = {
  floors: [],
  loadingList: false,
  loadingForm: false,
};

const adminClientMapFloorSlice = createSlice({
  name: "adminClientMapFloorSlice",
  initialState,
  reducers: {
    setFloors: (state, action: PayloadAction<FloorDetails[]>) => {
      state.floors = action.payload;
    },
    updateFloor: (state, action: PayloadAction<FloorDetails>) => {
      state.floors = state.floors.map((f) =>
        f.id === action.payload.id ? action.payload : f
      );
    },
    addFloor: (state, action: PayloadAction<FloorDetails>) => {
      state.floors.push(action.payload);
    },
    deleteFloor: (state, action: PayloadAction<string>) => {
      state.floors = state.floors.filter(f => f.id !== action.payload)
    },
    setLoadingFloorList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    setLoadingFloorForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.floors = [];
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

export const {
  actions: adminClientMapFloorActions,
  reducer: adminClientMapFloorReducer,
} = adminClientMapFloorSlice;