import { createSlice } from "@reduxjs/toolkit";
import { userProfilActions } from "../../Profil/userProfilSlice";
import IOnEventAdminModel from "models/Admin/IOnEventAdminModel";
import IConnectedEntityModel from "models/Admin/IConnectedEntityModel";

export interface IAdminRealtimeLogStore {
  enabled: boolean,
  logs: IOnEventAdminModel[]
  connectedUsers: IConnectedEntityModel[]
}
  
  
const initialState: IAdminRealtimeLogStore = {
  enabled : false,
  logs: [],
  connectedUsers: []
}


const adminRealtimeLogSlice = createSlice({
  name: "adminRealtimeLogSlice",
  initialState,
  reducers: {
    setRealtimeEnabled: (state, action) => {
      state.enabled = action.payload;
    },
    clearRealtimeLog: (state) => {
      state.logs = [];
    },
    addRealtimeLog: (state, action) => {
      state.logs.push(action.payload);
    },
    clearConnectedUser: (state) => {
      state.connectedUsers = [];
    },
    addConnectedUser: (state, action) => {
      state.connectedUsers.push(action.payload);
    },
    removeConnectedUser: (state, action) => {
      state.connectedUsers = state.connectedUsers.filter(
        (m) => m.connectionId !== action.payload.connectionId
      );
    },
    setConnectedUsers: (state, action) => {
      state.connectedUsers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.logs = [];
      state.enabled = false;
      state.connectedUsers = [];
    });
  },
});

export const { actions: adminRealtimeLogActions, reducer: adminRealtimeLogReducer } = adminRealtimeLogSlice