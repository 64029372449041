/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, Operation, PolygonCreate, PolygonDetails, PolygonUpdate } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MapPolygon<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags MapPolygon
     * @name PolygonsList
     * @summary [🤵 OR 🔑] Get all polygons for a map. [🔒: map_read].
     * @request GET:/map/{mapId}/polygons
     * @secure
     */
    polygonsList = (mapId: string, params: RequestParams = {}) =>
        this.request<PolygonDetails[], ApiErrorsResponse | void>({
            path: `/map/${mapId}/polygons`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapPolygon
     * @name PolygonsPost
     * @summary [🤵 OR 🔑] Create several polygons. [🔒: map_create].
     * @request POST:/map/{mapId}/polygons
     * @secure
     */
    polygonsPost = (mapId: string, data: PolygonCreate[], params: RequestParams = {}) =>
        this.request<PolygonDetails[], void>({
            path: `/map/${mapId}/polygons`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapPolygon
     * @name PolygonsPut
     * @summary [🤵 OR 🔑] Update all fields of several map polygons. [🔒: map_update].
     * @request PUT:/map/{mapId}/polygons
     * @secure
     */
    polygonsPut = (mapId: string, data: PolygonUpdate[], params: RequestParams = {}) =>
        this.request<PolygonDetails[], void>({
            path: `/map/${mapId}/polygons`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapPolygon
     * @name PolygonsDelete
     * @summary [🤵 OR 🔑] [🔒: map_update].
     * @request DELETE:/map/{mapId}/polygons
     * @secure
     */
    polygonsDelete = (mapId: string, data: string[], params: RequestParams = {}) =>
        this.request<any, ApiErrorsResponse | void>({
            path: `/map/${mapId}/polygons`,
            method: 'DELETE',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        })
    /**
     * No description
     *
     * @tags MapPolygon
     * @name FloorPolygonsDetails
     * @summary [🤵 OR 🔑] Get all polygons for a map for a specifc floor. [🔒: map_read].
     * @request GET:/map/{mapId}/floor/{floorId}/polygons
     * @secure
     */
    floorPolygonsDetails = (mapId: string, floorId: string, params: RequestParams = {}) =>
        this.request<PolygonDetails[], ApiErrorsResponse | void>({
            path: `/map/${mapId}/floor/${floorId}/polygons`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapPolygon
     * @name PolygonPost
     * @summary [🤵 OR 🔑] Create new polygon. [🔒: map_create].
     * @request POST:/map/{mapId}/polygon
     * @secure
     */
    polygonPost = (mapId: string, data: PolygonCreate, params: RequestParams = {}) =>
        this.request<PolygonDetails, void>({
            path: `/map/${mapId}/polygon`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapPolygon
     * @name PolygonPut
     * @summary [🤵 OR 🔑] Update all fields of a polygon. [🔒: map_update].
     * @request PUT:/map/{mapId}/polygon/{polygonId}
     * @secure
     */
    polygonPut = (mapId: string, polygonId: string, data: PolygonUpdate, params: RequestParams = {}) =>
        this.request<PolygonDetails, void>({
            path: `/map/${mapId}/polygon/${polygonId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapPolygon
     * @name PolygonPatch
     * @summary [🤵 OR 🔑] Update specific field of a map polygon. [🔒: map_update].
     * @request PATCH:/map/{mapId}/polygon/{polygonId}
     * @secure
     */
    polygonPatch = (mapId: string, polygonId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<PolygonDetails, void>({
            path: `/map/${mapId}/polygon/${polygonId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapPolygon
     * @name PolygonDelete
     * @summary [🤵 OR 🔑] Delete a polygon. [🔒: map_update].
     * @request DELETE:/map/{mapId}/polygon/{polygonId}
     * @secure
     */
    polygonDelete = (mapId: string, polygonId: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/map/${mapId}/polygon/${polygonId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
