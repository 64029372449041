import React, { useEffect } from 'react'
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { Badge} from 'react-bootstrap';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import ColorPicker from 'components/inputs/ColorPicker';
import { useAppDispatch, useAppSelector } from 'store/store';
import { loadTemplatesThunks } from 'store/reducers/Admin/ClientMapTemplate/thrunks/loadTemplatesThunks';
import { TemplateCreate, TemplateDetails, TemplateUpdate } from 'backend/ApiBackofficeDefinition/data-contracts';
import { updateTemplateThunk } from 'store/reducers/Admin/ClientMapTemplate/thrunks/updateTemplateThunk';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { createTemplateThunk } from 'store/reducers/Admin/ClientMapTemplate/thrunks/createTemplateThunk';
import { deleteTemplateThrunks } from 'store/reducers/Admin/ClientMapTemplate/thrunks/deleteTemplateThrunk';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';

const AdminClientMapTemplate: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapTemplate}), { useSuspense: false});
    const dispatch = useAppDispatch();

    const {
        loadingList,
        loadingForm,
        templates
    } = useAppSelector(state => state.adminClientMapTemplate);

    const { clientId, mapId } = useParams();

    useEffect(() => {
        document.title = t('[Admin] Templates de la map');
        if(clientId && mapId && mapId != "new")
        {
            dispatch(loadTemplatesThunks(mapId));
        }
    }, [clientId, mapId])

    const onUpdate = (Template: TemplateDetails, closeModal?: () => void) => {
        if(clientId && mapId)
        {
            dispatch(updateTemplateThunk(mapId, Template as TemplateUpdate,{
                onSuccess: (Template) => {
                    if(closeModal) closeModal();
                    toast.success(t("Le Template a bien été modifié"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onCreate = (Template: TemplateDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(createTemplateThunk(mapId, Template as TemplateCreate,{
                onSuccess: (Template) => {
                    closeModal();
                    toast.success(t("Le Template a bien été créé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onDelete = (template: TemplateDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(deleteTemplateThrunks(mapId, template.id,{
                onSuccess: (success) => {
                    toast.success(t("Le Template a bien été supprimé"))
                    closeModal();
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const columns: TableColumn<TemplateDetails>[] = [
        {
            id:'name',
            name: t('Nom'),
            sortable: true,
            selector: row => row.name,
        },
        {
            id:'categories',
            name: t('Catégories'),
            sortable: true,
            selector: row => row.categories.join(" "),
            cell: (row, index, column, id) =>{
                return <div>
                    {row.categories.map((cat) => <Badge key={cat} className='me-1'>{cat}</Badge>)}
                </div>
            }
        },
        {
            id:'groundColor',
            name: t('Couleur sol'),
            sortable: true,
            selector: row => row.groundColor,
            cell: (row, index, column, id) =>{
                return  <ColorPicker
                    value={row.groundColor}
                    onChange={(color) => onUpdate({... row, groundColor: color})}
                />;
            }
        },
        {
            id:'wallColor',
            name: t('Couleur mur'),
            sortable: true,
            selector: row => row.wallColor,
            cell: (row, index, column, id) =>{
                return  <ColorPicker
                    value={row.wallColor}
                    onChange={(color) => onUpdate({... row, wallColor: color})}
                />;
            }
        },
        {
            id:'topWallColor',
            name: t('Couleur haut mur'),
            sortable: true,
            selector: row => row.topWallColor,
            cell: (row, index, column, id) =>{
                return  <ColorPicker
                    value={row.topWallColor}
                    onChange={(color) => onUpdate({... row, topWallColor: color})}
                />;
            }
        },
        {
            id:'groundHeight',
            name: t('Hauteur sol'),
            sortable: true,
            selector: row => row.groundHeight + "m",
        },
        {
            id:'wallHeight',
            name: t('Hauteur mur'),
            sortable: true,
            selector: row => row.wallHeight + "m",
        },
        {
            id:'wallWidth',
            name: t('Largeur mur'),
            sortable: true,
            selector: row => row.wallWidth + "m",
        },
        {
            id:'dateUpdated',
            name: t('MAJ'),
            sortable: true,
            selector: row => `${row.dateUpdated ? dayjs(row.dateUpdated).unix(): ""}`,
            cell: row => `${row.dateUpdated ? dayjs(row.dateUpdated).format("DD/MM/YYYY HH:mm"): ""}`
        }
    ];


    return <TableWithCrudComponent 
        entities={templates} 
        columns={columns}
        loadingForm={loadingForm}
        loadingList={loadingList}
        onDelete={onDelete}
        onUpdate={onUpdate}
        onCreate={onCreate}
        translations={{
            tableTitle: t("Templates de la carte"),
            noEntityText: t("Aucun template disponible"),
            createTitle: t("Ajouter un template"),
            createButtonText: t("Ajouter un template"),
            deleteText: (entity) => (t("Voulez-vous supprimer le template ?")),
            deleteTitle: (entity) => (t("Supprimer le template ") + entity.name ?? entity.id),
            updateText: (entity) => (t("Modifier le template ")  + entity.name ?? entity.id),
        }}
        fieldSearchable={(entity) => [
            entity.name,
            entity.id
        ]}
        initialValueCreateForm={{
            name: "Nouveau template",
            categories: ["category"],
            groundColor: "#9b896fff",
            topWallColor: "#525252ff",
            wallColor: "#d2d2d2ff",
            groundHeight: 0,
            wallHeight: 1,
            wallWidth: 0.1,
        }as TemplateCreate}
        />;
}

export default AdminClientMapTemplate
