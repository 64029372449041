import React, { useEffect, useState } from 'react'
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sdk from 'fno-sdk';
import IPickingOrder from 'models/IPickingOrder';
import { Accordion, Badge, Col, Row, Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import { IOrderRow } from './AuditPathCompare';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import { useTranslation } from 'react-i18next';
import "./AuditPathCompareRowRender.scss";
import Loading from 'components/Loading';
import _ from 'lodash';
import { PathDetails, PathResult } from 'models/IGlobalOptimizationResult';
import appColors from 'resources/appColors';
import { mapControlsActions } from 'store/reducers/mapControls/mapControls';
import AccordionWithPagination from 'components/AccordionWithPagination';

type Props = {
    sdk: Sdk;
    pathResult: PathResult | undefined;
    loading: boolean;
  };

type PathModel = {
    result: PathDetails;
    ref: string
}

const AuditPathCompareRowRender: React.FC<Props> = ({sdk, pathResult, loading}) => {
    const { t } = useTranslation(nameOf({AuditPathCompareRowRender}), { useSuspense: false});
    const [missionRefSelected, setMissionRefSelected] = useState<string | undefined>(undefined);
    const [showInitial, setShowInitial] = useState(true);
    const [showOptimzed, setShowOptimzed] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const rawData = useAppSelector(state => state.audit.data);
    const [paths, setPaths] = useState<PathModel[]>([]);

    const {
        currentFloor
    } = useAppSelector(state => state.mapControls);

    const dispatch = useAppDispatch();

    const handleClick = async (ref: string | undefined) => {
        sdk?.pathfinding.clearItineraries();
        sdk?.asset.clearHighlights();
        if(!ref) {
            setMissionRefSelected(undefined);
        }
        else if(ref == missionRefSelected)
        {
            setMissionRefSelected(undefined);
            sdk?.asset.clearHighlights();
        }
        else
        {
            const optimizedFound = pathResult?.paths[ref];

            if(optimizedFound)
            {
                const originalPath = await sdk?.pathfinding.setItineraryToAssetsByLocationCode(optimizedFound.original.emplacements, appColors.orange);
                const optimizedPath = await sdk?.pathfinding.setItineraryToAssetsByLocationCode(optimizedFound.optimized.emplacements, appColors.blue);
                toggleSelectedOrderAssets(optimizedFound, true);


                // Change current floor if needed for showing optimized start emplacement
                //const assets = (await sdk?.asset.getAssetDataByLocationCodes(optimizedFound.optimized.emplacements))?.assets ?? [];


                if(originalPath && originalPath.currentFloorId){
                    dispatch(mapControlsActions.setCurrentFloorById(originalPath.currentFloorId))
                }
                else if (optimizedPath && optimizedPath.currentFloorId){
                    dispatch(mapControlsActions.setCurrentFloorById(optimizedPath.currentFloorId))
                }

            }

            setMissionRefSelected(ref);
        }
        
    }

    useEffect(() => {
        setPaths(_.map(pathResult?.paths, (value, ref) => ({
            result: value,
            ref
         } as PathModel)))
    },[pathResult])

    useEffect(() => {

        if(!missionRefSelected) return;

        sdk?.pathfinding.clearItineraries();

        const optimizedFound = pathResult?.paths[missionRefSelected];
        if(!optimizedFound) return;

        if(showInitial){
            sdk?.pathfinding.setItineraryToAssetsByLocationCode(optimizedFound.original.emplacements, appColors.orange);
        }

        if(showOptimzed){
            sdk?.pathfinding.setItineraryToAssetsByLocationCode(optimizedFound.optimized.emplacements, appColors.blue);  
        }
    }, [showInitial, showOptimzed])


    const highlightAssets = async (locationCodes: string[], enabled: boolean) => {
        if(pathResult && missionRefSelected)
        {
            const optimizedFound = pathResult.paths[missionRefSelected];
            if(optimizedFound)
                await toggleSelectedOrderAssets(optimizedFound, !enabled);   
        }

        const assets = (await sdk.asset.getAssetDataByLocationCodes(locationCodes)).assets;
        sdk.asset.setHighlightAssetByIds(assets.map(m => m.id), enabled, appColors.blue)
    }
    

    const toggleSelectedOrderAssets = async (missionPathDetails: PathDetails, enabled: boolean) => {
        
        if(missionPathDetails)
        {
            const allAssets = (await sdk.asset.getAssetDataByLocationCodes(missionPathDetails.original.emplacements)).assets;
            sdk.asset.setHighlightAssetByIds(allAssets.map(m => m.id), enabled, appColors.blue)
        }
    }


    const checkComponent = (enabled: boolean, onClick: () => void) => {
        if(enabled) return <span className='checkbox selected' onClick={onClick}><FontAwesomeIcon icon={["fas", "square-check"]} size="lg"/> </span>;
        else return <span className='checkbox' onClick={onClick}><FontAwesomeIcon icon={["fas", "square"]} size="lg"/></span>
    }


    if(loading)
    {
        return <div className='mt-5'><Loading text={t("Calcul de l'optimisation de chemin en cours")}/></div>;
    }

    if(!loading && !pathResult)
    {
        return <div className='mt-5 text-center'>{t("Aucune donnée disponible")}</div>;
    }


    const pathsFiltered = _.filter(paths, (path) => {
        return searchTerm ? path.ref.toLocaleLowerCase().includes(searchTerm) : true
    });


    const accordionData = pathsFiltered ? _.map(pathsFiltered, (path) => {
        
        return {
            ...path,
            id: path.ref
        }
    }) : [];

    return <>
        <div>
            <input className='w-100 search-input mb-2' placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>
        </div>
        
        <AccordionWithPagination data={accordionData} 
        accordionId='AuditPathCompareRowRender'
        loading={loading}
        renderHeader={(item) => {

            const originalMetric = item.result.original;
            const optimizedMetric = item.result.optimized;

            const oldCost = (originalMetric.totalDistance );
            const newCost = (optimizedMetric.totalDistance);

            const gainpourcent = -((newCost - oldCost) / oldCost) * 100;
            const gainPourcentString = gainpourcent.toFixed(2);

            return <>
            <FontAwesomeIcon icon={["fas", "tag"]} /> 
            <span className='text-truncate' style={{maxWidth: 224}}>{item.ref}</span>
            <Badge className='ms-2' bg="primary">{originalMetric.emplacements.length} {t("Products")}</Badge>
            <Badge className='ms-2' bg="orange"> {gainPourcentString + "%"} {t("Gain")}</Badge>
        </>;
        }} 
        renderBody={(item) => {

            const originalMetric = item.result.original;
            const optimizedMetric = item.result.optimized;

            const oldCost = (originalMetric.totalDistance );
            const newCost = (optimizedMetric.totalDistance);

            const gainpourcent = -((newCost - oldCost) / oldCost) * 100;
            const gainPourcentString = gainpourcent.toFixed(2);

            return <>
                <Row className='text-center mb-3'>
                    <Col><FontAwesomeIcon icon={["fas", "tag"]}/> {item.id}</Col>
                </Row>
                <Table striped bordered hover className={' ' + (showInitial ? "show-initial " : "") + (showOptimzed ? "show-optimized " : "")}>
                    <thead>
                        <tr>
                            <th className=''></th>
                            <th className='initial'>{checkComponent(showInitial, () => setShowInitial(!showInitial))} {t("Original")}</th>
                            <th className='initial'>{t("Poids")}</th>
                            <th className='optimized'>{checkComponent(showOptimzed, () => setShowOptimzed(!showOptimzed))} {t("Optimisé")}</th>
                            <th className='optimized'>{t("Poids")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {originalMetric.emplacements.map((emplacementOrigin, i) => {

                            const emplacementOptim = optimizedMetric.emplacements[i] ?? "";

                            const weightOrigin = rawData ? _.find(rawData.rows, (row) => row.adresse == emplacementOrigin) : undefined;
                            const weightOpti = rawData ? _.find(rawData.rows, (row) => row.adresse == emplacementOptim) : undefined;


                            return <tr key={i}>
                                <td onMouseEnter={() => highlightAssets([emplacementOrigin, emplacementOptim], true)} onMouseLeave={() => highlightAssets([emplacementOrigin, emplacementOptim], false)}>{i + 1}</td>
                                <td className='initial' onMouseEnter={() => highlightAssets([emplacementOrigin], true)} onMouseLeave={() => highlightAssets([emplacementOrigin], false)}>{emplacementOrigin}</td>
                                <td className='initial'>{weightOrigin?.weight}</td>
                                <td className='optimized' onMouseEnter={() => highlightAssets([emplacementOptim], true)} onMouseLeave={() => highlightAssets([emplacementOptim], false)}>{emplacementOptim}</td>
                                <td className='optimized'>{weightOpti?.weight}</td>
                            </tr>
                        })}
                    </tbody>
                    <tfoot>
                    {newCost > 0 &&<tr>
                            <th style={{fontWeight: "normal"}}className=''><FontAwesomeIcon icon={["fas", "flag-checkered"]} /> </th>
                            <th style={{fontWeight: "normal"}}className='text-center initial' colSpan={2}>{oldCost.toLocaleString("fr-FR")} {t("mètres")}</th>
                            <th style={{fontWeight: "normal"}}className='text-center optimized' colSpan={2}>{newCost.toLocaleString("fr-FR")} {t("mètres")}</th>
                    </tr>}
                    {gainPourcentString && gainPourcentString!="NaN" && <tr>
                            <th className=''><FontAwesomeIcon icon={["fas", "percent"]} /> </th>
                            <th className='text-center' colSpan={4}>{parseFloat(gainPourcentString)}%</th>
                    </tr>}
                    </tfoot>
                </Table>
            </>
        }}
        onSelect={(item) => handleClick(item?.id)}/>
        
        </>

}

export default AuditPathCompareRowRender