import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { toast } from 'react-toastify';
import DataTable, { TableColumn } from 'react-data-table-component';
import ColorPicker from 'components/inputs/ColorPicker';
import { ZoneCreate, ZoneDetails, ZoneUpdate } from 'backend/ApiBackofficeDefinition/data-contracts';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import { updateZoneThunk } from 'store/reducers/Admin/ClientMapZone/thrunks/updateZoneThunk';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { createZoneThunk } from 'store/reducers/Admin/ClientMapZone/thrunks/createZoneThunk';
import { deleteZoneThrunks } from 'store/reducers/Admin/ClientMapZone/thrunks/deleteZoneThrunk';
import { loadZonesThunks } from 'store/reducers/Admin/ClientMapZone/thrunks/loadZonesThunks';

const AdminClientMapZone: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapZone}), { useSuspense: false});
    const dispatch = useAppDispatch();

    const {
        loadingList,
        loadingForm,
        zones
    } = useAppSelector(state => state.adminClientMapZone);


    const { clientId, mapId } = useParams();

    useEffect(() => {
        document.title = t('[Admin] Zones de la map');
        if(clientId && mapId && mapId != "new")
        {
            dispatch(loadZonesThunks(mapId));
        }
    }, [clientId, mapId])

    const onUpdate = (Zone: ZoneDetails, closeModal?: () => void) => {
        if(clientId && mapId)
        {
            dispatch(updateZoneThunk(mapId, Zone as ZoneUpdate,{
                onSuccess: (Zone) => {
                    if(closeModal)closeModal();
                    toast.success(t("Le zone a bien été modifié"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onCreate = (Zone: ZoneDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(createZoneThunk(mapId, Zone as ZoneCreate,{
                onSuccess: (Zone) => {
                    closeModal();
                    toast.success(t("Le zone a bien été créé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onDelete = (Zone: ZoneDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(deleteZoneThrunks(mapId, Zone.id,{
                onSuccess: (success) => {
                    toast.success(t("Le zone a bien été supprimé"))
                    closeModal();
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    
    const columns: TableColumn<ZoneDetails>[] = [
        {
            id:'name',
            name: t('name'),
            sortable: true,
            selector: row => row.name,
        },
        {
            id:'labelTemplateId',
            name: t('label template'),
            sortable: true,
            selector: row => row.labelTemplateId,
        },
        {
            id:'floorId',
            name: t('Etage id'),
            sortable: false,
            selector: row => row.floorId ?? "",
        },
        {
            id:'color',
            name: t('Couleur'),
            sortable: true,
            selector: row => row.color,
            cell: (row, index, column, id) =>{
                    return  <ColorPicker
                    value={row.color}
                    onChange={(color) => onUpdate({... row, color: color})}
                />;
            }
        },
        {
            id:'groundHeight',
            name: t('Hauteur'),
            center:true,
            selector: row => row.groundHeight,
        },
        {
            id:'points',
            name: t('Nb points'),
            center:true,
            selector: row => row.points.length,
        },
        {
            id:'version',
            name: t('Version'),
            center:true,
            selector: row => row.version,
        }
    ];

    return <TableWithCrudComponent 
        entities={zones} 
        columns={columns}
        loadingForm={loadingForm}
        loadingList={loadingList}
        onDelete={onDelete}
        onUpdate={onUpdate}
        onCreate={onCreate}
        translations={{
            tableTitle: t("Zones de la carte"),
            noEntityText: t("Aucun zone disponible"),
            createTitle: t("Ajouter un zone"),
            createButtonText: t("Ajouter un zone"),
            deleteText: (entity) => (t("Voulez-vous supprimer le zone ?")),
            deleteTitle: (entity) => (t("Supprimer le zone ") + entity.name ?? entity.id),
            updateText: (entity) => (t("Modifier le zone ")  + entity.name ?? entity.id),
        }}
        fieldSearchable={(entity) => [
            entity.name,
            entity.id
        ]}
        initialValueCreateForm={{
            name: "Nouvelle zone",
            mapId: mapId ?? "",
            color: "",
            floorId: "",
            position: {
                "x": 0,
                "y": 0,
                "z": 0
              },
            points: [ {
                "x": 0,
                "y": 0,
                "z": 0
              }]
        }as ZoneCreate}
        />;


    /*return (
        <div>
        <div>
            <div className='table-component'>
                {<DataTable 
                    columns={columns} 
                    data={datableValues} 
                    defaultSortAsc={true} 
                    progressPending={loading}
                    progressComponent={<Loading text={t("Chargement")}/>}
                    defaultSortFieldId={'id'} 
                    pagination
                    striped={true}
                    subHeader={true}
                    highlightOnHover={true}
                    noDataComponent={<div className='w-100 text-center p-5'>{t("Aucune zone disponible")}</div>}
                    subHeaderComponent={<> 
                        <h5 className='text-uppercase'> {t("Les zones") + " ("+mapZones.length+")"} {loading && <Loading inline size={20}/>}</h5>
                        <div className='search-panel'>
                            <ButtonFno sm className='me-2' onClick={() => setShowCreateForm(true)}><FontAwesomeIcon className='me-2' icon={["fas", "plus"]} /> {t("Créer une zone")}</ButtonFno>
                            <input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>
                        </div>
                        </>}
                    sortServer={false}/>}
            </div>
        </div>

        {selectedMapZone && <Modal dialogClassName='modal-fno' show={true} onHide={() => setSelectedMapZone(undefined)}>
            <Modal.Header closeButton>
                <Modal.Title>{t("Modifier la zone")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditJsonRaw onCancel={() => setSelectedMapZone(undefined)} onSubmit={onUpdateZone} value={selectedMapZone} loading={loadingUpdate}/>
            </Modal.Body>
        </Modal>}

        {showCreateForm && <Modal dialogClassName='modal-fno' show={true} onHide={() => setShowCreateForm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Créer une zone")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditJsonRaw onCancel={() => setShowCreateForm(false)} onSubmit={onCreateZone} value={{
                        name: "Nouvelle zone",
                        mapId: mapId ?? "",
                        color: "",
                        position: {
                            "x": 0,
                            "y": 0,
                            "z": 0
                          },
                        points: [ {
                            "x": 0,
                            "y": 0,
                            "z": 0
                          }]
                    }} loading={loadingUpdate}/>
                </Modal.Body>
            </Modal>}

            {selectedEntityToDelete && <Modal dialogClassName='modal-fno' show={true} onHide={() => setSelectedEntityToDelete(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Supprimer la zone") + " " + selectedEntityToDelete.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DeleteConfirmForm onCancel={() => setSelectedEntityToDelete(undefined)} 
                                        onSubmit={() => onDeleteEntity(selectedEntityToDelete)} 
                                        text={t("Êtes vous sûre de vouloir supprimer cette zone ?")}
                                        loading={loadingUpdate}/>
                </Modal.Body>
            </Modal>}
    </div>
    )*/
}

export default AdminClientMapZone