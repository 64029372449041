/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ApiErrorsResponse,
    BatchingColumnBindingProperty,
    BatchingRawFileCreateBaseBatchingRawFileColumnsBinding,
    BatchingRawFileCreateResult,
    BatchingRawFileDetails,
    DownloadFileType,
    PagedListResultBatchingRawFileDetails,
    StorageDownloadLinkResult
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Batching<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Batching
     * @name StartBatchingForFile
     * @request POST:/batching/map/{mapId}/batching/{fileId}/run
     * @secure
     */
    startBatchingForFile = (
        mapId: string,
        fileId: string,
        query?: {
            /** @default false */
            notifyByMail?: boolean
        },
        params: RequestParams = {}
    ) =>
        this.request<BatchingRawFileDetails, ApiErrorsResponse>({
            path: `/batching/map/${mapId}/batching/${fileId}/run`,
            method: 'POST',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Batching
     * @name SearchBatchingRawFiles
     * @request GET:/batching/map/{mapId}/files
     * @secure
     */
    searchBatchingRawFiles = (
        mapId: string,
        query: {
            mapId?: string
            /** @format int32 */
            page: number
            /** @format int32 */
            pageSize: number
            sortColumn?: string
            sortDirection?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<PagedListResultBatchingRawFileDetails, any>({
            path: `/batching/map/${mapId}/files`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Batching
     * @name CreateBatchingRawFile
     * @request POST:/batching/map/{mapId}/batching
     * @secure
     */
    createBatchingRawFile = (mapId: string, data: BatchingRawFileCreateBaseBatchingRawFileColumnsBinding, params: RequestParams = {}) =>
        this.request<BatchingRawFileCreateResult, ApiErrorsResponse>({
            path: `/batching/map/${mapId}/batching`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Batching
     * @name SetBatchingRawFileUploaded
     * @request POST:/batching/map/{mapId}/batching/{fileId}/uploaded
     * @secure
     */
    setBatchingRawFileUploaded = (mapId: string, fileId: string, params: RequestParams = {}) =>
        this.request<BatchingRawFileDetails, ApiErrorsResponse>({
            path: `/batching/map/${mapId}/batching/${fileId}/uploaded`,
            method: 'POST',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Batching
     * @name GetBatchingRawFileDownloadUrl
     * @request GET:/batching/map/{mapId}/batching/{fileId}/download
     * @secure
     */
    getBatchingRawFileDownloadUrl = (
        mapId: string,
        fileId: string,
        query?: {
            fileType?: DownloadFileType
        },
        params: RequestParams = {}
    ) =>
        this.request<StorageDownloadLinkResult, ApiErrorsResponse>({
            path: `/batching/map/${mapId}/batching/${fileId}/download`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Batching
     * @name DeleteBatchingRawFile
     * @request DELETE:/batching/map/{mapId}/batching/{fileId}
     * @secure
     */
    deleteBatchingRawFile = (mapId: string, fileId: string, params: RequestParams = {}) =>
        this.request<void, ApiErrorsResponse>({
            path: `/batching/map/${mapId}/batching/${fileId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
    /**
     * No description
     *
     * @tags Batching
     * @name GetFileColumnsBinding
     * @request GET:/batching/map/{mapId}/columns/binding
     * @secure
     */
    getFileColumnsBinding = (mapId: string, params: RequestParams = {}) =>
        this.request<BatchingColumnBindingProperty[], ApiErrorsResponse>({
            path: `/batching/map/${mapId}/columns/binding`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
}
