/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HeatMapPointDetails } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class HeatMapGenerate<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags HeatMapGenerate
     * @name GenerateFromPaths
     * @summary [🤵 OR 🔑] Generate heat map points from heatmap path. [🔒: map_read].
     * @request GET:/map/{mapId}/heatmap/generate/paths
     * @secure
     */
    generateFromPaths = (
        mapId: string,
        query: {
            /** @format date-time */
            from: string
            /** @format date-time */
            to: string
            /** Type of points to return */
            category?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<HeatMapPointDetails[], void>({
            path: `/map/${mapId}/heatmap/generate/paths`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags HeatMapGenerate
     * @name GenerateScaleFromPaths
     * @summary [🤵 OR 🔑] Generate heat map scales from heatmap path. [🔒: map_read].
     * @request GET:/map/{mapId}/heatmap/generate/scale/paths
     * @secure
     */
    generateScaleFromPaths = (
        mapId: string,
        query: {
            /** @format date-time */
            from: string
            /** @format date-time */
            to: string
            /** Type of points to return */
            category?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<number[], void>({
            path: `/map/${mapId}/heatmap/generate/scale/paths`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
}
