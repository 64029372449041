import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getCurrentTenant from 'backend/utils/getCurrentTenant';
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import sdk, { Asset } from 'fno-sdk';
import useFnoMapSdk from 'hooks/useFnoMapSdk';
import React, { useEffect, useRef, useState } from 'react'
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import AssetSelectedComponent from './AssetSelectedComponent';
import MapRender from 'components/mapControls/MapRender';
import appColors from 'resources/appColors';
import { locationCodeUsed } from './TimcodeDemoData';


const _skdTag = "TimcodeMapTagManager"

const TimcodeMapTagManager: React.FC = () => {
   const { t } = useTranslation(nameOf({TimcodeMapTagManager}), { useSuspense: false});
   const currentMap = useAppSelector(state => state.map.currentMap);
   const {
    sdk,
    loading,
    mapReady
} = useFnoMapSdk(getCurrentUserToken(), getCurrentTenant()?.tenant ?? "", currentMap?.id ?? "");

    const [assetSelected, setAssetSelected] = useState<Asset | undefined>();
    // ref for getting value inside unity sdk callback (ex: registerOnHoverLeaveAsset)
    const _assetSelected = useRef<Asset | undefined>();

   const dispatch = useAppDispatch();

   useEffect(() => {
        return () => {
            sdk.asset.unRegisterOnHoverAsset(_skdTag);
            sdk.asset.unRegisterOnHoverLeaveAsset(_skdTag);
            sdk.asset.unRegisterOnSelectAsset(_skdTag);
            sdk.asset.unRegisterOnUnselectAsset(_skdTag);
            sdk.asset.clearHighlights();
            sdk.pathfinding.clearItineraries();
            toggleVusionTagAssets(false);
        }
   }, [])

   useEffect(() => {
        if(mapReady)
        {

            sdk.asset.registerOnHoverAsset((asset) => {
                sdk.asset.setHighlightAssetById(asset.asset.id, true);
            }, _skdTag);

            sdk.asset.registerOnHoverLeaveAsset((asset) => {
                if(_assetSelected.current?.id != asset.asset.id)
                {
                    sdk.asset.setHighlightAssetById(asset.asset.id, false);
                    toggleVusionTagAssets(true);
                }
            }, _skdTag);

            sdk.asset.registerOnSelectAsset((asset) => {
                sdk.asset.setHighlightAssetById(asset.asset.id, true);
                setAssetSelected(asset.asset as unknown as Asset)
            }, _skdTag)

            sdk.asset.registerOnUnselectAsset((asset) => {
                sdk.asset.setHighlightAssetById(asset.asset.id, false);
                setAssetSelected(undefined)
            }, _skdTag);

            toggleVusionTagAssets(true);

        }
    }, [mapReady])




    useEffect(() => {
        _assetSelected.current = assetSelected;
    }, [assetSelected])


    const toggleVusionTagAssets = async (enabled: boolean) => {
        const assets = (await sdk.asset.getAssetDataByLocationCodes(locationCodeUsed.map(m => m.locationCode))).assets;
        sdk.asset.setHighlightAssetByIds(assets.map(m => m.id), enabled, "#21d8de");
    };



   return (
        <div id="statistic-picking-optimization-path">
            {<PageHeader title={t('Editeur de carte')}/>}
            <Row style={{height: "100%"}}>
                <Col sm="8">
                    <MapRender 
                        sdk={sdk} 
                        mapReady={mapReady}
                        assetToolipConfig={{
                            highlightColor: appColors.orange
                        }}/>

                </Col>
                <Col id="col-table-container" sm="4">
                    {mapReady && !loading && <div>
                        <div>
                            {sdk && assetSelected && <AssetSelectedComponent asset={assetSelected}/>}

                            {sdk && !assetSelected && <>
                                <h4 className='text-center'>{t("Sélectionner un emplacement sur la carte")}</h4>
                            </>}
                        </div>
                    </div>}
                    {!mapReady && <div className='loading-map-panel'><Loading text={t("Chargement des données")}/></div>}
                </Col>
            </Row>
        </div>
    )
}
export default TimcodeMapTagManager