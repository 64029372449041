import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/PageHeader';
import nameOf from 'utility/nameOf';

const AdminDashboard: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminDashboard}), { useSuspense: false});

    useEffect(() => {
        document.title = t('[Admin] Dashboard');
    }, [])

    
    return (
        <div>
             <PageHeader title={t("Dashboard")} admin/>
            <div>
                Backoffice admin
            </div>
        </div>
    )
}

export default AdminDashboard
