import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AccountInfos, ClientItem, LanguageItem, MiddlewareApiDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import IUserProfil from "models/IUserProfil";

export interface IUserProfilStore {
  currentUser: IUserProfil | undefined;
  culture: string;
  currentTenant: ClientItem | undefined;
  loadingUserData: boolean;
  userInfos: AccountInfos | undefined;
  languages: LanguageItem[];
}

const initialState: IUserProfilStore = {
  currentUser: undefined,
  userInfos: undefined,
  culture: 'fr',
  currentTenant: undefined,
  loadingUserData: false,
  languages: []
};

const userProfilSlice = createSlice({
  name: 'userProfil',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<IUserProfil | undefined>) => {
      state.currentUser = action.payload;
    },
    setLogout: (state) => {
      state.currentUser = undefined;
      state.userInfos = undefined;
      state.culture = 'fr';
      state.currentTenant = undefined;
      state.loadingUserData = false;
      state.languages = [];
    },
    setLoadingUserData: (state, action: PayloadAction<boolean>) => {
      state.loadingUserData = action.payload;
    },
    setCurrentTenant: (state, action: PayloadAction<ClientItem | undefined>) => {
      state.currentTenant = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<AccountInfos>) => {
      state.userInfos = action.payload;
    },
    setCulture: (state, action: PayloadAction<string>) => {
      state.culture = action.payload;
    },
    setLanguages: (state, action: PayloadAction<LanguageItem[]>) => {
      state.languages = action.payload;
    },

  },
});

export const { actions: userProfilActions, reducer: userProfilReducer } = userProfilSlice;