/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, UserCreateAdmin, UserDetailsAdmin, UserDetailsAdminCreated, UserResetPasswordModel, UserUpdateAdmin } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class User<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags User
     * @name UsersList
     * @summary [🤵] Return all app users.
     * @request GET:/admin/users
     * @secure
     */
    usersList = (params: RequestParams = {}) =>
        this.request<UserDetailsAdmin[], void>({
            path: `/admin/users`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags User
     * @name UserPost
     * @summary [🤵] Create new user.
     * @request POST:/admin/user
     * @secure
     */
    userPost = (data: UserCreateAdmin, params: RequestParams = {}) =>
        this.request<UserDetailsAdminCreated, ApiErrorsResponse | void>({
            path: `/admin/user`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags User
     * @name UserPut
     * @summary [🤵]
     * @request PUT:/admin/user
     * @secure
     */
    userPut = (data: UserUpdateAdmin, params: RequestParams = {}) =>
        this.request<UserDetailsAdmin, ApiErrorsResponse | void>({
            path: `/admin/user`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags User
     * @name UserPasswordResetPost
     * @summary [🤵]
     * @request POST:/admin/user/password/reset
     * @secure
     */
    userPasswordResetPost = (data: UserResetPasswordModel, params: RequestParams = {}) =>
        this.request<UserDetailsAdminCreated, ApiErrorsResponse | void>({
            path: `/admin/user/password/reset`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
}
