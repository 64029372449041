import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiErrorModel from "backend/utils/apiErrorModel";
import apiPickingDefinition from "backend/apiPickingDefinition";


export const reconnectRealtimeRunnerThrunk = (runnerId: string): AppThunk => {
    return async (dispatch, getState) => {
       try{

            await apiPickingDefinition.runnerManagement.reconnectRunner(runnerId)
                .then((result) => {
                    if(result.data)
                    {
                        toast.success(i18next.t("Le runner a bien été connecté"))
                    }
                    else
                    {
                        toast.error(i18next.t("Impossible de reconnecter le runner"))
                    }
                })
                .catch((apiResponse: apiErrorModel) => {
                    toast.error(i18next.t("Impossible de reconnecter le runner"))
                });
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la reconnexion du runner"))
        }
    }
}