import _ from "lodash";
import { AppThunk } from "store/store";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiMiddlewareDefinition from "backend/apiMiddlewareDefinition";
import { middlewareBatchingActions } from "../middlewareBatchingSlice";




/**
 * Thunk action creator for start compute batching optimization in the middleware
 * @param baseUrl - Base url of the middleware
 * @param tenant - Tenant to use for middleware api calls
 * @param mapId - The ID of the map
 * @param fileId - The ID of the file to delete
 * @param notifyByMail - If true, send a mail to author when batching result is ready
 * @param callbacks - Callbacks for success and error handling
 * @returns An AppThunk function
 */
export const runBatchingForRawFileThunk = (
    baseUrl: string,
    tenant: string,
    mapId: string,
    fileId: string,
    notifyByMail: boolean,
    callbacks: ThrunkApiCallBacks<boolean>
): AppThunk => {
    return async (dispatch, getState) => {
        // Delete audit raw file
        const result = await apiMiddlewareDefinition(baseUrl, tenant).batching.startBatchingForFile(mapId, fileId, {
            notifyByMail: notifyByMail
        });
        if (!result.ok) {
            // Call onError callback with API error response
            callbacks.onError(result.error as apiErrorModel);
        } else {
            dispatch(middlewareBatchingActions.updateBatchingRawFile(result.data));
            // Call onSuccess callback with true indicating successful deletion
            callbacks.onSuccess(true);
        }
    };
};
