import getCurrentTenant from "./getCurrentTenant";
import getCurrentUserToken from "./getCurrentUserToken";

/**
 * Helper for create api class instance
 * @returns 
 */
export default function getApiConfig(tenant?: string, token?: string) {
    return {
        baseUrl: process.env.REACT_APP_API_URL,
        baseApiParams: {
            secure: true
        },
        securityWorker() {
    
            const tokenToUse = token ?? getCurrentUserToken();
            const headers: {[key: string]: any} = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
            const tenantToUse = tenant ?? getCurrentTenant()?.tenant;
            
            if(tenantToUse)
            {
                headers["tenant"] = tenantToUse;
            }

            if(tokenToUse)
                headers.Authorization = "Bearer " +tokenToUse;

            return {
                headers
            }
        }
    }
}