import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { 
  BatchingColumnBindingProperty,
  BatchingRawFileDetails, 
  PagedListResultBatchingRawFileDetails, 
  StatisticsByDay, 
  StatisticsByDayBatching} from "backend/ApiMiddlewareDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

export interface IMiddlewareBatchingStore {
  /** The list of column to define when creating a new batching optimization */
  batchingColumnBindings: BatchingColumnBindingProperty[];
  globalStatisticsByDay: StatisticsByDayBatching[];
  loadingList: boolean;
  loadingForm: boolean;
  batchingFiles: PagedListResultBatchingRawFileDetails | undefined;
  batchingFileSelected: BatchingRawFileDetails | undefined;
}

const initialState: IMiddlewareBatchingStore = {
  batchingColumnBindings: [],
  globalStatisticsByDay: [],
  loadingList: false,
  loadingForm: false,
  batchingFiles: undefined,
  batchingFileSelected: undefined
};

/**
 * Manage all batching feature from a middleware
 */
const middlewareBatchingSlice = createSlice({
  name: "middlewareBatchingSlice",
  initialState,
  reducers: {
    setGlobalStatisticsByDay: (state, action: PayloadAction<StatisticsByDay[]>) => {
      state.globalStatisticsByDay = action.payload;
    },
    setLoadingList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    setLoadingForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
    setBatchingRawFiles: (state, action: PayloadAction<PagedListResultBatchingRawFileDetails | undefined>) => {
      state.batchingFiles = action.payload;
    },
    setBatchingColumnBindings: (state, action: PayloadAction<BatchingColumnBindingProperty[]>) => {
      state.batchingColumnBindings = action.payload;
    },
    setBatchingFileSelected: (state, action: PayloadAction<BatchingRawFileDetails | undefined>) => {
      state.batchingFileSelected = action.payload;
    },
    updateBatchingRawFile: (state, action: PayloadAction<BatchingRawFileDetails>) => {
      if (state.batchingFiles) {
        state.batchingFiles = {
          ...state.batchingFiles,
          items: state.batchingFiles.items.map((item) => {
            if (item.id === action.payload.id) {
              return action.payload;
            } else {
              return item;
            }
          })
        };
      }
    }
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state = initialState;
    });
  },
});

// Export the actions and reducer from the admin client map asset slice
export const {
  actions: middlewareBatchingActions,
  reducer: middlewareBatchingReducer,
} = middlewareBatchingSlice;
