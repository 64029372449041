import { Operation, TokenDetails } from "backend/ApiAdminDefinition/data-contracts";
import apiAdminDefinition from "backend/apiAdminDefinition";
import apiErrorModel from "backend/utils/apiErrorModel";
import i18next from "i18next";
import _ from "lodash";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { toast } from "react-toastify";
import { adminClientTokenActions } from "store/reducers/Admin/ClientToken/adminClientTokenSlice";
import { AppThunk } from "store/store";


/**
 * Enable or disable a token
 * @param tokenId 
 * @param enabled 
 * @returns 
 */
export const updateTokenEnableThunk = (tokenId: string, enabled: boolean, callbacks: ThrunkApiCallBacks<TokenDetails>): AppThunk => {
    return async (dispatch, getState) => {




        const token =  getState().adminClientToken.tokens.find((p) => p.id == tokenId);

        if(!token)
        {
            toast.error(i18next.t("Le token n'existe pas"))
            return
        }


        const tokenToSave = _.cloneDeep(token);
        tokenToSave.active = enabled;


        // save new permission state before api call for refresh interface
        dispatch(adminClientTokenActions.updateToken(tokenToSave));

        apiAdminDefinition.token.clientTokenPatch(tokenId, 
            [{op:"replace", path: "/active", value: enabled.toString()} as Operation])
            .then((result) => {
                callbacks.onSuccess(result.data);
            })
            .catch((apiResponse: apiErrorModel) => {
                //reverse changes
                dispatch(adminClientTokenActions.updateToken(token));
                callbacks.onError(apiResponse);
            });
    }
}
