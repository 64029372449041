import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientMapAssetActions } from "../adminClientMapAssetSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Delete an asset
 * @returns 
 */
export const deleteAssetThrunks = (mapId: string, assetId: string, callbacks: ThrunkApiCallBacks<boolean>): AppThunk => {
    return async (dispatch, getState) => {
       try{

            dispatch(adminClientMapAssetActions.setLoadingAssetForm(true));
            await apiBackofficeDefinition.asset.assetDelete(mapId, assetId)
                .then((result) => {
                    dispatch(adminClientMapAssetActions.deleteAsset(assetId))
                    callbacks.onSuccess(true);
                })
                .catch((apiResponse: apiErrorModel) => {
                    callbacks.onError(apiResponse);
                })
                .finally(() => dispatch(adminClientMapAssetActions.setLoadingAssetForm(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la suppression de l'asset"))
        }
    }
}