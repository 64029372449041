import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientMapLabelActions } from "../adminClientMapLabelSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Delete a label
 * @returns 
 */
export const deleteLabelThrunks = (mapId: string, labelId: string, callbacks: ThrunkApiCallBacks<boolean>): AppThunk => {
    return async (dispatch, getState) => {
       try{

            dispatch(adminClientMapLabelActions.setLoadingLabelForm(true));
            await apiBackofficeDefinition.label.labelDelete(mapId, labelId)
                .then((result) => {
                    dispatch(adminClientMapLabelActions.deleteLabel(labelId))
                    callbacks.onSuccess(true);
                })
                .catch((apiResponse: apiErrorModel) => {
                    callbacks.onError(apiResponse);
                })
                .finally(() => dispatch(adminClientMapLabelActions.setLoadingLabelForm(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la suppression du label"))
        }
    }
}