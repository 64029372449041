import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import * as yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import { Formik, FormikHelpers } from 'formik';
import dayjs from 'dayjs';
import DatePicker from "react-datepicker";
import "./DatesRangeForm.scss";
import useOutsideClick from 'hooks/useOutsideClick ';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

 export interface IDatesRangeFormInputs{
    from: Date;
    to: Date;
}

interface OwnProps{
    from: Date;
    to: Date;
    onDateChange: (from: Date, to: Date) => void;
}


/**
 * Component for selecting a date range.
 * @param from - The initial start date of the range.
 * @param to - The initial end date of the range.
 * @param onDateChange - Callback function called when the date range changes.
 * @returns A form with a date picker.
 */
const DatesRangeForm: React.FC<OwnProps> = ({from, to, onDateChange}) => {
    const { t } = useTranslation([nameOf({DatesRangeForm}), "TransverseError"], { useSuspense: false});

    const [calendarOpen, setCalendarOpen] = React.useState(false);

    const ref = useOutsideClick(() => {
        setCalendarOpen(false);
      });
      
    const onSubmit = async (values: IDatesRangeFormInputs, {setFieldError}:FormikHelpers<IDatesRangeFormInputs>) => {
        onDateChange(values.from, values.to);
        setCalendarOpen(false);
    };

    const schema = yup.object().shape({
        from: yup.date().required(t("required", { ns:"TransverseError"}) as string),
        to: yup.date().required(t("required", { ns:"TransverseError"}) as string),
      });

     

    return (
        <div ref={ref} className='DatesRangeFormContainer d-flex align-items-center' onClick={() => setCalendarOpen(true)}>
            <div className='icon'><FontAwesomeIcon icon={["fas", "calendar-days"]} /></div>
            <Formik
                validationSchema={schema}
                onSubmit={onSubmit}
                
                initialValues={{
                    from: from,
                    to: to,
                } as IDatesRangeFormInputs}>
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    values,
                    touched,
                    isValid,
                    isSubmitting,
                    errors,
                    submitForm
                }) => {
                    
                    const CustomInput = forwardRef(() => {

                        const totalDaysPeriode = dayjs(values.to).diff(dayjs(values.from), 'day') + 1;

                        let label = `${dayjs(values.from).format("DD/MM/YYYY")} ${t('au')} ${values.to ? dayjs(values.to).format("DD/MM/YYYY") : ""}`;

                        if(dayjs(values.to).isSame(dayjs(), "day")) label = `${totalDaysPeriode} ${t('derniers jours')}`

                        return <div className='w-100'>{label}</div>
                    });

                    const setDatesAndSubmit = (from: Date | null, to: Date | null) => {

                        setFieldValue("from", from)
                        setFieldValue("to", to);
                        if(from && to) {
                            // Hack to force submit form. TODO: find sexiest way to do it
                            setTimeout(() => submitForm(), 100)
                        }
                    }


                    return (
                        <div className="DatesRangeForm">
                                    <div>
                                        <Form.Group>
                                            <label style={{cursor: 'pointer'}}>{t('Période')}</label>
                                            {<DatePicker
                                                className='datePickerInput'
                                                startDate={values.from ? values.from : undefined}
                                                endDate={values.to ? values.to: undefined}
                                                selectsRange
                                                
                                                customInput={<CustomInput />}
                                                onChange={(dates) => {
                                                    const [start, end] = dates;
                                                    setDatesAndSubmit(start, end);
                                                }}
                                                locale="fr"
                                                dateFormat={"dd/MM/yyyy"}
                                                excludeDateIntervals={[{start:dayjs().toDate(), end: dayjs().add(9999, 'years').toDate()}]}
                                                placeholderText={t('Période')}
                                                open={calendarOpen}
                                                onCalendarClose={() => setCalendarOpen(false)}> 
                                                    <Row>
                                                        <Col sm={12} className='mb-2'><ButtonFno className='w-100' sm color='orange' onClick={() => setDatesAndSubmit(dayjs().add(-6, 'day').toDate(), dayjs().toDate())} >{t('7 derniers jours')}</ButtonFno></Col>
                                                        <Col sm={12} className='mb-2'><ButtonFno className='w-100' sm color='orange' onClick={() => setDatesAndSubmit(dayjs().add(-29, 'day').toDate(), dayjs().toDate())} >{t('30 derniers jours')}</ButtonFno></Col>
                                                        <Col sm={12}><ButtonFno className='w-100' sm color='orange' onClick={() => setDatesAndSubmit(dayjs().add(-59, 'day').toDate(), dayjs().toDate())} >{t('60 derniers jours')}</ButtonFno></Col>
                                                    </Row>
                                                </DatePicker>}
                                        </Form.Group>
                                    </div>
                            </div>
                        )
                }}
                </Formik> 
        </div>
    )
}

export default DatesRangeForm
