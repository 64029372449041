import { AppThunk } from "store/store"
import { auditActions } from "store/reducers/Audit/auditSlice";
import { SelectAuditDataThunk } from "./SelectAuditDataThunk";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";
import _ from "lodash";


/**
 * Load audit data for current map
 * @returns 
 */
export const LoadAuditDataThunk = (mapId: string): AppThunk => {
    return async (dispatch, getState) => {

        try{
            dispatch(auditActions.setLoadingList(true));
            const auditRawFiles = await apiBackofficeDefinition.auditFile.auditRawFilesList(mapId)
            dispatch(auditActions.setLoadingList(false));
            dispatch(auditActions.setRawFiles(auditRawFiles.data));
            // select first by default
            dispatch(SelectAuditDataThunk(auditRawFiles.data.length > 0 ? _.orderBy(auditRawFiles.data, ['dateCreated'], ['desc']) [0] : undefined))
        }
        catch(error: any)
        {
            /*if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des données d'audit"))*/
            console.error("[LoadAuditDataThunk] error", error)
        }
        finally
        {
            dispatch(auditActions.setLoadingList(false));
        }
    }
}