import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store'
import nameOf from 'utility/nameOf';
import "./LayoutMapDetails.scss"
import PageHeader from 'components/PageHeader';
import { ROUTES } from 'resources/routes-constants';
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSignalRHub from 'hooks/useSignalRHub';
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import getCurrentTenant from 'backend/utils/getCurrentTenant';

type Props = {
    children?: React.ReactNode
  };

const LayoutMapDetails: React.FC<Props> = ({children}) => {
    const { t } = useTranslation(nameOf({LayoutMapDetails}), { useSuspense: false});
    const { clientId, mapId } = useParams();
    const selectedClient = useAppSelector(state => state.userProfil.currentTenant);
    const allPlaces = useAppSelector(state => state.adminClientMap.maps);
    const dispatch = useAppDispatch();

 //   // TODO modifier dans le store les entités de la map qui on changé
 //   const mapChangesHub = useSignalRHub( `${process.env.REACT_APP_API_URL}/live/map/update?token=${getCurrentUserToken()}&tenant=${getCurrentTenant()?.tenant}&mapId=${mapId}`, {
 //       onConnected: (hub) => {
 //           if(mapId != "new" && selectedClient){
 //               /*hub.invoke("RegisterMapChanges", selectedClient.tenant, mapId)
 //               .then((result) => {
 //                   console.log("result => ", result); 
 //               })*/
 //           hub.on("Asset_Update", (metadata: any, data: string) =>  console.log("Asset_Update !! ", metadata, data));
 //           hub.on("Template_Update", (metadata: any, data: string) =>  console.log("Template_Update !! ", metadata, data));
 //           }
 //       },
 //       enabled: true,
 //       automaticReconnect: true,
 //       httpTransportTypeOrOptions: {accessTokenFactory: getCurrentUserToken}
 //     })
    
    const currentPlace = mapId != "new" ? allPlaces.find(m => m.id.toString() == mapId) : undefined

    useEffect(() => {
    }, [])

    if(!selectedClient)
    {
        return <Navigate to={ROUTES.ADMIN.CLIENTS} replace />
    }

    return (<div className='layout-map-details'>
        <PageHeader title={currentPlace ? currentPlace.name : t("Nouveau lieu")} admin middlePart={[
                {to: ROUTES.ADMIN.CLIENTS, title: t("Client")},
                {to: ROUTES.ADMIN.CLIENT(clientId).DETAILS, title: selectedClient.name}]}/>
            <div className='content'>
                {children}
            </div>
        
    </div>)
}

export default LayoutMapDetails
