import React, { useEffect } from 'react'
import  { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { loadNavmeshThunks } from 'store/reducers/Admin/ClientMapNavmesh/thrunks/loadNavmeshThunks';
import { useAppDispatch, useAppSelector } from 'store/store';
import { updateNavmeshThunk } from 'store/reducers/Admin/ClientMapNavmesh/thrunks/updateNavmeshThunk';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { deleteMarkerThrunks } from 'store/reducers/Admin/ClientMapMarker/thrunks/deleteMarkerThrunks';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import { NavmeshDetails, NavmeshUpdate } from 'backend/ApiBackofficeDefinition/data-contracts';
import { deleteNavmeshThrunks } from 'store/reducers/Admin/ClientMapNavmesh/thrunks/deleteNavmeshThrunk';

const AdminClientMapNavmesh: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapNavmesh}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const { clientId, mapId } = useParams();
    
    const {
        loadingList,
        loadingForm,
        navmeshes
    } = useAppSelector(state => state.adminMapNavmesh);


    useEffect(() => {
        document.title = t('[Admin] Les navmeshes');
        if(clientId && mapId && mapId != "new")
        {
            dispatch(loadNavmeshThunks(mapId))
        }


    }, [mapId])


    const onUpdate = (navmesh: NavmeshDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            if(clientId && mapId)
            {
                dispatch(updateNavmeshThunk(mapId, navmesh as NavmeshUpdate,{
                    onSuccess: () => {
                        closeModal();
                        toast.success(t("La navmesh a bien été modifiée"))
                    },
                    onError: (error) => {
                        parseApiErrorToast(error);
                    }
                }))
            }
        }
    }

    const onDelete = (navmesh: NavmeshDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(deleteNavmeshThrunks(mapId, navmesh.id,{
                onSuccess: (success) => {
                    toast.success(t("La navmesh a bien été supprimée"))
                    closeModal();
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const columns: TableColumn<NavmeshDetails>[] = [
        {
            id:'name',
            name: t('Nom'),
            sortable: true,
            selector: row => row.name,
        },
        {
            id:'storagePathNavmesh',
            name: t('Chemin storage'),
            sortable: true,
            selector: row => `${row.storagePathNavmesh }`,
            cell: row => `${row.storagePathNavmesh }`
        },
        {
            id:'dateUpdated',
            name: t('MAJ'),
            sortable: true,
            selector: row => `${row.dateUpdated ? dayjs(row.dateUpdated).unix(): ""}`,
            cell: row => `${row.dateUpdated ? dayjs(row.dateUpdated).format("DD/MM/YYYY HH:mm"): ""}`
        }
    ];

    return <TableWithCrudComponent 
        entities={navmeshes} 
        columns={columns}
        loadingForm={loadingForm}
        loadingList={loadingList}
        onDelete={onDelete}
        onUpdate={onUpdate}
        translations={{
            tableTitle: t("Navmeshs de la carte"),
            noEntityText: t("Aucune navmesh disponible"),
            createTitle: t("Ajouter une navmesh"),
            createButtonText: t("Ajouter une navmesh"),
            deleteText: (entity) => (t("Voulez-vous supprimer la navmesh ?")),
            deleteTitle: (entity) => (t("Supprimer la navmesh ") + entity.name ?? entity.id),
            updateText: (entity) => (t("Modifier la navmesh ")  + entity.name ?? entity.id),
        }}
        fieldSearchable={(entity) => [
            entity.name,
            entity.id
        ]}
    />;
}

export default AdminClientMapNavmesh
