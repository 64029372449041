import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from "react-switch";
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store'
import nameOf from 'utility/nameOf';
import StatisticCard from 'components/StatisticCard';
import { Col, Row } from 'react-bootstrap';
import _ from 'lodash';
import { selectCurrentMapThrunks } from 'store/reducers/Map/thrunks/selectCurrentMapThrunks';
import { MapDetails } from 'backend/ApiBackofficeDefinition/data-contracts';

const Dashboard: React.FC = () => {
    const { t } = useTranslation(nameOf({Dashboard}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const [searchTerm, setSearchTerm] = useState('');

    const auditRawData = useAppSelector(state => state.audit.data);
    const loadingAuditRawFile = useAppSelector(state => state.audit.loadingList);
    const loadingOptimizationResult = useAppSelector(state => state.audit.loadingOptimizationResult);
    const auditSettingSelected = useAppSelector(state => state.audit.auditRawFileSelected);
    const optimizationResult = useAppSelector(state => state.audit.optimizationResult);

    const allMaps = useAppSelector(state => state.map.maps) || [];
    const selectedMap = useAppSelector(state => state.map.currentMap);

    useEffect(() => {
        document.title = t('Dashboard');
    }, [])


    const columns: TableColumn<MapDetails>[] = [
        {
            width:"50px",
            center:true,
            cell: (row, index, column, id) =>{
                return <div>
                    {row.id == selectedMap?.id ? 
                        <span className='checkbox selected'><FontAwesomeIcon icon={["fas", "square-check"]} size="lg"/> </span>:
                        <span className='checkbox' onClick={() => dispatch(selectCurrentMapThrunks(row))}><FontAwesomeIcon icon={["fas", "square"]} size="lg"/></span>}
                </div>
            }
        },
        {
            id:'id',
            width:"70px",
            name: t('Id'),
            sortable: true,
            selector: row => row.idShort,
        },
        {
            id:'name',
            name: t('Nom'),
            sortable: true,
            selector: row => row.name.toLocaleLowerCase(),
            cell: row => <div className='w-100' onClick={() => dispatch(selectCurrentMapThrunks(row))}>{row.name}</div>,
        },
        {
            id:'audit',
            name: t('Nb audits'),
            sortable: true,
            selector: row => row.totalAudits
        },
        {
            id:'maintenance',
            width:"150px",
            center:true,
            name: <div className='text-center'>{t('Mode maintenance')}</div>,
            sortable: true,
            selector: row => row.isUnderMaintenance,
            cell: row => <div>
                 <Switch checked={row.isUnderMaintenance} onChange={(val) => {
                    console.log("todo set isUnderMaintenance = ", val)
                }}/>
            </div>,
            },
        {
            name:<div>{t('Actions')}</div>,
            center:true,
            width:"150px",
            cell: (row, index, column, id) =>{
                return <div>
                </div>
            }
        }
    ];


    const placesFiltered = searchTerm ? allMaps.filter((place) => {
        return place.name.toLowerCase().includes(searchTerm)/* ||
                place.address?.toLowerCase().includes(searchTerm) */
    }) : allMaps



    
    let nbMission: number | undefined = undefined;
    let nbOrders: number | undefined = undefined;
    let nbProducts: number | undefined = undefined;
    let nbDeplacement: number | undefined = undefined;
    let moneyWinOptimizationPath: number | undefined = undefined;
    let oldDistancePath: number | undefined = undefined;
    let newDistancePath: number | undefined = undefined;

    if(auditSettingSelected && auditRawData)
    {
        nbMission = _.size(_.unionBy(auditRawData.rows, auditRawData.fields[auditSettingSelected.columnsBindingData.fieldMission] ));
        nbOrders = _.size(_.unionBy(auditRawData.rows, auditRawData.fields[auditSettingSelected.columnsBindingData.fieldSupport]));
        if(auditSettingSelected.columnsBindingData.fieldMission)
        {
            nbProducts = _.sumBy(auditRawData.rows, (item) => {
                const fieldQuantity = auditRawData.fields[auditSettingSelected.columnsBindingData.fieldQuantity];
                const quantity = parseInt(item[fieldQuantity]);
                if(quantity) return quantity;
                else return 0;
                
            });
        }
        
        nbDeplacement = auditRawData.rows.length;
    }

    if(optimizationResult)
    {
        newDistancePath = _.sumBy(_.map(optimizationResult.final.missions, (value, key) => {
            return value.totalDistance
        }));

        oldDistancePath = _.sumBy(_.map(optimizationResult.path.paths, (value, key) => {
            return value.original.totalDistance
        }));

        moneyWinOptimizationPath = Math.abs(Math.trunc(((oldDistancePath - newDistancePath) / 2500) * 29.48));
    } 


    return (
        <div className='page'>
            <PageHeader title={t("Dashboard") }/>
            
            <Row>
                {/* Disable 16/04/2024 for Arthur (slack)
                moneyWinOptimizationPath && <Col><StatisticCard title={t("Economisé/période")} color='orange' value={moneyWinOptimizationPath.toLocaleString("fr-FR")} icon='euro' loading={loadingOptimizationResult}/></Col>*/}
                {nbMission && <Col><StatisticCard title={t("Missions/période")} color='blue' value={nbMission.toLocaleString("fr-FR")} icon='boxes-stacked'  loading={loadingAuditRawFile}/></Col>}
                {nbOrders && <Col><StatisticCard title={t("Commandes/période")} color='orange' value={nbOrders.toLocaleString("fr-FR")} icon='box'   loading={loadingAuditRawFile}/></Col>}
                {nbProducts && <Col><StatisticCard title={t("Produits/période")} color='blue' value={nbProducts.toLocaleString("fr-FR")} icon='barcode'   loading={loadingAuditRawFile}/></Col>}
                {nbDeplacement && <Col><StatisticCard title={t("Déplacements/période")} color='orange' value={nbDeplacement.toLocaleString("fr-FR")} icon='location-dot'   loading={loadingAuditRawFile}/></Col>}
            </Row>


            <div className='table-component'>
               
                <DataTable 
                    columns={columns} 
                    data={placesFiltered} 
                    defaultSortAsc={true} 
                    progressPending={false}
                    progressComponent={<Loading text={t("Chargement")}/>}
                    defaultSortFieldId={'name'} 
                    striped={true}
                    subHeader={true}
                    highlightOnHover={true}
                    noDataComponent={<div className='w-100 text-center p-5'>{t("Aucun lieu disponible")}</div>}
                    subHeaderComponent={<> 
                        <h5 className='text-uppercase'> {t("Mes lieux") + " ("+allMaps.length+")"}</h5>
                        <div className='search-panel'>
                            <input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>
                        </div>
                        </>}
                    sortServer={false}/>
            </div>
        </div>
    )
}

export default Dashboard
