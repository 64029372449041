import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiErrorModel from "backend/utils/apiErrorModel";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { ZoneDetails, ZoneUpdate} from "backend/ApiBackofficeDefinition/data-contracts";
import { adminClientMapZoneActions } from "../adminClientMapZoneSlice";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Thunk action creator for updating an Zone
 * @param mapId 
 * @param Zone 
 * @param callbacks 
 * @returns 
 */
export const updateZoneThunk = (
    mapId: string,
    Zone: ZoneUpdate,
    callbacks: ThrunkApiCallBacks<ZoneDetails>
  ): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapZoneActions.setLoadingZoneForm(true));
  
        // Make API call to create Zone
        await apiBackofficeDefinition.zone.zonePut(mapId, Zone.id ,Zone)
          .then((result) => {
            // Dispatch action to add newly created Zone to store
            dispatch(adminClientMapZoneActions.updateZone(result.data));
            // Call onSuccess callback
            callbacks.onSuccess(result.data);
          })
          .catch((apiResponse: apiErrorModel) => {
            // Call onError callback with API error response
            callbacks.onError(apiResponse);
          });
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors de la modification du Zone"));
          callbacks.onError({ errors:{"api":[error.message]} } as apiErrorModel)
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapZoneActions.setLoadingZoneForm(false));
      }
    };
  };