import _ from 'lodash';
import React  from 'react';
import "./ButtonFno.scss";


interface OwnProps {
    color?: 'blue' | 'orange' | 'gray';
    children?: React.ReactNode;
    type?: "button" | "submit" | "reset";
    disabled?: boolean;
    className?: string;
    loading?: boolean;
    onClick?: () => void;
    sm?:boolean;
}


/**
 * React functional component that renders a button with optional loading state, color customization, and size adjustment.
 *
 * @param {OwnProps} props - The component props.
 * @param {string} [props.color='blue'] - The color of the button.
 * @param {React.ReactNode} [props.children] - The content of the button.
 * @param {"button" | "submit" | "reset"} [props.type="button"] - The type of the button.
 * @param {boolean} [props.sm=false] - Flag indicating if the button should be displayed in small size.
 * @param {boolean} [props.loading=false] - Flag indicating if the button is in loading state.
 * @param {boolean} [props.disabled=false] - Flag indicating if the button is disabled.
 * @param {string} [props.className] - Additional CSS class(es) to apply to the button.
 * @param {() => void} [props.onClick] - The function to call when the button is clicked.
 * @return {JSX.Element} The rendered button component.
 */
const ButtonFno: React.FC<OwnProps> = ({children, color = 'blue', type = "button", sm = false, loading = false,
                    disabled=false, className = undefined, onClick = undefined}) => {

    const bgClass = 'bg-' + color;


    return (<button className={`btn-fno ${bgClass} ${className} ${sm ? "sm": ""}`} type= {type} disabled={disabled || loading} onClick={onClick}>
        {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> } {children}
    </button>)
}

export default ButtonFno
