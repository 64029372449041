import React, { useEffect, useState } from 'react'
import "./ZoomComponent.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sdk from 'fno-sdk';


interface OwnProps { 
    sdk: Sdk;
}

const ZoomComponent: React.FC<OwnProps> = ({sdk}) => {
    useEffect(() => {
        if(sdk)
        {
            sdk.camera.getZoom().then((result) => {
                console.log("getZoom", result.zoomStep)
            });
        }

    }, [sdk])

    if(!sdk) return <></>; 
    
    return (
        <div className='zoom-component'>
            <div className='btn-zoom btn-round' onClick={() => sdk.camera.zoomIn()}><FontAwesomeIcon icon={["fas", "plus"]} /></div>
            <div className='btn-zoom' onClick={() => sdk.camera.resetZoom()}>Reset</div>
            <div className='btn-zoom btn-round' onClick={() => sdk.camera.zoomOut()}><FontAwesomeIcon icon={["fas", "minus"]} /></div>
        </div>
    )
}

export default ZoomComponent
