import { store } from 'store/store';

/**
 * Get tenant from client id
 * @param clientId 
 * @returns 
 */
export default function getTenantFromClientId(clientId: number| string){

    const clientIdInt =  typeof clientId === "string" ? parseInt(clientId) : clientId;
    return store.getState().adminClient.allClients.find(m => m.id == clientIdInt)?.tenant
}