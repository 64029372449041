import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store'
import nameOf from 'utility/nameOf';
import Loading from 'components/Loading';
import { Badge, Button, Col, Form, Row } from 'react-bootstrap';
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import getCurrentTenant from 'backend/utils/getCurrentTenant';
import "./AuditPathCompare.scss";
import _ from 'lodash';
import AuditPathCompareRowRender from './AuditPathCompareRowRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useFnoMapSdk from 'hooks/useFnoMapSdk';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import appColors from 'resources/appColors';
import MapRender from 'components/mapControls/MapRender';
import computeGainPourcent from 'utility/computeGainPourcent';
import checkPermissions from 'utility/checkPermissions';
import { PermissionsEnum } from 'backend/ApiBackofficeDefinition/data-contracts';

export interface IOrderRow{
    ref: string;
    products: {
        ean: string;
        emplacement: string;
        quantity: string;
    }[];
    searchField: string;
}



const AuditPathCompare: React.FC = () => {
    const { t } = useTranslation(nameOf({AuditPathCompare}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    const currentMap = useAppSelector(state => state.map.currentMap);
    const loadingRawFile = useAppSelector(state => state.audit.loadingList);
    const loadingOptimizationResult = useAppSelector(state => state.audit.loadingOptimizationResult);
    const auditSettingSelected = useAppSelector(state => state.audit.auditRawFileSelected);
    const auditOptimizationResults = useAppSelector(state => state.audit.optimizationResult);
    const {
        sdk,
        mapReady
    } = useFnoMapSdk(getCurrentUserToken(), getCurrentTenant()?.tenant ?? "", currentMap?.id ?? "");
    
    const [rowSelected, setRowSelected] = useState<IOrderRow | undefined>(undefined);
    const [rows, setRows] = useState<IOrderRow[]>([]);
    const rawData = useAppSelector(state => state.audit.data);


    useEffect(() => {
        document.title = t('Optimisation de chemin');
        return () => {
            clearItineraries();
        }
    }, [])


    useEffect(() => {
        if(!checkPermissions(PermissionsEnum.AuditList))
        {
            navigate(ROUTES.DASHBOARD)
        }
    }, [currentMap])

    useEffect(() => {
        if(rawData && auditSettingSelected)
        {
            const group: IOrderRow[] = _.map(_.groupBy(rawData.rows, rawData.fields[auditSettingSelected?.columnsBindingData.fieldMission] ), (items, key) => {
                return {
                    ref: key,
                    products: [
                        {ean:"start", emplacement:"start", quantity:1},
                        ...items.map((product) => {
                            return {
                                ean: product[rawData.fields[auditSettingSelected?.columnsBindingData.fieldEan]] as string,
                                emplacement: product[rawData.fields[auditSettingSelected?.columnsBindingData.fieldEmplacement]] as string,
                                quantity: product[rawData.fields[auditSettingSelected?.columnsBindingData.fieldQuantity]] as string
                            }
                        }),
                        {ean:"end", emplacement:"end", quantity:1}
                    ],
                    // merge all fields in one for search purpose
                    searchField: _.join(_.values(items), " ").toLocaleLowerCase() 
                }  as IOrderRow
            });

            setRows(group);
        
        }
        else{
            setRows([]);
        }
    }, [rawData, auditSettingSelected])


    useEffect(() => {
        if(!rowSelected)
        {
            clearItineraries();
        }
        else
        {
            showItinerary(rowSelected);
        }
    }, [rowSelected])



    const showItinerary = async (order: IOrderRow) => {
        

        if(sdk.pathfinding)
        {
            const itieraries = await sdk?.pathfinding.setItineraryToAssetsByLocationCode(order.products.map(item => item.emplacement), appColors.blue);
            await sdk?.floor.setFloors([itieraries.currentFloorId]);
        }
    }


    const clearItineraries = async () => {
        if(sdk.pathfinding)
            await sdk?.pathfinding.clearItineraries();
    }


    // compute global gain pourcent
    const oldCost = _.sum(_.map(auditOptimizationResults?.path.paths, (path) => path.original.totalDistance));
    const newCost = _.sum(_.map(auditOptimizationResults?.path.paths, (path) => path.optimized.totalDistance));

    //const gainpourcent = -((newCost - oldCost) / oldCost) * 100;
    const gainPourcentString = computeGainPourcent(oldCost, newCost).toString();


    return (
        <div id="statistic-picking-optimization-path">
             {<PageHeader title={t('Digital twin')}/>}
             <Row style={{height: "100%"}}>
                <Col sm="8">
                    <MapRender 
                        sdk={sdk} 
                        mapReady={mapReady}
                        assetToolipConfig={{
                            highlightColor: appColors.blue
                        }}/>
                </Col>
                <Col id="col-table-container" sm="4">
                    {mapReady && !loadingRawFile && <div>
                        <h4>
                            {t("Missions")} 
                            {<Badge  className='ms-2 fs-6' bg="orange">{`${t("Gain moyen")} ${gainPourcentString}%`}</Badge>}
                            <Button size='sm' variant='link' title={t('Centrer caméra')} className='ms-1 float-end' onClick={() => sdk?.user.moveCameraToUserPosition()}>
                                <FontAwesomeIcon icon={["fas", "bullseye"]} />
                            </Button>
                        </h4>
                        <div>
                            {sdk && <>
                            <AuditPathCompareRowRender 
                                        sdk={sdk} 
                                        loading={loadingOptimizationResult}
                                        pathResult={auditOptimizationResults?.path} />
                            </>}
                        </div>
                    </div>}
                    {loadingRawFile && <div className='loading-map-panel'><Loading text={t("Chargement des données")}/></div>}
                </Col>
             </Row>
        </div>
    )
}

export default AuditPathCompare
