import { toast } from "react-toastify";

/**
 * copy into the user clipboard the data and show popup with confirmation message
 * @param data 
 * @param message 
 */
export default function copyToClipboard(data: string, message: string | undefined = undefined){
    navigator.clipboard.writeText(data)
        .then(() => message ? toast.success(message): "")
        .catch(() => toast.error("Impossible de copier la data"));
    
}