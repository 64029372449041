import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sdk from 'fno-sdk';
import { Badge, Col, Row, Table } from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import { useTranslation } from 'react-i18next';
import "./AuditFinalCompareRowRender.scss";
import Loading from 'components/Loading';
import _ from 'lodash';
import { MissionDetails } from 'models/IGlobalOptimizationResult';
import appColors from 'resources/appColors';
import { mapControlsActions } from 'store/reducers/mapControls/mapControls';
import { IOrderRow } from './AuditFinalResult';
import AccordionWithPagination from 'components/AccordionWithPagination';

type Props = {
    sdk: Sdk;
    finalOrders: Record<string, MissionDetails>
    onClick: (order: IOrderRow | undefined) => void;
    onHoverEnter: (order: IOrderRow) => void;
    onHoverLeave: (order: IOrderRow) => void;
  };

const AuditFinalCompareRowRender: React.FC<Props> = ({sdk, finalOrders}) => {
    const { t } = useTranslation(nameOf({AuditFinalCompareRowRender}), { useSuspense: false});
    const dispatch = useAppDispatch();

    const [missionRefSelected, setMissionRefSelected] = useState<string | undefined>(undefined);
    const loading = useAppSelector(state => state.audit.loadingList);
    const rawData = useAppSelector(state => state.audit.data);
    const {
        currentFloor
    } = useAppSelector(state => state.mapControls);

    const handleClick = async (missionRef: string | undefined) => {
        sdk?.pathfinding.clearItineraries();
        sdk?.asset.clearHighlights();
        if(!missionRef) {
            setMissionRefSelected(undefined);
        }
        else if(missionRef == missionRefSelected)
        {
            setMissionRefSelected(undefined);
            sdk?.asset.clearHighlights();
        }
        else
        {
            const mission = finalOrders[missionRef];
            if(mission)
            {
                await sdk?.pathfinding.setItineraryToAssetsByLocationCode(mission.emplacement, appColors.blue);

                //sdk.asset.setHighlightAssetById
                setMissionRefSelected(missionRef);
                toggleSelectedOrderAssets(mission.emplacement, true);

                // Change current floor if needed for showing optimized start emplacement
                const assets = (await sdk?.asset.getAssetDataByLocationCodes(mission.emplacement))?.assets ?? [];

                if(assets && assets.length > 0 && currentFloor && assets[0].floorId != currentFloor.id){
                    dispatch(mapControlsActions.setCurrentFloorById(assets[0].floorId))
                }
            }
        }
        
    }

    let optimizedFound: MissionDetails | undefined
    if(missionRefSelected)
    {
        optimizedFound = finalOrders[missionRefSelected];
    }
    
    

    const highlightAssets = async (locationCodes: string[], enabled: boolean) => {

        if(finalOrders && missionRefSelected)
        {
            const optimizedFound = finalOrders[missionRefSelected];
            if(optimizedFound)
                await toggleSelectedOrderAssets(optimizedFound.emplacement, !enabled);   
        }
        //await toggleSelectedOrderAssets(locationCodes, !enabled);

        const assets = (await sdk.asset.getAssetDataByLocationCodes(locationCodes)).assets;
        sdk.asset.setHighlightAssetByIds(assets.map(m => m.id), enabled, appColors.blue);
    }
    

    const toggleSelectedOrderAssets = async (locationCodes: string[], enabled: boolean) => {
        
        const allAssets = (await sdk.asset.getAssetDataByLocationCodes(locationCodes)).assets;
        sdk.asset.setHighlightAssetByIds(allAssets.map(m => m.id), enabled, appColors.blue);
    }



    if(loading)
    {
        return <div className='mt-5'><Loading text={t("Calcul de l'optimisation de chemin en cours")}/></div>;
    }

    if(!loading && !finalOrders)
    {
        return <div className='mt-5 text-center'>{t("Aucune donnée disponible")}</div>;
    }



    const accordionData = finalOrders ? _.map(_.toPairs(finalOrders), ([key, value]) => {
        return {
            ...value,
            id: key
        }
    }) : [];

    return <>
        <AccordionWithPagination data={accordionData} 
        accordionId='AuditFinalCompareRowRender'
        loading={loading}
        renderHeader={(item) => <>
            <FontAwesomeIcon icon={["fas", "tag"]} /> 
            {item.id}
            <Badge className='ms-2' bg="primary">{item.emplacement ? item.emplacement.length : 0} {t("Products")}</Badge>
        </>} 
        renderBody={(item) => {
            return <>
                <Row className='text-center mb-3'>
                    <Col><FontAwesomeIcon icon={["fas", "tag"]}/> {item.id}</Col>
                </Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th className=''></th>
                            <th>{t("Emplacement")}</th>
                            <th >{t("Support")}</th>
                            <th >{t("Poids")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {item.emplacement.map((emplacement, i) => {

                            const optimizedSupports = item.supports[i] ?? "";
                            const weight = rawData ? _.find(rawData.rows, (row) => row.adresse == emplacement) : undefined;

                            return <tr key={i}>
                                <td onMouseEnter={() => highlightAssets([emplacement, optimizedSupports], true)} onMouseLeave={() => highlightAssets([emplacement, optimizedSupports], false)}>{i + 1}</td>
                                <td onMouseEnter={() => highlightAssets([emplacement], true)} onMouseLeave={() => highlightAssets([emplacement], false)}>{emplacement}</td>
                                <td><Badge className='ms-2' bg="primary">{optimizedSupports}</Badge></td>
                                <td>{weight?.weight}</td>
                            </tr>
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th style={{fontWeight: "normal"}} className='' colSpan={1}><FontAwesomeIcon icon={["fas", "flag-checkered"]} /> </th>
                            <th style={{fontWeight: "normal"}} className='text-center initial' colSpan={3}>{(item.totalDistance ?? 0).toLocaleString("fr-FR")} {t("mètres")}</th>
                        </tr>
                    </tfoot>
                </Table>
            </>
        }}
        onSelect={(item) => handleClick(item?.id)}/>
    </>
}

export default AuditFinalCompareRowRender