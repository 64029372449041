import { AnyAction, combineReducers, configureStore, ThunkAction  } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import storage from 'redux-persist/lib/storage'
import {userProfilReducer} from './reducers/Profil/userProfilSlice'
import {loginReducer} from './reducers/Login/loginSlice'
import {auditReducer} from './reducers/Audit/auditSlice'
import {middlewarePickingReducer} from './reducers/MiddlewarePicking/middlewarePickingSlice'
import {mapReducer} from './reducers/Map/mapSlice'
import {adminUserReducer} from './reducers/Admin/User/adminUserSlice'
import {adminClientReducer} from './reducers/Admin/Client/adminClientSlice'
import {adminClientMapReducer} from './reducers/Admin/ClientMap/adminClientMapSlice'
import {adminTranslationsReducer} from './reducers/Admin/Translation/adminTranslationsSlice'
import {adminClientFileManagerReducer} from './reducers/Admin/FileManager/adminClientFileManagerSlice'
import {adminClientPermissionReducer} from './reducers/Admin/ClientPermission/adminClientPermissionSlice'
import {adminClientRolesReducer} from './reducers/Admin/ClientRole/adminClientRolesSlice'
import {adminClientPickingReducer} from './reducers/Admin/ClientPicking/adminClientPickingSlice'
import {adminClientTokenReducer} from './reducers/Admin/ClientToken/adminClientTokenSlice'
import {adminGlobalReducer} from './reducers/Admin/Global/adminGlobalReducer'
import {adminRealtimeLogReducer} from './reducers/Admin/RealtimeLog/adminRealtimeLogSlice'
import { adminClientMapAssetReducer } from './reducers/Admin/ClientMapAsset/adminClientMapAssetSlice'
import { adminClientMapBuildingReducer } from './reducers/Admin/ClientMapBuilding/adminClientMapBuildingSlice'
import { adminClientMapFloorReducer } from './reducers/Admin/ClientMapFloor/adminClientMapFloorSlice'
import { adminClientMapMarkerReducer } from './reducers/Admin/ClientMapMarker/adminClientMapMarkerSlice'
import { adminClientMapMarkerTemplateReducer } from './reducers/Admin/ClientMarkerTemplate/adminClientMapMarkerTemplateSlice'
import { adminClientMapLabelReducer } from './reducers/Admin/ClientMapLabel/adminClientMapLabelSlice'
import { adminClientMapMetadataReducer } from './reducers/Admin/ClientMapMetadata/adminClientMapMetadataSlice'
import { adminClientMapPolygonReducer } from './reducers/Admin/ClientMapPolygon/adminClientMapPolygonSlice'
import { adminClientMapNavmeshReducer } from './reducers/Admin/ClientMapNavmesh/adminClientMapNavmeshSlice'
import { adminClientMapOffMeshLinkReducer } from './reducers/Admin/ClientOffMeshLink/adminClientMapOffMeshLinkSlice'
import { adminClientAuditOptimizationSettingReducer } from './reducers/Admin/ClientMapPickingSetting/adminClientMapOptimizationSettingSlice'
import { adminClientMapTemplateReducer } from './reducers/Admin/ClientMapTemplate/adminClientMapTemplateSlice'
import { adminClientMapZoneReducer } from './reducers/Admin/ClientMapZone/adminClientMapZoneSlice'
import { adminMiddlewareReducer } from './reducers/Admin/RealtimeMiddleware/adminMiddlewareSlice'
import { adminClientMapSettingReducer } from './reducers/Admin/ClientMapSettings/adminClientMapSettingsSlice'
import { adminMiddlewareRequestReducer } from './reducers/Admin/MiddlewareRequests/middlewareRequestSlice'
import { mapControlsReducer } from './reducers/mapControls/mapControls'
import { adminClientMapLabelTemplateReducer } from './reducers/Admin/ClientMapLabelTemplate/adminClientMapLabelTemplateSlice'
import { timcodeReducer } from 'pages/Demos/Timcode/store/timcodeStore'
import { menuDatesSelectorReducer } from './reducers/MenuDatesSelector/menuDatesSelectorSlice'
import { middlewareBatchingReducer } from './reducers/MiddlewareBatching/middlewareBatchingSlice'
import { middlewareApisReducer } from './reducers/MiddlewareApis/middlewareApisSlice'



const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['userProfil', 'map', 'adminClient', 'middlewarePicking'], // elements that will be persisted
    blacklist: [] // elements that will not be persisted
}

const rootReducer = combineReducers({
    userProfil: userProfilReducer,
    login: loginReducer,
    map: mapReducer,
    audit: auditReducer,
    middlewarePicking: middlewarePickingReducer,
    middlewareBatching: middlewareBatchingReducer,
    middlewareApis: middlewareApisReducer,
    mapControls: mapControlsReducer,

    adminUser: adminUserReducer,
    adminClient: adminClientReducer,
    adminClientMap: adminClientMapReducer,
    adminTranslations: adminTranslationsReducer,
    adminClientFileManager: adminClientFileManagerReducer,
    adminClientPermission: adminClientPermissionReducer,
    adminClientPicking: adminClientPickingReducer,
    adminClientRole: adminClientRolesReducer,
    adminClientToken: adminClientTokenReducer,
    adminRealtimeLog: adminRealtimeLogReducer,
    adminRealtimeMiddleware: adminMiddlewareReducer,
    adminMiddlewareRequest: adminMiddlewareRequestReducer,
    adminGlobal: adminGlobalReducer,
    // for map management
    adminMapAsset: adminClientMapAssetReducer,
    adminMapBuilding: adminClientMapBuildingReducer,
    adminMapFloor: adminClientMapFloorReducer,
    adminMapMarker: adminClientMapMarkerReducer,
    adminMapMarkerTemplate: adminClientMapMarkerTemplateReducer,
    adminClientMapLabel:adminClientMapLabelReducer,
    adminClientMapLabelTemplate:adminClientMapLabelTemplateReducer,
    adminMapMetadata: adminClientMapMetadataReducer,
    adminMapPolygon: adminClientMapPolygonReducer,
    adminMapNavmesh: adminClientMapNavmeshReducer,
    adminOffmeshLink: adminClientMapOffMeshLinkReducer,
    adminOptimizationSetting: adminClientAuditOptimizationSettingReducer,
    adminClientMapTemplate: adminClientMapTemplateReducer,
    adminClientMapZone: adminClientMapZoneReducer,
    adminClientMapSetting: adminClientMapSettingReducer,

    menuDatesSelector: menuDatesSelectorReducer,

    // For demo purpose
    timcodeDemo: timcodeReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer:persistedReducer,
    middleware:(getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
      })
})

const persistor = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export { store, persistor }

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>
