import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store";
import apiErrorModel from "backend/utils/apiErrorModel";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { BuildingDetails, BuildingUpdate } from "backend/ApiBackofficeDefinition/data-contracts";
import { adminClientMapBuildingActions } from "../adminClientMapBuildingSlice";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";

/**
 * Thunk action creator for updating a building
 * @param mapId - The ID of the map containing the building to be updated
 * @param building - The updated building data
 * @param callbacks - Callbacks for handling success and error scenarios
 * @returns An AppThunk function
 */
export const updateBuildingThunk = (
    mapId: string,
    building: BuildingUpdate,
    callbacks: ThrunkApiCallBacks<BuildingDetails>
  ): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapBuildingActions.setLoadingBuildingForm(true));
  
        // Make API call to update building
        await apiBackofficeDefinition.building.buildingPut(mapId, building.id, building)
          .then((result) => {
            // Dispatch action to update building in store
            dispatch(adminClientMapBuildingActions.updateBuilding(result.data));
            // Call onSuccess callback
            callbacks.onSuccess(result.data);
          })
          .catch((apiResponse: apiErrorModel) => {
            // Call onError callback with API error response
            callbacks.onError(apiResponse);
          });
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Error updating the building"));
          // Call onError callback with error message
          callbacks.onError({ errors: { "api": [error.message] } } as apiErrorModel)
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapBuildingActions.setLoadingBuildingForm(false));
      }
    };
  };
