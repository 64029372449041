import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import { ROUTES } from 'resources/routes-constants';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import MapForm, { IMapFormInputs } from 'components/Admin/forms/MapForm';
import { FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { updateClientMapThunk } from 'store/reducers/Admin/ClientMap/thrunks/updateClientMapThunk';
import parseApiErrorSetFieldError from 'utility/parseApiErrorSetFieldError';
import { MapDetails } from 'backend/ApiBackofficeDefinition/data-contracts';

const AdminClientMapEdit: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation(nameOf({AdminClientMapEdit}), { useSuspense: false});
    const allPlaces = useAppSelector(state => state.adminClientMap.maps);
    const loadingPlaces = useAppSelector(state => state.adminClientMap.loadingList);
    const loadingForm = useAppSelector(state => state.adminClientMap.loadingForm);
    const navigate = useNavigate();
    const { clientId, mapId } = useParams();



    useEffect(() => {
        document.title = t('[Admin] Modifier une carte');
    }, [])


    if(!clientId) {
        return <Navigate to={ROUTES.ADMIN.CLIENTS} state={{ from: location }} replace />
    }


    const onSubmit = async (values: IMapFormInputs, {setFieldError}:FormikHelpers<IMapFormInputs>) => {

        dispatch(updateClientMapThunk(values as MapDetails,{
            onSuccess(result) {
                toast.success(t("La carte a bien été modifiée"))
                navigate(ROUTES.ADMIN.CLIENT(clientId).MAPS)
            },
            onError(error) {
                parseApiErrorSetFieldError(error, setFieldError);
            },
        }))

    };

    
    const currentPlace = mapId != "new" ? allPlaces.find(m => m.id.toString() == mapId) : undefined

    return (
        <div>
            <div>
                <div className='table-component'>
                    {loadingPlaces ? 
                        <Loading text={t("Chargement")}/>: 
                        <MapForm initialValues={currentPlace as MapDetails} loading={loadingForm} onSubmit={onSubmit} onCancel={() => navigate(ROUTES.ADMIN.CLIENT(clientId).MAPS)}/>}
                </div>
            </div>
        </div>
    )
}

export default AdminClientMapEdit
