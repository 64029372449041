import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import * as yup from 'yup';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import { Formik, FormikHelpers } from 'formik';
import Switch from "react-switch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadFileForm from './UploadFileForm';
import { ContainerDetails } from 'backend/ApiAdminDefinition/data-contracts';
import ConfirmForm from 'components/ConfirmForm';
import { useAppSelector } from 'store/store';
import HelperTooltip from 'components/HelperTooltip';
import _ from 'lodash';



interface OwnProps {
    initialValues?: {
        name: string;
        enabled: boolean;
    };
    loading: boolean;
    onSubmit: ((values: PickingConfigurationFormInputs, formikHelpers: FormikHelpers<PickingConfigurationFormInputs>) => void | Promise<any>) & ((values: PickingConfigurationFormInputs, { setFieldError }: FormikHelpers<PickingConfigurationFormInputs>) => void | Promise<void>);
    onCancel: () => void
 }

 export interface PickingConfigurationFormInputs{
    name: string;
    enabled: boolean;
    matrixFile: File;
    matrixComputeFile: File,
    dictionnaryFile: File;
    pointsFile: File;
}


/**
 * Form component for managing picking configuration data.
 * 
 * @param onSubmit Callback function for form submission.
 * @param onCancel Callback function for form cancellation.
 * @param loading Indicates if the form is currently loading.
 * @param initialValues Initial values for the form.
 * @returns The picking configuration form component.
 */
const PickingConfigurationForm: React.FC<OwnProps> = ({onSubmit, onCancel, loading, initialValues = undefined}) => {
    const { t } = useTranslation([nameOf({PickingConfigurationForm}), "TransverseError"], { useSuspense: false});

    const [showConfirmForm, setShowConfirmForm] = useState(false);
    const currentUser = useAppSelector(state => state.userProfil.userInfos);

    const schema = yup.object().shape({
        name: yup.string().required(t("required", { ns:"TransverseError"}) as string),
        matrixFile:yup.mixed().required(t("required", { ns:"TransverseError"}) as string),
        matrixComputeFile:yup.mixed().required(t("required", { ns:"TransverseError"}) as string),
        //.test('fileFormat', 'PDF only', (value) => {return value && ['application/pdf'].includes(value.type);}),
        dictionnaryFile:yup.mixed().required(t("required", { ns:"TransverseError"}) as string),
        pointsFile:yup.mixed().required(t("required", { ns:"TransverseError"}) as string),
      });


    return (
        <div>
                <Formik
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    initialValues={{
                        enabled: initialValues?.enabled ?? false,
                        name: initialValues?.name ?? "",
                    } as PickingConfigurationFormInputs}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        isSubmitting,
                        setFieldValue,
                        errors,
                    }) => {



                        const noFileSelected = !values.matrixFile && !values.matrixComputeFile && !values.dictionnaryFile && !values.pointsFile;


                        return (
                            <fieldset disabled={loading}>
                                    
                                    <Row>
                                        <Col>
                                            <Form.Control type="hidden" name="id" value={values.name}></Form.Control>
                                                <Form.Group className='mb-3' controlId="validationFormik03">
                                                    <Form.Label>{t("Nom de la configuration")}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        disabled={loading}
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        isValid={touched.name && !errors.name}
                                                        isInvalid={!!errors.name}
                                                    />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik03">
                                                <Form.Label>{t("Activer à la création")}</Form.Label>
                                                <div>
                                                <Switch checked={values.enabled} disabled={isSubmitting} onChange={(enabled) => {
                                                        setFieldValue("enabled", enabled)
                                                    }}/>
                                                </div>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.enabled}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>


                                    {noFileSelected && <Row className='mt-3'>
                                        <Col sm={12}>
                                            <Form.Group className='mb-3' controlId="validationFormik03">
                                                <Form.Label><b>{t("Importer les 4 fichiers ici")}</b> <HelperTooltip text={t("Vous pouvez importer les 4 fichiers d'une configuration d'un coup. Les noms des fichiers doivent contenir les mots 'calcul', 'distance', 'client' et 'position' pour pouvoir les retrouver")} /></Form.Label>
                                                
                                                <UploadFileForm size='sm' loading={loading} onSubmit={(files) => {
                                                    _.each(files, (file) => {
                                                        if(_.toLower(file.name).includes("calcul")) setFieldValue("matrixComputeFile", file);
                                                        else if(_.toLower(file.name).includes("distance")) setFieldValue("matrixFile", file);
                                                        else if(_.toLower(file.name).includes("client")) setFieldValue("dictionnaryFile", file);
                                                        else if(_.toLower(file.name).includes("position")) setFieldValue("pointsFile", file);
                                                    })
                                                }}/>

                                            </Form.Group>
                                        </Col>

                                        <Col sm={12}>
                                            <div className='text-center'><b>{t("Ou importer les 4 fichiers séparément:")}</b></div>
                                        </Col>
                                   </Row>}


                                   <Row className='mt-4'>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik03">
                                                <Form.Label><b>{t("Fichier matrice de distances")}</b></Form.Label>
                                                {values.matrixFile ? 
                                                <div><Button size='sm' variant='danger' className='ms-1' onClick={() => setFieldValue("matrixFile", undefined)}><FontAwesomeIcon icon={["fas", "trash"]} /></Button> {values.matrixFile.name}</div>: 
                                                <UploadFileForm size='sm' loading={loading} onSubmit={(files) => setFieldValue("matrixFile", files[0])}/>}

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.matrixFile? t("required", { ns:"TransverseError"}) as string : ""}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik03">
                                                <Form.Label><b>{t("Fichier matrice de calculs")}</b></Form.Label>
                                                {values.matrixComputeFile ? 
                                                <div><Button size='sm' variant='danger' className='ms-1' onClick={() => setFieldValue("matrixComputeFile", undefined)}><FontAwesomeIcon icon={["fas", "trash"]} /></Button> {values.matrixComputeFile.name}</div>: 
                                                <UploadFileForm size='sm' loading={loading} onSubmit={(files) => setFieldValue("matrixComputeFile", files[0])}/>}

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.matrixComputeFile? t("required", { ns:"TransverseError"}) as string : ""}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                   </Row>

                                   <Row className='mt-2'>
                                    <Col>
                                        <Form.Group className='mb-3' controlId="validationFormik03">
                                            <Form.Label><b>{t("Fichier index refs client")}</b></Form.Label>
                                            {values.dictionnaryFile ?  
                                            <div><Button size='sm' variant='danger' className='ms-1' onClick={() => setFieldValue("dictionnaryFile", undefined)}><FontAwesomeIcon icon={["fas", "trash"]} /></Button> {values.dictionnaryFile.name}</div>: 
                                            <UploadFileForm size='sm' loading={loading} onSubmit={(files) => setFieldValue("dictionnaryFile", files[0])}/>}
                                            <Form.Control.Feedback type="invalid">
                                                {errors.dictionnaryFile? t("required", { ns:"TransverseError"}) as string : ""}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik03">
                                                <Form.Label><b>{t("Fichier vecteurs position 2D")}</b></Form.Label>
                                                {values.pointsFile ?  
                                                <div><Button size='sm' variant='danger' className='ms-1' onClick={() => setFieldValue("pointsFile", undefined)}><FontAwesomeIcon icon={["fas", "trash"]} /></Button> {values.pointsFile.name}</div>: 
                                                <UploadFileForm size='sm' loading={loading} onSubmit={(files) => setFieldValue("pointsFile", files[0])}/>}
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.pointsFile? t("required", { ns:"TransverseError"}) as string : ""}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                   </Row>
                                    <div className='d-flex justify-content-center'>
                                        <ButtonFno disabled={loading} className="w-50 me-1" color='blue' onClick={onCancel}>{t('Retour')}</ButtonFno>
                                        <ButtonFno disabled={loading} className="w-50 ms-1" color='orange' loading={loading} onClick={() => setShowConfirmForm(true)}>{t('Valider')}</ButtonFno>
                                    </div>

                                    <Row className='mt-2 mb-2'><Col className='text-center'><span className='text-orange'><b>{t("Attention l'unité des fichiers doit être en mètre !")}</b></span></Col></Row>

                                    {loading && <div className='d-flex justify-content-center mt-4'>
                                        <h5 className='text-orange'><FontAwesomeIcon icon={['fas', 'warning']} size="sm"/> {t('Cette opération peut prendre plusieurs secondes')}</h5>
                                    </div>}

                                    {currentUser && 
                                    <Modal dialogClassName='modal-fno' size='lg' show={showConfirmForm} onHide={() => setShowConfirmForm(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{`déclaration sur l'honneur`}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <ConfirmForm 
                                            text={<div>
                                                <p>{`Je soussigné(e) ${currentUser.firstName} ${currentUser.lastName},`}</p>
                                                <p>{`certifie sur l'honneur que l'unité de mesure utilisé dans les fichiers ci-dessus est en`} <b>{`en mètres`}</b></p>
                                                <p>{`Je suis conscient(e) qu'une fausse déclaration m'expose à des poursuites et que ce certificat pourra être utilisé en justice.`}</p>
                                                <p>{`Fait pour servir et valoir ce que de droit`}</p>
                                            </div>} 
                        
                                            onSubmit={() => {setShowConfirmForm(false); handleSubmit()}} 
                                            onCancel={() => setShowConfirmForm(false)} />
                                        </Modal.Body>
                                    </Modal>}
                                </fieldset>
                            )
                    }}
                   
                    </Formik> 
        </div>
    )
}

export default PickingConfigurationForm
