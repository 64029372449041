import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { toast } from 'react-toastify';
import DataTable, { TableColumn } from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OffMeshLinkCreate, OffMeshLinkDetails, OffMeshLinkUpdate } from 'backend/ApiBackofficeDefinition/data-contracts';
import { useAppDispatch, useAppSelector } from 'store/store';
import { loadOffMeshLinksThunks } from 'store/reducers/Admin/ClientOffMeshLink/thrunks/loadOffmeshLinksThunk';
import { updateOffMeshLinkThunk } from 'store/reducers/Admin/ClientOffMeshLink/thrunks/updateOffmeshLinkThunk';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { createOffMeshLinkThunk } from 'store/reducers/Admin/ClientOffMeshLink/thrunks/createOffmeshLinkThunk';
import { deleteOffMeshLinkThrunks } from 'store/reducers/Admin/ClientOffMeshLink/thrunks/deleteOffmeshLinkThrunk';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';

const AdminClientMapOffMeshLink: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapOffMeshLink}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const {
        loadingList,
        loadingForm,
        offMeshLinks
    } = useAppSelector(state => state.adminOffmeshLink);

    const { clientId, mapId } = useParams();

    useEffect(() => {
        document.title = t('[Admin] Off mesh link de la map');
        if(clientId && mapId && mapId != "new")
        {
            dispatch(loadOffMeshLinksThunks(mapId));
        }
    }, [clientId, mapId])

    const onUpdate = (marker: OffMeshLinkDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(updateOffMeshLinkThunk(mapId, marker as OffMeshLinkUpdate,{
                onSuccess: (marker) => {
                    closeModal();
                    toast.success(t("L'offmesh link a bien été modifié"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onCreate = (offmesh: OffMeshLinkDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(createOffMeshLinkThunk(mapId, offmesh as OffMeshLinkCreate,{
                onSuccess: (marker) => {
                    closeModal();
                    toast.success(t("L'offmesh link a bien été créé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onDelete = (offmesh: OffMeshLinkDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(deleteOffMeshLinkThrunks(mapId, offmesh.id,{
                onSuccess: (success) => {
                    toast.success(t("L'offmesh link a bien été supprimé"))
                    closeModal();
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const columns: TableColumn<OffMeshLinkDetails>[] = [
        {
            id:'activated',
            name: t('Activé'),
            sortable: true,
            selector: row => row.activated,
            cell: (row, index, column, id) => row.activated ? <FontAwesomeIcon icon={["fas", "check"]} /> : <FontAwesomeIcon icon={["fas", "xmark"]} />
        },
        {
            id:'costOverride',
            name: t('Coût'),
            sortable: true,
            selector: row => row.costOverride
        },
        {
            id:'bidirectional',
            name: t('Bidirectionnel'),
            sortable: true,
            selector: row => row.bidirectional,
            cell: (row, index, column, id) => row.bidirectional ? <FontAwesomeIcon icon={["fas", "check"]} /> : <FontAwesomeIcon icon={["fas", "xmark"]} />
        },
        {
            id:'autoUpdatePositions',
            name: t('MAJ auto'),
            sortable: true,
            selector: row => row.autoUpdatePositions,
            cell: (row, index, column, id) => row.autoUpdatePositions ? <FontAwesomeIcon icon={["fas", "check"]} /> : <FontAwesomeIcon icon={["fas", "xmark"]} />
        },
        {
            id:'version',
            name: t('Version'),
            center:true,
            selector: row => row.version,
        },
        {
            id:'navigationArea',
            name: t('Zone'),
            center:true,
            selector: row => row.navigationArea,
        }
    ];


    return <TableWithCrudComponent 
        entities={offMeshLinks} 
        columns={columns}
        loadingForm={loadingForm}
        loadingList={loadingList}
        onDelete={onDelete}
        onUpdate={onUpdate}
        onCreate={onCreate}
        translations={{
            tableTitle: t("OffMeshLinks de la carte"),
            noEntityText: t("Aucun offMeshLink disponible"),
            createTitle: t("Ajouter un offMeshLink"),
            createButtonText: t("Ajouter un offMeshLink"),
            deleteText: (entity) => (t("Voulez-vous supprimer le offMeshLink ?")),
            deleteTitle: (entity) => (t("Supprimer le offMeshLink ") + entity.id),
            updateText: (entity) => (t("Modifier le offMeshLink ")  + entity.id),
        }}
        fieldSearchable={(entity) => [
            entity.id,
            entity.start.x,
            entity.start.y,
            entity.start.z,
            entity.end.x,
            entity.end.y,
            entity.end.z
        ]}
        initialValueCreateForm={{
            //floorId: "",
            //mapId: mapId ?? "",
            costOverride: 3,
            bidirectional: true,
            autoUpdatePositions: true,
            activated: true,
            navigationArea: 0,
            start: {
                x: 0,
                y: 0,
                z: 0
            },
            end: {
                x: 0,
                y: 0,
                z: 0
            },
            position: {
                x: 0,
                y: 0,
                z: 0
            }
        }as OffMeshLinkCreate}
        />;
}

export default AdminClientMapOffMeshLink
