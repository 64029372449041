/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, MarkerCreate, MarkerDetails, MarkerUpdate, Operation } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MapMarker<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags MapMarker
     * @name MarkersList
     * @summary [🤵 OR 🔑] Get all markers from a mapId. [🔒: map_read].
     * @request GET:/map/{mapId}/markers
     * @secure
     */
    markersList = (mapId: string, params: RequestParams = {}) =>
        this.request<MarkerDetails[], ApiErrorsResponse | void>({
            path: `/map/${mapId}/markers`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapMarker
     * @name MarkersPost
     * @summary [🤵 OR 🔑] Create many markers. [🔒: map_create].
     * @request POST:/map/{mapId}/markers
     * @secure
     */
    markersPost = (mapId: string, data: MarkerCreate[], params: RequestParams = {}) =>
        this.request<MarkerDetails[], void>({
            path: `/map/${mapId}/markers`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapMarker
     * @name MarkersDelete
     * @summary [🤵 OR 🔑] [🔒: map_update].
     * @request DELETE:/map/{mapId}/markers
     * @secure
     */
    markersDelete = (mapId: string, data: string[], params: RequestParams = {}) =>
        this.request<any, ApiErrorsResponse | void>({
            path: `/map/${mapId}/markers`,
            method: 'DELETE',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        })
    /**
     * No description
     *
     * @tags MapMarker
     * @name MarkerPost
     * @summary [🤵 OR 🔑] Create new marker. [🔒: map_create].
     * @request POST:/map/{mapId}/marker
     * @secure
     */
    markerPost = (mapId: string, data: MarkerCreate, params: RequestParams = {}) =>
        this.request<MarkerDetails, void>({
            path: `/map/${mapId}/marker`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapMarker
     * @name MarkerPut
     * @summary [🤵 OR 🔑] Update all fields of a marker. [🔒: map_update].
     * @request PUT:/map/{mapId}/marker/{markerId}
     * @secure
     */
    markerPut = (mapId: string, markerId: string, data: MarkerUpdate, params: RequestParams = {}) =>
        this.request<MarkerDetails, void>({
            path: `/map/${mapId}/marker/${markerId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapMarker
     * @name MarkerPatch
     * @summary [🤵 OR 🔑] Update specific field of a marker. [🔒: map_update].
     * @request PATCH:/map/{mapId}/marker/{markerId}
     * @secure
     */
    markerPatch = (mapId: string, markerId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<MarkerDetails, void>({
            path: `/map/${mapId}/marker/${markerId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapMarker
     * @name MarkerDelete
     * @summary [🤵 OR 🔑] Delete a marker. [🔒: map_update].
     * @request DELETE:/map/{mapId}/marker/{markerId}
     * @secure
     */
    markerDelete = (mapId: string, markerId: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/map/${mapId}/marker/${markerId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
