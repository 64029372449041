import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { Accordion, Pagination } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import PaginationRender from './PaginationRender';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import Loading from './Loading';




interface OwnProps<T> {
    accordionId?: string;
    data: T[];
    pageSize?: number;
    loading?: boolean;
    renderHeader: (item: T) => React.ReactNode;
    renderBody: (item: T) => React.ReactNode;
    onSelect?: (item: T | undefined) => void;
    translation?:{
        loadingText?: string,
        noDataText?: string
    }
 }


/**
 * Renders an Accordion component with pagination.
 * @template T An object with an id property.
 * @param {Object} props - The component props.
 * @param {string} [props.accordionId] - The id of the accordion.
 * @param {number} [props.pageSize=10] - The number of items per page.
 * @param {T[]} props.data - The data to display.
 * @param {boolean} [props.loading=false] - Indicates if the data is still loading.
 * @param {{loadingText?: string, noDataText?: string}} [props.translation] - The translations for loading and no data texts.
 * @param {(item: T) => React.ReactNode} props.renderHeader - The header renderer.
 * @param {(item: T) => React.ReactNode} props.renderBody - The body renderer.
 * @param {(item: T | undefined) => void} [props.onSelect] - The select event handler.
 * @returns {React.ReactNode} The rendered component.
 */
const AccordionWithPagination = <T extends {id: string}>({accordionId, 
                            pageSize = 10,
                            data, loading = false, translation,
                            renderHeader, renderBody, onSelect: onClick}: OwnProps<T>) => {
   const { t } = useTranslation(nameOf({AccordionWithPagination}), { useSuspense: false});

   const [currentPage, setCurrentPage] = useState(1);
   const itemsToShow = _.take(_.drop(data, (currentPage - 1) * pageSize), 10);
   const [selectedKey, setSelectedKey] = useState<string | undefined>(undefined);

    const onItemSelect = (key: AccordionEventKey) => {
        const item = data.find(item => item.id == key);
        if(item)
        {
            if(onClick) onClick(item);
            setSelectedKey(item.id);
        }
        else
        {
            if(onClick) onClick(undefined);
            setSelectedKey(undefined);
        }
        
    } 

    const onChangePage = (page: number) => {
        if(currentPage != page)
        {
            onItemSelect(undefined);
        }
        setCurrentPage(page);
    }


    if(loading)
    {
        return <div className='mt-5'><Loading text={translation?.loadingText ?? t("Chargement...")}/></div>;
    }

    if(!loading && data.length == 0)
    {
        return <div className='mt-5 text-center'>{translation?.noDataText ?? t("Aucune donnée disponible")}</div>;
    }

   return <>
    <Accordion id={accordionId} activeKey={selectedKey} onSelect={onItemSelect}>
        {_.map(itemsToShow, (item, index) => {
            return <Accordion.Item key={item.id} eventKey={item.id} >
                <Accordion.Header>{renderHeader(item)}</Accordion.Header>
                <Accordion.Body>{renderBody(item)}</Accordion.Body>
            </Accordion.Item>
        })}
                <div className='mt-2'><PaginationRender 
                                        currentPage={currentPage} 
                                        totalPages={Math.ceil(data.length / pageSize)} 
                                        onChangePage={onChangePage} 
                                            pageSize={pageSize}/></div>
                <div className='text-center mt-2'>{t("Total : {{count}} mission(s)", {count: data.length})}</div>
    </Accordion>
   </>
}
export default AccordionWithPagination