import apiAdminDefinition from "backend/apiAdminDefinition";
import i18next from "i18next";
import { toast } from "react-toastify";
import { adminTranslationsActions } from "store/reducers/Admin/Translation/adminTranslationsSlice";
import { AppThunk } from "store/store"


/**
 * Load defaults languages availables
 * @returns 
 */
export const loadDefaultLanguagesThunk = (): AppThunk => {
    return async (dispatch, getState) => {
        try{
            //dispatch(setLoadingTranslation(true));
            const languages = await apiAdminDefinition.language.languagesList(); //apiAdmin.language.getDefaults();
            
            dispatch(adminTranslationsActions.setDefaultLanguages(languages.data));
            //dispatch(setLoadingTranslation(false));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des langues disponibles"));
            dispatch(adminTranslationsActions.setLoadingTranslation(false));
        }
       
    }
}