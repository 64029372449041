import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userProfilActions } from "../../Profil/userProfilSlice";
import { UserDetailsAdmin } from "backend/ApiAdminDefinition/data-contracts";

export interface IAdminUserStore {
  loading: boolean,
  users: UserDetailsAdmin[]
}

const initialState: IAdminUserStore = {
  loading: false,
  users: []
}

const adminUserSlice = createSlice({
  name: 'adminUserReducer',
  initialState,
  reducers: {
    setLoadingUsers: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setUsers: (state, action: PayloadAction<UserDetailsAdmin[]>) => {
      state.users = action.payload
    },
    addUser: (state, action: PayloadAction<UserDetailsAdmin>) => {
      state.users.push(action.payload)
    },
    updateUser: (state, action: PayloadAction<UserDetailsAdmin>) => {
      state.users = state.users.map(user => {
        if (user.id === action.payload.id) return action.payload;
        else return user;
      })
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(userProfilActions.setLogout, (state) => {
        state.users = [];
        state.loading = false;
      })
});

export const { reducer: adminUserReducer, actions: adminUserActions } = adminUserSlice;