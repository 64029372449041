import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store";
import { adminClientMapBuildingActions } from "../adminClientMapBuildingSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";

/**
 * Delete a building
 * @param mapId - The ID of the map containing the building
 * @param buildingId - The ID of the building to delete
 * @param callbacks - Callbacks for handling success and error scenarios
 * @returns An AppThunk function
 */
export const deleteBuildingThrunks = (mapId: string, buildingId: string, callbacks: ThrunkApiCallBacks<boolean>): AppThunk => {
    return async (dispatch, getState) => {
       try {
            // Set loading state
            dispatch(adminClientMapBuildingActions.setLoadingBuildingForm(true));
            // Call API to delete the building
            await apiBackofficeDefinition.building.buildingDelete(mapId, buildingId)
                .then((result) => {
                    // Dispatch action to delete the building from state
                    dispatch(adminClientMapBuildingActions.deleteBuilding(buildingId))
                    // Call success callback
                    callbacks.onSuccess(true);
                })
                .catch((apiResponse: apiErrorModel) => {
                    // Call error callback with API response
                    callbacks.onError(apiResponse);
                })
                .finally(() => dispatch(adminClientMapBuildingActions.setLoadingBuildingForm(false)));
        }
        catch(error: any) {
            // Handle error, show toast if not unauthorized
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la suppression du bâtiment"));
        }
    }
}