import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { Badge, Button, Form, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditJsonRaw from 'components/Admin/EditJsonRaw';
import apiErrorModel from 'backend/utils/apiErrorModel';
import { toast } from 'react-toastify';
import ButtonFno from 'components/inputs/ButtonFno';
import dayjs from 'dayjs';
import DeleteConfirmForm from 'components/DeleteConfirmForm';
import PageHeader from 'components/PageHeader';
import { useAppSelector } from 'store/store';
import IPagedList from 'models/IPagedList';
import WarehouseOrderData from 'backend/apiSAP/models/WarehouseOrder';
import { apiSAP } from 'backend/apiSAP/apiSap';


const _apiSap = new apiSAP();
const SAPOrdersList: React.FC = () => {
    const { t } = useTranslation(nameOf({PickingOrder: SAPOrdersList}), { useSuspense: false});
    const [selectedOrderEdit, setSelectedOrderEdit] = useState<WarehouseOrderData>();
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [orders, setOrders] = useState<WarehouseOrderData[]>([]);
    const mapId = useAppSelector(state => state.map.currentMap?.id);
    const clientId = useAppSelector(state => state.userProfil.currentTenant?.tenant);

    useEffect(() => {
        document.title = t('Commandes picking');
        _apiSap.getWarehouseOrders('1050',50)  
            .then((orders) => setOrders(orders));
    }, [mapId])


    const columns: TableColumn<WarehouseOrderData>[] = [
        {
            id:'id',
            name: t('Id'),
            sortable: true,
            selector: row => row.WarehouseOrder,
        },
        {
            id:'dateCreation',
            name: t('Supports initiaux'),
            sortable: true,
            selector: row => row.WhseOrderCreationDateTime,
            cell: row => dayjs(row.WhseOrderCreationDateTime).format("DD/MM/YYYY HH:mm")
        },
        {
            name:<div>{t('Actions')}</div>,
            center:true,
            width:"150px",
            cell: (row, index, column, id) =>{
                return <div>
                    <Button size='sm' variant='success' className='me-1' onClick={() => setSelectedOrderEdit(row)}>
                        <FontAwesomeIcon icon={["fas", "edit"]} />
                    </Button>
                </div>
            }
        }
    ];

    return (
        <div>
             <PageHeader title={t("Les commandes")}/>
            <div>
                <div className='table-component'>

                <DataTable 
                        className={loading ? "loading": ""}
                        dense
                        columns={columns} 
                        data={orders} 
                        progressPending={false}
                        progressComponent={<Loading text={t("Chargement")}/>}
                        striped={true}
                        subHeader={true}
                        highlightOnHover={true}
                        paginationServer
                        pagination
                        noDataComponent={<div className='w-100 text-center p-5'>{loading ? t("Chargement") : t("Aucune commande disponible")}</div>}
                        subHeaderComponent={<> 
                            <h5 className='text-uppercase'> {t("Les commandes") + " ("+orders.length+")"} {loading && <Loading inline size={20} />}</h5>
                            <div className='search-panel'>
                                <input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>
                            </div>
                            </>}
                        sortServer={true}/>


                    {/*<DataTable 
                        columns={columns} 
                        data={datableValues} 
                        defaultSortAsc={false} 
                        defaultSortFieldId={'dateUpdated'} 
                        progressPending={loading}
                        progressComponent={<Loading text={t("Chargement")}/>}
                        pagination
                        paginationPerPage={30}
                        striped={true}
                        subHeader={true}
                        highlightOnHover={true}
                        noDataComponent={<div className='w-100 text-center p-5'>{t("Aucune commande disponible")}</div>}
                        subHeaderComponent={<> 
                            <h5 className='text-uppercase'> {t("Les commandes") + " ("+orders.length+")"} {(loading || loadingUpdate) && <Loading inline size={20}/>}</h5>
                            <div className='search-panel'>
                                <ButtonFno sm className='me-2' color='orange' disabled={!orders.some(m => !m.processed)} onClick={() => setShowRunOptimizationForm(true)}><FontAwesomeIcon className='me-2' icon={["fas", "play"]} /> {t("Lancer l'optimisation")}</ButtonFno>
                                <ButtonFno sm className='me-2' onClick={() => setShowCreateForm(true)}><FontAwesomeIcon className='me-2' icon={["fas", "plus"]} /> {t("Créer une commande")}</ButtonFno>
                                <input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>
                            </div>
                            </>}
                        sortServer={false}/>*/}
                </div>
            </div>

            {selectedOrderEdit && <Modal dialogClassName='modal-fno' show={true} onHide={() => setSelectedOrderEdit(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Modifier la commande")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditJsonRaw onCancel={() => setSelectedOrderEdit(undefined)} onSubmit={() => setSelectedOrderEdit(undefined)} value={selectedOrderEdit} loading={false}/>
                </Modal.Body>
            </Modal>}
        </div>
    )
}

export default SAPOrdersList
