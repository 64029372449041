import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation, redirect } from 'react-router-dom'
import Dashboard from './pages/Client/Dashboard'
import Login from './pages/Login'
import NotFoundPage from './pages/NotFoundPage'
import { ROUTES } from './resources/routes-constants'
import { RootState, useAppDispatch, useAppSelector } from './store/store'
import './styles/global.scss'
import i18next from 'i18next';
import Layout from './pages/Layout'
import ManageUsers from './pages/Client/ManageUsers'
import ManageTokens from './pages/Client/ManageTokens'
import Statistics from './pages/Client/Statistics'
import LayoutAdmin from './pages/Admin/LayoutAdmin'
import AdminDashboard from './pages/Admin/AdminDashboard'
import AdminClients from './pages/Admin/AdminClients'
import { loginActions } from './store/reducers/Login/loginSlice'
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import AdminClientMaps from './pages/Admin/client/Map/AdminClientMaps'
import AdminClientDetails from './pages/Admin/client/Details/AdminClientDetails'
import AdminTranslations from './pages/Admin/AdminTranslations'
import AdminClientPermissions from './pages/Admin/client/Permissions/AdminClientPermissions'
import AdminClientApiTokens from './pages/Admin/client/ApiToken/AdminClientApiTokens'
import AdminClientMapEdit from 'pages/Admin/client/Map/AdminClientMapEdit'
import AdminClientCreate from 'pages/Admin/AdminClientCreate'
import AdminUsers from 'pages/Admin/user/AdminUsers'
import AdminUserCreate from 'pages/Admin/user/AdminUserCreate'
import AdminUserDetails from 'pages/Admin/user/AdminUserDetails'
import LayoutMapDetails from 'pages/Admin/client/Map/LayoutMapDetails'
import AdminClientMapPolygones from 'pages/Admin/client/Map/AdminClientMapPolygones'
import AdminClientMapBuildings from 'pages/Admin/client/Map/AdminClientMapBuildings'
import currentUserIsAdmin from 'backend/utils/currentUserIsAdmin'
import AdminClientMapCreate from 'pages/Admin/client/Map/AdminClientMapCreate'
import AdminClientMapFloors from 'pages/Admin/client/Map/AdminClientMapFloors'
import AdminClientMapOffMeshLink from 'pages/Admin/client/Map/AdminClientMapOffMeshLink'
import AdminClientMapTemplate from 'pages/Admin/client/Map/AdminClientMapTemplate'
import AdminRealtimeMapChanges from 'pages/Admin/realtime/AdminRealtimeMapChanges'
import AdminRealtimeConnectedUsers from 'pages/Admin/realtime/AdminRealtimeConnectedUsers'
import AdminRealtimeDashboard from 'pages/Admin/realtime/AdminRealtimeDashboard'
import AdminClientMapMarkers from 'pages/Admin/client/Map/AdminClientMapMarkers'
import AdminClientFileManager from 'pages/Admin/client/FileManager/AdminClientFileManager'
import AdminClientMapMarkerTemplate from 'pages/Admin/client/Map/AdminClientMapMarkerTemplate'
import AdminClientMapNavmesh from 'pages/Admin/client/Map/AdminClientMapNavmesh'
import AdminClientMapSetting from 'pages/Admin/client/Map/AdminClientMapSetting'
import AdminClientMapMetadataGroup from 'pages/Admin/client/Map/AdminClientMapMetadataGroup'
import AdminClientMapZone from 'pages/Admin/client/Map/AdminClientMapZone'
import AdminClientMapAssets from 'pages/Admin/client/Map/AdminClientMapAssets'
import AdminClientMapViewer3D from 'pages/Admin/client/Map/AdminClientMapViewer3D'
import DemoPicking from 'pages/Demos/DemoPicking/DemoPicking'
import DemoNotion from 'pages/Demos/DemoNotion'
import AdminClientPickingRunner from 'pages/Admin/Picking/PickingRunner/AdminClientPickingRunner'
import AdminClientMapPickingSetting from 'pages/Admin/client/Map/AdminClientMapPickingSetting'
import SAPOrdersList from 'pages/Demos/SAPOrdersList'
import AdminClientMapAudits from 'pages/Admin/client/Map/AdminClientMapAudits'
import AuditDashboard from 'pages/Client/Audit/AuditDashboard'
import AuditRawDataList from 'pages/Client/Audit/AuditRawDataList'
import AuditBatching from 'pages/Client/Audit/AuditBatching'
import AuditFinalResult from 'pages/Client/Audit/AuditFinalResult'
import AuditPathCompare from 'pages/Client/Audit/AuditPathCompare'
import { LoadUserInfosThunk } from 'store/reducers/Profil/thrunks/LoadUserInfosThunk'
import AdminMiddlewareApi from 'pages/Admin/Picking/Middleware/AdminMiddlewareApi'
import MiddlewareRequestList from 'pages/Admin/client/MiddlewareRequest/MiddlewareRequestList'
import PickingDashboard from 'pages/Admin/Picking/PickingDashboard'
import MiddlewareStatisticsPicking from 'pages/Client/Middleware/Picking/MiddlewareStatisticsPicking'
import MiddlewarePathCompare from 'pages/Client/Middleware/Picking/MiddlewarePathCompare'
import MiddlewarePickingDashboard from 'pages/Client/Middleware/Picking/MiddlewarePickingDashboard'
import MiddlewareRawData from 'pages/Client/Middleware/Picking/MiddlewareRawData'
import MapEditor from 'pages/Client/MapEditor/MapEditor'
import TimcodeMapTagManager from 'pages/Demos/Timcode/TimcodeMapTagManager'
import TimcodeFinalResultSuperU from 'pages/Demos/Timcode/TimcodeFinalResultSuperU'
import TimcodeFinalResultTimcode from 'pages/Demos/Timcode/TimcodeFinalResultTimcode'
import TimcodeBatching from 'pages/Demos/Timcode/TimcodeBatching'
import IventiHeatmap from 'pages/Demos/Ivanti/IvantiHeatmap'
import AdminClientMapLabels from 'pages/Admin/client/Map/AdminClientMapLabels'
import AdminClientMapLabelTemplates from 'pages/Admin/client/Map/AdminClientMapLabelTemplates'
import IntermarcheTestMiddleware from 'pages/Demos/IntermarcheTestMiddleware/IntermarcheTestMiddleware'
import DemoHeatmapSAP from 'pages/Demos/HeatmapSAP/DemoHeatmapSAP'
import AuditFinalPathClientResult from 'pages/Client/Audit/AuditFinalPathClientResult'
import AuditHeatmapCompare from 'pages/Client/Audit/AuditHeatmapCompare'
import BatchingDashboard from 'pages/Client/Middleware/Batching/BatchingDashboard'
import MiddlewareStatisticsBatching from 'pages/Client/Middleware/Batching/MiddlewareStatisticsBatching'


const RootComponent: React.FC = () => {
  const currentUser = useAppSelector(state => state.userProfil.currentUser);
  const currentCulture = useAppSelector(state => state.userProfil.culture);
  const currentTenant = useAppSelector(state => state.userProfil.currentTenant);
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if(currentTenant)
    {
      dispatch(LoadUserInfosThunk(currentTenant.tenant))
    }
  }, [currentTenant])


  useEffect(() => {
    i18next.changeLanguage(currentCulture)
  }, [currentCulture])
  

  useEffect(() => {
    // enable translation saving if current user is admin
    i18next.options.saveMissing = currentUserIsAdmin();
  }, [currentUser])

    return (<>
       <Router>
            <Routes>
                <Route path="*" element={<RequireAuth><NotFoundPage /></RequireAuth>} />
                <Route path="/" element={<Navigate to={ROUTES.DASHBOARD} replace />} />
                <Route path={ROUTES.LOGIN} element={<RequireNotAuth><Login /></RequireNotAuth>} />

                {/* CLIENT ROUTES */}
                <Route path={ROUTES.DASHBOARD} element={<RequireAuth><Dashboard /></RequireAuth>} />
                

                {/*<Route path={ROUTES.PICKING_REQUESTS} element={<RequireAuth><PickingRequestList /></RequireAuth>} />*/}
                {/*<Route path={ROUTES.PICKING_ORDERS} element={<RequireAuth><PickingOrderList /></RequireAuth>} />*/}

                
                <Route path={ROUTES.PICKING_DASHBOARD} element={<RequireAuth><MiddlewarePickingDashboard /></RequireAuth>} />
                <Route path={ROUTES.PICKING_STATISTICS} element={<RequireAuth><MiddlewareStatisticsPicking /></RequireAuth>} />
                <Route path={ROUTES.PICKING_RAW_DATA} element={<RequireAuth><MiddlewareRawData /></RequireAuth>} />
                <Route path={ROUTES.PICKING_PATH_COMPARE} element={<RequireAuth><MiddlewarePathCompare /></RequireAuth>} />

                <Route path={ROUTES.BATCHING_DASHBOARD} element={<RequireAuth><BatchingDashboard /></RequireAuth>} />
                <Route path={ROUTES.BATCHING_STATISTICS} element={<RequireAuth><MiddlewareStatisticsBatching /></RequireAuth>} />
                


                <Route path={ROUTES.MAP_EDITOR} element={<RequireAuth><MapEditor /></RequireAuth>} />


                <Route path={ROUTES.STATISTICS} element={<RequireAuth><Statistics /></RequireAuth>} />
                <Route path={ROUTES.MANAGE_USERS} element={<RequireAuth><ManageUsers /></RequireAuth>} />
                <Route path={ROUTES.MANAGE_TOKENS} element={<RequireAuth><ManageTokens /></RequireAuth>} />


                <Route path={ROUTES.AUDIT_DASHBOARD} element={<RequireAuth><AuditDashboard /></RequireAuth>} />
                <Route path={ROUTES.AUDIT_RAW_DATA} element={<RequireAuth><AuditRawDataList /></RequireAuth>} />
                <Route path={ROUTES.AUDIT_PATH_COMPARE} element={<RequireAuth><AuditPathCompare /></RequireAuth>} />
                <Route path={ROUTES.AUDIT_BATCHING} element={<RequireAuth><AuditBatching /></RequireAuth>} />
                <Route path={ROUTES.AUDIT_FINAL_PATH_CLIENT_RESULT} element={<RequireAuth><AuditFinalPathClientResult /></RequireAuth>} />
                <Route path={ROUTES.AUDIT_FINAL_RESULT} element={<RequireAuth><AuditFinalResult /></RequireAuth>} />
                <Route path={ROUTES.AUDIT_HEATMAP_COMPARE} element={<RequireAuth><AuditHeatmapCompare /></RequireAuth>} />

                
                
                
                


                {/* ADMIN ROUTES */}
                <Route path="/admin" element={<Navigate to={ROUTES.ADMIN.DASHBOARD} replace />} />
                
                <Route path={ROUTES.ADMIN.DASHBOARD} element={<RequireAdmin><AdminDashboard /></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.TRANSLATIONS} element={<RequireAdmin><AdminTranslations /></RequireAdmin>} />


                
                <Route path={ROUTES.ADMIN.PICKING.INDEX} element={<RequireAdmin><PickingDashboard /></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.PICKING.MIDDLEWARES} element={<RequireAdmin><AdminMiddlewareApi /></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.PICKING.RUNNERS} element={<RequireAdmin><AdminClientPickingRunner /></RequireAdmin>} />


                <Route path={ROUTES.ADMIN.REALTIME.INDEX}  element={<RequireAdmin><AdminRealtimeDashboard /></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.REALTIME.MAP_CHANGES} element={<RequireAdmin><AdminRealtimeMapChanges /></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.REALTIME.CONNECTED_USERS} element={<RequireAdmin><AdminRealtimeConnectedUsers /></RequireAdmin>} />
                
                

                <Route path={ROUTES.ADMIN.CLIENTS} element={<RequireAdmin><AdminClients /></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.USERS} element={<RequireAdmin><AdminUsers /></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.USER().CREATE} element={<RequireAdmin><AdminUserCreate /></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.USER(':userId').DETAILS} element={<RequireAdmin><AdminUserDetails /></RequireAdmin>} />

                <Route path={ROUTES.ADMIN.CLIENT_CREATE} element={<RequireAdmin><AdminClientCreate /></RequireAdmin>} />
                
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAPS} element={<RequireAdmin><AdminClientMaps /></RequireAdmin>} />

                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_DETAILS(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapEdit /></LayoutMapDetails></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_VIEWER_3D(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapViewer3D /></LayoutMapDetails></RequireAdmin>} />
                

                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_CREATE} element={<RequireAdmin><LayoutMapDetails><AdminClientMapCreate /></LayoutMapDetails></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_POLYGONS(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapPolygones /></LayoutMapDetails></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_ASSETS(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapAssets /></LayoutMapDetails></RequireAdmin>} />
                
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_BUILDINGS(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapBuildings /></LayoutMapDetails></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_MARKERS(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapMarkers /></LayoutMapDetails></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_LABELS(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapLabels /></LayoutMapDetails></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_LABEL_TEMPLATES(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapLabelTemplates /></LayoutMapDetails></RequireAdmin>} />

                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_MARKER_TEMPLATES(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapMarkerTemplate /></LayoutMapDetails></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_FLOORS(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapFloors /></LayoutMapDetails></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_OFFMESHLINKS(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapOffMeshLink /></LayoutMapDetails></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_NAVMESH(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapNavmesh /></LayoutMapDetails></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_SETTING(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapSetting /></LayoutMapDetails></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_ZONE(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapZone /></LayoutMapDetails></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_METADATA_GROUPS(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapMetadataGroup /></LayoutMapDetails></RequireAdmin>} />
                
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_PICKING_SETTING(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapPickingSetting /></LayoutMapDetails></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_AUDITS(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapAudits /></LayoutMapDetails></RequireAdmin>} />

                <Route path={ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_TEMPLATES(":mapId")} element={<RequireAdmin><LayoutMapDetails><AdminClientMapTemplate /></LayoutMapDetails></RequireAdmin>} />

                <Route path={ROUTES.ADMIN.CLIENT(':clientId').DETAILS} element={<RequireAdmin><AdminClientDetails /></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').PERMISSIONS} element={<RequireAdmin><AdminClientPermissions /></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').API_TOKENS} element={<RequireAdmin><AdminClientApiTokens /></RequireAdmin>} />
                
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').PICKING_REQUESTS} element={<RequireAdmin><MiddlewareRequestList /></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').TEST_MIDDLEWARE} element={<RequireAdmin><IntermarcheTestMiddleware /></RequireAdmin>} />
                <Route path={ROUTES.ADMIN.CLIENT(':clientId').FILE_MANAGER} element={<RequireAdmin><AdminClientFileManager /></RequireAdmin>} />
                

                {/* DEMO ROUTES */}
                <Route path={ROUTES.DEMO.TIMCODE.TAG_MANAGER} element={<RequireAuth><TimcodeMapTagManager /></RequireAuth>} />
                <Route path={ROUTES.DEMO.TIMCODE.BATCHING_SUPER_U} element={<RequireAuth><TimcodeFinalResultSuperU /></RequireAuth>} />
                <Route path={ROUTES.DEMO.TIMCODE.BATCHING_TIMCODE} element={<RequireAuth><TimcodeFinalResultTimcode /></RequireAuth>} />
                <Route path={ROUTES.DEMO.TIMCODE.BATCHING_RESULT} element={<RequireAuth><TimcodeBatching /></RequireAuth>} />
                
                <Route path={ROUTES.DEMO.IVANTI.HEATMAP} element={<RequireAuth><IventiHeatmap /></RequireAuth>} />

                <Route path="demo/notion" element={<DemoNotion />} />
                <Route path="demo/picking" element={<DemoPicking />} />
                <Route path="demo/heatmap/sap" element={<DemoHeatmapSAP />} />
                {<Route path="demo/sap/order/list" element={<RequireAuth><SAPOrdersList /></RequireAuth>} />}
                
            </Routes>
        </Router>
        <ToastContainer />
        </>
    )
}

/**
 * Redirige vers la page de login si l'utilisateur n'est pas connecté
 * @returns 
 */
 function RequireAuth({ children }: { children: JSX.Element }) {
    const currentUser = useSelector((state:RootState ) => state.userProfil.currentUser);
    const redirectTo = useAppSelector(state => state.login.redirectUrl);
    const dispatch = useAppDispatch();

    const location = useLocation();
    if (!currentUser) {
      return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
    }
    // if redirectTo exist, force redirection
    else if(redirectTo)
    {
      dispatch(loginActions.setRedirectUrl(undefined));
      return <Navigate to={redirectTo} state={{ from: location }} replace />;
    }

    return <Layout>{children}</Layout>;
  }



/**
 * Redirige vers la page d'acceuil si l'utilisateur est déjà connecté
 * @returns 
 */
  function RequireNotAuth({ children }: { children: JSX.Element }) {
    const currentUser = useSelector((state:RootState ) => state.userProfil.currentUser);
    const location = useLocation();
    if (currentUser) {
      const isAdmin = currentUser.roles.some(m => m.toLocaleUpperCase() == "ADMIN")
      return <Navigate to={isAdmin ? ROUTES.ADMIN.DASHBOARD : ROUTES.DASHBOARD} state={{ from: location }} replace />;
    }
    return children ;
  }


  function RequireAdmin({ children }: { children: JSX.Element }) {
    if (!currentUserIsAdmin()) {
      return <Navigate to={ROUTES.DASHBOARD} replace />;
    }
    return <LayoutAdmin>{children}</LayoutAdmin> ;
  }
  

export default RootComponent