import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import RootComponent from './RootComponent'
import { persistor, store } from './store/store'
import initI18nTranslation from './utility/initI18nTranslation'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import initFetchInterceptor from './utility/initFetchInterceptor'
import Sdk from 'fno-sdk';
import initChartjs from 'utility/initChartjs'
import initReactDatepicker from 'utility/initReactDatepicker'
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

library.add(fas)
library.add(far)

initI18nTranslation();
initFetchInterceptor();
initChartjs();
initReactDatepicker();

declare global {
    interface Window {
        //fnoSdk: any;
        fnoSdk: Sdk | undefined
    }
  }


const App: React.FC = () => {
    
    return (<Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <RootComponent />
                </PersistGate>
            </Provider>)
}

export default App