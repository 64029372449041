import React from 'react'
import { Vector3 } from 'backend/ApiBackofficeDefinition/data-contracts';

type Props = {
    vector: Vector3
  };

/**
 * Renders a Vector3 as a string.
 * 
 * @param {Props} props - An object containing a Vector3.
 * @param {Vector3} props.vector - The Vector3 to render.
 * @returns {React.ReactElement} A React element containing the Vector3's x, y, and z values.
 */
const Vector3Render: React.FC<Props> = ({vector}) => {

        return <>{`{ x:${vector.x}, y:${vector.y}, z:${vector.z} }`}</>
}

export default Vector3Render