import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import * as yup from 'yup';
import {  Button, Col, Form, Row } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import { Formik, FormikHelpers } from 'formik';
import Section from 'components/Section';
import IUserClient from 'models/IUserClient';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useMap } from 'usehooks-ts';
import Loading from 'components/Loading';
import Switch from "react-switch";
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RoleClientDetails, UserDetailsAdmin } from 'backend/ApiAdminDefinition/data-contracts';
import { getClientRolesByClientIdThunk } from 'store/reducers/Admin/ClientRole/thrunks/getClientRolesByClientIdThunk';
import apiBackofficeDefinition from 'backend/apiBackofficeDefinition';
import { MapDetails } from 'backend/ApiBackofficeDefinition/data-contracts';



interface OwnProps {
    initialValues?: UserDetailsAdmin;
    loading: boolean;
    onClose: () => void;
    onSubmit: (user: UserDetailsAdmin, setFieldError:(field: string, message: string | undefined) => void) => Promise<void>;
 }

interface IUserFormInputs{
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    userRoles: string[];
    clients: IUserClient[];
    lastLoginDate?: Date;
}


/**
 * Form to create or update a user.
 *
 * @param onSubmit function called when the form is submitted, takes a user object and a setFieldError function as parameters
 * @param onClose function called when the form is closed, takes no parameter
 * @param loading boolean indicating if the form is currently submitting
 * @param initialValues optional UserDetailsAdmin object used to initialize the form, defaults to undefined
 * @returns React component
 */
const UserForm: React.FC<OwnProps> = ({onSubmit, onClose, loading, initialValues = undefined}) => {
    const { t } = useTranslation([nameOf({UserForm}), "TransverseError"], { useSuspense: false});
    const appRoles = useAppSelector(state => state.adminGlobal.appRoles);
    const clients = useAppSelector(state => state.adminClient.allClients);
    
    const dispatch = useAppDispatch();

    const isCreate = !initialValues;
    const [clientRoles, clientRoleAction] = useMap<number, RoleClientDetails[] | "loading">();
    const [clientMaps, clientMapAction] = useMap<string, MapDetails[] | "loading">();


    useEffect(() => {
        if(initialValues)
        {
            if(initialValues.clients) initialValues.clients.forEach(m => {
                loadClientRoles(m.id);
                loadClientMaps(m.tenant);
            })
        }
    }, [initialValues])


    const onValidate = async (values: IUserFormInputs, {setFieldError}:FormikHelpers<IUserFormInputs>) => {
       
        const userToCreate = {
            id: initialValues?.id,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            userRoles: values.userRoles,
            clients: values.clients
        } as UserDetailsAdmin;

        onSubmit(userToCreate, setFieldError);

    };

    const loadClientRoles = (clientId: number) => {
       
        if(!clientRoles.has(clientId)){
            clientRoleAction.set(clientId, 'loading')
            dispatch(getClientRolesByClientIdThunk(clientId.toString(), (roles) => {
                clientRoleAction.set(clientId, roles)
            }))
        }
    }

    const loadClientMaps = (clientTenant: string) => {
        if(!clientMaps.has(clientTenant)){
            clientMapAction.set(clientTenant, 'loading')
            apiBackofficeDefinition.map.mapsList({headers: {"tenant": clientTenant}}).then((maps) =>clientMapAction.set(clientTenant, maps.data));
        }
    }

    const schema = yup.object().shape({
        email: yup.string().required(t("required", { ns:"TransverseError"}) as string),
        firstName: yup.string().required(t("required", { ns:"TransverseError"}) as string),
        lastName: yup.string().required(t("required", { ns:"TransverseError"}) as string),
        userRoles:yup.array().of(yup.string()).min(1, t("required", { ns:"TransverseError"}) as string),
        clients: yup.array().of(
            yup.object().shape({
                tenant: yup.string().required(t("required", { ns:"TransverseError"}) as string),
                roleId: yup.string().required(t("required", { ns:"TransverseError"}) as string),
            })
          )

      });

     
    return (
        <div>
            <Section>
                <Formik
                    validationSchema={schema}
                    onSubmit={onValidate}
                    
                    initialValues={{
                        id: initialValues?.id || "",
                        email: initialValues?.email ||'',
                        firstName: initialValues?.firstName ||"",
                        lastName: initialValues?.lastName ||"",
                        userRoles: initialValues?.userRoles || [],
                        clients: _.cloneDeep(initialValues?.clients) || []
                    } as IUserFormInputs}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        isSubmitting,
                        errors,
                        setFieldValue
                    }) => {
                        return (
                            <fieldset disabled={isSubmitting}>
                                    <Row>
                                        <h2>{t("Détails")}</h2>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik03">
                                                <Form.Label>{t("Email de l'utilisateur")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="email"
                                                    disabled={!isCreate}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    isValid={touched.email && !errors.email}
                                                    isInvalid={!!errors.email}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className='mb-3' controlId="validationFormik07">
                                                <Form.Label>{t("Type de compte")}</Form.Label>
                                                <Form.Select
                                                    name="userRoles"
                                                    value={values.userRoles ? values.userRoles[0] : ""}
                                                    onChange={async (val) => {
                                                        await setFieldValue("userRoles", val.target.value ? [val.target.value] : [], true)
                                                        if(val.target.value == "Client") await setFieldValue("clients", [{ name: "", tenant: "" } as IUserClient], true)
                                                        else await setFieldValue("clients", [], true)

                                                        //await setFieldTouched("userRoles", true);
                                                        
                                                    }}
                                                    isValid={touched.userRoles && !errors.userRoles}
                                                    isInvalid={!!errors.userRoles}>
                                                    <option></option>
                                                    {appRoles.map((role) => <option key={role.id} value={role.name}>{role.name}</option>)}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.userRoles}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>


                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik04">
                                                <Form.Label>{t("Prénom")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="firstName"
                                                    value={values.firstName}
                                                    onChange={handleChange}
                                                    isValid={touched.firstName && !errors.firstName}
                                                    isInvalid={!!errors.firstName}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.firstName}
                                                </Form.Control.Feedback>
                                            </Form.Group>
    
                                            <Form.Group className='mb-3' controlId="validationFormik06">
                                                <Form.Label>{t("Nom de famille")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="lastName"
                                                    value={values.lastName}
                                                    onChange={handleChange}
                                                    isValid={touched.lastName && !errors.lastName}
                                                    isInvalid={!!errors.lastName}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.lastName}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {values.clients.map((client, index, array) => {

                                        const onChangeClient = async (clientId: number) => {
                                            const client = clients.find(m => m.id == clientId);

                                            array[index].tenant = client?.tenant || "";
                                            array[index].name = client?.name || "";
                                            array[index].id = clientId;
                                            array[index].roleId = "";
                                            array[index].roleName = "";
                                            array[index].maps = [];

                                            await setFieldValue("clients", array)
                                            if(client)
                                            {
                                                loadClientRoles(clientId);
                                                loadClientMaps(client.tenant);
                                            }
                                            

                                        }

                                        const onChangeRole = async (roleId: string) => {
                                            const clientRole = clientRoles.get(array[index].id);
                                            const allClientRoles =  clientRole != 'loading' && clientRole?.length ? clientRole : [];
                                            const roleFound = allClientRoles.find(m => m.id == roleId)
                                            
                                            array[index].roleId = roleId;
                                            array[index].roleName = roleFound?.name || "";
                                            await setFieldValue("clients", array)
                                            
                                        }

                                        const selectAllMap = async () => {
                                            const clientMap = clientMaps.get(array[index].tenant);
                                            const allClientMaps =  clientMap != 'loading' && clientMap?.length ? clientMap : [];
                                            array[index].maps = allClientMaps.map(m => {return {id: m.id, name: m.name}})
                                            await setFieldValue("clients", array)
                                        }
                                    
                                        const deselectAllMap = async () => {
                                            array[index].maps = []
                                            await setFieldValue("clients", array)
                                        }

                                        const clientRole = clientRoles.get(array[index].id);
                                        const allClientRoles =  clientRole != 'loading' && clientRole?.length ? clientRole : [];
                                        const isLoadingRole = clientRole == 'loading';

                                        const clientMap = clientMaps.get(array[index].tenant);
                                        const allClientMaps =  clientMap != 'loading' && clientMap?.length ? clientMap : [];
                                        const isLoadingMap = clientMap == 'loading';

                                        const allChecked = client.maps ? client.maps.length == allClientMaps.length : false;


                                        const tenantErrorPath = `clients[${index}].tenant`;
                                        const tenantError = _.get(errors, tenantErrorPath);

                                        const roleIdErrorPath = `clients[${index}].roleId`;
                                        const roleIdError = _.get(errors, roleIdErrorPath);

                                        return <div key={index}>
                                            <Row>
                                            <Col>
                                                <Form.Group className='mb-3' controlId="validationFormik07">
                                                    <Form.Label>{t("Affecter au client:")}</Form.Label>
                                                    <Form.Select
                                                        value={client.id}
                                                        name={tenantErrorPath}
                                                        isInvalid={!!tenantError}
                                                        isValid={!tenantError}
                                                        onChange={(val) => onChangeClient(parseInt(val.target.value))}>
                                                        <option></option>
                                                        {clients.map((c) => <option key={c.id} value={c.id}>{c.name}</option>)}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {tenantError}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                {client.id && <Form.Group className='mb-3' controlId="validationFormik07">
                                                    <Form.Label>{t("Affecter le rôle:")}</Form.Label>
                                                    {isLoadingRole && <div><Loading inline size={35}/></div>}
                                                    {!isLoadingRole && <Form.Select
                                                        value={client.roleId}
                                                        name={roleIdErrorPath}
                                                        isInvalid={!!roleIdError}
                                                        isValid={!roleIdError}
                                                        onChange={(val) => onChangeRole(val.target.value)}>
                                                        <option></option>
                                                        {allClientRoles.map((r) => <option key={r.id} value={r.id}>{r.name}</option>)}
                                                    </Form.Select>}
                                                    <Form.Control.Feedback type="invalid">
                                                        {roleIdError}
                                                    </Form.Control.Feedback>
                                                </Form.Group>}
                                            </Col>
                                        </Row>

                                        {client.id && <Row>
                                            <Form.Label>{t("Affecter les cartes:")}
                                                <Button size="sm" variant='link' className='text-orange' onClick={() => allChecked ? deselectAllMap() : selectAllMap()} title={allChecked ? t("Tout désélectionner"):  t("Tout sélectionner")}>
                                                    <FontAwesomeIcon icon={['fas', allChecked ? 'square': 'square-check']} size="sm"/>
                                                </Button>
                                            </Form.Label>
                                            {isLoadingMap && <div><Loading inline size={35}/></div>}
                                            {!isLoadingMap && allClientMaps.map(map => { 
                                                return <Col key={map.id} sm="3">
                                                    <Row key={map.id}>
                                                        <Col sm="2"><Switch checked={client.maps ? client.maps.some(m => m.id == map.id): false} onChange={async (enabled) => {

                                                            if(!array[index].maps) array[index].maps = [];

                                                            if(enabled) array[index].maps.push({id: map.id, name: map.name})
                                                            else array[index].maps = array[index].maps.filter(m => m.id != map.id)
                                                            await setFieldValue("clients", array)
                                                        }}/></Col>
                                                        <Col sm="10">{map.name} </Col>
                                                    </Row>
                                                </Col>
                                                })}
                                        </Row>}
                                    </div>
                                    })}

                                    
                                   
                                    <div className='d-flex justify-content-center mt-3'>
                                        <ButtonFno className="w-50 me-1" color='blue' onClick={onClose}>{t('Retour')}</ButtonFno>
                                        <ButtonFno className="w-50 ms-1" color='orange' loading={loading} onClick={handleSubmit}>{t('Valider')}</ButtonFno>
                                    </div>
                                </fieldset>
                            )
                    }}
                    </Formik>
            </Section>
                
        </div>
    )
}

export default UserForm
