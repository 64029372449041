import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import * as yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import { Formik, FormikHelpers } from 'formik';
import Switch from "react-switch";
import dayjs from 'dayjs';
import { useAppDispatch } from 'store/store';
import { updateMapMaintenanceThunk } from 'store/reducers/Admin/ClientMap/thrunks/updateMapMaintenanceThunk';
import { MapDetails } from 'backend/ApiBackofficeDefinition/data-contracts';



interface OwnProps {
    initialValues?: MapDetails;
    loading: boolean;
    onSubmit: ((values: IMapFormInputs, formikHelpers: FormikHelpers<IMapFormInputs>) => void | Promise<any>) & ((values: IMapFormInputs, { setFieldError }: FormikHelpers<IMapFormInputs>) => Promise<void>);
    onCancel: () => void | undefined
 }

export interface IMapFormInputs{
    name: string;
    reference: string;
    isUnderMaintenance: boolean;
}


/**
 * Component for managing map data.
 * 
 * @param onSubmit Callback function for form submission.
 * @param loading Indicates if the form is currently loading.
 * @param initialValues Initial values for the form.
 * @param onCancel Callback function for form cancellation.
 * @returns The map form component.
 */
const MapForm: React.FC<OwnProps> = ({onSubmit, loading, initialValues = undefined, onCancel = undefined}) => {
    const { t } = useTranslation([nameOf({MapForm}), "TransverseError"], { useSuspense: false});
    const dispatch = useAppDispatch();

    const isCreate = !initialValues;

    const schema = yup.object().shape({
        name: yup.string().required(t("required", { ns:"TransverseError"}) as string),
        reference: yup.string().nullable(),
        /*languages: yup.array().required(t("required", { ns:"TransverseError"}) as string)
                            .min(1, t("required", { ns:"TransverseError"}) as string)*/
      });


    return (
        <div>
                <Formik
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    initialValues={initialValues ? initialValues as IMapFormInputs : {
                        name: '',
                        reference: '',
                        isUnderMaintenance: true,
                        languages: []
                    } as IMapFormInputs}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        setValues,
                        values,
                        touched,
                        isValid,
                        isSubmitting,
                        errors,
                    }) => {
                        return (
                            <fieldset disabled={isSubmitting || loading}>
                                    <h2>{t("Carte")}</h2>
                                    {!isCreate && initialValues && <Row>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik03">
                                                <Form.Label>{t("Id")}</Form.Label>
                                                <div>
                                                    <label>{initialValues.idShort}</label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik03">
                                                <Form.Label>{t("Dernière modification")}</Form.Label>
                                                <div>
                                                    <label>{dayjs(initialValues.dateUpdated).format("DD/MM/YYYY HH:mm")}</label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik03">
                                                <Form.Label>{t("Version")}</Form.Label>
                                                <div>
                                                    <label>{initialValues.version}</label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col>

                                        <Form.Group className='mb-3' controlId="validationFormik03">
                                                <Form.Label>{t("Mode maintenance")}</Form.Label>
                                                <div>
                                                <Switch checked={initialValues.isUnderMaintenance} onChange={(enable) => {
                                                    dispatch(updateMapMaintenanceThunk(initialValues, enable));
                                                    setValues({
                                                        ...initialValues,
                                                        isUnderMaintenance: enable
                                                    } as IMapFormInputs);
                                                }}/>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>}
                                    <Row>
                                        
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik03">
                                                <Form.Label>{t("Nom")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isValid={touched.name && !errors.name}
                                                    isInvalid={!!errors.name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik04">
                                                <Form.Label>{t("Référence client")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="reference"
                                                    value={values.reference}
                                                    onChange={handleChange}
                                                    isValid={touched.reference && !errors.reference}
                                                    isInvalid={!!errors.reference}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.reference}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <div className='d-flex justify-content-center'>
                                        {onCancel && <ButtonFno className="w-50 me-1" color='blue' disabled={loading} onClick={onCancel}>{t('Retour')}</ButtonFno>}
                                        <ButtonFno className="w-50 ms-1" color='orange' loading={loading} disabled={loading} onClick={handleSubmit}>{t('Valider')}</ButtonFno>
                                    </div>
                                </fieldset>
                            )
                    }}
                    </Formik>   
        </div>
    )
}

export default MapForm
