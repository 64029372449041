import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OptionsOrGroups } from 'react-select';
import { ROUTES } from 'resources/routes-constants';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';


interface OptionGlobalSearch {
    name: string;
    type: "client" | "map";
    route: string;
    client?: string;
  }

/**
 * React component for the global search form.
 * 
 * This component displays a typeahead input allowing to search for clients or maps.
 * When a client or a map is selected, the component navigates to its corresponding page.
 * 
 * @returns {JSX.Element} The global search form.
 */
const GlobalSearchForm: React.FC = () => {
   const { t } = useTranslation(nameOf({GlobalSearchForm}), { useSuspense: false});
   const dispatch = useAppDispatch();
   const [globalOptions, setGlobalOptions] = useState<OptionGlobalSearch[]>([]);
   const clients = useAppSelector(state => state.adminClient);
   const navigate = useNavigate();

   useEffect(() => {
    const globalOption: OptionGlobalSearch[] = []
    if(clients) {
        clients.allClients.forEach(client => {
            globalOption.push({name: client.name, type: "client", route: ROUTES.ADMIN.CLIENT(client.id.toString()).DETAILS})
            client.maps.forEach(map => {
                globalOption.push({name: `${map.name}`, type: "map", route: ROUTES.ADMIN.CLIENT(client.id.toString()).MAP_DETAILS(map.id), client: client.name})
            })
        })
    }


    setGlobalOptions(globalOption)
   }, [clients])

   return (
       <div className='m-2'>
           <Typeahead
                id="global-search"
                labelKey="name"
                options={globalOptions}
                placeholder={t("Recherche rapide ...")}
                selected={[]}
                onChange={(value) => {
                    const valueParsed = _.first((value as OptionGlobalSearch[]));
                    if(valueParsed) navigate(valueParsed.route)
                }}
                renderMenuItemChildren={(option) => {
                    const optionParsed = option as OptionGlobalSearch

                    let iconName: IconName | undefined = "question"
                    if(optionParsed.type === "client"){
                        iconName = "database"
                    }

                    if(optionParsed.type === "map"){
                        iconName = "location-dot"
                    }


                    return <div>
                        <Row className='align-items-center'>
                            <Col className='col-auto'><FontAwesomeIcon icon={["fas", iconName]} size="lg"/></Col>
                            <Col>
                                <div><b>{optionParsed.name}</b></div>
                                <small>{optionParsed.client}</small>
                            </Col>
                        </Row>
                    </div>
                }}
            />
       </div>
   )
}
export default GlobalSearchForm