import _ from "lodash";
import { AppThunk } from "store/store";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiPickingDefinition from "backend/apiPickingDefinition";


/**
 * Thunk action creator for running the optimization process
 * @param mapId - The ID of the map
 * @param fileId - The ID of the file to run optimization process on
 * @param callbacks - Callbacks for success and error handling
 * @returns An AppThunk function
 */
export const runOptimizationProcess = (
    mapId: string,
    fileId: string,
    callbacks: ThrunkApiCallBacks<boolean>
): AppThunk => {
    return async (dispatch, getState) => {
    
       try{
         // Start the audit optimization process
         const result = await apiPickingDefinition.auditOptimization.auditOptimizationProcessStart(mapId, fileId);
        
         if (!result.ok) {
             // Call onError callback with API error response
             callbacks.onError(result.error as apiErrorModel);
         } else {
             // Call onSuccess callback with true indicating successful optimization process
             callbacks.onSuccess(true);
         }
       }
       catch(error: any)
       {
            callbacks.onError({errors:{
                "": ["Une erreur serveur s'est produite"]
            }} as apiErrorModel);
       }
    };
};