import { loginActions } from "store/reducers/Login/loginSlice";
import { userProfilActions} from "store/reducers/Profil/userProfilSlice";
import { AppThunk } from "store/store"


/**
 * Called when api return 401 response because token expired
 * @returns 
 */
export const ApiTokenExpiredThunk = (): AppThunk => {
    return async (dispatch, getState) => {

        const redirectUrl = getState().login.redirectUrl
        if(!redirectUrl) {
            dispatch(loginActions.setShowSessionExpiredModal(true));
            dispatch(loginActions.setRedirectUrl(window.location.pathname));
        }
        dispatch(userProfilActions.setLogout());
    }
}