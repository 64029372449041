/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { RoleAppDetails } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class RolesApp<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Roles App
     * @name AppRolesList
     * @summary [🤵] Account role (admin, client etc ...).
     * @request GET:/admin/app/roles
     * @secure
     */
    appRolesList = (params: RequestParams = {}) =>
        this.request<RoleAppDetails[], void>({
            path: `/admin/app/roles`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
}
