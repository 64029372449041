import i18next from "i18next";
import { toast } from "react-toastify";
import { adminMiddlewareActions } from "../adminMiddlewareSlice";
import { AppThunk } from "store/store"
import apiMiddlewareDefinition from "backend/apiMiddlewareDefinition";



/**
 * Reconnects the realtime middleware with the given ID.
 *
 * @param {string} middlewareId - The ID of the middleware to reconnect.
 * @return {AppThunk} An async function that dispatches actions to reconnect the middleware.
 */
export const reconnectRealtimeMiddlewaresThrunk = (middlewareId: string): AppThunk => {
    return async (dispatch, getState) => {
      try {

        const middleware = getState().adminRealtimeMiddleware.middlewares.find(m => m.id == middlewareId);

        if(!middleware)
        {
          toast.error(i18next.t("Le middleware n'existe pas"))
          return;
        }


        const result = await apiMiddlewareDefinition(middleware.baseUrl, middleware.clientTenant).requests.realtimeReconnect();

        if(result.data.connected)
        {
          toast.success(i18next.t("Le middleware a bien été connecté"))
        }
        else
        {
          toast.error(i18next.t("Impossible de reconnecter le middleware"))
        }
  

      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Impossible de reconnecter le middleware"))
        }
      }
    };
};