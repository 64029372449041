/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PermissionDetails } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Permissions<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Permissions
     * @name ClientPermissionsList
     * @summary [🤵] All permissions availables.
     * @request GET:/admin/client/permissions
     * @secure
     */
    clientPermissionsList = (params: RequestParams = {}) =>
        this.request<PermissionDetails[], void>({
            path: `/admin/client/permissions`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
}
