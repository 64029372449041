import _ from "lodash";
import { AppThunk } from "store/store";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { auditActions } from "../auditSlice";
import { AuditRawFileCreate, AuditRawFileDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import apiErrorModel from "backend/utils/apiErrorModel";
import uploadFileToStorage from "utility/uploadFileToStorage";
import apiPickingDefinition from "backend/apiPickingDefinition";
import { AnalysisCreate } from "backend/ApiPickingDefinition/data-contracts";
import parseApiErrorToast from "utility/parseApiErrorToast";
import readFileContent from "utility/readFileContent";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";

/**
 * Thunk action creator for creating and uploading an audit raw file
 * @param mapId - The ID of the map
 * @param data - The data for creating the audit raw file
 * @param csvFile - The CSV file to upload
 * @param resultJsonFile - The JSON file that contains result to save
 * @param callbacks - Callbacks for success and error handling
 * @returns An AppThunk function
 */
export const createAndUploadAuditRawFileThunk = (
    mapId: string,
    data: AuditRawFileCreate,
    csvFile: File,
    resultJsonFile: File | undefined = undefined,
    callbacks: ThrunkApiCallBacks<AuditRawFileDetails>
  ): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(auditActions.setLoadingFileRawForm(true));
        
        // Create audit raw file
        const result = await apiBackofficeDefinition.auditFile.auditRawFilePost(mapId, data);
  
        // Check if creation was successful
        if (!result.ok) {
          // Dispatch action to set loading state back to false
          dispatch(auditActions.setLoadingFileRawForm(false));
          // Call onError callback with API error response
          callbacks.onError(result.error as apiErrorModel);
          return;
        }
  
        // Upload CSV file to storage
        const uploadFileResult = await uploadFileToStorage(result.data.temporaryUploadUrlDataCsv ?? "", csvFile);
  
        // Save json result if exist
        if(resultJsonFile &&  result.data){

          const bodyString = await readFileContent(resultJsonFile);
          const bodyJson = JSON.parse(bodyString) as AnalysisCreate;
          bodyJson.auditRawFileId = result.data.id;
          const jsonResult = await apiPickingDefinition.auditOptimization.auditRawFileOptimizationResultPost(mapId, result.data.id, bodyJson)
          if(!jsonResult.ok){
            parseApiErrorToast(jsonResult.error);
          }
          else
          {
            result.data.auditResultId = jsonResult.data.id
          }
        }



        // Check if upload was successful
        if (!uploadFileResult) {
          // Dispatch action to set loading state back to false
          dispatch(auditActions.setLoadingFileRawForm(false));
          // Call onError callback with error message
          callbacks.onError({errors:{
                "csvFile": [`Erreur lors de l'upload des fichiers sur le storage. La configuration crée est corrompue, veuillez la supprimer et la recréer.`]
            }} as apiErrorModel);
          return;
        }
  

        // Add newly created raw file to the store
        dispatch(auditActions.addRawFile(result.data));
  
        // Call onSuccess callback with the created raw file
        callbacks.onSuccess(result.data);
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        // Display error notification or perform error handling as needed
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(auditActions.setLoadingFileRawForm(false));
      }
    };
  };
