import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import { useAppDispatch } from 'store/store';
import Sdk from 'fno-sdk';
import { SapDemoData } from '../DemoHeatmapSAP';


type Props = {
    sdk: Sdk;
    data: SapDemoData | undefined;
  };


const PathPanel: React.FC<Props> = ({sdk, data}) => {
   const { t } = useTranslation(nameOf({PathPanel}), { useSuspense: false});
   const dispatch = useAppDispatch();

   useEffect(() => {
   }, [])
   return (
       <div>
           Page Path
       </div>
   )
}
export default PathPanel