import _ from 'lodash';
import { store } from 'store/store';


/**
 * Return true if current logged user have admin role
 * @returns 
 */
export default function currentUserIsAdmin(){
    return _.some(store.getState().userProfil.currentUser?.roles, (n) => n == "Admin") 
}