import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientMapActions } from "../adminClientMapSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Delete a map
 * @returns 
 */
export const deleteClientMapThrunk = (mapId: string, callbacks: ThrunkApiCallBacks<boolean>): AppThunk => {
    return async (dispatch, getState) => {
       try{

            dispatch(adminClientMapActions.setLoadingMapForm(true));
            await apiBackofficeDefinition.map.deleteMap(mapId)
                .then((result) => {
                    dispatch(adminClientMapActions.removeMap(mapId))
                    callbacks.onSuccess(true);
                })
                .catch((apiResponse: apiErrorModel) => {
                    callbacks.onError(apiResponse);
                })
                .finally(() => dispatch(adminClientMapActions.setLoadingMapForm(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la suppression de la map"))
        }
    }
}