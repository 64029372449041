import React, { useEffect, useState } from 'react'
import "./ImplementationList.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sdk, {MetadataGroup, Asset} from 'fno-sdk';
import { Accordion, Badge, Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import _ from 'lodash';
import ButtonFno from 'components/inputs/ButtonFno';
import Loading from 'components/Loading';
import LocationCodeForm from './LocationCodeForm';
import LocationsList from './LocationsList';
import { toast } from 'react-toastify';


interface OwnProps { 
    sdk: Sdk;
}

const ImplementationList: React.FC<OwnProps> = ({sdk}) => {

    const [assetSelected, setAssetSelected] = useState<string | undefined>();
    const [loadingMetadata, setLoadingMetadata] = useState<boolean>(false);
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
    
    const [metadataGroup, setMetadataGroup] = useState<MetadataGroup | undefined>();
    
    const [newEtiquette, setNewEtiquette] = useState("");
    const [newSku, setNewSku] = useState("");


    useEffect(() => {
        if(sdk)
        {
            sdk.asset.registerOnSelectAsset((event) => {
                setAssetSelected(event.asset.id);
            })
        }
        return () => {
            sdk.asset.clearHighlights();
        }
    }, [sdk])


    useEffect(() => {
        if(sdk)
        {
            sdk.asset.clearHighlights();

            if(assetSelected){
                
                sdk.asset.setHighlightAssetById(assetSelected, true);
                // sdk.camera.zoomOnEntity(assetSelected);
                setLoadingMetadata(true);
                
                sdk.metadata.getByAssetId(assetSelected)
                    .then((metadataGroups) => {
                        const metadataGroupFound = metadataGroups.find(m => m.name == "asset_products");
                        setMetadataGroup(metadataGroupFound);
                        setLoadingMetadata(false);
                    })
                    .finally(() => {
                        setLoadingMetadata(false);
                    })
            }
        }
        return () => {
        }
    }, [assetSelected])


    async function addMetadata(){
        if(newEtiquette && newSku && assetSelected){
           try{
                setLoadingUpdate(true);
                if(!metadataGroup){

                    const metadata: Record<string, string> = {};
                    metadata[newEtiquette] = newSku;
                    const result = await sdk.metadata.create(assetSelected, "asset_products",  metadata);
                    setMetadataGroup(result);
                }
                else
                {
                    metadataGroup.data[newEtiquette] = newSku;
                    const result = await sdk.metadata.update(assetSelected, metadataGroup);
                    setMetadataGroup(result);
                }
                setLoadingUpdate(false);
                setNewEtiquette("");
                setNewSku("");
           }
           catch(e)
           {
                setLoadingUpdate(false);
                setNewEtiquette("");
                setNewSku("");
           }
        }
    }

    async function deleteMetadata(key: string){
        if(key && assetSelected && metadataGroup){
            setLoadingUpdate(true);
            if(Object.keys(metadataGroup.data).length > 1){
                delete metadataGroup.data[key];
                const result = await sdk.metadata.update(assetSelected, metadataGroup);
                setMetadataGroup(result);
            }
            else
            {
                const result = await sdk.metadata.delete(assetSelected, metadataGroup.id);
                setMetadataGroup(undefined);
            }
            setLoadingUpdate(false);
        }
    }

    if(!sdk) return <></>; 


    const trs: JSX.Element[] = []
    _.forOwn(metadataGroup?.data, (value, key) => {
        trs.push(<tr key={key}>
            <td className=''>{key}</td>
            <td className=''>{value}</td>
            <td className=''><ButtonFno sm color='orange' onClick={() => deleteMetadata(key)}><FontAwesomeIcon icon={["fas", "trash"]} /></ButtonFno></td>
        </tr>)
    })



    return (
        <div id='demo-picking-implementation-list'>
            <h2 className='text-center mt-2'>Tag binding</h2>

            {!assetSelected && <div>
                <h4 className='text-center'>Select an asset in the 3D scene for editing location code</h4>

               <LocationsList sdk={sdk} onSelectAsset={(assetId) => setAssetSelected(assetId)}/>
                
            </div>}
            

            { assetSelected && <div>
                {<LocationCodeForm sdk={sdk} assetId={assetSelected}/>}

                {loadingMetadata && <div className='text-center mt-5'><Loading text='Loading metadata ...' /></div>}

                {!loadingMetadata && <div>
                    <Table striped bordered hover className={"table-metadata mt-5"}>
                        <thead>
                            <tr>
                                
                                <th>Etiquette</th>
                                <th>SKU</th>
                                <th>#</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loadingUpdate && <tr className='text-center mt-5'><td colSpan={3}><Loading inline size={40} /></td></tr>}
                            {!loadingUpdate && trs}
                        </tbody>
                        <tfoot>
                            {!loadingUpdate && <tr>
                                <th className=''><Form.Control value={newEtiquette} onChange={(e) => setNewEtiquette(e.target.value)}></Form.Control></th>
                                <th className=''><Form.Control value={newSku} onChange={(e) => setNewSku(e.target.value)}></Form.Control></th>
                                <th className=''><ButtonFno sm onClick={addMetadata}><FontAwesomeIcon icon={["fas", "plus"]} /></ButtonFno></th>
                            </tr>}
                        </tfoot>
                    </Table>
                </div>}

                {!loadingMetadata && <div className='text-center mt-5'>
                    <ButtonFno className='w-50' onClick={() => setAssetSelected(undefined)}><FontAwesomeIcon icon={["fas", "arrow-left"]}/> Back</ButtonFno>
                </div>}
            </div>}

        </div>
    )
}

export default ImplementationList
