import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PageHeader from 'components/PageHeader'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'resources/routes-constants'
import nameOf from 'utility/nameOf'

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation(nameOf({NotFoundPage}), { useSuspense: false});

    useEffect(() => {
        document.title = t('Page introuvable');
    }, [])
    
    /**
     * Call this function to redirect the user to the homepage.
     */
    const redirectToHomePage = () => {
        navigate(ROUTES.DASHBOARD)
    }

    return ( <div>
        <PageHeader title={t("La page demandée n'existe pas")}/>
        <div className='text-center'>
            <div className='mt-5'><h1><FontAwesomeIcon icon={["far", "compass"]} /></h1></div>
            <div className='mt-3'><h3>{t('Page introuvable')}</h3></div>

            <div className='mt-5'><a style={{ cursor: 'pointer' }} onClick={() => redirectToHomePage()}>{t("Page d'acceuil")}</a></div> 
        </div>
   </div>)
}

export default NotFoundPage
