import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import * as yup from 'yup';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import Switch from "react-switch";
import { ROUTES } from 'resources/routes-constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadFileForm from '../../Admin/forms/UploadFileForm';
import { useAppSelector } from 'store/store';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { IRawData } from 'store/reducers/Audit/auditSlice';
import { MapDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import { BaseBatchingRawFileColumnsBinding, BatchingColumnBindingProperty, BatchingRawFileDetails } from 'backend/ApiMiddlewareDefinition/data-contracts';


interface OwnProps {
    initialValues?: BatchingRawFileDetails;
    loading: boolean;
    allMaps: MapDetails[];
    columnsBinding: BatchingColumnBindingProperty[];
    onSubmit: ((values: BatchingRawFileFormInputs, formikHelpers: FormikHelpers<BatchingRawFileFormInputs>) => void | Promise<any>) & ((values: BatchingRawFileFormInputs, { setFieldError }: FormikHelpers<BatchingRawFileFormInputs>) => Promise<void>);
    onCancel: () => void
 }

 export interface BatchingRawFileFormInputs{
    name: string;
    mapId: string;
    dataCsvFile: File;
    columnsBindingData:Record<string, number>;
    totalSupports: number;
    totalRows: number;
    // if true, keep create modal open with same setting
    createAnother: boolean;
    // if true, send notification when batching optimization is ready
    sendNotificationWhenReady: boolean;
}



const BatchingUploadRawFileForm: React.FC<OwnProps> = ({onSubmit, onCancel, allMaps, loading, columnsBinding, initialValues = undefined}) => {
    
    const { t } = useTranslation([nameOf({PickingConfigurationForm: BatchingUploadRawFileForm}), "TransverseError"], { useSuspense: false});
    const [csvFileData, setCsvFileData] = useState<IRawData | undefined>(undefined);
    const formRef = useRef<FormikProps<BatchingRawFileFormInputs> | null>();


    const schemaForColumnBinding = {};
    columnsBinding.forEach(column => {
        //@ts-ignore
        schemaForColumnBinding[column.fieldName] = yup.string().required(t("required", { ns:"TransverseError"}) as string)

    })

    const schema = yup.object().shape({
        name: yup.string().required(t("required", { ns:"TransverseError"}) as string),
        mapId: yup.string().required(t("required", { ns:"TransverseError"}) as string),
        dataCsvFile:yup.mixed().required(t("required", { ns:"TransverseError"}) as string)
            .test('fileFormat', t("Le fichier doit être un csv", { ns:"TransverseError"}) as string, (value) => {return value && ['text/csv'].includes(value.type);}),
        columnsBindingData:yup.object().shape(schemaForColumnBinding)
      });






      const onSubmitForm = async (values: BatchingRawFileFormInputs, formikHelpers:FormikHelpers<BatchingRawFileFormInputs>) => {
        if(csvFileData && values.columnsBindingData.fieldSupport)
        {
            values.totalSupports = _.size(_.unionBy(csvFileData.rows, csvFileData.fields[values.columnsBindingData.fieldSupport]));
        }
        await onSubmit(values, formikHelpers);

        if(values.createAnother)
        {
            const {
                setFieldValue
            } = formikHelpers;

            setCsvFileData(undefined);
            setFieldValue("dataCsvFile", undefined)
            setFieldValue("totalRows", 0);
            setFieldValue("name", "")
        }

        return;
      }


      const onSelectFile = (file: File | undefined, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => 
      {
        setFieldValue("dataCsvFile", file)
        if(!file)
        {
            setCsvFileData(undefined);
            setFieldValue("totalRows", 0);
            return;
        }

        setFieldValue("name", file.name)

    


        Papa.parse<any[]>(file, {
            download: true,
            worker: true,
            header: true,
            error(error) {
                toast.error(t("Impossible de lire et d'extraire le contenu du fichier CSV"))
            },
            complete(results) {
                setCsvFileData({
                    fields: results.meta.fields,
                    rows: results.data.filter(m => m != undefined)
                } as IRawData);

                if(results.meta.fields?.length ?? 0 > 0)
                {
                    
                    setFieldValue("totalRows", results.data.length);

                    //const missionIndex = results.meta.fields?.findIndex((value) => _.toLower(value).includes("mission")) ?? -1;
                    const SupportIndex = results.meta.fields?.findIndex((value) => _.toLower(value).includes("support") || _.toLower(value).includes("box_code"))?? -1;
                    const EmplacementIndex = results.meta.fields?.findIndex((value) => _.toLower(value).includes("emplacement") || _.toLower(value).includes("adresse"))?? -1;

                    if(SupportIndex > -1) setFieldValue("columnsBindingData.fieldSupport", SupportIndex);
                    if(EmplacementIndex > -1) setFieldValue("columnsBindingData.fieldEmplacement", EmplacementIndex);

                    // trick for update validation in the form (setFieldValue is async so wait all setFieldValue. better solution had to be find. WAZA !)
                    setTimeout(() => formRef.current?.validateForm(formRef.current?.values), 250)
                } 
            },
        });
      }

      
      let columnIndexOptions: JSX.Element[] = [];
      if(csvFileData)
      {
        columnIndexOptions = [
            <option key={"A"} value={""}>{""}</option>,
            ...csvFileData.fields.map((header, index) => {
                return <option key={index} value={index}>{header}</option>
            })
          ]
      }

    return (
        <div>
                <Formik
                    innerRef={(item) => formRef.current = item}
                    validationSchema={schema}
                    onSubmit={onSubmitForm}
                    initialValues={{
                        name: initialValues?.name ?? `Data ${dayjs().format("YYYY-MM-DD")}`,
                        createAnother: false,
                        sendNotificationWhenReady: true,
                        mapId: initialValues?.mapId,
                        columnsBindingData: initialValues?.columnsBindingData ?? {
                            fieldSupport: initialValues?.columnsBindingData?.fieldSupport ?? "",
                            fieldEmplacement: initialValues?.columnsBindingData?.fieldEmplacement ?? "",
                        }
                    } as BatchingRawFileFormInputs}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        isSubmitting,
                        setFieldValue,
                        validateForm,
                        errors,
                    }) => {

                        const renderColumnSelector = (label: string, fieldName: string, fieldValueIndex: number, isValid?: boolean, isInvalid?: boolean, error?: string) => {
                            return <Row>
                                <Col>
                                    <Form.Group className='mb-3 text-center'>
                                        <Form.Select disabled={loading} value={fieldValueIndex > -1 ? fieldValueIndex :  ""} className='text-center'
                                            onChange={(item) => setFieldValue(`columnsBindingData.${fieldName}`, item.currentTarget.value)}
                                            isValid={isValid} isInvalid={isInvalid}>
                                                {columnIndexOptions}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm="1" className='pt-1'><FontAwesomeIcon icon={['fas', 'right-long']} size="lg"/></Col>
                                <Col>
                                    <Form.Control type="hidden" name="id" value={fieldValueIndex}></Form.Control>
                                    <Form.Label className='text-center w-100 m-1'>{label}</Form.Label>
                                </Col>
                            </Row>;
                        }

                        return (
                            <fieldset disabled={isSubmitting}>
                                    <Row>
                                        <Col>
                                            <Form.Control type="hidden" name="id" value={values.name}></Form.Control>
                                                <Form.Group className='mb-3' controlId="validationFormik03">
                                                    <Form.Label>{t("Nom du fichier")}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        disabled={loading}
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        isValid={touched.name && !errors.name}
                                                        isInvalid={!!errors.name}
                                                    />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik07">
                                                <Form.Label>{t("Carte")}</Form.Label>
                                                <Form.Select
                                                    value={values.mapId}
                                                    name={"mapId"}
                                                    disabled={!!initialValues?.mapId}
                                                    isValid={touched.mapId && !errors.mapId}
                                                    isInvalid={!!errors.mapId}
                                                    onChange={(item) => setFieldValue("mapId", item.target.value)}>
                                                    <option></option>
                                                    {allMaps.map((r) => <option key={r.id} value={r.id}>{r.name}</option>)}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.mapId}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                   <Row>
                                    <Col>
                                        <Form.Group className='mb-3'>
                                             <Form.Control type="hidden" name="totalRows" value={values.totalRows}></Form.Control>

                                            <Form.Label>{t("Fichier data (.csv)")}</Form.Label>
                                            <Form.Control.Feedback type="invalid" style={{display:"block"}}>
                                                {errors.dataCsvFile  && t(errors.dataCsvFile as string, { ns:"TransverseError"}) }
                                            </Form.Control.Feedback>
                                            {values.dataCsvFile ? 
                                            <div><Button size='sm' variant='danger' className='ms-1' 
                                                        onClick={() => onSelectFile(undefined, setFieldValue)}>
                                                            <FontAwesomeIcon icon={["fas", "trash"]} />
                                                    </Button> {values.dataCsvFile.name}</div>: 
                                            <UploadFileForm  loading={loading}  
                                                            onSubmit={(files) => onSelectFile(files[0], setFieldValue)}/>}

  
                                        </Form.Group>
                                        </Col>
                                   </Row>

                                    {csvFileData && csvFileData.fields.length > 0 && <>
                                        <div className="mb-3 text-center">{t("Total de lignes: ")} <b>{values.totalRows}</b></div>
                                        <Row className="mb-3">
                                            <Col className='text-center'><h4>{t("Column du CSV")}</h4></Col>
                                            <Col sm="1" className='pt-1'><FontAwesomeIcon icon={['fas', 'right-long']} size="lg"/></Col>
                                            <Col className='text-center'><h4>{t("Propriété F&O")}</h4></Col>
                                        </Row>


                                        {columnsBinding.map(col => {

                                            const fieldName = col.fieldName;
                                            const fieldError = errors.columnsBindingData?.[fieldName];
                                            const fieldTouched = touched.columnsBindingData?.[fieldName];
                                            return renderColumnSelector(
                                                t(col.fieldLabel), fieldName, 
                                                values.columnsBindingData[fieldName], 
                                                fieldTouched && !fieldError, 
                                                !!fieldError, 
                                                fieldError)
                                        })}

                                        {/*renderColumnSelector(t("Support"), "fieldSupport", values.columnsBindingData?.fieldSupport, touched.columnsBindingData?.fieldSupport && !errors.columnsBindingData?.fieldSupport, !!errors.columnsBindingData?.fieldSupport, errors.columnsBindingData?.fieldSupport)}
                                        {renderColumnSelector(t("Emplacement"), "fieldEmplacement", values.columnsBindingData?.fieldEmplacement, touched.columnsBindingData?.fieldEmplacement && !errors.columnsBindingData?.fieldEmplacement, !!errors.columnsBindingData?.fieldEmplacement, errors.columnsBindingData?.fieldEmplacement)*/}

                                    </>}

                                    <div className='d-flex justify-content-center'>
                                        <ButtonFno disabled={loading} className="w-50 me-1" color='blue' onClick={onCancel}>{t('Retour')}</ButtonFno>
                                        <ButtonFno disabled={loading} className="w-50 ms-1" color='orange' loading={loading} onClick={handleSubmit}>{t('Valider')}</ButtonFno>
                                    </div>

                                    {loading && <div className='d-flex justify-content-center mt-4'>
                                        <h5 className='text-orange'><FontAwesomeIcon icon={['fas', 'warning']} size="sm"/> {t('Cette opération peut prendre plusieurs secondes')}</h5>
                                    </div>}

                                    <Row>
                                        <Col>
                                            <Form.Group className='mt-2'>
                                                <Form.Check
                                                        type={"checkbox"}
                                                        defaultChecked={values.sendNotificationWhenReady}
                                                        id={`sendNotificationWhenReady`}
                                                        onChange={(item) => setFieldValue("sendNotificationWhenReady", item.target.checked)}
                                                        label={t("Recevoir le résultat par mail")}
                                                    />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className='mt-2'>
                                                <Form.Check
                                                    type={"checkbox"}
                                                    id={`createAnother`}
                                                    onChange={(item) => setFieldValue("createAnother", item.target.checked)}
                                                    label={t("Importer un autre fichier après celui là")}
                                                />
                                            </Form.Group>
                                        </Col>
                                   </Row>
                                    
                                </fieldset>
                            )
                    }}
                    </Formik> 
        </div>
    )
}

export default BatchingUploadRawFileForm
