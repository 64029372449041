import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import httpApi from 'i18next-http-backend'
import getApiUrl from "backend/utils/getApiUrl";
import currentUserIsAdmin from "backend/utils/currentUserIsAdmin";
import getCurrentUserToken from "backend/utils/getCurrentUserToken";

/**
 * Init i18n translation for application
 */
export default function initI18nTranslation(){
    i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(httpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    /*resources: {
      en: {
        translation: {
          "welcome": "Welcome"
        }
      },
      fr: {
        translation: {
          "welcome": "Bienvenue"
        }
      },
    },*/
    //resources: {},
    react:{
      useSuspense: false
    },
    lng: "fr", // if you're using a language detector, do not define the lng option
    fallbackLng: "fr",
    saveMissing:true,
    saveMissingPlurals: true,
    returnNull: false,
    backend:{
      loadPath:(lng, ns) => {
        return getApiUrl(`/locale/${lng}/${ns}`);
      },
      addPath: (lng, ns) => {
        if(currentUserIsAdmin())
        {
          return getApiUrl(`/locale/${lng}/${ns}`);
        }
        return "";
      },
      customHeaders:() => {
        const token = getCurrentUserToken();
        return {
          ["Authorization"] : token ? `Bearer ${token}` : ''
        };
        /*if(token)
        {
         
        }
        else{
          return {["Authorization"]: ''};
        }*/
       
      }
    },
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });
}