import React from 'react'
import "./StatisticCard.scss"
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from './Loading';


type Props = {
    value: string | number;
    title: string;
    onClick?: () => void;
    color: 'blue' | 'orange' | 'green' | 'red' | 'yellow';
    icon: IconName;
    loading?: boolean;
    sm?: boolean
  };

/**
 * A statistic card is a component used to display a value and its title associated with an icon.
 * The card can be colored in blue, orange, green, red or yellow.
 * It can also display a loading spinner if the data is not yet loaded.
 * The card can be clicked, and a function can be called when the card is clicked.
 * The card can be small, and it will be displayed in a smaller size.
 * 
 * @param value The value to display, can be a number or a string.
 * @param title The title of the statistic, displayed under the value.
 * @param color The color of the statistic card, can be 'blue', 'orange', 'green', 'red' or 'yellow'. Defaults to 'blue'.
 * @param icon The icon to display in the statistic card.
 * @param loading Whether the statistic card is loading or not.
 * @param sm Whether the statistic card is small or not.
 * @param onClick The function to call when the statistic card is clicked.
 * @returns The statistic card component.
 */
const StatisticCard: React.FC<Props> = ({value, title, color = "blue", icon, loading = false, sm = false, onClick = undefined}) => {

    const onClickCard = () => {
        if(onClick) onClick();
    }

    return <div className={'statistic-card ' + color + " " + (sm ? "sm" : "") + " " + (onClick ? "clickable" : "") } onClick={onClickCard}>
        <div className='icon d-none d-md-block d-lg-block'><FontAwesomeIcon icon={["fas", icon]}/></div>
        <div className='data'>
            {loading ?<div className='text-center mt-2'><Loading inline color='white'/></div> : <>
                <div className={'value ' + (value.toString().length > 6 ? 'big' : '')}>{value}</div>
                <div className='title'>{title}</div>
            </>}

        </div>
    </div>

}

export default StatisticCard