import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiErrorModel from "backend/utils/apiErrorModel";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { BuildingCreate, BuildingDetails} from "backend/ApiBackofficeDefinition/data-contracts";
import { adminClientMapBuildingActions } from "../adminClientMapBuildingSlice";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";

/**
 * Thunk action creator for creating a new building 
 * @param mapId 
 * @param building
 * @param callbacks 
 * @returns 
 */
export const createBuildingThunk = (
    mapId: string,
    building: BuildingCreate,
    callbacks: ThrunkApiCallBacks<BuildingDetails>
  ): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapBuildingActions.setLoadingBuildingForm(true)); 
        // Make API call to create building 
        await apiBackofficeDefinition.building.buildingPost(mapId, building)
          .then((result) => {
            // Dispatch action to add newly created building to store 
            dispatch(adminClientMapBuildingActions.addBuilding(result.data)); 
            // Call onSuccess callback
            callbacks.onSuccess(result.data);
          })
          .catch((apiResponse: apiErrorModel) => {
            // Call onError callback with API error response
            callbacks.onError(apiResponse);
          });
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors de la création du bâtiment"));
          callbacks.onError({ errors:{"api":[error.message]} } as apiErrorModel)
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapBuildingActions.setLoadingBuildingForm(false));
      }
    };
  };
