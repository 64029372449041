import _ from "lodash";
import { AppThunk } from "store/store";
import { adminClientAuditOptimizationSettingActions } from "../adminClientMapOptimizationSettingSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiAdminDefinition from "backend/apiAdminDefinition";



export const deleteOptimizationSettingThunk = (
    mapId: string,
    settingId: string,
    callbacks: ThrunkApiCallBacks<boolean>
): AppThunk => {
    return async (dispatch, getState) => {
        
        // Set loading state to true
        dispatch(adminClientAuditOptimizationSettingActions.setLoadingForm(true));

        // Delete audit raw file
        const result = await apiAdminDefinition.optimizationSetting.mapOptimizationSettingDelete(mapId, settingId);
        if (!result.ok) {
            // Call onError callback with API error response
            callbacks.onError(result.error as apiErrorModel);
        } else {
            dispatch(adminClientAuditOptimizationSettingActions.deleteOptimizationSetting(settingId));
            // Call onSuccess callback with true indicating successful deletion
            callbacks.onSuccess(true);
        }
        
        // Set loading state back to false after the operation completes
        dispatch(adminClientAuditOptimizationSettingActions.setLoadingForm(false));
    };
};
