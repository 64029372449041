import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminGlobalActions } from "store/reducers/Admin/Global/adminGlobalReducer";
import apiAdminDefinition from "backend/apiAdminDefinition";


/**
 * Load all app's roles (Admin, client)
 * @returns 
 */
export const loadAppRolesThunk = (): AppThunk => {
    return async (dispatch, getState) => {
        try{
            const roles = await apiAdminDefinition.appRole.appRolesList();
            

            dispatch(adminGlobalActions.setAppRoles(roles.data));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des rôle de l'application"));
            console.error("[loadAppRolesThunk] error", error)
        }
    }
}