import { AuditOptimization } from "./ApiPickingDefinition/AuditOptimization";
import { RunnerManagement } from "./ApiPickingDefinition/RunnerManagement";
import getApiPickingConfig from "./utils/getApiPickingConfig";





export default {
    auditOptimization: new AuditOptimization(getApiPickingConfig()),
    runnerManagement: new RunnerManagement(getApiPickingConfig()),
}