import i18next from "i18next";
import { toast } from "react-toastify";
import IUserProfil from "models/IUserProfil";
import { loginActions } from "store/reducers/Login/loginSlice";
import { userProfilActions} from "store/reducers/Profil/userProfilSlice";
import { AppThunk } from "store/store"
import { selectTenantForClientThunk } from "../../Profil/thrunks/selectTenantForClientThunk";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";

/**
 * Log user to API and set user data (token, tenants etc ...) in the store
 * @param email 
 * @param password 
 * @returns 
 */
export const loginThunk = (email: string, password: string): AppThunk => {
    return async (dispatch, getState) => {

        try{

            dispatch(loginActions.setLoading(true));
            const result = await apiBackofficeDefinition.auth.loginPost({email, password});

            if(result.ok)
            {
                dispatch(userProfilActions.setCurrentUser({
                    email: email,
                    token: result.data.token,
                    tenants: result.data.clientTenants,
                    roles: result.data.roles
                } as IUserProfil));
                dispatch(loginActions.setErrors(undefined));
                dispatch(loginActions.setShowSessionExpiredModal(false));
                
                // select first tenant if user not admin+
                if(!result.data.roles.some(m => m == "Admin")){
                    dispatch(selectTenantForClientThunk(result.data.clientTenants[0]));
                }
            }
            else{
                dispatch(loginActions.setErrors(result.error.errors));
            }
            dispatch(loginActions.setLoading(false));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Email ou mot de passe non valide"))
            dispatch(loginActions.setLoading(false));
        }

    }
}