/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, MiddlewareApiCreate, MiddlewareApiDetails } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Middlewares<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Middlewares
     * @name MiddlewaresList
     * @summary [🤵] Get all middleware. [🔒: map_read].
     * @request GET:/admin/middlewares
     * @secure
     */
    middlewaresList = (params: RequestParams = {}) =>
        this.request<MiddlewareApiDetails[], ApiErrorsResponse | void>({
            path: `/admin/middlewares`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Middlewares
     * @name MiddlewarePost
     * @summary [🤵]
     * @request POST:/admin/middleware
     * @secure
     */
    middlewarePost = (data: MiddlewareApiCreate, params: RequestParams = {}) =>
        this.request<MiddlewareApiDetails, ApiErrorsResponse | void>({
            path: `/admin/middleware`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Middlewares
     * @name MiddlewareDelete
     * @summary [🤵]
     * @request DELETE:/admin/middleware/{id}
     * @secure
     */
    middlewareDelete = (id: string, params: RequestParams = {}) =>
        this.request<any, ApiErrorsResponse | void>({
            path: `/admin/middleware/${id}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
