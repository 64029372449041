import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { TableColumn } from 'react-data-table-component';
import { MetadataGroupCreate, MetadataGroupDetails, MetadataGroupUpdate } from 'backend/ApiBackofficeDefinition/data-contracts';
import { loadMetadataThunks } from 'store/reducers/Admin/ClientMapMetadata/thrunks/loadMetadataThunks';
import { updateMetadataThunk } from 'store/reducers/Admin/ClientMapMetadata/thrunks/updateMetadataThunk';
import { createMetadataThunk } from 'store/reducers/Admin/ClientMapMetadata/thrunks/createMetadataThunk';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';

const AdminClientMapMetadataGroup: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapMetadataGroup}), { useSuspense: false});
    const dispatch = useAppDispatch();

    const {
        metadataGroups,
        loadingList,
        loadingForm
    } = useAppSelector(state => state.adminMapMetadata);


    const { clientId, mapId } = useParams();

    useEffect(() => {
        document.title = t('[Admin] Metadata groupes de la map');
        if(clientId && mapId && mapId != "new")
        {
            dispatch(loadMetadataThunks(mapId));
        }
    }, [clientId, mapId])

    const onUpdate = (marker: MetadataGroupDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(updateMetadataThunk(mapId, marker as MetadataGroupUpdate,{
                onSuccess: (marker) => {
                    closeModal();
                    toast.success(t("Le template a bien été modifié"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onCreate = (marker: MetadataGroupDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(createMetadataThunk(mapId, marker as MetadataGroupCreate,{
                onSuccess: (marker) => {
                    closeModal();
                    toast.success(t("Le template a bien été créé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onDelete = (marker: MetadataGroupDetails, closeModal: () => void) => {
        /*TODO a terminer
        if(clientId && mapId)
        {
            dispatch(deleteMarkerTemplateThrunks(mapId, marker.id,{
                onSuccess: (success) => {
                    closeModal();
                    toast.success(t("Le template a bien été supprimé"))
                    setSelectedTemplateDelete(undefined);
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }*/
    }


    const columns: TableColumn<MetadataGroupDetails>[] = [
        {
            id:'name',
            name: t('name'),
            sortable: true,
            selector: row => row.name,
        },
        {
            id:'data',
            name: t('Nb data'),
            sortable: true,
            selector: row => _.size(row.data),
        },
        {
            id:'version',
            name: t('Version'),
            center:true,
            selector: row => row.version,
        }
    ];


    return <TableWithCrudComponent 
        entities={metadataGroups} 
        columns={columns}
        loadingForm={loadingForm}
        loadingList={loadingList}
        onDelete={undefined/*TODO a terminer: onDelete*/}
        onUpdate={onUpdate}
        onCreate={onCreate}
        translations={{
            tableTitle: t("Metadata de la carte"),
            noEntityText: t("Aucun metadata disponible"),
            createTitle: t("Ajouter un metadata"),
            createButtonText: t("Ajouter un metadata"),
            deleteText: (entity) => (t("Voulez-vous supprimer le metadata ?")),
            deleteTitle: (entity) => (t("Supprimer le metadata ") + entity.name ?? entity.id),
            updateText: (entity) => (t("Modifier le metadata ")  + entity.name ?? entity.id),
        }}
        fieldSearchable={(entity) => [
            entity.name,
            entity.id
        ]}
        initialValueCreateForm={{
            name: "Nouveau groupe",
            mapId: mapId ?? "",
            data: {
                "key1": "value1"
            }
        } as MetadataGroupCreate}
        />;
}

export default AdminClientMapMetadataGroup
