import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import currentUserIsAdmin from 'backend/utils/currentUserIsAdmin';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store'
import nameOf from 'utility/nameOf';

const ManageTokens: React.FC = () => {
    const { t } = useTranslation(nameOf({ManageTokens}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(state => state.userProfil.currentUser);
    
    useEffect(() => {
        document.title = t('Clés API');
    }, [])

    return (
        <div>
            <PageHeader title={t("Clés API")}/>
            <div>
                TODO
            </div>
        </div>
    )
}

export default ManageTokens
