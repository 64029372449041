import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LabelTemplateDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

// Interface representing the state of the admin client map LabelTemplate store
export interface IAdminClientMapLabelTemplateStore {
  labelTemplates: LabelTemplateDetails[];
  loadingList: boolean;
  loadingForm: boolean;
}

// Initial state for the admin client map LabelTemplate store
const initialState: IAdminClientMapLabelTemplateStore = {
  labelTemplates: [],
  loadingList: false,
  loadingForm: false,
};

// Create a slice for the admin client map LabelTemplate store
const adminClientMapLabelTemplateSlice = createSlice({
  name: "adminClientMapLabelTemplateSlice",
  initialState,
  reducers: {
    // Set the LabelTemplates in the state
    setLabelTemplates: (state, action: PayloadAction<LabelTemplateDetails[]>) => {
      state.labelTemplates = action.payload;
    },
    // Update a specific LabelTemplate in the state
    updateLabelTemplate: (state, action: PayloadAction<LabelTemplateDetails>) => {
      state.labelTemplates = state.labelTemplates.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new LabelTemplate to the state
    addLabelTemplate: (state, action: PayloadAction<LabelTemplateDetails>) => {
      state.labelTemplates.push(action.payload);
    },
    // remove an LabelTemplate
    deleteLabelTemplate: (state, action: PayloadAction<string>) => {
      state.labelTemplates = state.labelTemplates.filter(m => m.id !== action.payload)
    },
    // Set the loadingList state
    setLoadingLabelTemplateList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingForm state
    setLoadingLabelTemplateForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.labelTemplates = [];
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

// Export the actions and reducer from the admin client map LabelTemplate slice
export const {
  actions: adminClientMapLabelTemplateActions,
  reducer: adminClientMapLabelTemplateReducer,
} = adminClientMapLabelTemplateSlice;
