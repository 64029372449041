import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiErrorModel from "backend/utils/apiErrorModel";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { MarkerTemplateDetails, MarkerTemplateUpdate} from "backend/ApiBackofficeDefinition/data-contracts";
import { adminClientMapMarkerTemplateActions } from "../adminClientMapMarkerTemplateSlice";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Thunk action creator for updating an MarkerTemplate
 * @param mapId 
 * @param MarkerTemplate 
 * @param callbacks 
 * @returns 
 */
export const updateMarkerTemplateThunk = (
    mapId: string,
    MarkerTemplate: MarkerTemplateUpdate,
    callbacks: ThrunkApiCallBacks<MarkerTemplateDetails>
  ): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapMarkerTemplateActions.setLoadingMarkerTemplateForm(true));
  
        // Make API call to create MarkerTemplate
        await apiBackofficeDefinition.markerTemplate.markerTemplatePut(mapId, MarkerTemplate.id ,MarkerTemplate)
          .then((result) => {
            // Dispatch action to add newly created MarkerTemplate to store
            dispatch(adminClientMapMarkerTemplateActions.updateMarkerTemplate(result.data));
            // Call onSuccess callback
            callbacks.onSuccess(result.data);
          })
          .catch((apiResponse: apiErrorModel) => {
            // Call onError callback with API error response
            callbacks.onError(apiResponse);
          });
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors de la modification du MarkerTemplate"));
          callbacks.onError({ errors:{"api":[error.message]} } as apiErrorModel)
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapMarkerTemplateActions.setLoadingMarkerTemplateForm(false));
      }
    };
  };