import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getCurrentTenant from 'backend/utils/getCurrentTenant';
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import FloorControls from 'components/mapControls/FloorControls';
import sdk, { Asset } from 'fno-sdk';
import useFnoMapSdk from 'hooks/useFnoMapSdk';
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/store';
import DatePicker from "react-datepicker";
import nameOf from 'utility/nameOf';
import dayjs from 'dayjs';
import DatesRangeForm from 'components/inputs/DatesRangeForm';
import AssetTooltip from 'components/mapControls/AssetTooltip';
import MapRender from 'components/mapControls/MapRender';
import appColors from 'resources/appColors';
import ButtonFno from 'components/inputs/ButtonFno';

const IventiHeatmap: React.FC = () => {
   const { t } = useTranslation(nameOf({IventiHeatmap}), { useSuspense: false});
   const currentMap = useAppSelector(state => state.map.currentMap);
   const {
    sdk,
    loading,
    mapReady
} = useFnoMapSdk(getCurrentUserToken(), getCurrentTenant()?.tenant ?? "", currentMap?.id ?? "");

    const [dateFrom, setDateFrom] = useState<Date>(dayjs().add(-29, 'days').toDate());
    const [dateTo, setDateTo] = useState<Date>(dayjs().toDate());
    const [categoryToShow, setCategoryToShow] = useState<'paths' | "wifi">("wifi");

   const dispatch = useAppDispatch();

   useEffect(() => {

    return () => {
        sdk.asset.clearHighlights();
    }
   }, [])


    useEffect(() => {
        if(mapReady && dateTo && dateFrom){
            sdk.heatmap.displayHeatmap(dateFrom, dateTo, categoryToShow);
        }
    }, [mapReady, dateFrom, dateTo, categoryToShow])

    
   return (
        <div id="statistic-picking-optimization-path">
            {<PageHeader title={t('Editeur de carte')}/>}
            <Row style={{height: "100%"}}>
                <Col sm="8">
                    <MapRender 
                        sdk={sdk} 
                        mapReady={mapReady}
                        assetToolipConfig={{
                            highlightColor: appColors.orange
                        }}/>
                </Col>
                <Col id="col-table-container" sm="4">
                    {mapReady && !loading && <div>
                        <div style={{maxWidth:"300px", margin:"auto"}}>
                            <DatesRangeForm from={dateFrom} to={dateTo} onDateChange={(from, to)=> {
                                setDateFrom(from);
                                setDateTo(to);
                            }}/>
                        </div>
                        <div className='d-flex'>
                            <div className='col p-1'><ButtonFno color={categoryToShow == "paths" ?  "blue" : "gray"} className='m-1 w-100' onClick={() => setCategoryToShow("paths")} ><FontAwesomeIcon className='me-2' icon={["fas", "route"]} /></ButtonFno></div>
                            <div className='col p-1'><ButtonFno color={categoryToShow == "wifi" ?  "blue" : "gray"} className='m-1 w-100' onClick={() => setCategoryToShow("wifi")} ><FontAwesomeIcon className='me-2' icon={["fas", "wifi"]} /></ButtonFno></div>
                        </div>
                    </div>}
                    {!mapReady && <div className='loading-map-panel'><Loading text={t("Chargement des données")}/></div>}
                </Col>
            </Row>
        </div>
    )
}
export default IventiHeatmap