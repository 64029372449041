import { AppThunk } from "store/store"
import { IRawData, auditActions } from "store/reducers/Audit/auditSlice";
import Papa from "papaparse";
import { AuditRawFileDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { toast } from "react-toastify";
import i18next from "i18next";
import { LoadAuditHeatMap } from "./LoadAuditHeatMap";


/**
 * Load audit data for current map
 * @returns 
 */
export const SelectAuditDataThunk = (audit: AuditRawFileDetails | undefined): AppThunk => {
    return async (dispatch, getState) => {

        try{

            const currentAuditSelected = getState().audit.auditRawFileSelected

            

            if(audit?.id == currentAuditSelected?.id)
            {
                return;
            }
            
            if(audit)
            {
                dispatch(auditActions.setLoadingList(true));
                
                dispatch(auditActions.setAuditRawFileSelected(audit));
                // Remove old result values
                dispatch(auditActions.setOptimizationResults(undefined));

                // preload heatmap data
                dispatch(LoadAuditHeatMap(audit.mapId,audit))
                
                Papa.parse<any[]>(audit.downloadUrlDataCsv, {
                    download: true,
                    worker: true,
                    header: true,
                    error: function(error, file) {
                        console.error("SelectAuditDataThunk parse csv error", error)
                        toast.error(i18next.t('Erreur lors du chargement du CSV: ' + error.message))
                        dispatch(auditActions.setLoadingList(false));
                    },
                    complete: function(results) {
                        if(!results.meta.fields) results.meta.fields = [];

                        const rawData = {
                            fields: results.meta.fields,
                            rows: results.data.filter(m => m != undefined)
                        } as IRawData;

                        dispatch(auditActions.setAuditData(rawData))
                        dispatch(auditActions.setLoadingList(false));
                    }
                });
            }
            else
            {
                dispatch(auditActions.setAuditData(undefined));
            }
            
        }
        catch(error: any)
        {
            /*if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des données d'audit"))*/
            console.error("[SelectAuditDataThunk] error", error)
            
        }
    }
}