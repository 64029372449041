import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiErrorModel from "backend/utils/apiErrorModel";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { MarkerCreate, MarkerDetails} from "backend/ApiBackofficeDefinition/data-contracts";
import { adminClientMapMarkerActions } from "../adminClientMapMarkerSlice";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Thunk action creator for creating a new asset
 * @param mapId 
 * @param marker 
 * @param callbacks 
 * @returns 
 */
export const createMarkerThunk = (
    mapId: string,
    marker: MarkerCreate,
    callbacks: ThrunkApiCallBacks<MarkerDetails>
  ): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapMarkerActions.setLoadingMarkerForm(true));
        // Make API call to create entity
        await apiBackofficeDefinition.marker.markerPost(mapId, marker)
          .then((result) => {
            // Dispatch action to add newly created asset to store
            dispatch(adminClientMapMarkerActions.addMarker(result.data));
            // Call onSuccess callback
            callbacks.onSuccess(result.data);
          })
          .catch((apiResponse: apiErrorModel) => {
            // Call onError callback with API error response
            callbacks.onError(apiResponse);
          });
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors de la création du marker"));
          callbacks.onError({ errors:{"api":[error.message]} } as apiErrorModel)
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapMarkerActions.setLoadingMarkerForm(false));
      }
    };
  };