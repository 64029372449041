import React from 'react'
import { useTranslation } from 'react-i18next';
import currentUserIsAdmin from 'backend/utils/currentUserIsAdmin';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store'
import nameOf from 'utility/nameOf';
import { Navigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';

const MiddlewarePickingDashboard: React.FC = () => {
    const { t } = useTranslation(nameOf({MiddlewarePickingDashboard}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(state => state.userProfil.currentUser);

    return (
        <div>
             <PageHeader title={t("Picking Dashboard")}/>
            <div>
                <Navigate to={ROUTES.PICKING_STATISTICS} replace={true} />
            </div>
        </div>
    )
}

export default MiddlewarePickingDashboard
