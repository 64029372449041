import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SpinnerDotted,   } from 'spinners-react';
import "./Loading.scss"


interface OwnProps {
    text?: string;
    fullScreen? : boolean;
    inline?: boolean;
    size?: number;
    fullScreenBackgroundColor?: string;
    color?: string;
 }
 
 /**
 * React functional component for displaying loading spinner with optional text.
 *
 * @param {OwnProps} text - optional text to display with the spinner
 * @param {boolean} fullScreen - flag to display the loading spinner in full screen
 * @param {boolean} inline - flag to display the loading spinner inline
 * @param {type} size - description of parameter
 * @param {type} fullScreenBackgroundColor - description of parameter
 * @param {string} color - color of the spinner
 * @return {ReactElement} the loading spinner element
 */
const Loading: React.FC<OwnProps> = ({text = undefined, fullScreen = false, inline = false, size = undefined, fullScreenBackgroundColor = undefined, color = "#ff6d3a"}) => {
    useEffect(() => {
        
    }, [])

    if(fullScreen)
    {
        const fullscreenStyles: React.CSSProperties = {
            backgroundColor : fullScreenBackgroundColor ? fullScreenBackgroundColor : undefined
        }

        return (
            <Row className={'loading-component fullscreen align-items-center g-0'} style={fullscreenStyles}>
                <Col>
                    <SpinnerDotted color={color}/>
                    <div style={{ marginTop:"20px"}}>
                        {text}
                    </div>
                </Col>
            </Row>
        )
    }
    else if(inline)
    {
        return <SpinnerDotted size={size} color={color}/>;
    }

    return (
        <div className={'loading-component text-center mt-2'}>
            <SpinnerDotted color={color}/>
           {text && <div style={{ marginTop:"20px"}}>
                {text}
            </div>}
        </div>
    )



   
}

export default Loading
