import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RoleClientDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";


// Interface representing the state of the admin role store
export interface IAdminRoleStore {
  roles: RoleClientDetails[];
  loadingList: boolean;
  loadingEdit: boolean;
}

// Initial state for the admin role store
const initialState: IAdminRoleStore = {
  roles: [],
  loadingList: false,
  loadingEdit: false
};

// Create a slice for the admin client roles store
const adminClientRolesSlice = createSlice({
  name: "adminClientRolesSlice",
  initialState,
  reducers: {
    // Set the roles in the state
    setRoles: (state, action: PayloadAction<RoleClientDetails[]>) => {
      state.roles = action.payload;
    },
    // Update a specific role in the state
    updateRole: (state, action: PayloadAction<RoleClientDetails>) => {
      state.roles = state.roles.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new role to the state
    addRole: (state, action: PayloadAction<RoleClientDetails>) => {
      state.roles = [...state.roles, action.payload];
    },
    // Set the loadingList state
    setLoadingRolesList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingEdit state
    setLoadingEdit: (state, action: PayloadAction<boolean>) => {
      state.loadingEdit = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.roles = [];
      state.loadingList = false;
      state.loadingEdit = false;
    });
  },
});

// Export the actions and reducer from the admin client roles slice
export const {
  actions: adminClientRolesActions,
  reducer: adminClientRolesReducer,
} = adminClientRolesSlice;