
import { PermissionsEnum } from 'backend/ApiBackofficeDefinition/data-contracts';
import currentUserIsAdmin from 'backend/utils/currentUserIsAdmin';
import { store } from 'store/store';

/**
 * Check if current user have right for a specific permissions for current place. If current user is admin, always return true
 * @param feature 
 * @returns true if use have permission or false
 */
export default function checkPermissions(feature: PermissionsEnum){

    if(currentUserIsAdmin()) return true;


    const state = store.getState();

    const currenMap = state.map.currentMap
    if(!currenMap) return false;

    const features = state.userProfil.userInfos?.permissionsByMap[currenMap.id]
    if(!features) return false;

    return features[feature] == true;
}