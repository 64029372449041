import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientPickingActions } from "../adminClientPickingSlice";
import apiAdminDefinition from "backend/apiAdminDefinition";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";


/**
 * Delete a runner
 * @returns 
 */
export const deleteRunnerThrunks = (runnerID: string, callbacks: ThrunkApiCallBacks<boolean>): AppThunk => {
    return async (dispatch, getState) => {

       try{

            dispatch(adminClientPickingActions.setLoadingEdit(true));
            await apiAdminDefinition.pickingRunner.pickingRunnerDelete(runnerID)
                .then((result) => {
                    dispatch(adminClientPickingActions.deleteRunner(runnerID))
                    callbacks.onSuccess(true);
                })
                .catch((apiResponse: apiErrorModel) => {
                    callbacks.onError(apiResponse);
                })
                .finally(() => dispatch(adminClientPickingActions.setLoadingEdit(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la suppression du runner"))
        }
    }
}