import React from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import * as yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import { Formik, FormikHelpers } from 'formik';
import { MiddlewareApiCreate, MiddlewareApiDetails } from 'backend/ApiAdminDefinition/data-contracts';
import { useAppSelector } from 'store/store';



interface OwnProps {
    initialValues?: MiddlewareApiCreate;
    loading: boolean;
    onSubmit: ((values: IMiddlewareFormInputs, formikHelpers: FormikHelpers<IMiddlewareFormInputs>) => void | Promise<any>) & ((values: IMiddlewareFormInputs, { setFieldError }: FormikHelpers<IMiddlewareFormInputs>) => Promise<void>);
    onCancel: () => void
 }

 export interface IMiddlewareFormInputs{
    name: string;
    clientTenant: string;
    baseUrl: string;
}


/**
 * Component for managing middleware data.
 * 
 * @param onSubmit Callback function for form submission.
 * @param onCancel Callback function for form cancellation.
 * @param loading Indicates if the form is currently loading.
 * @param initialValues Initial values for the form.
 * @returns The middleware form component.
 */
const MiddlewareForm: React.FC<OwnProps> = ({onSubmit, onCancel, loading, initialValues = undefined}) => {
    const { t } = useTranslation([nameOf({MiddlewareForm}), "TransverseError"], { useSuspense: false});
    const allClients = useAppSelector(state => state.adminClient.allClients);
    
    const schema = yup.object().shape({
        name: yup.string().required(t("required", { ns:"TransverseError"}) as string),
        clientTenant: yup.string().required(t("required", { ns:"TransverseError"}) as string),
        baseUrl: yup.string().required(t("required", { ns:"TransverseError"}) as string)
      });


    return (
        <div>
                <Formik
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    
                    initialValues={{
                        name: initialValues?.name ?? '',
                        clientTenant: initialValues?.clientTenant ?? '',
                        baseUrl: initialValues?.baseUrl ?? '',
                    } as IMiddlewareFormInputs}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        values,
                        touched,
                        isValid,
                        isSubmitting,
                        errors,
                    }) => {
                        return (
                            <fieldset disabled={isSubmitting}>
                                    <Row>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik05">
                                                <Form.Label>{t("Nom du middleware")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    disabled={loading}
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isValid={touched.name && !errors.name}
                                                    isInvalid={!!errors.name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group className='mb-3'>
                                                <Form.Label>{t("Client")}</Form.Label>
                                                <Form.Select disabled={loading} value={values.clientTenant}
                                                    onChange={(item) => setFieldValue(`clientTenant`, item.currentTarget.value)}
                                                    isValid={touched.clientTenant && !errors.clientTenant} isInvalid={!!errors.clientTenant}>
                                                        <option key={"A"} value={""}>{""}</option>,
                                                        {allClients.map((client, index) => {
                                                            return <option key={index} value={client.tenant}>{client.name}</option>
                                                        })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.clientTenant}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                   

                                    <Row>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik05">
                                                <Form.Label>{t("Middleware API Url")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="baseUrl"
                                                    disabled={loading}
                                                    value={values.baseUrl}
                                                    onChange={handleChange}
                                                    isValid={touched.baseUrl && !errors.baseUrl}
                                                    isInvalid={!!errors.baseUrl}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.baseUrl}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    
    
                                  
    
    
                                    <div className='d-flex justify-content-center'>
                                        <ButtonFno className="w-50 me-1" color='blue' onClick={onCancel}>{t('Retour')}</ButtonFno>
                                        <ButtonFno className="w-50 ms-1" color='orange' loading={loading} onClick={handleSubmit}>{t('Valider')}</ButtonFno>
                                    </div>
                                </fieldset>
                            )
                    }}
                    </Formik> 
        </div>
    )
}

export default MiddlewareForm
