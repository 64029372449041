import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminUserActions } from "store/reducers/Admin/User/adminUserSlice";
import apiAdminDefinition from "backend/apiAdminDefinition";


/**
 * Load all app's users for admin page
 * @returns 
 */
export const regenerateUserPasswordAdminThunk = (userMail: string, onSuccess: undefined | ((newPassword: string) => void) = undefined): AppThunk => {
    return async (dispatch, getState) => {
        try{
            const users = await apiAdminDefinition.user.userPasswordResetPost({email: userMail});
            if(users.ok && onSuccess) onSuccess(users.data.password);
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des utilisateurs"));
            dispatch(adminUserActions.setLoadingUsers(false));
        }
    }
}