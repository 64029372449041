import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store';
import { loadTranslationsThunk } from 'store/reducers/Admin/Translation/thrunks/loadTranslationsThunk';
import nameOf from 'utility/nameOf';
import { Badge, Form, InputGroup } from 'react-bootstrap';
import { ILoadingTranslationItem, adminTranslationsActions } from 'store/reducers/Admin/Translation/adminTranslationsSlice';
import { updateTranslationThunk } from 'store/reducers/Admin/Translation/thrunks/updateTranslationThunk';
import _ from 'lodash';
import { TranslationItemAdmin } from 'backend/ApiAdminDefinition/data-contracts';

const AdminTranslations: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminTranslations}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const translations = useAppSelector(state => state.adminTranslations.translations);
    const languages = useAppSelector(state => state.adminTranslations.defaultLanguages);
    const loading = useAppSelector(state => state.adminTranslations.loadingTranslation);
    const allTranslationLoading = useAppSelector(state => state.adminTranslations.loadingUpdateTranslations);

    // save initial input value when focused for determine if need to save when focus is loose
    const [initialValueTranslationFocused, setInitialValueTranslationFocused] = useState('');

    const [searchTerm, setSearchTerm] = useState('');
    const [showOnlyEmpty, setShowOnlyEmpty] = useState(false);

    useEffect(() => {
        document.title = t('[Admin] Traduction');
        searchTranslations();
    }, [])


    const searchTranslations = () => {
        dispatch(loadTranslationsThunk());
    }



    const saveTranslation = (lang: string, page: string, ref: string, translation: string) => {
        
        dispatch(updateTranslationThunk({
            page:page,
            name: ref,
            lang: lang,
            translation: translation
        } as ILoadingTranslationItem))
    }

    const onTranslationChange = (lang: string, page: string, ref: string, translation: string) => {

        const translationFound = translations.find(m => m.name == ref && m.page == page)
        if(translationFound)
        {
            const translationToSave = _.cloneDeep(translationFound);
            translationToSave.translations[lang] = translation;
            dispatch(adminTranslationsActions.updateTranslation(translationToSave));
        }
        
    }


    const getColumns = () => {
        const columns:TableColumn<TranslationItemAdmin>[] = [
            {
                id:'page',
                name: t('Page'),
                sortable: true,
                selector: row => row.page.toLocaleLowerCase(),
                cell: (row, index, column, id) =>{
                    return <Badge bg={row.page.toLocaleLowerCase().includes('admin') ? "danger": "primary"}>{row.page} </Badge>
                }
            },
            {
                id:'name',
                name: t('Référence'),
                sortable: true,
                selector: row => row.name,
            }
        ];


        languages.forEach(lang => {
            columns.push({
                id:'lang_' + lang.code,
                name: <div>{lang.name} </div>,
                center:true,
                cell: row => {
                    const trans = row.translations[lang.code] || "";
                    const isLoading = allTranslationLoading.some(m => m.lang == lang.code && m.name == row.name && m.page == row.page);
                    return <InputGroup >
                            <Form.Control type="text" 
                                        placeholder="" 
                                        value={trans} 
                                        disabled={isLoading}
                                        onFocus={(e) => setInitialValueTranslationFocused(e.target.value)}
                                        onChange={(e) =>{
                                            onTranslationChange(lang.code, row.page, row.name, e.target.value)
                                        }}
                                        onBlur={(e) =>{
                                            if(trans != initialValueTranslationFocused)saveTranslation(lang.code, row.page, row.name, e.target.value)
                                        }}/>
                                        {isLoading && <InputGroup.Text><Loading size={20} inline /></InputGroup.Text>}
                            </InputGroup>
                },
            })
        })


        return columns;
    };
    


    let translationsData = translations;
    if(searchTerm)
    {
        translationsData = translations.filter((trans) => {
            const termsToCheck = [
                trans.name,
                trans.page
            ];
            _.values(trans.translations).forEach((t) => termsToCheck.push(t))
            const joinTerm = _.join(termsToCheck, ' ').toLocaleLowerCase();

            return joinTerm.includes(searchTerm);
        });
    }


    return (
        <div>
             <PageHeader title={t("Traduction")} admin/>
             <div className='table-component'>
                <DataTable 
                    columns={getColumns()} 
                    data={translationsData} 
                    defaultSortAsc={true} 
                    progressPending={loading}
                    progressComponent={<Loading text={t("Chargement")}/>}
                    defaultSortFieldId={'page'} 
                    pagination={true}
                    paginationPerPage={15}
                    striped={true}
                    subHeader={true}
                    highlightOnHover={true}
                    noDataComponent={<div className='w-100 text-center p-5'>{t("Aucune traduction disponible")}</div>}
                    subHeaderComponent={<> 
                        <h5 className='text-uppercase'> {t("Traduction du backoffice")} {loading && <Loading inline size={20}/>}</h5>
                        <div className='search-panel'>
                            <input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>
                        </div>
                        </>}
                    sortServer={false}/>
            </div>
        </div>
    )
}

export default AdminTranslations
