import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientMapNavmeshActions } from "../adminClientMapNavmeshSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Delete an navmesh
 * @returns 
 */
export const deleteNavmeshThrunks = (mapId: string, entityId: string, callbacks: ThrunkApiCallBacks<boolean>): AppThunk => {
    return async (dispatch, getState) => {
       try{

            dispatch(adminClientMapNavmeshActions.setLoadingNavmeshForm(true));
            await apiBackofficeDefinition.navmesh.navmeshDelete(mapId, entityId)
                .then((result) => {
                    dispatch(adminClientMapNavmeshActions.deleteNavmesh(entityId))
                    callbacks.onSuccess(true);
                })
                .catch((apiResponse: apiErrorModel) => {
                    callbacks.onError(apiResponse);
                })
                .finally(() => dispatch(adminClientMapNavmeshActions.setLoadingNavmeshForm(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la suppression de la navmesh"))
        }
    }
}