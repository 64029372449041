/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TranslationItemAdmin, UpdateTranslationItemAdmin } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class BackofficeTranslation<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Backoffice translation
     * @name TranslationsList
     * @summary [🤵] Return all tenants.
     * @request GET:/admin/translations
     * @secure
     */
    translationsList = (params: RequestParams = {}) =>
        this.request<TranslationItemAdmin[], void>({
            path: `/admin/translations`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Backoffice translation
     * @name TranslationPut
     * @summary [🤵]
     * @request PUT:/admin/translation
     * @secure
     */
    translationPut = (data: UpdateTranslationItemAdmin, params: RequestParams = {}) =>
        this.request<TranslationItemAdmin, void>({
            path: `/admin/translation`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
}
