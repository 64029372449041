import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiMiddlewareDefinition from "backend/apiMiddlewareDefinition";
import _ from "lodash";
import { middlewareBatchingActions } from "../middlewareBatchingSlice";

/**
 * get batching raw files from middleware with criteria
 * @param baseUrl 
 * @param mapId 
 * @param page 
 * @param pageSize 
 * @param setLoading 
 * @param sortColumn 
 * @param sortDirection 
 * @returns 
 */
export const loadBatchingRawFileThrunk = (
        baseUrl: string, 
        mapId: string, 
        page: number,
        pageSize = 10,
        setLoading = true,
        sortColumn = "dateCreated",
        sortDirection = "desc"): AppThunk => {
    return async (dispatch, getState) => {
      try {
        
        const tenant = getState().userProfil.currentTenant?.tenant
        if(setLoading)
        {
          dispatch(middlewareBatchingActions.setLoadingList(true));
        }

        const batchingFileResult = await apiMiddlewareDefinition(baseUrl, tenant ?? "")
                                  .batching.searchBatchingRawFiles(mapId, {
                                    mapId: mapId,
                                    pageSize: pageSize,
                                    page: page,
                                    
                                  });

       dispatch(middlewareBatchingActions.setBatchingRawFiles(batchingFileResult.data));

      } catch (error: any) {

        if (!error.status || error.status != 401) {
          toast.error(i18next.t("Erreur lors du chargement des fichiers de batching"));
          dispatch(middlewareBatchingActions.setBatchingRawFiles(undefined));
        }
      } finally {
        // Set loading state back to false after the operation completes
        if(setLoading)
        {
          dispatch(middlewareBatchingActions.setLoadingList(false));
        }
       
      }
    };
  };