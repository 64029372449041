import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiErrorModel from "backend/utils/apiErrorModel";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { MetadataGroupCreate, MetadataGroupDetails} from "backend/ApiBackofficeDefinition/data-contracts";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";
import { adminClientMapMetadataActions } from "../adminClientMapMetadataSlice";


/**
 * Thunk action creator for creating a new Metadata
 * @param mapId 
 * @param Metadata 
 * @param callbacks 
 * @returns 
 */
export const createMetadataThunk = (
    mapId: string,
    Metadata: MetadataGroupCreate,
    callbacks: ThrunkApiCallBacks<MetadataGroupDetails>
  ): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapMetadataActions.setLoadingMetadataForm(true));
        // Make API call to create entity
        await apiBackofficeDefinition.metadataGroup.metadataGroupPost(mapId, Metadata)
          .then((result) => {
            // Dispatch action to add newly created asset to store
            dispatch(adminClientMapMetadataActions.addMetadata(result.data));
            // Call onSuccess callback
            callbacks.onSuccess(result.data);
          })
          .catch((apiResponse: apiErrorModel) => {
            // Call onError callback with API error response
            callbacks.onError(apiResponse);
          });
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors de la création du Metadata"));
          callbacks.onError({ errors:{"api":[error.message]} } as apiErrorModel)
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapMetadataActions.setLoadingMetadataForm(false));
      }
    };
  };