import i18next from "i18next";
import { toast } from "react-toastify";
import { adminMiddlewareActions } from "../adminMiddlewareSlice";
import { AppThunk } from "store/store"
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";
import apiAdminDefinition from "backend/apiAdminDefinition";


/**
 * Thunk action creator for loading middlewares
 * @returns 
 */
export const loadMiddlewaresThrunk = (): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminMiddlewareActions.setLoadingList(true));
  
        // Fetch middlewares from the API
        const middlewares = await apiAdminDefinition.middlewareApi.middlewaresList();

        // Dispatch action to set the fetched middlewares in the store
        dispatch(adminMiddlewareActions.setMiddlewares(middlewares.data));
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors du chargement des middlewares de la map"));
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminMiddlewareActions.setLoadingList(false));
      }
    };
};