import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import currentUserIsAdmin from 'backend/utils/currentUserIsAdmin';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store'
import nameOf from 'utility/nameOf';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';

const Statistics: React.FC = () => {
    const { t } = useTranslation(nameOf({Statistics}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(state => state.userProfil.currentUser);
    const location = useLocation();
    
    useEffect(() => {
        document.title = t('Statistiques');
    }, [])
    
    return (
        <div>
             <PageHeader title={t("Statistiques")}/>
            <div>
                TODO
            </div>
        </div>
    )
}

export default Statistics
