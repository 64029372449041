import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import allTimcodeFiles, { ITimcodeDemoFile, ITimcodeFileData } from '../TimcodeDemoData';
import { userProfilActions } from 'store/reducers/Profil/userProfilSlice';
import _ from 'lodash';


export interface ITimcodeStoreStore {
  allFiles: ITimcodeDemoFile[];
  selectedFile: ITimcodeDemoFile | undefined;
  selectedFileData: ITimcodeFileData | undefined;
  loadingFile: boolean;
}

const initialState: ITimcodeStoreStore = {
  allFiles: allTimcodeFiles,
  selectedFile: undefined,
  selectedFileData: undefined,
  loadingFile: false
};

const timcodeStore = createSlice({
  name: 'timcodeStore',
  initialState,
  reducers: {
    setSelectedFile: (state, action: PayloadAction<ITimcodeDemoFile | undefined>) => {
      state.selectedFile = action.payload
    },
    setLoadingFile: (state, action: PayloadAction<boolean>) => {
      state.loadingFile = action.payload
    },
    setSelectedFileData: (state, action: PayloadAction<ITimcodeFileData | undefined>) => {
      state.selectedFileData = action.payload;
      
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userProfilActions.setLogout, (state) => {

    })
  }
});


export const { actions: timcodeActions, reducer: timcodeReducer } = timcodeStore;