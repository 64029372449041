import getCurrentTenant from "./getCurrentTenant";
import getCurrentUserToken from "./getCurrentUserToken";

/**
 * Helper for create api picking class instance
 * @returns 
 */
export default function getApiPickingConfig(tenant?: string){
    return {
        baseUrl: process.env.REACT_APP_API_PICKING_URL,
        baseApiParams: {
            secure: true
        },
        securityWorker() {
    
            const token = getCurrentUserToken();
            const headers: {[key: string]: any} = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
            const tenantToUse = tenant ? tenant : getCurrentTenant()?.tenant;
            
            if(tenantToUse)
            {
                headers["tenant"] = tenantToUse;
            }

            if(token)
                headers.Authorization = "Bearer " +token;

            return {
                headers
            }
        }
    }
}