import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NavmeshDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

// Interface representing the state of the admin client map navmesh store
export interface IAdminClientMapNavmeshStore {
  navmeshes: NavmeshDetails[];
  loadingList: boolean;
  loadingForm: boolean;
}

// Initial state for the admin client map navmesh store
const initialState: IAdminClientMapNavmeshStore = {
  navmeshes: [],
  loadingList: false,
  loadingForm: false,
};

// Create a slice for the admin client map navmesh store
const adminClientMapNavmeshSlice = createSlice({
  name: "adminClientMapNavmeshSlice",
  initialState,
  reducers: {
    // Set the markers in the state
    setNavmesh: (state, action: PayloadAction<NavmeshDetails[]>) => {
      state.navmeshes = action.payload;
    },
    // Update a specific navmesh in the state
    updateNavmesh: (state, action: PayloadAction<NavmeshDetails>) => {
      state.navmeshes = state.navmeshes.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new navmesh to the state
    addNavmesh: (state, action: PayloadAction<NavmeshDetails>) => {
      state.navmeshes.push(action.payload);
    },
    // remove an marker
    deleteNavmesh: (state, action: PayloadAction<string>) => {
      state.navmeshes = state.navmeshes.filter(m => m.id !== action.payload)
    },
    // Set the loadingList state
    setLoadingNavmeshList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingForm state
    setLoadingNavmeshForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.navmeshes = [];
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

// Export the actions and reducer from the admin client map navmesh slice
export const {
  actions: adminClientMapNavmeshActions,
  reducer: adminClientMapNavmeshReducer,
} = adminClientMapNavmeshSlice;
