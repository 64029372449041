/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ErrorsReturn, RequestClientModel } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Optimization<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Optimization
     * @name OptimizationRunPost
     * @request POST:/map/{mapId}/optimization/run
     * @secure
     */
    optimizationRunPost = (mapId: string, data: RequestClientModel, params: RequestParams = {}) =>
        this.request<RequestClientModel, ErrorsReturn | void>({
            path: `/map/${mapId}/optimization/run`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
}
