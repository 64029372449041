import i18next from "i18next";
import { toast } from "react-toastify";
import { adminTranslationsActions } from "store/reducers/Admin/Translation/adminTranslationsSlice";
import { AppThunk } from "store/store"
import apiAdminDefinition from "backend/apiAdminDefinition";



export const loadTranslationsThunk = (): AppThunk => {
    return async (dispatch, getState) => {
        try{
            dispatch(adminTranslationsActions.setLoadingTranslation(true));
            const translations = await apiAdminDefinition.translation.translationsList();
            
            dispatch(adminTranslationsActions.setTranslations(translations.data));
            dispatch(adminTranslationsActions.setLoadingTranslation(false));
        }
        catch(error:any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des traductions du backoffice"));
            dispatch(adminTranslationsActions.setLoadingTranslation(false));
        }
    }
}