import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import "./DemoPicking.scss";
import FloorsComponent from '../Components/FloorsComponent';
import ZoomComponent from '../Components/ZoomComponent';
import Sdk from 'fno-sdk';
import Loading from 'components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderList from './OrderList';
import Configuration from './Configuration';
import Statistics from './Statistics';
import ImplementationList from './ImplementationList';
import OrderListSAP from './OrderListSAP';
import useFnoMapSdk from 'hooks/useFnoMapSdk';
import MapRender from 'components/mapControls/MapRender';
import appColors from 'resources/appColors';


/**
 * All menu selectable
 */
enum menuItems{
    ORDERS = "ORDERS",
    CONFIGURATIONS = "CONFIGURATIONS",
    STATISTICS = "STATISTICS",
    IMPLEMENTATION = "IMPLEMENTATION"
}




const DemoPicking: React.FC = () => {
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZW5hbnQiOiJkZW1vQ2xpZW50IiwidG9rZW4tdHlwZSI6IlByb2plY3QiLCJzZWVkIjoiZDBmODNlZTAtOGNhYi00Mzk0LWI3YjctZjlmZTI3NzkxYjE2IiwiaXNzIjoiRmluZCAmIE9yZGVyIGRldiIsImF1ZCI6IkZpbmQgJiBPcmRlciBkZXYifQ.iAGE2X-NxL3RJfmCRLqahtFUVZvvFCrZCbjtXgEKZj8';
    const mapId = "6526b1f36e179431c7dd412f";
    const tenant = "demoClient";
    
    const {
        sdk,
        loading,
        mapReady
    } = useFnoMapSdk(token, tenant, mapId);
    
    const [menuSelected, setMenuSelected] = useState<menuItems>(menuItems.ORDERS);


    useEffect(() => {
        document.title = "Demo picking";

        const baseUrlStorage = "https://storagefnodev.blob.core.windows.net/sdk/webgl/1.0.1/";
        const buildUrl = baseUrlStorage + "Build";

        /*setSdk(new Sdk(token, tenant, {  <----- TODO
            storageUrl: baseUrlStorage
        }));*/
    }, [])

    /*useEffect(() => {
        if(sdk)
        {
            sdk.initEngine("MapContainer").then(() => {
                sdk.loadMap(mapId).then(async () => {
                    setMapReady(true);

                    //sdk.camera.zoomOnEntity("6526b2796e179431c7dd4378");
                    await sdk.user.setUserPosition(2.2947190911982, 48.912240768386 ,0);
                    await sdk.user.moveCameraToUserPosition();
                    await sdk.camera.setZoom(0.4);
                })
            })
        }
    }, [sdk])*/

    




    return (<div id="demo-picking-body">
        <Container fluid>
            {<Row id='menu'>
                <Col sm="3" onClick={() => setMenuSelected(menuItems.IMPLEMENTATION)} className={'g-0 item ' + (menuSelected == menuItems.IMPLEMENTATION ? "selected" : "")}><FontAwesomeIcon icon={["fas", "tags"]} /> Implantation</Col>
                <Col sm="3" onClick={() => setMenuSelected(menuItems.CONFIGURATIONS)} className={'g-0 item ' + (menuSelected == menuItems.CONFIGURATIONS ? "selected" : "")}><FontAwesomeIcon icon={["fas", "gear"]} /> Picking settings</Col>
                <Col sm="3" onClick={() => setMenuSelected(menuItems.ORDERS)} className={'g-0 item ' + (menuSelected == menuItems.ORDERS ? "selected" : "")}><FontAwesomeIcon icon={["fas", "box-open"]} /> Orders</Col>
                <Col sm="3" onClick={() => setMenuSelected(menuItems.STATISTICS)} className={'g-0 item ' + (menuSelected == menuItems.STATISTICS ? "selected" : "")}><FontAwesomeIcon icon={["fas", "chart-line"]} /> Statistics</Col>
            </Row>}
        </Container>
        <Container  className='g-0'  fluid style={{height: "100%"}}>
            <Row id='content' className='g-0'>
                <Col id="map-overlay" sm="8">
                    <MapRender 
                        sdk={sdk} 
                        mapReady={mapReady}
                        assetToolipConfig={{
                            highlightColor: appColors.blue
                        }}/>
                </Col>
                <Col sm="4" className='sub-menu'>
                   {sdk && mapReady && menuSelected == menuItems.ORDERS && <OrderListSAP sdk={sdk} />}
                   {sdk && mapReady && menuSelected == menuItems.CONFIGURATIONS && <Configuration sdk={sdk} />}
                   {sdk && mapReady && menuSelected == menuItems.STATISTICS && <Statistics sdk={sdk} />}
                   {sdk && mapReady && menuSelected == menuItems.IMPLEMENTATION && <ImplementationList sdk={sdk} />}
                   
                </Col>
            </Row>
        </Container>
        {!mapReady && <div id='fullscreen-loading'><Loading text={"Loading ..."} fullScreen fullScreenBackgroundColor='#fff'/></div>}
        </div>)
}

export default DemoPicking
