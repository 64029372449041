import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import apiMiddlewareDefinition from 'backend/apiMiddlewareDefinition';
import { BatchingRawFileDetails, DownloadFileType } from 'backend/ApiMiddlewareDefinition/data-contracts';
import currentUserIsAdmin from 'backend/utils/currentUserIsAdmin';
import CsvDataTableRawByUrl from 'components/CsvDataTableRawByUrl';
import ButtonFno from 'components/inputs/ButtonFno';
import Loading from 'components/Loading';
import LogsListComponent from 'components/Middleware/LogsListComponent';
import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { middlewareBatchingActions } from 'store/reducers/MiddlewareBatching/middlewareBatchingSlice';
import { getDownloadBatchingRawFileThunk } from 'store/reducers/MiddlewareBatching/thrunks/getDownloadBatchingRawFileThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';

type Props = {
    batchingFile: BatchingRawFileDetails
  };


const BatchingFileDetails: React.FC<Props> = ({batchingFile}) => {
    const { t } = useTranslation(nameOf({BatchingFileDetails}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const userIsAdmin = currentUserIsAdmin();
    const [originaFileUrl, setOriginaFileUrl] = useState("");
    const [optimizedFileUrl, setOptimizedFileUrl] = useState("");
    const [errorFileUrl, setErrorFileUrl] = useState("");
    const {
        middlewareSelected,
    } = useAppSelector(state => state.middlewareApis);

   useEffect(() => {
        if(batchingFile){
            getDownloadUrls(batchingFile);
        }
   }, [batchingFile])


   const getDownloadUrls = async (batchingFile: BatchingRawFileDetails) => { 
        setLoading(true)
        const apiMiddleware = apiMiddlewareDefinition(middlewareSelected?.baseUrl ?? "", middlewareSelected?.clientTenant?? "");

        const requestsUrl = [
            apiMiddleware.batching.getBatchingRawFileDownloadUrl(batchingFile.mapId, batchingFile.id, {fileType: DownloadFileType.Original})
        ]

        if(batchingFile.storagePathResultCsv != "")
        {
            requestsUrl.push(apiMiddleware.batching.getBatchingRawFileDownloadUrl(batchingFile.mapId, batchingFile.id, {fileType: DownloadFileType.Final}))
        }

        if(batchingFile.storagePathErrorCsv != "")
            {
                requestsUrl.push(apiMiddleware.batching.getBatchingRawFileDownloadUrl(batchingFile.mapId, batchingFile.id, {fileType: DownloadFileType.Error}))
            }

        const urlResults = await Promise.all(requestsUrl);

        setOriginaFileUrl(urlResults[0] ? urlResults[0].data.downloadUrl ?? "": "");
        setOptimizedFileUrl(urlResults[1] ? urlResults[1].data.downloadUrl ?? "": "");
        setErrorFileUrl(urlResults[2] ?urlResults[2].data.downloadUrl ?? "": "");
        setLoading(false)
    }

    const defaultActiveKey = batchingFile.storagePathResultCsv ? "result" : "source";

   return (
       <div>
           <div>
                <Tabs fill defaultActiveKey={defaultActiveKey}  className="mb-3">
                    <Tab eventKey="result" title={t("Fichier Résultat")} disabled={!optimizedFileUrl}>
                        {loading && <Loading/>}
                        {!loading && <CsvDataTableRawByUrl title={t("Fichier optimisé")} csvFileUrl={optimizedFileUrl}/>}
                    </Tab>
                    <Tab eventKey="error" title={t("Fichier d'erreurs")} disabled={errorFileUrl == ""}>
                        {loading && <Loading text={t("Chargement des données")}/>}
                        {!loading && <CsvDataTableRawByUrl title={t("Fichier erreurs")} csvFileUrl={errorFileUrl}/>}
                    </Tab>
                    <Tab eventKey="source" title={t("Fichier source")}>
                        {loading && <Loading text={t("Chargement des données")}/>}
                        {!loading && <CsvDataTableRawByUrl title={t("Fichier client")} csvFileUrl={originaFileUrl}/>}
                    </Tab>
                    {userIsAdmin && <Tab eventKey="logs" title={t("Logs")}>
                        <LogsListComponent logs={batchingFile?.logs ?? []} />
                    </Tab>}
                </Tabs>
           </div>
       </div>
   )
}
export default BatchingFileDetails
