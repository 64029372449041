import _ from "lodash";
import { AppThunk } from "store/store";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiMiddlewareDefinition from "backend/apiMiddlewareDefinition";
import { DownloadFileType, StorageDownloadLinkResult } from "backend/ApiMiddlewareDefinition/data-contracts";




/**
 * Get download url for a batching file
 * @param baseUrl 
 * @param tenant 
 * @param mapId 
 * @param fileId 
 * @param fileType 
 * @param callbacks 
 * @returns 
 */
export const getDownloadBatchingRawFileThunk = (
    baseUrl: string,
    tenant: string,
    mapId: string,
    fileId: string,
    fileType: DownloadFileType,
    callbacks: ThrunkApiCallBacks<StorageDownloadLinkResult>
): AppThunk => {
    return async (dispatch, getState) => {
        try{
            // Delete audit raw file
            const result = await apiMiddlewareDefinition(baseUrl, tenant).batching.getBatchingRawFileDownloadUrl(mapId, fileId,{
                fileType: fileType
            });
            if (!result.ok) {
                // Call onError callback with API error response
                callbacks.onError(result.error as apiErrorModel);
            } else {
                // Call onSuccess callback with true indicating successful deletion
                callbacks.onSuccess(result.data);
            }
        }
        catch(error){
            callbacks.onError(error as apiErrorModel);
        }
        
    };
};
