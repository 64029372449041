import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientMapMarkerTemplateActions } from "../adminClientMapMarkerTemplateSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Delete a MarkerTemplate
 * @returns 
 */
export const deleteMarkerTemplateThrunks = (mapId: string, MarkerTemplateId: string, callbacks: ThrunkApiCallBacks<boolean>): AppThunk => {
    return async (dispatch, getState) => {
       try{

            dispatch(adminClientMapMarkerTemplateActions.setLoadingMarkerTemplateForm(true));
            await apiBackofficeDefinition.markerTemplate.markerTemplateDelete(mapId, MarkerTemplateId)
                .then((result) => {
                    dispatch(adminClientMapMarkerTemplateActions.deleteMarkerTemplate(MarkerTemplateId))
                    callbacks.onSuccess(true);
                })
                .catch((apiResponse: apiErrorModel) => {
                    callbacks.onError(apiResponse);
                })
                .finally(() => dispatch(adminClientMapMarkerTemplateActions.setLoadingMarkerTemplateForm(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la suppression du MarkerTemplate"))
        }
    }
}