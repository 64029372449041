/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { LanguageItem } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Language<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Language
     * @name LanguagesList
     * @summary Return all default language availables
     * @request GET:/languages
     */
    languagesList = (params: RequestParams = {}) =>
        this.request<LanguageItem[], any>({
            path: `/languages`,
            method: 'GET',
            format: 'json',
            ...params
        })
}
