import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sdk from 'fno-sdk';
import { Accordion, Badge, Col, Row, Table } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import { useTranslation } from 'react-i18next';
import "./TimcodeFinalCompareRowRender.scss";
import Loading from 'components/Loading';
import _ from 'lodash';
import { MissionDetails } from 'models/IGlobalOptimizationResult';
import appColors from 'resources/appColors';
import { mapControlsActions } from 'store/reducers/mapControls/mapControls';
import { IOrderRow } from './TimcodeFinalResultSuperU';

type Props = {
    sdk: Sdk;
    orders: IOrderRow[]
    onClick: (order: IOrderRow | undefined) => void;
    onHoverEnter: (order: IOrderRow) => void;
    onHoverLeave: (order: IOrderRow) => void;
  };

const TimcodeFinalCompareRowRender: React.FC<Props> = ({sdk, orders, onClick, onHoverEnter, onHoverLeave}) => {
    const { t } = useTranslation(nameOf({TimcodeFinalCompareRowRender}), { useSuspense: false});
    const dispatch = useAppDispatch();

    const [missionRefSelected, setMissionRefSelected] = useState<string | undefined>(undefined);
    const loading = useAppSelector(state => state.audit.loadingList);
    //const auditOptimizationResult = useAppSelector(state => state.audit.optimizationResult);

    const {
        allFiles,
        selectedFile,
        selectedFileData,
        loadingFile
    } = useAppSelector(state => state.timcodeDemo);

    const {
        currentFloor
    } = useAppSelector(state => state.mapControls);

    
    useEffect(() => {
        if(sdk && sdk.pathfinding)
        {
            sdk.pathfinding.clearItineraries();
            sdk.asset.clearHighlights();
        }
    }, [orders])

    const handleClick = async (row: IOrderRow) => {
        //onClick(order)
        sdk?.pathfinding.clearItineraries();
        sdk?.asset.clearHighlights();
        if(row.ref == missionRefSelected)
        {
            setMissionRefSelected(undefined);
            sdk?.asset.clearHighlights();
        }
        else
        {
            const allAddressCurrentRow = row.products.map(m => m.emplacement);

            await sdk?.pathfinding.setItineraryToAssetsByLocationCode(allAddressCurrentRow, appColors.blue);

            //sdk.asset.setHighlightAssetById
            setMissionRefSelected(row.ref);
            toggleSelectedOrderAssets(allAddressCurrentRow, true);

            // Change current floor if needed for showing optimized start emplacement
            const assets = (await sdk?.asset.getAssetDataByLocationCodes(allAddressCurrentRow))?.assets ?? [];

            if(assets && assets.length > 0 && currentFloor && assets[0].floorId != currentFloor.id){
                dispatch(mapControlsActions.setCurrentFloorById(assets[0].floorId))
            }
            
        }
        
    }


    const highlightAssets = async (locationCodes: string[], enabled: boolean) => {

        if(missionRefSelected)
        {
            const optimizedFound = orders.find(m => m.ref == missionRefSelected)
            if(optimizedFound)
                await toggleSelectedOrderAssets(optimizedFound.products.map(m => m.emplacement), !enabled);   
        }
        //await toggleSelectedOrderAssets(locationCodes, !enabled);

        const assets = (await sdk.asset.getAssetDataByLocationCodes(locationCodes)).assets;
        sdk.asset.setHighlightAssetByIds(assets.map(m => m.id), enabled, appColors.orange);
    }
    

    const toggleSelectedOrderAssets = async (locationCodes: string[], enabled: boolean) => {
        
        const allAssets = (await sdk.asset.getAssetDataByLocationCodes(locationCodes)).assets;
        sdk.asset.setHighlightAssetByIds(allAssets.map(m => m.id), enabled, appColors.orange);
    }



    if(loading || loadingFile)
    {
        return <div className='mt-5'><Loading text={t("Calcul de l'optimisation de chemin en cours")}/></div>;
    }


    return <Accordion id="AuditFinalCompareRowRender" activeKey={missionRefSelected}>
                {_.map(orders, (row) => {
                    return <Accordion.Item key={row.ref} eventKey={row.ref} >
                        <Accordion.Header onClick={() => handleClick(row)} >
                                <FontAwesomeIcon icon={["fas", "tag"]} /> 
                                {row.ref}
                                <Badge className='ms-2' bg="primary">{row.products.length} Products</Badge>
                            </Accordion.Header>
                        <Accordion.Body>
                            {/*<Row className='text-center mb-3'>
                                <Col><FontAwesomeIcon icon={["fas", "user"]} /> {"User"}</Col>
                                <Col><FontAwesomeIcon icon={["fas", "calendar"]} /> {""/}</Col>
                            </Row>*/}
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th className=''></th>
                                        <th>{t("Emplacement")}</th>
                                        <th >{t("Support")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {row.products.map((product, i) => {

                                        return <tr key={i} onMouseEnter={() => highlightAssets([product.emplacement], true)} onMouseLeave={() => highlightAssets([product.emplacement], false)}>
                                            <td>{i + 1}</td>
                                            <td>{product.emplacement}</td>
                                            <td><Badge className='ms-2' bg="primary">{product.ean}</Badge></td>
                                        </tr>
                                    })}
                                </tbody>
                                <tfoot>
                                   <tr>
                                        <th style={{fontWeight: "normal"}} className=''><FontAwesomeIcon icon={["fas", "flag-checkered"]} /> </th>
                                        <th style={{fontWeight: "normal"}} className='text-center initial' colSpan={2}>{(row.distance ?? 0).toLocaleString("fr-FR")} {t("mètres")}</th>
                                 </tr>
                                </tfoot>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                })}
            </Accordion>

}

export default TimcodeFinalCompareRowRender