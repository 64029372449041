/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, Operation, ZoneCreate, ZoneDetails, ZoneUpdate } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MapZone<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags MapZone
     * @name ZonesList
     * @summary [🤵 OR 🔑] Get all zones for a map. [🔒: map_read].
     * @request GET:/map/{mapId}/zones
     * @secure
     */
    zonesList = (mapId: string, params: RequestParams = {}) =>
        this.request<ZoneDetails[], void>({
            path: `/map/${mapId}/zones`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapZone
     * @name ZonesPost
     * @summary [🤵 OR 🔑] Create several zones. [🔒: map_create].
     * @request POST:/map/{mapId}/zones
     * @secure
     */
    zonesPost = (mapId: string, data: ZoneCreate[], params: RequestParams = {}) =>
        this.request<ZoneDetails[], void>({
            path: `/map/${mapId}/zones`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapZone
     * @name ZonesPut
     * @summary [🤵 OR 🔑] Update many zones. [🔒: map_update].
     * @request PUT:/map/{mapId}/zones
     * @secure
     */
    zonesPut = (mapId: string, data: ZoneUpdate[], params: RequestParams = {}) =>
        this.request<ZoneDetails[], ApiErrorsResponse | void>({
            path: `/map/${mapId}/zones`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapZone
     * @name ZonesDelete
     * @summary [🤵 OR 🔑] [🔒: map_update].
     * @request DELETE:/map/{mapId}/zones
     * @secure
     */
    zonesDelete = (mapId: string, data: string[], params: RequestParams = {}) =>
        this.request<any, ApiErrorsResponse | void>({
            path: `/map/${mapId}/zones`,
            method: 'DELETE',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        })
    /**
     * No description
     *
     * @tags MapZone
     * @name ZonePost
     * @summary [🤵 OR 🔑] Create new zone. [🔒: map_create].
     * @request POST:/map/{mapId}/zone
     * @secure
     */
    zonePost = (mapId: string, data: ZoneCreate, params: RequestParams = {}) =>
        this.request<ZoneDetails, void>({
            path: `/map/${mapId}/zone`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapZone
     * @name ZonePut
     * @summary [🤵 OR 🔑] Update all field of a zone. [🔒: map_update].
     * @request PUT:/map/{mapId}/zone/{zoneId}
     * @secure
     */
    zonePut = (mapId: string, zoneId: string, data: ZoneUpdate, params: RequestParams = {}) =>
        this.request<ZoneDetails, void>({
            path: `/map/${mapId}/zone/${zoneId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapZone
     * @name ZonePatch
     * @summary [🤵 OR 🔑] Update specific field of a map zone. [🔒: map_update].
     * @request PATCH:/map/{mapId}/zone/{zoneId}
     * @secure
     */
    zonePatch = (mapId: string, zoneId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<ZoneDetails, void>({
            path: `/map/${mapId}/zone/${zoneId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapZone
     * @name ZoneDelete
     * @summary [🤵 OR 🔑] Delete a zone. [🔒: map_update].
     * @request DELETE:/map/{mapId}/zone/{zoneId}
     * @secure
     */
    zoneDelete = (mapId: string, zoneId: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/map/${mapId}/zone/${zoneId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
