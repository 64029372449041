import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { userProfilActions } from "../../Profil/userProfilSlice";
import { MiddlewareApiDetails } from "backend/ApiAdminDefinition/data-contracts";
import IConnectedMiddlewareModel from "models/Admin/IConnectedMiddlewareModel";

export interface IAdminMiddlewareStore {
  /**Realtime enable or not */
  enabled: boolean,
  /**Connected middlewares via realtime signal R */
  middlewareConnected: IConnectedMiddlewareModel[]
  /**All Database middlewares (connected or not) */
  middlewares: MiddlewareApiDetails[];

  loadingList: boolean;
  loadingForm: boolean;
}
  
  
const initialState: IAdminMiddlewareStore = {
  enabled : false,
  loadingList: false,
  loadingForm: false,
  middlewareConnected: [],
  middlewares: []
}


const adminMiddlewareSlice = createSlice({
  name: "adminMiddlewareSlice",
  initialState,
  reducers: {

    setRealtimeEnabled: (state, action:PayloadAction<boolean>) => {
      state.enabled = action.payload;
    },
    clearConnectedMiddleware: (state) => {
      state.middlewareConnected = [];
    },
    addOrUpdateConnectedMiddleware: (state, action:PayloadAction<IConnectedMiddlewareModel>) => {
      const index = state.middlewareConnected.findIndex((m) => m.id === action.payload.id);

      if (index >= 0) {
        state.middlewareConnected[index] = action.payload;
      }
      else {
        state.middlewareConnected.push(action.payload);

        // if middleware exist only in connected array, add it in middlewares
        const middlewareFound = state.middlewares.some((m) => m.id === action.payload.id);
        if(!middlewareFound){
          state.middlewares.push(action.payload);
        }
      }
    },
    addConnectedMiddleware: (state, action:PayloadAction<IConnectedMiddlewareModel>) => {
      state.middlewareConnected.push(action.payload);
    },
    removeConnectedMiddleware: (state, action:PayloadAction<IConnectedMiddlewareModel>) => {
      state.middlewareConnected = state.middlewareConnected.filter(
        (m) => m.id !== action.payload.id
      );
    },
    setConnectedMiddlewares: (state, action:PayloadAction<IConnectedMiddlewareModel[]>) => {
      state.middlewareConnected = action.payload;
    },


    setLoadingList: (state, action:PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    setLoadingForm: (state, action:PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
    setMiddlewares: (state, action:PayloadAction<MiddlewareApiDetails[]>) => {
      state.middlewares = action.payload;
    },
    addMiddleware: (state, action:PayloadAction<MiddlewareApiDetails>) => {
      state.middlewares.push(action.payload);
    },
    deleteMiddleware: (state, action:PayloadAction<string>) => {
      state.middlewares = state.middlewares.filter(m => m.id !== action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.enabled = false;
      state.middlewareConnected = [];
    });
  },
});

export const { actions: adminMiddlewareActions, reducer: adminMiddlewareReducer } = adminMiddlewareSlice