/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { MetadataGroupCreate, MetadataGroupDetails, MetadataGroupUpdate, Operation } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MetadataGroup<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags MetadataGroup
     * @name MetadataGroupsList
     * @summary [🤵 OR 🔑] Get all metadata group for a map. [🔒: map_read].
     * @request GET:/map/{mapId}/metadata/groups
     * @secure
     */
    metadataGroupsList = (mapId: string, params: RequestParams = {}) =>
        this.request<MetadataGroupDetails[], void>({
            path: `/map/${mapId}/metadata/groups`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MetadataGroup
     * @name MetadataGroupPost
     * @summary [🤵 OR 🔑] Create new metadata group. [🔒: map_create].
     * @request POST:/map/{mapId}/metadata/group
     * @secure
     */
    metadataGroupPost = (mapId: string, data: MetadataGroupCreate, params: RequestParams = {}) =>
        this.request<MetadataGroupDetails, void>({
            path: `/map/${mapId}/metadata/group`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MetadataGroup
     * @name MetadataGroupPut
     * @summary [🤵 OR 🔑] Update all fields of a metadata group. [🔒: map_update].
     * @request PUT:/map/{mapId}/metadata/group/{metadateGroupId}
     * @secure
     */
    metadataGroupPut = (mapId: string, metadateGroupId: string, data: MetadataGroupUpdate, params: RequestParams = {}) =>
        this.request<MetadataGroupDetails, void>({
            path: `/map/${mapId}/metadata/group/${metadateGroupId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MetadataGroup
     * @name MetadataGroupPatch
     * @summary [🤵 OR 🔑] Update specific field of a metadata group. [🔒: map_update].
     * @request PATCH:/map/{mapId}/metadata/group/{metadateGroupId}
     * @secure
     */
    metadataGroupPatch = (mapId: string, metadateGroupId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<MetadataGroupDetails, void>({
            path: `/map/${mapId}/metadata/group/${metadateGroupId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MetadataGroup
     * @name MetadataGroupAssetDetails
     * @summary [🤵 OR 🔑] Get all metadata group for an asset id. [🔒: metadata_read].
     * @request GET:/map/{mapId}/metadata/group/asset/{assetId}
     * @secure
     */
    metadataGroupAssetDetails = (mapId: string, assetId: string, params: RequestParams = {}) =>
        this.request<MetadataGroupDetails[], void>({
            path: `/map/${mapId}/metadata/group/asset/${assetId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MetadataGroup
     * @name MetadataGroupAssetPost
     * @summary [🤵 OR 🔑] Create new metadata group for an asset. [🔒: metadata_create].
     * @request POST:/map/{mapId}/metadata/group/asset/{assetId}
     * @secure
     */
    metadataGroupAssetPost = (mapId: string, assetId: string, data: MetadataGroupCreate, params: RequestParams = {}) =>
        this.request<MetadataGroupDetails, void>({
            path: `/map/${mapId}/metadata/group/asset/${assetId}`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MetadataGroup
     * @name MetadataGroupAssetPut
     * @summary [🤵 OR 🔑] Update all fields of a metadata group by asset id. [🔒: metadata_update].
     * @request PUT:/map/{mapId}/metadata/group/{metadateGroupId}/asset/{assetId}
     * @secure
     */
    metadataGroupAssetPut = (mapId: string, assetId: string, metadateGroupId: string, data: MetadataGroupUpdate, params: RequestParams = {}) =>
        this.request<MetadataGroupDetails, void>({
            path: `/map/${mapId}/metadata/group/${metadateGroupId}/asset/${assetId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MetadataGroup
     * @name MetadataGroupAssetDelete
     * @summary [🤵 OR 🔑] Delete metadatagroup and remove it from asset. [🔒: metadata_delete].
     * @request DELETE:/map/{mapId}/metadata/group/{metadateGroupId}/asset/{assetId}
     * @secure
     */
    metadataGroupAssetDelete = (mapId: string, assetId: string, metadateGroupId: string, params: RequestParams = {}) =>
        this.request<MetadataGroupDetails, void>({
            path: `/map/${mapId}/metadata/group/${metadateGroupId}/asset/${assetId}`,
            method: 'DELETE',
            secure: true,
            format: 'json',
            ...params
        })
}
