import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MarkerDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

// Interface representing the state of the admin client map marker store
export interface IAdminClientMapMarkerStore {
  markers: MarkerDetails[];
  loadingList: boolean;
  loadingForm: boolean;
}

// Initial state for the admin client map marker store
const initialState: IAdminClientMapMarkerStore = {
  markers: [],
  loadingList: false,
  loadingForm: false,
};

// Create a slice for the admin client map marker store
const adminClientMapMarkerSlice = createSlice({
  name: "adminClientMapMarkerSlice",
  initialState,
  reducers: {
    // Set the markers in the state
    setMarkers: (state, action: PayloadAction<MarkerDetails[]>) => {
      state.markers = action.payload;
    },
    // Update a specific marker in the state
    updateMarker: (state, action: PayloadAction<MarkerDetails>) => {
      state.markers = state.markers.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new marker to the state
    addMarker: (state, action: PayloadAction<MarkerDetails>) => {
      state.markers.push(action.payload);
    },
    // remove an marker
    deleteMarker: (state, action: PayloadAction<string>) => {
      state.markers = state.markers.filter(m => m.id !== action.payload)
    },
    // Set the loadingList state
    setLoadingMarkerList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingForm state
    setLoadingMarkerForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.markers = [];
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

// Export the actions and reducer from the admin client map marker slice
export const {
  actions: adminClientMapMarkerActions,
  reducer: adminClientMapMarkerReducer,
} = adminClientMapMarkerSlice;
