import React from 'react'
import SelectLarge from '../inputs/SelectLarge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClientItem } from 'backend/ApiBackofficeDefinition/data-contracts';

type Props = {
    tenants: ClientItem[];
    onTenantChange: (tenant: ClientItem) => void;
    currentTenant: ClientItem | undefined;
  };

/**
 * Tenant selector component
 * @returns 
 */
const TenantSelector: React.FC<Props> = ({tenants, onTenantChange, currentTenant}) => {
    if(tenants && tenants.length > 1)
    {
        return <SelectLarge options={tenants} 
                        value={currentTenant} 
                        onChange={onTenantChange} 
                        valueRender={(tenant) => <div className='text-truncate text-uppercase'> <FontAwesomeIcon className='me-2' icon={["fas", "database"]} />  {tenant.name}</div>}
                        optionRender={(tenant) => <div className='text-truncate text-uppercase'><FontAwesomeIcon className='me-2' icon={["fas", "database"]} />  {tenant.name}</div>}/>
    }
    else
    {
        return <></>
    }

}

export default TenantSelector