import _ from "lodash";
import { AppThunk } from "store/store";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { adminClientAuditOptimizationSettingActions } from "../adminClientMapOptimizationSettingSlice";
import { OptimizationSettingDetails, OptimizationSettingCreate } from "backend/ApiAdminDefinition/data-contracts";
import apiAdminDefinition from "backend/apiAdminDefinition";
import apiErrorModel from "backend/utils/apiErrorModel";
import uploadFileToStorage from "utility/uploadFileToStorage";

export const createAndUploadOptimizationSettingFilesThunk = (
    mapId: string,
    data: OptimizationSettingCreate,
    files:{
      matrixFile: File,
      matrixComputeFile: File,
      dictionnaryFile: File,
      pointsFile: File
    },
    callbacks: ThrunkApiCallBacks<OptimizationSettingDetails>
  ): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientAuditOptimizationSettingActions.setLoadingForm(true));
  
        // Create audit raw file
        const result = await apiAdminDefinition.optimizationSetting.mapOptimizationSettingPost(mapId, data);
  
        // Check if creation was successful
        if (!result.ok) {
          // Dispatch action to set loading state back to false
          dispatch(adminClientAuditOptimizationSettingActions.setLoadingForm(false));
          // Call onError callback with API error response
          callbacks.onError(result.error as apiErrorModel);
          return;
        }
  
        
        const resultUpload = await Promise.all([
            uploadFileToStorage(result.data.temporaryUploadUrlMatrix ?? "", files.matrixFile),
            uploadFileToStorage(result.data.temporaryUploadUrlComputeMatrix ?? "", files.matrixComputeFile),
            uploadFileToStorage(result.data.temporaryUploadUrlIndexes ?? "", files.dictionnaryFile),
            uploadFileToStorage(result.data.temporaryUploadUrlPoints ?? "", files.pointsFile),
        ]);

  


        // Check if upload was successful
        if (_.some(resultUpload, m => m == false)) {
          // Dispatch action to set loading state back to false
          dispatch(adminClientAuditOptimizationSettingActions.setLoadingForm(false));
          // Call onError callback with error message
          callbacks.onError({errors:{
                "csvFile": [`Un ou plusieurs fichiers n'ont pas pu être upload sur le storage.`]
            }} as apiErrorModel);
          return;
        }
  

        // Add newly created raw file to the store
        dispatch(adminClientAuditOptimizationSettingActions.addOptimizationSetting(result.data));
  
        // Call onSuccess callback with the created raw file
        callbacks.onSuccess(result.data);
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        // Display error notification or perform error handling as needed
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientAuditOptimizationSettingActions.setLoadingForm(false));
      }
    };
  };
