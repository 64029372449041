import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OptimizationBatchDetails, PathDetailsModel, ProcessedProductDetails, RequestRawProductDetails } from 'backend/ApiMiddlewareDefinition/data-contracts';
import React, { useEffect } from 'react'
import { Col, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import AceEditor  from "react-ace-builds";



interface OwnProps {
    batch: OptimizationBatchDetails;
    pathDetailsClient: PathDetailsModel;
    pathDetailsOptimized: PathDetailsModel | undefined;
    processedProducts: ProcessedProductDetails[];
    rawProducts: RequestRawProductDetails[];
 }


/**
 * React component to display a table of details of a batch (optimisation path details).
 * @param batch - The batch details.
 * @param pathDetailsClient - The path details for the original path.
 * @param pathDetailsOptimized - The path details for the optimized path.
 * @param processedProducts - The processed products details.
 * @param rawProducts - The raw products details.
 * @returns The table component.
 */
const MiddlewareBatchTable: React.FC<OwnProps> = ({batch, pathDetailsClient, pathDetailsOptimized, processedProducts, rawProducts}) => {
   const { t } = useTranslation(nameOf({MiddlewareBatchTable}), { useSuspense: false});

   useEffect(() => {
   }, [])


   const renderJsonEditor = (json1: any, json2: any) => {

        /*return <Row>
            <Col>{JSON.stringify(json1, null, "\t")}</Col>
            <Col>{JSON.stringify(json2, null, "\t")}</Col>
        </Row>*/

       return  <AceEditor
       mode="json"
       readOnly
       theme="monokai"
       name="jsonEditor"
       height="600px"
       fontSize={14}
       showPrintMargin={true}
       showGutter={true}
       highlightActiveLine={true}
       value={`// original:
${JSON.stringify(json1, null, "\t")} 

// Optimisé:
${JSON.stringify(json2, null, "\t")}`}
       setOptions={{
           enableBasicAutocompletion: true,
           enableLiveAutocompletion: false,
           enableSnippets: false,
           useWorker: false,
           showLineNumbers: true,
           tabSize: 2,
       }}/>
   }



   /**
        <OverlayTrigger
            key={i}
            placement={"right"}
            overlay={
                <Tooltip style={{width: "100%"}}>
                        <div>{renderJsonEditor(rawProductClient?.rawData)}</div>
                        <div>{renderJsonEditor(rawProductOptimized?.rawData)}</div>
                </Tooltip>
            }
            >
            <FontAwesomeIcon icon={["fas", "info-circle"]} />
        </OverlayTrigger>
    */

   return (<Table striped bordered hover className={' '}>
                <thead>
                 <tr>
                        <th className=''></th>
                        <th className='initial text-center' colSpan={2}>{`${t("Original")} (${pathDetailsClient.processedProductIds.length})`}</th>
                        <th className='optimized text-center' colSpan={2}>{`${t("Optimisé")} (${pathDetailsOptimized?.processedProductIds.length})` }</th>
                    </tr>
                    <tr>
                        <th className=''></th>
                        <th className='initial'>{t("adresse")}</th>
                        <th className='initial'>{t("quantité")}</th>

                        <th className='optimized'>{t("adresse")}</th>
                        <th className='optimized'>{t("quantité")}</th>
                    </tr>
                </thead>
                <tbody>
                    {pathDetailsClient.processedProductIds.map((processedProductIdClient, i) => {

                        
                        const processedProductClient= processedProducts.find(p => p.id == processedProductIdClient);
                        const rawProductClient = rawProducts.find(p => p.id == processedProductClient?.requestRawProductId);

                        const processedProductIdOptimized = pathDetailsOptimized?.processedProductIds[i];
                        const processedProductOptimized= processedProducts.find(p => p.id == processedProductIdOptimized);
                        const rawProductOptimized = rawProducts.find(p => p.id == processedProductOptimized?.requestRawProductId);


                        return <OverlayTrigger
                            key={i}
                            placement={"right"}
                            overlay={
                                <Tooltip style={{maxWidth: "100%"}}>
                                        {renderJsonEditor(rawProductClient?.rawData, rawProductOptimized?.rawData)}
                                </Tooltip>
                            }>
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td className={`${processedProductClient?.error ? "text-danger" : ""}`} title={processedProductClient?.error ?? processedProductClient?.address}>{processedProductClient?.address}</td>
                                    <td className='initial'>{processedProductClient?.quantity}</td>
                                    <td className={`${processedProductOptimized?.error ? "text-danger" : ""}`} title={processedProductOptimized?.error ?? processedProductOptimized?.address}>{processedProductOptimized?.address}</td>
                                    <td className='optimized'>{processedProductOptimized?.quantity}</td>
                                </tr>
                    </OverlayTrigger>                    
                    })}
                </tbody>
                <tfoot>
                {<tr>
                        <th style={{fontWeight: "normal"}}className=''><FontAwesomeIcon icon={["fas", "flag-checkered"]} /> </th>
                        <th style={{fontWeight: "normal"}}className='text-center initial' colSpan={2}>{pathDetailsClient.distance.toLocaleString("fr-FR")} {t("mètres")}</th>
                        <th style={{fontWeight: "normal"}}className='text-center optimized' colSpan={2}>{pathDetailsOptimized?.distance.toLocaleString("fr-FR") ?? "N/C"} {t("mètres")}</th>
                </tr>}
                {/*gainPourcentString && gainPourcentString!="NaN" && <tr>
                        <th className=''><FontAwesomeIcon icon={["fas", "percent"]} /> </th>
                        <th className='text-center' colSpan={4}>{parseFloat(gainPourcentString)}%</th>
                </tr>*/}
                </tfoot>
                </Table>)
}
export default MiddlewareBatchTable