import i18next from "i18next";
import { toast } from "react-toastify";
import { userProfilActions} from "store/reducers/Profil/userProfilSlice";
import { AppThunk } from "store/store"
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";
import { loadMiddlewareThunk } from "./loadMiddlewareThunk";


/**
 * Load user infos from api for current tenant
 * @returns 
 */
export const LoadUserInfosThunk = (tenant?: string | undefined): AppThunk => {
    return async (dispatch, getState) => {

        try{

            const tenantExist = getState().userProfil.currentTenant?.tenant === tenant
            

            if(!tenantExist)
            {
                console.error("[LoadUserInfosThunk] no tenant selected")
                return
            }
            dispatch(loadMiddlewareThunk());
            
            dispatch(userProfilActions.setLoadingUserData(true));
            const [
                resultUserInfos,
                allLanguages,
                
            ] = await Promise.all([
                apiBackofficeDefinition.account.userInfosList(),
                apiBackofficeDefinition.language.languagesList()
            ]) 

            if(resultUserInfos)
            {
                dispatch(userProfilActions.setUserInfo(resultUserInfos.data));
            }
            else{
                throw new Error ("Error getting user infos for current tenant")
            }
            dispatch(userProfilActions.setLanguages(allLanguages.data));


            
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des données utilisateur"))
            console.error("[LoadUserInfosThunk] error", error)
        }
        finally{
            dispatch(userProfilActions.setLoadingUserData(false));
        }
    }
}