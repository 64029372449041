import { AppThunk } from "store/store"
import { adminClientFileManagerActions } from "store/reducers/Admin/FileManager/adminClientFileManagerSlice";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Load current selected client's files (storage)
 * @returns 
 */
export const loadClientFilesThunk = (): AppThunk => {
    return async (dispatch, getState) => {
        dispatch(adminClientFileManagerActions.setLoadingList(true));
        const files = await apiBackofficeDefinition.storage.filesList();
        if(files) dispatch(adminClientFileManagerActions.setFiles(files.data));
        dispatch(adminClientFileManagerActions.setLoadingList(false));
    }
}