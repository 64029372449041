import i18next from "i18next";
import { toast } from "react-toastify";
import { adminClientMapAssetActions} from "../adminClientMapAssetSlice";
import { AppThunk } from "store/store"
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Thunk action creator for loading assets
 * @param mapId 
 * @returns 
 */
export const loadAssetsThunks = (mapId: string): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapAssetActions.setLoadingAssetList(true));
  
        // Fetch assets from the API
        const clientMaps = await apiBackofficeDefinition.asset.assetsList(mapId);
  
        // Dispatch action to set the fetched assets in the store
        dispatch(adminClientMapAssetActions.setAssets(clientMaps.data));
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors du chargement des assets de la map"));
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapAssetActions.setLoadingAssetList(false));
      }
    };
  };