import i18next from "i18next";
import { toast } from "react-toastify";
import { adminClientRolesActions } from "store/reducers/Admin/ClientRole/adminClientRolesSlice";
import { AppThunk } from "store/store"
import apiAdminDefinition from "backend/apiAdminDefinition";


/**
 * Load current selected client's roles
 * @returns 
 */
export const loadClientRolesThunk = (): AppThunk => {
    return async (dispatch, getState) => {
        const selectedClient = getState().userProfil.currentTenant;

        if(!selectedClient) {
            toast.warn(i18next.t("Impossible de récupérer les permissions car aucun client n'est sélectionné"))
            return;
        }

       try{

            dispatch(adminClientRolesActions.setLoadingRolesList(true));
            apiAdminDefinition.roleClient.clientRolesList()
                .then((roles) => dispatch(adminClientRolesActions.setRoles(roles.data)))
            
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des roles du client"))
            console.error("[loadClientRolesThunk] error", error)
        }
        dispatch(adminClientRolesActions.setLoadingRolesList(false));
    }
}