import { AppThunk } from "store/store"
import { LoadAuditDataThunk } from "store/reducers/Audit/thrunks/LoadAuditDataThunk";
import { MapDetails } from "backend/ApiBackofficeDefinition/data-contracts";



/**
 * Thunk action creator for loading related data for the current map
 * @param map - The current map to load related data for
 * @returns An AppThunk function
 */
export const loadRelatedDataCurrentMapThrunks = (map: MapDetails): AppThunk => {
    return async (dispatch, getState) => {
        // Check if the current map has audits
        if (map.totalAudits > 0 && getState().userProfil.currentTenant) {
            // If there are audits, dispatch action to load audit data
            dispatch(LoadAuditDataThunk(map.id));
        }
    };
};