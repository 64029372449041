import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OffMeshLinkDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

// Interface representing the state of the admin client map OffMeshLink store
export interface adminClientMapOffMeshLinkStore {
  offMeshLinks: OffMeshLinkDetails[];
  loadingList: boolean;
  loadingForm: boolean;
}

// Initial state for the admin client map OffMeshLink store
const initialState: adminClientMapOffMeshLinkStore = {
  offMeshLinks: [],
  loadingList: false,
  loadingForm: false,
};

// Create a slice for the admin client map OffMeshLink store
const adminClientMapOffMeshLinkSlice = createSlice({
  name: "adminClientMapOffMeshLinkSlice",
  initialState,
  reducers: {
    // Set the OffMeshLinks in the state
    setOffMeshLinks: (state, action: PayloadAction<OffMeshLinkDetails[]>) => {
      state.offMeshLinks = action.payload;
    },
    // Update a specific OffMeshLink in the state
    updateOffMeshLink: (state, action: PayloadAction<OffMeshLinkDetails>) => {
      state.offMeshLinks = state.offMeshLinks.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new OffMeshLink to the state
    addOffMeshLink: (state, action: PayloadAction<OffMeshLinkDetails>) => {
      state.offMeshLinks.push(action.payload);
    },
    // remove an OffMeshLink
    deleteOffMeshLink: (state, action: PayloadAction<string>) => {
      state.offMeshLinks = state.offMeshLinks.filter(m => m.id !== action.payload)
    },
    // Set the loadingList state
    setLoadingOffMeshLinkList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingForm state
    setLoadingOffMeshLinkForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.offMeshLinks = [];
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

// Export the actions and reducer from the admin client map OffMeshLink slice
export const {
  actions: adminClientMapOffMeshLinkActions,
  reducer: adminClientMapOffMeshLinkReducer,
} = adminClientMapOffMeshLinkSlice;
