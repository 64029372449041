import React, { useEffect, useState } from 'react'
import "./Configuration.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sdk from 'fno-sdk';
import { Form } from 'react-bootstrap';

interface OwnProps { 
    sdk: Sdk;
}

const Configuration: React.FC<OwnProps> = ({sdk}) => {

    useEffect(() => {
        if(sdk)
        {
            sdk.pathfinding.clearItineraries();
        }
        return () => {
            sdk.pathfinding.clearItineraries();
        }
    }, [sdk])
    if(!sdk) return <></>; 
    

    const renderSelectPrio = (order: number) => {
        const options = [];
        for (let i = 1; i < 11; i++) {
          options.push(<option key={i} selected={order == i}>{i}</option>)
        }
        
        return <select style={{width: "70px", marginRight:"10px"}}>{options}</select>
    }


    return (
        <div id='demo-picking-statistics'>
            <div id="order-panel">

                <div className="picking-config-field mt-3">
                    <h3>Picking rules</h3>
                </div>
                <div className="picking-config-field">
                    <div>Products max by mission</div>
                    <div><Form.Control value={"20"}/></div>
                </div>
                <div className="picking-config-field">
                    <div>Orders max by mission</div>
                    <div><Form.Control value={"5"}/></div>
                </div>
                <div className="picking-config-field">
                    <div>Support max by mission</div>
                    <div><Form.Control value={"10"}/></div>
                </div>



                <div className="picking-config-field mt-5">
                    <h3>Picking priorities</h3>
                </div>
                <div className="picking-config-field">
                    <div><span><img src='https://fnoproduction.blob.core.windows.net/waypointicon/home.png' /></span>Zone 1</div>
                    <div>{renderSelectPrio(1)}</div>
                </div>
                <div className="picking-config-field">
                    <div><span><img src='https://fnoproduction.blob.core.windows.net/waypointicon/health.png' /></span>Zone 2</div>
                    <div>{renderSelectPrio(2)}</div>
                </div>
                <div className="picking-config-field">
                    <div><span><img src='https://fnoproduction.blob.core.windows.net/waypointicon/grocery.png' /></span>Zone 3</div>
                    <div>{renderSelectPrio(3)}</div>
                </div>
                <div className="picking-config-field">
                    <div><span><img src='https://fnoproduction.blob.core.windows.net/waypointicon/bottle.png' /></span>Zone 4</div>
                    <div>{renderSelectPrio(4)}</div>
                </div>
                <div className="picking-config-field">
                    <div><span><img src='https://fnoproduction.blob.core.windows.net/waypointicon/bottle_water.png' /></span>Zone 5</div>
                    <div>{renderSelectPrio(5)}</div>
                </div>
                <div className="picking-config-field">
                    <div><span><img src='https://fnoproduction.blob.core.windows.net/waypointicon/winter_ice.png' /></span>Zone 6</div>
                    <div>{renderSelectPrio(6)}</div>
                </div>
            </div>
        </div>
    )
}

export default Configuration
