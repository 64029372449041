import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, redirect } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import { useAppDispatch } from 'store/store';
import nameOf from 'utility/nameOf';

const PickingDashboard: React.FC = () => {
   const { t } = useTranslation(nameOf({PickingDashboard}), { useSuspense: false});
   const dispatch = useAppDispatch();

   useEffect(() => {
   }, [])

   return <Navigate to={ROUTES.ADMIN.PICKING.MIDDLEWARES} replace={true} />
   /*return (
       <div>
           Page Picking Dashboard
       </div>
   )*/
}
export default PickingDashboard