import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClientDetails } from "backend/ApiAdminDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

export interface IAdminClientStore {
  allClients: ClientDetails[];
  loadingAllClients: boolean;
  loadingUpsert: boolean;
}

const initialState: IAdminClientStore = {
  allClients: [],
  loadingAllClients: true,
  loadingUpsert: false
};

const adminClientSlice = createSlice({
  name: 'adminClientReducer',
  initialState,
  reducers: {
    setLoadingAllClients(state, action: PayloadAction<boolean>) {
      state.loadingAllClients = action.payload;
    },
    setloadingUpsert(state, action: PayloadAction<boolean>) {
      state.loadingUpsert = action.payload;
    },
    setAdminClients(state, action: PayloadAction<ClientDetails[]>) {
      state.allClients = action.payload;
    },
    addClient(state, action: PayloadAction<ClientDetails>) {
      state.allClients.push(action.payload);
    },
    updateClient(state, action: PayloadAction<ClientDetails>) {
      state.allClients = state.allClients.map((client) => {
        if (client.id === action.payload.id) return action.payload;
        else return client;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userProfilActions.setLogout, (state) => {
      state.allClients = [];
      state.loadingAllClients = true;
    })
  }
});

export const { actions: adminClientActions, reducer: adminClientReducer } = adminClientSlice;