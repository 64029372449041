import Item from "./models/Item";
import { Label } from "./models/Label";

// doc: https://api-portal-eu.vusion.io/api-details#api=675160b8-23b0-486d-ad3f-d403e92212cc&operation=5ea828da9e98051308a85868

export class apiVusion {
    private _apiKey = "";
    private _baseUrl = "https://api-eu.vusion.io/vcloud/v1";
    private _storeId = "";


    constructor(apiKey: string, storeId: string) {
        this._storeId= storeId;
        this._apiKey = apiKey;
    }


    private getHeaderHelper()
    {
        const headers: {[key: string]: any} = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Ocp-Apim-Subscription-Key': this._apiKey
        }
        return headers;
    }

    public getItemByLocationCode(itemId: string): Promise<Item>{
        const requestOptions = {
            method: 'GET',
            headers: this.getHeaderHelper(),
        };
    
        return fetch((`${this._baseUrl}/stores/${this._storeId}/items/${itemId}`), {
            ...requestOptions
        })
        .then(response => {
            
            return response.json()
        })
        .then((data) => {
            return data as Item
        });
    }

    public getLabelById(labelId: string): Promise<Label>{
        const requestOptions = {
            method: 'GET',
            headers: this.getHeaderHelper(),
        };
    
        return fetch((`${this._baseUrl}/stores/${this._storeId}/labels/${labelId}`), {
            ...requestOptions
        })
        .then(response => {
            
            return response.json()
        })
        .then((data) => {
            return data as Label
        });
    }

    public flashItem(labelId: string, color = "WHITE", duration = 10, pattern = "EACH_2_SECONDS"): Promise<boolean>{
        const requestOptions = {
            method: 'POST',
            headers: this.getHeaderHelper(),
            body: JSON.stringify([{
                "labelId": labelId,
                "color": color,
                "duration": duration,
                "pattern": pattern
            }])
        };
    
        return fetch((`${this._baseUrl}/stores/${this._storeId}/labels/flashes`), {
            ...requestOptions
        })
        .then(response => response.json())
        .then((data) => true);
    }
}