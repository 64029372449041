import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sdk from 'fno-sdk';
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Orders from '../Orders';
import dayjs from 'dayjs';


interface OwnProps { 
    dateFrom: Date;
    dateTo: Date;
}


interface IStatsData{
    name: string;
    nbOrders: number;
    nbProducts: number;
    nbMissions: number;
}

const NbOrders: React.FC<OwnProps> = ({dateFrom, dateTo}) => {

    const [data, setData] = useState<IStatsData[]>([]);


    useEffect(() => {
        if(dayjs(dateFrom).isBefore(dateTo)){
            const tmpData:IStatsData[] = []
            let currentDate = dayjs(dateFrom);

            while(dayjs(currentDate).isBefore(dateTo)){ 
                tmpData.push({
                    name: currentDate.format("DD/MM"), 
                nbOrders: Math.trunc(Math.random() * (100 - 15) + 15), 
                nbProducts: Math.trunc(Math.random() * (300 - 50) + 50),
                nbMissions: Math.trunc(Math.random() * (30 - 10) + 10),
            });
                currentDate = currentDate.add(1, "day");   
            }
            setData(tmpData);
        }
        else
        {
            setData([]);
        }
    }, [dateFrom, dateTo])


    return (
        <div className='mt-3'>
            <h4 className=' mt-2'>Missions, orders and products</h4>
            <ResponsiveContainer width={"100%"} height={200}>
                <BarChart data={data} >
                    
                    <CartesianGrid />
                    <XAxis dataKey="name" angle={45} tickSize={18} height={40} interval={0}/>
                    <YAxis interval={0}/>
                    <Tooltip />
                    <Legend/>
                    <Bar dataKey="nbOrders" fill="#ff6d3a" />
                    <Bar dataKey="nbProducts" fill="#21d8dc" />
                    <Bar dataKey="nbMissions" fill="#e7b525" />
                </BarChart>
            </ResponsiveContainer>
            
        </div>
    )
}

export default NbOrders
