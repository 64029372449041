import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import getTenantFromClientId from "backend/utils/getTenantFromClientId";
import getApiConfig from "backend/utils/getApiConfig";
import { RoleClientDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { OnSuccess } from "models/ThrunkApiCallbacks";
import { Roles } from "backend/ApiAdminDefinition/Roles";


/**
 * Load client's roles for specific client id. Doesn't save in state client
 * @returns 
 */
export const getClientRolesByClientIdThunk = (clientId: string, onSuccess: OnSuccess<RoleClientDetails[]>): AppThunk => {
    return async (dispatch, getState) => {
       try{

            const tenant = getTenantFromClientId(clientId);
            // regenerate Roles api for override tenant in header
            const apiAdminRolesCient = new Roles(getApiConfig(tenant));
            apiAdminRolesCient.clientRolesList()
                .then((roles) => onSuccess(roles.data))
            
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des roles du client"))
            console.error("[getClientRolesByClientIdThunk] error", error)
        }
    }
}