import Sdk from 'fno-sdk';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import "./FloorControls.scss";
import { Floor } from 'fno-sdk/build/models/unityEventModels/Floor';
import { useAppDispatch, useAppSelector } from 'store/store';
import { mapControlsActions } from 'store/reducers/mapControls/mapControls';
import _ from 'lodash';

interface Props {
    sdk: Sdk;
    loading: boolean;
}


/**
 * FloorControls is a React functional component, it provides a way to switch between different floors in the map.
 * It is meant to be used in a MapRender component.
 * @param sdk Sdk: The fno-sdk instance.
 * @param loading boolean: A boolean indicating if the sdk is still loading.
 * @returns JSX.Element
 */
const FloorControls: React.FC<Props> = ({sdk, loading}) => {
   const { t } = useTranslation(nameOf({FloorControls}), { useSuspense: false});
   const dispatch = useAppDispatch();
   
   const {
    currentFloor,
    floors
   } = useAppSelector(state => state.mapControls);

   useEffect(() => {

    if(sdk && !loading){
        sdk.floor.getFloors().then((data) => {
            const floors = _.orderBy(data.floors, ['floorIndex'], ['desc']);
            dispatch(mapControlsActions.setFloors(floors))
            if(floors.length > 0){
                dispatch(mapControlsActions.setCurrentFloor(floors[floors.length-1]))
            }
        })

    }
   }, [sdk, loading])


   useEffect(() => {

    if(sdk && currentFloor){
        sdk.floor.setFloors([currentFloor.id])
    }
   }, [currentFloor])


   const onSelectFloor = (floor: Floor) => {
       dispatch(mapControlsActions.setCurrentFloor(floor))
   }

   if(floors.length < 2) return <></>
   return (
       <div className='floor-controls'>
           {floors.map((f, i) => <div key={i} className={'floor ' + (f.id == currentFloor?.id ? "active" : "")} onClick={() => onSelectFloor(f)}>{f.name}</div>)}
       </div>
   )
}
export default FloorControls