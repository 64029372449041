import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/store';
import SelectLarge from '../inputs/SelectLarge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { SelectAuditDataThunk } from 'store/reducers/Audit/thrunks/SelectAuditDataThunk';
import { AuditRawFileDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import _ from 'lodash';


interface OwnProps {
    audits: AuditRawFileDetails[];
    auditSelected: AuditRawFileDetails | undefined;
    onAuditChange: (auditFile: AuditRawFileDetails) => void
 }



/**
 * The AuditSelector component displays a dropdown list of audit files along with their creation date.
 * The audit files are sorted in descending order of creation date.
 * The selected audit file is displayed in a special format, showing the audit name and creation date range.
 * 
 * @param {OwnProps} props - The props object containing the list of audit files, the selected audit file, and the callback function to handle audit selection.
 * @param {AuditRawFileDetails[]} props.audits - The list of audit files.
 * @param {AuditRawFileDetails | undefined} props.auditSelected - The selected audit file.
 * @param {(auditFile: AuditRawFileDetails) => void} props.onAuditChange - The callback function to handle audit selection.
 * @return {JSX.Element} The AuditSelector component.
 */
const AuditSelector: React.FC<OwnProps> = ({audits, auditSelected, onAuditChange}) => {
    if(audits && audits.length > 0)
    {
        return <SelectLarge options={_.orderBy(audits, ['dateCreated'], ['desc'])} 
                        value={auditSelected} 
                        onChange={onAuditChange} 
                        valueRender={(audit) => {

                            const dateFrom = dayjs(audit.dateFrom).format("DD/MM/YYYY");
                            const dateTo = dayjs(audit.dateTo).format("DD/MM/YYYY");

                            const dateString = (dateFrom == dateTo) ? `${dateFrom}` : `${dateFrom} - ${dateTo}`;
       

                            return <div className='text-truncate text-uppercase' title={audit.name}> 
                                <div><FontAwesomeIcon className='me-2' icon={["fas", "file-csv"]} />  {audit.name}</div>
                                <small>{dateString}</small>
                            </div>;
                        }}
                        optionRender={(audit) => {
                            const dateFrom = dayjs(audit.dateFrom).format("DD/MM/YYYY");
                            const dateTo = dayjs(audit.dateTo).format("DD/MM/YYYY");

                            const dateString = (dateFrom == dateTo) ? `${dateFrom}` : `${dateFrom} - ${dateTo}`;

                            return <div className='text-truncate text-uppercase' title={audit.name}> 
                                <div><FontAwesomeIcon className='me-2' icon={["fas", "file-csv"]} />  {audit.name}</div>
                                <small>{dateString}</small>
                            </div>;
                        }}/>
    }
    else
    {
        return <></>
    }

}

export default AuditSelector