/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, MiddlewareApiDetails } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Middlewares<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Middlewares
     * @name GetAllClientMiddlewares
     * @summary [🤵 OR 🔑] Get all middlewares. [🔒: map_read].
     * @request GET:/middlewares
     * @secure
     */
    getAllClientMiddlewares = (params: RequestParams = {}) =>
        this.request<MiddlewareApiDetails[], ApiErrorsResponse | void>({
            path: `/middlewares`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
}
