import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userProfilActions } from '../Profil/userProfilSlice';
import { MapDetails } from 'backend/ApiBackofficeDefinition/data-contracts';

export interface IMapStore {
  maps: MapDetails[];
  currentMap: MapDetails | undefined;
}

const initialState: IMapStore = {
  maps: [],
  currentMap: undefined,
};

const mapSlice = createSlice({
  name: 'place',
  initialState,
  reducers: {
    setMaps: (state, action: PayloadAction<MapDetails[]>) => {
      state.maps = action.payload;
    },
    setCurrentMap: (state, action: PayloadAction<MapDetails | undefined>) => {
      state.currentMap = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userProfilActions.setLogout, (state) => {
        state.maps = [];
        state.currentMap = undefined;
    })
  }
});


export const { actions: mapActions, reducer: mapReducer } = mapSlice;