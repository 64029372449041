

/**
 * Read the content of the given file using FileReader and return a promise that resolves to the file content.
 *
 * @param {File} file - The file to read
 * @return {Promise<string>} A promise that resolves to the content of the file
 */
export default async function readFileContent(file: File): Promise<string> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => {
        const content = event.target?.result as string;
        resolve(content);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  }