import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import PageHeader from 'components/PageHeader';
import { ROUTES } from 'resources/routes-constants';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonFno from 'components/inputs/ButtonFno';
import "./AdminClientFileManager.scss"
import { ChonkyActions, ChonkyIconName, FileArray, FileData, FileHelper, FullFileBrowser, I18nConfig, defineFileAction, setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import UploadFileForm from 'components/Admin/forms/UploadFileForm';
import { adminClientFileManagerActions } from 'store/reducers/Admin/FileManager/adminClientFileManagerSlice';
import IFileUploadStatus from 'models/IFileUploadStatus';
import dayjs from 'dayjs';
import DeleteConfirmForm from 'components/DeleteConfirmForm';
import StorageFileStatus from 'components/StorageFileStatus';
import { downloadFile } from 'utility/downloadFile';
import { loadClientFilesThunk } from 'store/reducers/Admin/FileManager/thrunks/loadClientFilesThunk';
import { createAndUploadFilesThunk } from 'store/reducers/Admin/FileManager/thrunks/createAndUploadFilesThunk';
import { updateAndUploadFilesThunk } from 'store/reducers/Admin/FileManager/thrunks/updateAndUploadFilesThunk';
import { FileDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import { deleteFilesThunk } from 'store/reducers/Admin/FileManager/thrunks/deleteFilesThunk';


setChonkyDefaults({ iconComponent: ChonkyIconFA });

const AdminClientFileManager: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientFileManager}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const selectedClient = useAppSelector(state => state.userProfil.currentTenant);
    const { clientId } = useParams();
    const files = useAppSelector(state => state.adminClientFileManager.files);
    const loadingSave= useAppSelector(state => state.adminClientFileManager.loadingUpload);
    const uploadingFiles= useAppSelector(state => state.adminClientFileManager.uploadingFilesStatus);
    const deletingFiles = useAppSelector(state => state.adminClientFileManager.deletingFilesStatus);

    const [fileToUpdate, setFileToUpdate] = useState<FileDetails | undefined>(undefined);
    const [showCreateForm, setShowCreateForm] = useState(false);
    

    const copyFileUrlAction = useMemo(() => {
        return defineFileAction({
            id: 'copyFileUrlAction',
            requiresSelection: true,
            button: {
              name: t("Copier l'url du fichier"),
              toolbar: false,
              contextMenu: true,
              icon: ChonkyIconName.copy,
            },
          })
    }, []);

    const copyFileIdAction = useMemo(() => {
        return defineFileAction({
            id: 'copyFileIdAction',
            requiresSelection: true,
            button: {
              name: t("Copier l'id du fichier"),
              toolbar: false,
              contextMenu: true,
              icon: ChonkyIconName.key,
            },
          })
    }, []);

    const editFileAction = useMemo(() => {
        return defineFileAction({
            id: 'editFileAction',
            requiresSelection: true,
            button: {
              name: t("Modifier le fichier"),
              toolbar: false,
              contextMenu: true,
              icon: ChonkyIconName.info,
            },
          })
    }, []);

    useEffect(() => {
        document.title = t('[Admin] Fichiers du client');

    }, [])

    useEffect(() => {
        if(selectedClient)
        {
            loadFiles();
        }
        
    }, [selectedClient])

    if(!selectedClient)
    {
        return <Navigate to={ROUTES.ADMIN.CLIENTS} replace />
    }



    const loadFiles = () => {
        dispatch(loadClientFilesThunk());
    }


    const copyToClipboard = (data: string) => {
        navigator.clipboard.writeText(data);
        toast.success(<>
            <div>{t("Copié dans le presse papier")}</div>
        </>)
    }


    const onSubmitCreateFiles = async (files: File[]) =>  {


        dispatch(createAndUploadFilesThunk(files, {
            onSuccess: (file) => {
                toast.success(file.length  + " " + t("Fichier(s) uploadé(s)"));
                loadFiles();
            },
            onError: (error) => {
                toast.error(t("Erreur lors de la création du fichier"))
            }
        }))
    }

    const onSubmitUpdateFile = async (files: File[]) =>  {
        if(!fileToUpdate) return;


        dispatch(updateAndUploadFilesThunk(fileToUpdate, files[0], {
            onSuccess: (file) => {
                toast.success( t("Fichier modifié"));
                setFileToUpdate(file);
                loadFiles();
            },
            onError: (error) => {
                toast.error(t("Erreur lors de la création du fichier"))
            }
        }))
    }

    const onSubmitDeleteFiles = async () =>  {


        dispatch(deleteFilesThunk(deletingFiles, {
            onSuccess: (file) => {
                toast.success(<><div>{deletingFiles.length} {t("Fichier(s) supprimé(s)")}</div></>);
                loadFiles();
                onCloseDeleteFiles();
            },
            onError: (error) => {
                toast.error(t("Erreur lors de la suppression du fichier"))
            }
        }))
    }


    const onCloseCreateFiles = () => {
        dispatch(adminClientFileManagerActions.setCreatingFilesStatus([]));
        setShowCreateForm(false)
    }

    const onCloseDeleteFiles = () => {
        dispatch(adminClientFileManagerActions.setDeletingFilesStatus([]));
    }

    const onCloseUpdateFile = () => {
        setFileToUpdate(undefined);
    }

    const onDowloadFiles = async (files: FileDetails[]) => {
        await Promise.all(files.map((f) => downloadFile(f.url, f.fileName)))
    }

    return (
        <div id="admin-client-file-manager">
             <PageHeader title={t("Storage")} admin middlePart={[
                {to: ROUTES.ADMIN.CLIENTS, title: t("Clients")},
                {to: ROUTES.ADMIN.CLIENT(clientId).DETAILS, title: selectedClient.name}]}/>
            <div id="file-panel">
                <div className='text-center mb-2'>
                    <ButtonFno className='me-2' onClick={() => setShowCreateForm(true)}><FontAwesomeIcon className='me-2' icon={["fas", "upload"]} /> {t("Uploader un fichier")}</ButtonFno>
                </div>
                <FullFileBrowser 
                    fileActions={[
                        ChonkyActions.DeleteFiles,
                        ChonkyActions.DownloadFiles,
                        copyFileUrlAction,
                        copyFileIdAction,
                        editFileAction
                    ]}

                    disableDefaultFileActions={[
                        ChonkyActions.OpenSelection.id,
                        ChonkyActions.SelectAllFiles.id,
                        ChonkyActions.ClearSelection.id
                    ]}
                    onFileAction={(data) => {
                        if(data.id == ChonkyActions.DeleteFiles.id) dispatch(adminClientFileManagerActions.setDeletingFilesStatus(data.state.selectedFiles.map((f) => {return { id: f.id, name: f.name, status: 'waiting' }  as IFileUploadStatus})));
                        else if (data.id == ChonkyActions.DownloadFiles.id) onDowloadFiles(data.state.selectedFiles.map((f) => f as unknown as FileDetails))
                        else if (data.id == copyFileUrlAction.id) copyToClipboard((data.state.selectedFiles[0] as unknown as FileDetails).url)
                        else if (data.id == copyFileIdAction.id) copyToClipboard((data.state.selectedFiles[0] as unknown as FileDetails).id)
                        else if (data.id == editFileAction.id) setFileToUpdate(data.state.selectedFiles[0] as unknown as FileDetails)
                    }}
                    i18n={{
                        formatters:{
                            formatFileModDate(intl, file) {
                                const safeModDate = FileHelper.getModDate(file);
                                return safeModDate ? dayjs(safeModDate).format("DD/MM/YYYY HH:mm"):"N/C";
                            },
                        },
                        messages:{
                            'chonky.toolbar.searchPlaceholder': t("Rechercher", { ns:"TransverseFileManager"}),
                            'chonky.toolbar.visibleFileCount': `{fileCount, plural,
                                =0 {}
                                one {# ${t("fichier", { ns:"TransverseFileManager"})}}
                                other {# ${t("fichiers", { ns:"TransverseFileManager"})}}
                            }`,
                            'chonky.toolbar.selectedFileCount': `{fileCount, plural,
                                =0 {}
                                one {# ${t("sélectionné", { ns:"TransverseFileManager"})}}
                                other {# ${t("sélectionnés", { ns:"TransverseFileManager"})}}
                            }`,
                            'chonky.toolbar.hiddenFileCount': `{fileCount, plural,
                                =0 {}
                                one {# 3a}
                                other {# 3b}
                            }`,
                            'chonky.fileList.nothingToShow': t("Aucun fichier", { ns:"TransverseFileManager"}),
                            'chonky.contextMenu.browserMenuShortcut': `${t("Menu du navigateur", { ns:"TransverseFileManager"})} : {shortcut}`,
                    
                            // File action translation strings. These depend on which actions you have
                            // enabled in Chonky.
                            [`chonky.actionGroups.Actions`]: t("Actions", { ns:"TransverseFileManager"}),
                            [`chonky.actionGroups.Options`]: t("Options", { ns:"TransverseFileManager"}),
                            [`chonky.actions.${ChonkyActions.OpenParentFolder.id}.button.name`]: '7',
                            [`chonky.actions.${ChonkyActions.DownloadFiles.id}.button.name`]:  t("Télécharger le fichier", { ns:"TransverseFileManager"}),
                            [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.name`]:  t("Créer un dossier", { ns:"TransverseFileManager"}),
                            [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.tooltip`]: t("Créer un dossier", { ns:"TransverseFileManager"}),
                            [`chonky.actions.${ChonkyActions.DeleteFiles.id}.button.name`]: t("Supprimer le fichier", { ns:"TransverseFileManager"}),
                            [`chonky.actions.${ChonkyActions.OpenSelection.id}.button.name`]: t("Ouvrir la sélection", { ns:"TransverseFileManager"}),
                            [`chonky.actions.${ChonkyActions.SelectAllFiles.id}.button.name`]: t("Sélectionner tous les fichiers", { ns:"TransverseFileManager"}),
                            [`chonky.actions.${ChonkyActions.ClearSelection.id}.button.name`]: t("Nettoyer la sélection", { ns:"TransverseFileManager"}),
                            [`chonky.actions.${ChonkyActions.EnableListView.id}.button.name`]: t("Afficher la vue liste", { ns:"TransverseFileManager"}),
                            [`chonky.actions.${ChonkyActions.EnableGridView.id}.button.name`]: t("Nettoyer la vue tableau", { ns:"TransverseFileManager"}),
                            [`chonky.actions.${ChonkyActions.SortFilesByName.id}.button.name`]: t("Trier par nom", { ns:"TransverseFileManager"}),
                            [`chonky.actions.${ChonkyActions.SortFilesBySize.id}.button.name`]: t("Trier par taille", { ns:"TransverseFileManager"}),
                            [`chonky.actions.${ChonkyActions.SortFilesByDate.id}.button.name`]: t("Trier par date de modification", { ns:"TransverseFileManager"}),
                            [`chonky.actions.${ChonkyActions.ToggleHiddenFiles.id}.button.name`]: t("Afficher les fichiers cachés", { ns:"TransverseFileManager"}),
                            [`chonky.actions.${ChonkyActions.ToggleShowFoldersFirst.id}.button.name`]: t("Afficher les dossiers en 1er", { ns:"TransverseFileManager"}),
                        }
                    } as I18nConfig}
                    files={files.map(file => {
                        return {
                            ...file,
                            name: file.fileName,
                            isHidden: false,
                            thumbnailUrl: file.url + "?v=" +file.version,
                            modDate: file.dateUpdated,
                            color:"#c7c7c7",
                            draggable: false,
                            droppable: false
                        } as FileData
                    })} />
            </div>

            {showCreateForm && <Modal dialogClassName='modal-fno' show={true} onHide={onCloseCreateFiles}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Uploader un fichier")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UploadFileForm  loading={loadingSave} onSubmit={onSubmitCreateFiles} onCancel={onCloseCreateFiles} uploadingFiles={uploadingFiles}/>
                </Modal.Body>
            </Modal>}

            {fileToUpdate && <Modal dialogClassName='modal-fno' show={true} onHide={onCloseUpdateFile}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Modifier un fichier")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UploadFileForm  loading={loadingSave} initialFile={fileToUpdate} onSubmit={onSubmitUpdateFile} onCancel={onCloseUpdateFile} uploadingFiles={uploadingFiles}/>
                </Modal.Body>
            </Modal>}

            {deletingFiles.length > 0 && <Modal dialogClassName='modal-fno' show={true} onHide={onCloseDeleteFiles}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Supprimer le(s) fichier(s)")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DeleteConfirmForm onCancel={onCloseDeleteFiles} 
                        onSubmit={onSubmitDeleteFiles} 
                        text={<>
                        <h3>{t("Êtes vous sûre de vouloir supprimer le(s) fichier(s) suivant ?")}</h3>
                            <StorageFileStatus uploadingFiles={deletingFiles} />
                        </>}
                        loading={loadingSave}/>
                </Modal.Body>
            </Modal>}
            
        </div>
    )
}

export default AdminClientFileManager;