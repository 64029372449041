import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { toast } from 'react-toastify';
import DataTable, { TableColumn } from 'react-data-table-component';
import { BuildingCreate, BuildingDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import { updateBuildingThunk } from 'store/reducers/Admin/ClientMapBuilding/thrunks/updateBuildingThunk';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { deleteBuildingThrunks } from 'store/reducers/Admin/ClientMapBuilding/thrunks/deleteBuildingThrunks';
import { loadBuildingsThunks } from 'store/reducers/Admin/ClientMapBuilding/thrunks/loadBuildingsThunks';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import { createBuildingThunk } from 'store/reducers/Admin/ClientMapBuilding/thrunks/createBuildingThunk';
import { MapBuilding } from 'backend/ApiBackofficeDefinition/MapBuilding';

const AdminClientMapBuildings: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapBuildings}), { useSuspense: false});
    const dispatch = useAppDispatch();

    const {
        loadingList,
        loadingForm,
        buildings
    } = useAppSelector(state => state.adminMapBuilding)

    const { clientId, mapId } = useParams();

    useEffect(() => {
        document.title = t('[Admin] Bâtiments de la map');
        if(clientId && mapId && mapId != "new")
        {
            dispatch(loadBuildingsThunks(mapId));
        }
    }, [clientId, mapId])


    const onCreate = (entity: BuildingDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(createBuildingThunk(mapId, entity,{
                onSuccess: (asset) => {
                    closeModal();
                    toast.success(t("Le bâtiment a bien été créé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onUpdate = (entity: BuildingDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {

            dispatch(updateBuildingThunk(mapId, entity,{
                onSuccess: (building) => {
                    closeModal();
                    toast.success(t("Le bâtiment a bien été modifié"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onDelete = (entity: BuildingDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {

            dispatch(deleteBuildingThrunks(mapId, entity.id,{
                onSuccess: (success) => {
                    toast.success(t("Le bâtiment a bien été supprimé"))
                    closeModal();
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const columns: TableColumn<BuildingDetails>[] = [
        {
            id:'name',
            name: t('Nom'),
            sortable: true,
            selector: row => row.name,
        },
        {
            id:'floors',
            name: t('Nb étages'),
            sortable: true,
            selector: row => row.floorIds.length,
        },
        {
            id:'version',
            name: t('Version'),
            center:true,
            selector: row => row.version,
        }
    ];

    return <TableWithCrudComponent 
    entities={buildings} 
    columns={columns}
    loadingForm={loadingForm}
    loadingList={loadingList}
    onDelete={onDelete}
    onUpdate={onUpdate}
    onCreate={onCreate}
    translations={{
        tableTitle: t("Bâtiments de la carte"),
        noEntityText: t("Aucun bâtiment disponible"),
        createTitle: t("Ajouter un bâtiment"),
        createButtonText: t("Ajouter un bâtiment"),
        deleteText: (entity) => (t("Voulez-vous supprimer le bâtiment ?")),
        deleteTitle: (entity) => (t("Supprimer le bâtiment") + " " + entity.name ?? entity.id),
        updateText: (entity) => (t("Modifier le bâtiment") + " " + entity.name ?? entity.id),
    }}
    fieldSearchable={(entity) => [
        entity.name,
        entity.id
    ]}
    initialValueCreateForm={{
        name: "New building",
        mapId: mapId ?? "",
        components:{},
        position: {
            "x": 0,
            "y": 0,
            "z": 0
        },
        floorIds: [],
    } as BuildingCreate}
    />; 
}

export default AdminClientMapBuildings
