import apiAdminDefinition from "backend/apiAdminDefinition";
import i18next from "i18next";
import { toast } from "react-toastify";
import { adminClientActions } from "store/reducers/Admin/Client/adminClientSlice";
import { AppThunk } from "store/store"


/**
 * Load all clients available for admin pages
 * @returns 
 */
export const loadClientsThunk = (): AppThunk => {
    return async (dispatch, getState) => {
        try{
            dispatch(adminClientActions.setLoadingAllClients(true));
            const tenants = await apiAdminDefinition.client.clientsList();
            
            dispatch(adminClientActions.setAdminClients(tenants.data));
            dispatch(adminClientActions.setLoadingAllClients(false));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des clients"));
            console.error("[loadClientsThunk] error", error)
            dispatch(adminClientActions.setLoadingAllClients(false));
        }
    }
}