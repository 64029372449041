import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { toast } from 'react-toastify';
import DataTable, { TableColumn } from 'react-data-table-component';
import { MapSettingDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import { useAppDispatch, useAppSelector } from 'store/store';
import { loadMapSettingsThunks } from 'store/reducers/Admin/ClientMapSettings/thrunks/loadMapSettingThunks';
import { createMapSettingThunk } from 'store/reducers/Admin/ClientMapSettings/thrunks/createMapSettingThunk';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { updateMapSettingThunk } from 'store/reducers/Admin/ClientMapSettings/thrunks/updateMapSettingThunk';
import { deleteMapSettingThrunks } from 'store/reducers/Admin/ClientMapSettings/thrunks/deleteMapSettingThrunks';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';


const AdminClientMapSetting: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapSetting}), { useSuspense: false});
    const dispatch = useAppDispatch();

    const { 
        mapSettings, 
        loadingList, 
        loadingForm } = useAppSelector(state => state.adminClientMapSetting);

    const { clientId, mapId } = useParams();

    useEffect(() => {
        document.title = t('[Admin] Settings de la map');
        if(clientId && mapId && mapId != "new")
        {
            dispatch(loadMapSettingsThunks(mapId));
        }
    }, [clientId, mapId])

    const onCreate = (asset: MapSettingDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(createMapSettingThunk(mapId, asset,{
                onSuccess: (asset) => {
                    closeModal();
                    toast.success(t("La configuration a bien été créée"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }
    
    const onUpdate = (asset: MapSettingDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {

            dispatch(updateMapSettingThunk(mapId, asset,{
                onSuccess: (asset) => {
                    closeModal();
                    toast.success(t("La configuration a bien été modifiée"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onDelete = (asset: MapSettingDetails, closeModal: () => void) => {
        if(clientId && mapId){

            dispatch(deleteMapSettingThrunks(mapId, asset.id,{
                onSuccess: (success) => {
                    toast.success(t("La configuration a bien été supprimée"))
                    closeModal();
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
        
    }

    const columns: TableColumn<MapSettingDetails>[] = [
        {
            id:'name',
            name: t('Nom'),
            sortable: true,
            selector: row => row.name,
        },
        {
            id:'userPosition',
            name: t("Position de l'utilisateur"),
            sortable: false,
            cell: row => <>x:{row.userPosition?.position?.x} y:{row.userPosition?.position?.y}</>,
        },
        {
            id:'version',
            name: t('Version'),
            center:true,
            selector: row => row.version,
        }
    ];

    return <TableWithCrudComponent 
            entities={mapSettings} 
            columns={columns}
            loadingForm={loadingForm}
            loadingList={loadingList}
            onDelete={onDelete}
            onUpdate={onUpdate}
            onCreate={onCreate}
            translations={{
                tableTitle: t("configuration de la carte"),
                noEntityText: t("Aucune configuration disponible"),
                createTitle: t("Ajouter une configuration"),
                createButtonText: t("Ajouter une configuration"),
                deleteText: (entity) => (t("Voulez-vous supprimer cette configuration?")),
                deleteTitle: (entity) => (t("Supprimer la configuration ") + entity.name),
                updateText: (entity) => (t("Modifier la configuration: ")  + entity.name),
            }}
            fieldSearchable={(entity) => [
                entity.id,
                entity.name
            ]}
            initialValueCreateForm={{
                name:'',
                mapId: mapId,
                userPosition: {
                    floorId:"",
                    position:{
                        x:0,
                        y:0,
                        z:0
                    }
                }
            } as MapSettingDetails}
            />;
}

export default AdminClientMapSetting
