import i18next from "i18next";
import { toast } from "react-toastify";
import {adminClientTokenActions } from "store/reducers/Admin/ClientToken/adminClientTokenSlice";
import { AppThunk } from "store/store"
import apiAdminDefinition from "backend/apiAdminDefinition";


/**
 * Load current selected client's tokens
 * @returns 
 */
export const loadClientTokensThunk = (): AppThunk => {
    return async (dispatch, getState) => {
       try{

            dispatch(adminClientTokenActions.setLoadingTokenList(true));
            const tokens = await apiAdminDefinition.token.clientTokensList();
            dispatch(adminClientTokenActions.setTokens(tokens.data));
            
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des clés API du client"))
        }
        dispatch(adminClientTokenActions.setLoadingTokenList(false));
    }
}