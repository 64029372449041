import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LanguageSelector from 'components/selectors/LanguageSelector';
import Loading from 'components/Loading';
import MenuClient from 'components/MenuClient';
import { useAppDispatch, useAppSelector } from 'store/store'
import nameOf from 'utility/nameOf';
import "./Layout.scss"
import { selectTenantForClientThunk } from 'store/reducers/Profil/thrunks/selectTenantForClientThunk';
import _ from 'lodash';
import { LoadOptimizationResultFromRawFileIdThunk } from 'store/reducers/Audit/thrunks/LoadAuditOptimizationThunk';
import { loadRelatedDataCurrentMapThrunks } from 'store/reducers/Map/thrunks/loadRelatedDataCurrentMapThrunks';
import getCurrentTenant from 'backend/utils/getCurrentTenant';

type Props = {
    children?: React.ReactNode
  };

/**
 * Layout for clients section
 * @returns 
 */
const Layout: React.FC<Props> = ({children}) => {
    const { t } = useTranslation(nameOf({Layout}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(state => state.userProfil.currentUser);
    const loadingUserData = useAppSelector(state => state.userProfil.loadingUserData);
    const currentTenant = useAppSelector(state => state.userProfil.currentTenant);
    const allLanguages = useAppSelector(state => state.userProfil.languages);
    const currentMap = useAppSelector(state => state.map.currentMap);
    const auditSettingSelected = useAppSelector(state => state.audit.auditRawFileSelected);

   /* const {
        sdk,
        mapReady
    } = useFnoMapSdk(getCurrentUserToken(), getCurrentTenant()?.tenant ?? "", currentMap?.id ?? "");

    useEffect(() => {

        if(mapReady && currentMap && sdk && auditSettingSelected){
            sdk.heatmap.preloadHeatmap(dayjs(auditSettingSelected?.dateFrom).toDate(), dayjs(auditSettingSelected?.dateTo).toDate(), 
            [`${auditSettingSelected.id}_Optimized`, 
            `${auditSettingSelected.id}_Original`])
        }

    }, [sdk, mapReady, currentMap, auditSettingSelected])*/

    useEffect(() => {
        // If tenant is undefined, select first by default
        if(!currentTenant && currentUser && currentUser.tenants.length > 0)
        {
            dispatch(selectTenantForClientThunk(currentUser.tenants[0]));
        }
        
        if(currentTenant && currentMap)
        {
            // Refresh related data for current map
            dispatch(loadRelatedDataCurrentMapThrunks(currentMap));
        }
        
    }, [currentTenant, currentMap])


    useEffect(() => {
        // when client raw data loaded, compute optimization
        if(auditSettingSelected && getCurrentTenant())
        {
            dispatch(LoadOptimizationResultFromRawFileIdThunk(auditSettingSelected));
        }
    }, [auditSettingSelected])


    return (<div style={{height: "100vh"}}>
            <Row id="top-pane" className='mx-0'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </Row>
            <Row className='mx-0'>
                <Col id="menu-panel" sm="2" className='px-0'>
                    <MenuClient/>
                    <div id="menu-footer">
                        {<LanguageSelector languages={allLanguages ?? []}/>}
                        <div><small>{((new Date()).getFullYear())} {t('Tous droits réservés')}</small></div>
                        <div><small>{t('Version')}</small> 2.0</div>
                    </div>
                </Col>
                <Col id="content-panel">
                    {loadingUserData && <Loading text={t('Chargement')} fullScreen/>}
                    {currentTenant && children}
                </Col>
            </Row>
    </div>)
}

export default Layout
