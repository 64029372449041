import i18next from "i18next";
import { toast } from "react-toastify";
import { adminClientMapFloorActions } from "../adminClientMapFloorSlice";
import { AppThunk } from "store/store"
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Thunk action creator for loading floors
 * @param mapId 
 * @returns 
 */
export const loadFloorsThrunks = (mapId: string): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapFloorActions.setLoadingFloorList(true));
  
        // Fetch floors from the API
        const clientMaps = await apiBackofficeDefinition.floor.floorsList(mapId);
  
        // Dispatch action to set the fetched floors in the store
        dispatch(adminClientMapFloorActions.setFloors(clientMaps.data));
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors du chargement des floors de la map"));
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapFloorActions.setLoadingFloorList(false));
      }
    };
};