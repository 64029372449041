import i18next from "i18next";
import { toast } from "react-toastify";
import { adminClientMapBuildingActions } from "../adminClientMapBuildingSlice";
import { AppThunk } from "store/store";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";

/**
 * Thunk action creator for loading buildings
 * @param mapId - The ID of the map for which buildings are to be loaded
 * @returns An AppThunk function
 */
export const loadBuildingsThunks = (mapId: string): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapBuildingActions.setLoadingBuildingList(true));
  
        // Fetch buildings from the API
        const buildings = await apiBackofficeDefinition.building.buildingsList(mapId);
  
        // Dispatch action to set the fetched buildings in the store
        dispatch(adminClientMapBuildingActions.setBuildings(buildings.data));
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Error loading buildings for the map"));
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapBuildingActions.setLoadingBuildingList(false));
      }
    };
};