
export interface ITimcodeDemoFile{
    name: string;
    url: string;
}
const allTimcodeFiles = [
    { name:"06/11/2023" , url: "https://stfnopreprod.blob.core.windows.net/c-7/667ab12b2e35ea2b5bdc0fd9"},
    { name:"07/11/2023" , url: "https://stfnopreprod.blob.core.windows.net/c-7/667ab12b2e35ea2b5bdc0fda"},
    { name:"08/11/2023" , url: "https://stfnopreprod.blob.core.windows.net/c-7/667ab12b2e35ea2b5bdc0fdb"},
    { name:"09/11/2023" , url: "https://stfnopreprod.blob.core.windows.net/c-7/667ab12c2e35ea2b5bdc0fdc"},
    { name:"10/11/2023" , url: "https://stfnopreprod.blob.core.windows.net/c-7/667ab12c2e35ea2b5bdc0fdd"},
    { name:"11/11/2023" , url: "https://stfnopreprod.blob.core.windows.net/c-7/667ab12c2e35ea2b5bdc0fde"},
    { name:"12/11/2023" , url: "https://stfnopreprod.blob.core.windows.net/c-7/667ab12c2e35ea2b5bdc0fdf"},
] as ITimcodeDemoFile[];



export interface ITimcodeFileData {
    auditRawFileId:    string;
    batching:          ITimcodeBatching;
    customerReference: string;
    final_superU:      ITimcodeFinal;
    final_timcod:      ITimcodeFinal;
}

export interface ITimcodeBatching {
    superU: { [key: string]: string[] };
    timcod: { [key: string]: string[] };
}

export interface ITimcodeFinal {
    missions: { [key: string]: ITimcodeMission };
}

export interface ITimcodeMission {
    emplacement:   string[];
    supports:      string[];
    totalDistance: number;
}


export const locationCodeUsed = [
    {locationCode:"ETA.8.1", vusionTagId: "F041DE34"},
    {locationCode:"DYN.5.1", vusionTagId: "D83F8BCC"},
    {locationCode:"DYN.11.3", vusionTagId: "F041DEAE"},
    {locationCode:"ETA.1.4", vusionTagId: "D83F9D0F"},
    {locationCode:"ETA.15.1", vusionTagId: "C5872E24"},
    {locationCode:"BAC.4.2", vusionTagId: "F041DAB6"},
    {locationCode:"BAC.2.5", vusionTagId: "D83F7241"},
    {locationCode:"ETA.14.5", vusionTagId: "C5872E2A"},
    {locationCode:"ETA.9.3", vusionTagId: "D83F7234"},
    {locationCode:"ETA.2.1", vusionTagId: "D83F709E"},
    {locationCode:"BAC.1.1", vusionTagId: "F7DA13C9"},
    {locationCode:"DYN.11.2", vusionTagId: "F7DA13E1"}
];


export default allTimcodeFiles;