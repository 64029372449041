import React, { useEffect, useState } from 'react'
import "./FloorsComponent.scss";
import Sdk from 'fno-sdk';
import { Floor } from 'fno-sdk/build/models/unityEventModels/Floor';


interface OwnProps { 
    sdk: Sdk;
}

const FloorsComponent: React.FC<OwnProps> = ({sdk}) => {


    const [floors, setFloors] = useState<Floor[]>();
    const [currentFloorIds, setCurrentFloorIds] = useState<string[]>();

    useEffect(() => {
        if(sdk)
        {
            sdk.floor.getFloors().then((result) => {
                setFloors(result.floors);
                setCurrentFloorIds(result.floors.map((f) => f.id))
            });
        }
    }, [sdk])

    if(!sdk) return <></>; 

    const onChangeFloor = async (floorId: string) => {
        const result = await sdk.floor.setFloors([floorId]);
        setCurrentFloorIds(result.floors.map((f) => f.id))
    }

    const onResetFloor = async () => {
        const result = await sdk.floor.resetFloors();
        setCurrentFloorIds(result.floors.map((f) => f.id))
    }

    if(!floors || floors.length == 1) return <></>


    return (
        <div className='floor-selection-component'>
            {floors?.map((floor) => {
                return <div key={floor.id} className={'floor-btn ' + (currentFloorIds?.some(m => m == floor.id) ? "isVisible" : "")} onClick={() => onChangeFloor(floor.id)}>{floor.name}</div>
            })}
            <div className={'floor-btn '} onClick={() => onResetFloor()}>All</div>
        </div>
    )
}

export default FloorsComponent
