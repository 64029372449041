import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";
import { MapDetails } from "backend/ApiBackofficeDefinition/data-contracts";

export interface IAdminClientMapStore {
  maps: MapDetails[];
  loadingList: boolean;
  loadingForm: boolean;
}

const initialState: IAdminClientMapStore = {
  maps: [],
  loadingList: false,
  loadingForm: false,
}

const adminClientMapSlice = createSlice({
  name: 'adminClientMapReducer',
  initialState,
  reducers: {
    setLoadingMapList: (state, action:PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    setLoadingMapForm: (state, action:PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
    setMaps: (state, action:PayloadAction<MapDetails[]>) => {
      state.maps = action.payload;
    },
    addMap: (state, action:PayloadAction<MapDetails>) => {
      state.maps = [...state.maps, action.payload];
    },
    removeMap: (state, action:PayloadAction<string>) => {
      state.maps = state.maps.filter((map) => map.id !== action.payload);
    },
    updateMap: (state, action: PayloadAction<MapDetails>) => {
      state.maps = state.maps.map((map) => {
        if (action.payload.id === map.id) return action.payload;
        else return map;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userProfilActions.setCurrentTenant, (state, action) => {
        if (!action.payload) {
          state.maps = [];
        }
      })
      .addCase(userProfilActions.setLogout, (state, action) => {
        state.maps = [];
        state.loadingList = false;
        state.loadingForm = false;
      });
  },
});

export const { actions: adminClientMapActions, reducer: adminClientMapReducer } = adminClientMapSlice