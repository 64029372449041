import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import { Image  } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import "./UploadFileForm.scss"
import IFileUploadStatus from 'models/IFileUploadStatus';
import StorageFileStatus from 'components/StorageFileStatus';
import { toast } from 'react-toastify';
import { FileDetails } from 'backend/ApiBackofficeDefinition/data-contracts';


interface OwnProps {
    initialFile?: FileDetails;
    loading: boolean;
    size? : 'md' | 'sm';
    uploadingFiles?: IFileUploadStatus[];
    onSubmit: (files: File[]) => void | Promise<any>;
    onCancel?: () => void | undefined
 }


/**
 * React functional component that renders a form allowing the user to upload a file. The component
 * supports both creating and editing files.
 *
 * @param {OwnProps} props - The component props.
 * @param {FileDetails} [props.initialFile] - The initial file to be edited (optional).
 * @param {boolean} props.loading - A flag indicating if the form is in a loading state.
 * @param {IFileUploadStatus[]} [props.uploadingFiles] - The list of files being uploaded (optional).
 * @param {(files: File[]) => void | Promise<any>} props.onSubmit - The function called when a file is submitted.
 * @param {() => void | undefined} [props.onCancel] - The function called when the form is canceled (optional).
 * @param {'md' | 'sm'} [props.size] - The size of the component (optional, default is 'md').
 * @returns {JSX.Element} The rendered form component.
 */
const UploadFileForm: React.FC<OwnProps> = ({onSubmit, loading, initialFile: initialValues = undefined, uploadingFiles = [], onCancel = undefined, size = "md"}) => {
    const { t } = useTranslation([nameOf({UploadFileForm}), "TransverseError"], { useSuspense: false});
    const isEditForm = !!initialValues

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if(acceptedFiles.length > 0) onSubmit(acceptedFiles);
    }, [])

    const onError = useCallback((error: Error) => {
        //onSubmit(acceptedFiles);
        console.log("onError", error)
    }, [])

    const onDropRejected = useCallback((fileRejections: FileRejection[], event: DropEvent) => {
        //onSubmit(acceptedFiles);
        console.log("onDropRejected", fileRejections, event)
        if(isEditForm && fileRejections.length > 1)
        {
            toast.error(t("Vous devez sélectionner qu'un seul fichier"));
        }
    }, [])

    
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, onError, onDropRejected, multiple: !isEditForm})


    const isDragActiveClass = isDragActive ? " drag-active" : "";

    return (
        <div className={`upload-form`}>
           {isEditForm && <div>
                <h4>{initialValues.fileName}</h4>
                {initialValues.fileType.startsWith("image/") && <Image className='mb-2' src={initialValues.url + "?v=" + initialValues.version} rounded style={{width: "100%", backgroundColor:"#c7c7c7"}}/>}
            </div>}
            {!loading && <div className={`dropzone-panel ${isDragActiveClass} size-${size}` } {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                    <p>{t('Lâcher le fichier')}</p> :
                    <p>{!isEditForm ? 
                        t('Déposer des fichiers ici ou bien cliquer ici pour ouvrir la boîte de dialogue'):
                        t("Déposer le fichier ici pour modifier l'ancien")}</p>
                }
            </div>}
            <StorageFileStatus uploadingFiles={uploadingFiles} />
            <div className='d-flex mt-3'>
                {onCancel && <ButtonFno className="w-100 me-1" color='blue' disabled={loading} loading={loading} onClick={onCancel}>{t('Retour')}</ButtonFno>}
            </div>
        </div>
    )
}

export default UploadFileForm
