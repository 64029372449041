import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiErrorModel from "backend/utils/apiErrorModel";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { LabelTemplateUpdate, LabelTemplateDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { adminClientMapLabelTemplateActions } from "../adminClientMapLabelTemplateSlice";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Thunk action creator for updating an labelTemplate
 * @param LabelTemplate 
 * @param callbacks 
 * @returns 
 */
export const updateLabelTemplateThunk = (
    LabelTemplate: LabelTemplateUpdate,
    callbacks: ThrunkApiCallBacks<LabelTemplateDetails>
  ): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapLabelTemplateActions.setLoadingLabelTemplateForm(true));
  
        // Make API call to update LabelTemplate
        await apiBackofficeDefinition.labelTemplate.labelTemplatePut(LabelTemplate.id ,LabelTemplate)
          .then((result) => {
            // Dispatch action to add newly created LabelTemplate to store
            dispatch(adminClientMapLabelTemplateActions.updateLabelTemplate(result.data));
            // Call onSuccess callback
            callbacks.onSuccess(result.data);
          })
          .catch((apiResponse: apiErrorModel) => {
            // Call onError callback with API error response
            callbacks.onError(apiResponse);
          });
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors de la modification du labelTemplate"));
          callbacks.onError({ errors:{"api":[error.message]} } as apiErrorModel)
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapLabelTemplateActions.setLoadingLabelTemplateForm(false));
      }
    };
  };