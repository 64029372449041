import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import Select, { components, ControlProps, GroupBase, OptionProps, ValueContainerProps } from 'react-select'
import "./SelectLarge.scss"

interface Props<T>{
    options: T[];
    value: T | undefined;
    isDisabled?: boolean;
    placeholder?: string;
    noOption?: string;
    isClearable?: boolean;
    onChange: (selected: T) => void;
    /** Call for show selected value */
    valueRender?: (selected: T) => JSX.Element;
    /** Call for show option item when drop down list is open */
    optionRender?: (selected: T) => JSX.Element;
}

/**
 * A large select input component.
 *
 * @param {Props<T>} props - The properties for this component.
 * @param {T[]} props.options - The list of options to display.
 * @param {T | undefined} props.value - The currently selected value.
 * @param {(selected: T) => void} props.onChange - The function to call when the value changes.
 * @param {(selected: T) => JSX.Element | undefined} [props.valueRender] - The function to call to render the selected value.
 * @param {(selected: T) => JSX.Element | undefined} [props.optionRender] - The function to call to render the options.
 * @param {boolean} [props.isDisabled=false] - Whether the select is disabled.
 * @param {string} [props.placeholder="Select ..."] - The placeholder text to display when no value is selected.
 * @param {string} [props.noOption="No option"] - The text to display when there are no options.
 * @param {boolean} [props.isClearable=false] - Whether the select allows clearing the selected value.
 * @return {JSX.Element} The large select component.
 */
const SelectLarge = <T extends {name: string}>({options, value, onChange, valueRender = undefined,  optionRender = undefined, isDisabled = false, placeholder = "Select ...", noOption = "No option", isClearable = false}: Props<T>) => {


    useEffect(() => {
    }, [])

    const optionContainerRender = (option :OptionProps<T, false, GroupBase<T>>) =>
    {

        return <div {...option.innerProps} className="">
            {optionRender ? optionRender(option.data) : option.data.name}
            </div>
    }

    const valueContainerRender = ({children, ...props} :ValueContainerProps<T, false, GroupBase<T>>) =>
    {
        // hotfix pour le bug où un click sur la valeur du select n'ouvre pas les options
        // voir: https://github.com/JedWatson/react-select/issues/2597#issuecomment-613871200
        //@ts-ignore
        const dumyInput = React.Children.map(children, (child) => (child && [components.SingleValue].indexOf(child.type) === -1) ? child : null)

        return <components.ValueContainer  {...props}>
            {valueRender && value ? valueRender(value) : <div className='shopName'>{value?.name} </div>}
            {dumyInput}
            </components.ValueContainer >
    };

    return (<Select
        value={value}
        isDisabled={isDisabled}
        getOptionLabel={(option) => option.name}
        
        className="m-3 select-large-component"
        isClearable={isClearable}
        noOptionsMessage={() => <div>{noOption}</div>}
        blurInputOnSelect={true}
        placeholder={placeholder}
        isSearchable={false}
        isMulti={false}
        classNamePrefix="select_large"
        styles={{

        }}
        components={{
            Option: optionContainerRender,
            ValueContainer: valueContainerRender,
        }}
        options={options}
        onChange={(val) => onChange(val as T)}
    /> )
}

export default SelectLarge
