import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/PageHeader';
import nameOf from 'utility/nameOf';
import { useAppDispatch, useAppSelector } from 'store/store'
import Loading from 'components/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import UserForm from 'components/Admin/forms/UserForm';
import { loadUsersAdminThunk } from 'store/reducers/Admin/User/thrunks/loadUsersAdminThunk';
import { toast } from 'react-toastify';
import { UserDetailsAdmin } from 'backend/ApiAdminDefinition/data-contracts';
import { updateUserAdminThunk } from 'store/reducers/Admin/User/thrunks/updateUserAdminThunk';
import parseApiErrorSetFieldError from 'utility/parseApiErrorSetFieldError';

const AdminUserDetails: React.FC = () => {
    const { t } = useTranslation([nameOf({AdminUserDetails}), "TransverseError"], { useSuspense: false});
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { userId } = useParams();
    const [user, setUser] = useState<UserDetailsAdmin | undefined>();

    const loading = useAppSelector(state => state.adminUser.loading);
    const users = useAppSelector(state => state.adminUser.users);

    useEffect(() => {

        if(!loading && users.length == 0){
            dispatch(loadUsersAdminThunk());
        }

        if(!loading && users.length > 0){
            const userFound = users.find(m => m.email.toUpperCase() == userId?.toUpperCase() || m.id == userId)
            setUser(userFound)
        }
        document.title = t('[Admin] Créer un utilisateur');


    }, [loading, users])

    useEffect(() => {

        if(user){
            document.title = t(`[Admin] Détails de ${user.firstName} ${user.lastName}`);
        }
        
    }, [user])


    const onSubmit = async (userToEdit: UserDetailsAdmin, setFieldError:(field: string, message: string | undefined) => void) => {
        dispatch(updateUserAdminThunk(userToEdit,
            {
                onSuccess: (userUpdated) => {
                    toast.success(t("L'utilisateur a bien été modifié"))
                    navigate(ROUTES.ADMIN.USERS)
                },
                onError: (apiResponse) => {
                    parseApiErrorSetFieldError(apiResponse, setFieldError);
                }
            }))
    };

    return (
        <div>
            {user && <>
                <PageHeader middlePart={[{to: ROUTES.ADMIN.USERS, title:t("Utilisateurs")}]} title={`${user.firstName} ${user.lastName}`} admin/>
                <UserForm initialValues={user} loading={loading} onSubmit={onSubmit} onClose={() => navigate(ROUTES.ADMIN.USERS)}/>
            </>}

            {loading && !users && <Loading text={t("Chargement de l'utilisateur")}/>}
        </div>
    )
}

export default AdminUserDetails
