import i18next from "i18next";
import { toast } from "react-toastify";
import { mapActions } from "store/reducers/Map/mapSlice";
import { userProfilActions} from "store/reducers/Profil/userProfilSlice";
import { AppThunk } from "store/store"
import { adminClientPermissionActions } from "store/reducers/Admin/ClientPermission/adminClientPermissionSlice";
import { adminClientRolesActions } from "store/reducers/Admin/ClientRole/adminClientRolesSlice";
import { adminClientTokenActions } from "store/reducers/Admin/ClientToken/adminClientTokenSlice";
import { ClientItem } from "backend/ApiBackofficeDefinition/data-contracts";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";
import { selectCurrentMapThrunks } from "store/reducers/Map/thrunks/selectCurrentMapThrunks";
import { middlewarePickingActions } from "store/reducers/MiddlewarePicking/middlewarePickingSlice";
import { loadMiddlewareThunk } from "./loadMiddlewareThunk";

/**
 * Select tenant and load additionnals data like user permission for this tenant, all places bind to current user etc ... (for non admin pages)
 * @param tenant 
 * @returns 
 */
export const selectTenantForClientThunk = (tenant: ClientItem): AppThunk => {
    return async (dispatch, getState) => {

        try{

            dispatch(userProfilActions.setLoadingUserData(true));
            dispatch(userProfilActions.setCurrentTenant(tenant));

            // clean old tenant data
            dispatch(mapActions.setMaps([]));
            dispatch(selectCurrentMapThrunks(undefined));
            dispatch(adminClientPermissionActions.setPermissions([]));
            dispatch(adminClientRolesActions.setRoles([]));
            dispatch(adminClientTokenActions.setTokens([]));

            dispatch(loadMiddlewareThunk());

            const [
                resultUserInfos, 
                resultAllPlaces] = await Promise.all([
                    apiBackofficeDefinition.account.userInfosList(), 
                    apiBackofficeDefinition.map.mapsList()]);

            if(resultUserInfos)
            {
                dispatch(userProfilActions.setUserInfo(resultUserInfos.data));
            }
            else{
                throw new Error ("Error getting user infos for current tenant")
            }
            
            dispatch(mapActions.setMaps(resultAllPlaces.ok ? resultAllPlaces.data : []));
            // by default select first place
            if(resultAllPlaces?.data.length > 0)dispatch(selectCurrentMapThrunks(resultAllPlaces.data[0]))
            

            dispatch(userProfilActions.setLoadingUserData(false));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des clients"))
                console.error("[selectTenantThunk] error", error)
            dispatch(userProfilActions.setLoadingUserData(false));
        }
    }
}