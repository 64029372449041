import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";
import IFileUploadStatus from "models/IFileUploadStatus";
import { FileDetails } from "backend/ApiBackofficeDefinition/data-contracts";

// Interface representing the state of the admin client file manager store
export interface IAdminClientFileManagerStore {
  files: FileDetails[];
  uploadingFilesStatus: IFileUploadStatus[];
  deletingFilesStatus: IFileUploadStatus[];
  updateFilesStatus: IFileUploadStatus[];

  loadingList: boolean;
  loadingUpload: boolean;
  loadingDelete: boolean;
}

// Initial state for the admin client file manager store
const initialState: IAdminClientFileManagerStore = {
  files: [],
  uploadingFilesStatus: [],
  deletingFilesStatus: [],
  updateFilesStatus: [],
  loadingList: false,
  loadingUpload: false,
  loadingDelete: false,
};

// Create a slice for the admin client file manager store
const adminClientFileManagerSlice = createSlice({
  name: "adminClientFileManagerSlice",
  initialState,
  reducers: {
    // Set the loadingList state
    setLoadingList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the uploadingFilesStatus state
    setCreatingFilesStatus: (state, action: PayloadAction<IFileUploadStatus[]>) => {
      state.uploadingFilesStatus = action.payload;
    },
    // Set the deletingFilesStatus state
    setDeletingFilesStatus: (state, action: PayloadAction<IFileUploadStatus[]>) => {
      state.deletingFilesStatus = action.payload;
    },
    // Set the updateFilesStatus state
    setUpdatingFilesStatus: (state, action: PayloadAction<IFileUploadStatus[]>) => {
      state.updateFilesStatus = action.payload;
    },
    // Set the loadingUpload state
    setLoadingUpload: (state, action: PayloadAction<boolean>) => {
      state.loadingUpload = action.payload;
    },
    // Set the loadingDelete state
    setLoadingDelete: (state, action: PayloadAction<boolean>) => {
      state.loadingDelete = action.payload;
    },
    // Set the files in the state
    setFiles: (state, action: PayloadAction<FileDetails[]>) => {
      state.files = action.payload;
    },
    // Update a specific uploadingFilesStatus in the state
    updateUploadingFilesStatus: (state, action: PayloadAction<IFileUploadStatus>) => {
      state.uploadingFilesStatus = state.uploadingFilesStatus.map((file) =>
        action.payload.name === file.name ? action.payload : file
      );
    },
    // Update a specific deletingFilesStatus in the state
    updateDeletingFilesStatus: (state, action: PayloadAction<IFileUploadStatus>) => {
      state.deletingFilesStatus = state.deletingFilesStatus.map((file) =>
        action.payload.name === file.name ? action.payload : file
      );
    },
    // Update a specific updateFilesStatus in the state
    updateUpdatingFilesStatus: (state, action: PayloadAction<IFileUploadStatus>) => {
      state.updateFilesStatus = state.updateFilesStatus.map((file) =>
        action.payload.name === file.name ? action.payload : file
      );
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.files = [];
      state.uploadingFilesStatus = [];
      state.deletingFilesStatus = [];
      state.updateFilesStatus = [];
      state.loadingList = false;
      state.loadingUpload = false;
      state.loadingDelete = false;
    });
  },
});

// Export the actions and reducer from the admin client file manager slice
export const {
  actions: adminClientFileManagerActions,
  reducer: adminClientFileManagerReducer,
} = adminClientFileManagerSlice;
