import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiErrorModel from "backend/utils/apiErrorModel";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { adminClientMapActions } from "../adminClientMapSlice";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";
import { MapDetails, MapUpdate } from "backend/ApiBackofficeDefinition/data-contracts";


/**
 * Update a map
 * @returns 
 */
export const updateClientMapThunk = (map: MapUpdate, callbacks: ThrunkApiCallBacks<MapDetails>): AppThunk => {
    return async (dispatch, getState) => {
        try{
            dispatch(adminClientMapActions.setLoadingMapForm(true))

            return apiBackofficeDefinition.map.putMap(map.id, map)
            .then((result) => {
                dispatch(adminClientMapActions.updateMap(result.data));
                callbacks.onSuccess(result.data);
            })
            .catch((apiResponse: apiErrorModel) => {
                callbacks.onError(apiResponse);
            })
            .finally(() => dispatch(adminClientMapActions.setLoadingMapForm(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la modification de la carte"));
            dispatch(adminClientMapActions.setLoadingMapForm(false));
        }
    }
}
