import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientPickingActions } from "../adminClientPickingSlice";
import apiAdminDefinition from "backend/apiAdminDefinition";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import Loading from "components/Loading";
import { ContainerDetails } from "backend/ApiAdminDefinition/data-contracts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


/**
 * Restart a runner container
 * @returns 
 */
export const restartRunnerThrunk = (runner: ContainerDetails, callbacks: ThrunkApiCallBacks<boolean>): AppThunk => {
    return async (dispatch, getState) => {
        const toastId = toast.info(<div>
            {i18next.t("Le runner redémarre ...")}
            <ul>
                <li><b>{runner.name}</b></li>
            </ul>
            <div className="text-center"><Loading inline size={30} /></div>
        </div>, { autoClose: false });

       try{

            await apiAdminDefinition.pickingRunner.pickingRunnerRestartPost(runner.id)
                .then((result) => {
                    dispatch(adminClientPickingActions.updateRunner(result.data))
                    callbacks.onSuccess(true);

                    toast.update(toastId, { render: <div>
                        <FontAwesomeIcon size="lg" icon={["fas", "check"]} /> {i18next.t("Runner redémarré")}
                    </div>, autoClose: 5000, type: "success" });
                    
                })
                .catch((apiResponse: apiErrorModel) => {
                    callbacks.onError(apiResponse);

                    toast.update(toastId, { render: <div>
                        <FontAwesomeIcon size="lg" icon={["fas", "warning"]} /> {i18next.t("Impossible de redémarrer le runner")}
                    </div>, autoClose: 5000, type: "error" });
                });
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
            {
                toast.update(toastId, { render: <div>
                    <FontAwesomeIcon size="lg" icon={["fas", "warning"]} /> {i18next.t("Erreur lors du redémarrage du runner")}
                </div>, autoClose: 5000, type: "error" });
            }
        }
    }
}