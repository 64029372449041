import { Client } from "./ApiAdminDefinition/Client";
import { LanguageAdmin } from "./ApiAdminDefinition/LanguageAdmin";
import { Middlewares } from "./ApiAdminDefinition/Middlewares";
import { OptimizationSetting } from "./ApiAdminDefinition/OptimizationSetting";
import { Permissions } from "./ApiAdminDefinition/Permissions";
import { PickingRunner } from "./ApiAdminDefinition/PickingRunner";
import { Roles } from "./ApiAdminDefinition/Roles";
import { Tokens } from "./ApiAdminDefinition/Tokens";
import { User } from "./ApiAdminDefinition/User";
import { BackofficeTranslation } from "./ApiAdminDefinition/BackofficeTranslation";
import { RolesApp } from "./ApiAdminDefinition/RolesApp";
import getApiConfig from "./utils/getApiConfig";



export default {
    user: new User(getApiConfig()),
    client: new Client(getApiConfig()),
    permission: new Permissions(getApiConfig()),
    pickingRunner: new PickingRunner(getApiConfig()),
    roleClient: new Roles(getApiConfig()),
    token: new Tokens(getApiConfig()),
    language: new LanguageAdmin(getApiConfig()),
    optimizationSetting: new OptimizationSetting(getApiConfig()),
    middlewareApi: new Middlewares(getApiConfig()),
    translation: new BackofficeTranslation(getApiConfig()),
    appRole: new RolesApp(getApiConfig())
}