import { ContainerCreate ,  ContainerDetails, ContainerUpdate} from "backend/ApiAdminDefinition/data-contracts";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { AppThunk } from "store/store";
import { adminClientPickingActions } from "../adminClientPickingSlice";
import apiAdminDefinition from "backend/apiAdminDefinition";
import apiErrorModel from "backend/utils/apiErrorModel";
import { toast } from "react-toastify";
import i18next from "i18next";


/**
 * Updates the picking runner thrunk.
 *
 * @param {ContainerUpdate} runner - The updated container information.
 * @param {ThrunkApiCallBacks<ContainerDetails>} callbacks - The callbacks for the API call.
 * @returns {AppThunk} The thunk function.
 */
export const updatePickingRunnerThrunk = (runner: ContainerUpdate , callbacks: ThrunkApiCallBacks<ContainerDetails>): AppThunk => {
    return async (dispatch, getState) => {
        try{
            dispatch(adminClientPickingActions.setLoadingEdit(true))

            return apiAdminDefinition.pickingRunner.pickingRunnerPut(runner.id ,runner)
            .then((result) => {
                dispatch(adminClientPickingActions.updateRunner(result.data))
                callbacks.onSuccess(result.data);
            })
            .catch((apiResponse: apiErrorModel) => {
                callbacks.onError(apiResponse);
            })
            .finally(() => dispatch(adminClientPickingActions.setLoadingEdit(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la modification du runner"));
            dispatch(adminClientPickingActions.setLoadingEdit(false));
        }
    }
}