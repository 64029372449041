import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import { Badge, Button, Col, Form, Row } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import { toast } from 'react-toastify';
import { AssetDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import copyToClipboard from 'utility/copyToClipboard';

interface OwnProps {
    assetsList: AssetDetails[];
    onCancel: () => void | undefined
 }

/**
 * React component to compare a list of location codes with the existing list of location codes in the database.
 * @param assetsList - The list of assets to compare with the location codes.
 * @param onCancel - Optional function to call when the cancel button is clicked.
 * @returns A React component that displays the compared location codes.
 */
const CompareLocationCodeForm: React.FC<OwnProps> = ({assetsList, onCancel = undefined}) => {
    const { t } = useTranslation([nameOf({PolygonForm: CompareLocationCodeForm}), "TransverseError"], { useSuspense: false});
    const [loading, setLoading] = useState(false);
    const [locationCodesToCheck, setLocationCodesToCheck] = useState("");

    const [locationCodesAdded, setLocationCodesAdded] = useState<string[]>([]);
    const [locationCodesRemoved, setLocationCodesRemoved] = useState<string[]>([]);
    const [locationCodesExistBoth, setLocationCodesExistBoth] = useState<string[]>([]);

    const [showResult, setShowResult] = useState(false);

    const onValidate = () => {
        setShowResult(false);

        setLocationCodesAdded([]);
        setLocationCodesRemoved([]);
        setLocationCodesExistBoth([]);

        const allUserLocationCodes = locationCodesToCheck.split("\n").map(row => _.trim(row));
        const allAssetsLocationCodes = assetsList.map(asset => asset.locationCode);

        setLocationCodesAdded(_.difference(allUserLocationCodes, allAssetsLocationCodes));
        setLocationCodesRemoved(_.difference(allAssetsLocationCodes, allUserLocationCodes));
        setLocationCodesExistBoth(_.intersection(allUserLocationCodes, allAssetsLocationCodes));

        setShowResult(true);
    }

    return (
        <div>
            <fieldset disabled={loading}>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>{t('Un location code par ligne')}</Form.Label>
                            <Form.Control as="textarea" rows={10} value={locationCodesToCheck} onChange={(e) => setLocationCodesToCheck(e.target.value)}/>
                        </Form.Group>
                    </Col>
                </Row>

                <div className='d-flex justify-content-center mt-3'>
                    <ButtonFno className={ "me-1 w-50"} color='blue' disabled={loading} onClick={onCancel}>{t('Retour')}</ButtonFno>
                    <ButtonFno className="w-50 ms-1" color='orange' loading={loading} disabled={loading} onClick={onValidate}>{t('Valider')}</ButtonFno>
                </div>

                {showResult && <div>
                    <div className='mb-3'>
                        <h5>
                            <FontAwesomeIcon className='me-2' icon={["fas", "plus"]} />{t('Codes ajoutés (non présent dans BDD)')} {`(${locationCodesAdded.length})`}
                            <Button size='sm' variant='link' onClick={() => copyToClipboard(locationCodesAdded.join(", "), t("Liste copiée"))} title={t("copier la liste")}><FontAwesomeIcon icon={["fas", "copy"]} /></Button>
                        </h5>
                        <div className='ps-4'>{locationCodesAdded.map((locationCode, index) => <Badge bg='danger' className='me-1' key={index}>{locationCode}</Badge>)}</div>
                    </div>
                    <div className='mb-3'>
                        <h5>
                            <FontAwesomeIcon className='me-2' icon={["fas", "trash"]} />{t('Codes supprimés (présent BDD mais pas dans le fichier)')} {`(${locationCodesRemoved.length})`}
                            <Button size='sm' variant='link' onClick={() => copyToClipboard(locationCodesRemoved.join(", "), t("Liste copiée"))} title={t("copier la liste")}><FontAwesomeIcon icon={["fas", "copy"]} /></Button>
                        </h5>
                        <div className='ps-4'>{locationCodesRemoved.map((locationCode, index) => <Badge bg='warning' className='me-1' key={index}>{locationCode}</Badge>)}</div>
                    </div>
                    <div className='mb-3'>
                        <h5>
                            <FontAwesomeIcon className='me-2' icon={["fas", "check"]} /> {t('Location codes existants dans les 2 listes')} {`(${locationCodesExistBoth.length})`}
                            <Button size='sm' variant='link' onClick={() => copyToClipboard(locationCodesExistBoth.join(", "), t("Liste copiée"))} title={t("copier la liste")}><FontAwesomeIcon icon={["fas", "copy"]} /></Button>
                        </h5>
                        <div className='ps-4'>{locationCodesExistBoth.map((locationCode, index) => <Badge bg='success' className='me-1' key={index}>{locationCode}</Badge>)}</div>
                    </div>
                </div>}
            </fieldset>
        </div>
    )
}

export default CompareLocationCodeForm
