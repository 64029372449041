import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MapSettingDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

// Interface representing the state of the admin client map mapSetting store
export interface IAdminClientMapMapSettingStore {
  mapSettings: MapSettingDetails[];
  loadingList: boolean;
  loadingForm: boolean;
}

// Initial state for the admin client map mapSetting store
const initialState: IAdminClientMapMapSettingStore = {
  mapSettings: [],
  loadingList: false,
  loadingForm: false,
};

// Create a slice for the admin client map mapSetting store
const adminClientMapSettingsSlice = createSlice({
  name: "adminClientMapSettingsSlice",
  initialState,
  reducers: {
    // Set the mapSettings in the state
    setMapSettings: (state, action: PayloadAction<MapSettingDetails[]>) => {
      state.mapSettings = action.payload;
    },
    // Update a specific mapSetting in the state
    updateMapSetting: (state, action: PayloadAction<MapSettingDetails>) => {
      state.mapSettings = state.mapSettings.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new mapSetting to the state
    addMapSetting: (state, action: PayloadAction<MapSettingDetails>) => {
      state.mapSettings.push(action.payload);
    },
    // remove an mapSetting
    deleteMapSetting: (state, action: PayloadAction<string>) => {
      state.mapSettings = state.mapSettings.filter(m => m.id !== action.payload)
    },
    // Set the loadingList state
    setLoadingMapSettingList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingForm state
    setLoadingMapSettingForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.mapSettings = [];
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

// Export the actions and reducer from the admin client map mapSetting slice
export const {
  actions: adminClientMapSettingActions,
  reducer: adminClientMapSettingReducer,
} = adminClientMapSettingsSlice;
