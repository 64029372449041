import _ from "lodash";
import { AppThunk } from "store/store";
import { adminClientTokenActions } from "store/reducers/Admin/ClientToken/adminClientTokenSlice";
import { TokenDetails, TokenUpdateAdmin } from "backend/ApiAdminDefinition/data-contracts";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiAdminDefinition from "backend/apiAdminDefinition";
import apiErrorModel from "backend/utils/apiErrorModel";


/**
 * update a token for current client
 * @param token 
 * @returns 
 */
export const updateTokenThunk = (token: TokenUpdateAdmin, callbacks: ThrunkApiCallBacks<TokenDetails>): AppThunk => {
    return async (dispatch, getState) => {


        dispatch(adminClientTokenActions.setLoadingTokenForm(true));
        apiAdminDefinition.token.clientTokenPut(token.id, token)
            .then((result) => {
                dispatch(adminClientTokenActions.updateToken(result.data));
                // close form modal
                dispatch(adminClientTokenActions.setShowCreateTokenModal(false));
                // deselect token after updated
                dispatch(adminClientTokenActions.setSelectedToken(undefined))
                callbacks.onSuccess(result.data);
            })
            .catch((errors: apiErrorModel) =>  {
                callbacks.onError(errors)
            })
            .finally(() => dispatch(adminClientTokenActions.setLoadingTokenForm(false)));
    }
}
