import { AppThunk } from "store/store"
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";
import { ITimcodeDemoFile, ITimcodeFileData } from "../TimcodeDemoData";
import { timcodeActions } from "./timcodeStore";



export const SelectedTimcodeDataThunk = (file: ITimcodeDemoFile): AppThunk => {
    return async (dispatch, getState) => {

        try{

            dispatch(timcodeActions.setLoadingFile(true));
            dispatch(timcodeActions.setSelectedFileData(undefined));
            dispatch(timcodeActions.setSelectedFile(file));
            const fileLoaded = await fetch(file.url)
            
            dispatch(timcodeActions.setSelectedFileData((await fileLoaded.json() )as ITimcodeFileData));
        }
        catch(error: any)
        {
            console.error("[SelectedTimcodeDataThunk] error", error)
        }
        finally
        {
            dispatch(timcodeActions.setLoadingFile(false));
        }
    }
}