/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HeatMapPointCreate, HeatMapPointDetails } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class HeatMapPoint<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags HeatMapPoint
     * @name HeatmapPointsList
     * @summary [🤵 OR 🔑] Get heatmap points. [🔒: map_read].
     * @request GET:/map/{mapId}/heatmap/points
     * @secure
     */
    heatmapPointsList = (
        mapId: string,
        query: {
            /** @format date-time */
            from: string
            /** @format date-time */
            to: string
            /** Type of points to return */
            category?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<HeatMapPointDetails[], void>({
            path: `/map/${mapId}/heatmap/points`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags HeatMapPoint
     * @name HeatmapPointsPost
     * @summary [🤵 OR 🔑] Create several heatmap points. [🔒: map_create].
     * @request POST:/map/{mapId}/heatmap/points
     * @secure
     */
    heatmapPointsPost = (mapId: string, data: HeatMapPointCreate[], params: RequestParams = {}) =>
        this.request<HeatMapPointDetails[], void>({
            path: `/map/${mapId}/heatmap/points`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags HeatMapPoint
     * @name HeatmapPointPost
     * @summary [🤵 OR 🔑] Create new heatmap point. [🔒: map_create].
     * @request POST:/map/{mapId}/heatmap/point
     * @secure
     */
    heatmapPointPost = (mapId: string, data: HeatMapPointCreate, params: RequestParams = {}) =>
        this.request<HeatMapPointDetails, void>({
            path: `/map/${mapId}/heatmap/point`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags HeatMapPoint
     * @name HeatmapPointDelete
     * @summary [🤵 OR 🔑] Delete heatmap point. [🔒: map_update].
     * @request DELETE:/map/{mapId}/heatmap/point/{heatmapPointId}
     * @secure
     */
    heatmapPointDelete = (mapId: string, heatmapPointId: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/map/${mapId}/heatmap/point/${heatmapPointId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
