import React, { useEffect, useState } from 'react'
import "./Statistics.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sdk from 'fno-sdk';
import NbOrders from './Statistics/NbOrders';
import AverageTime from './Statistics/AverageTime';
import dayjs from 'dayjs';
import { Form, InputGroup } from 'react-bootstrap';
import ProductsPerPicker from './Statistics/ProductsPerPicker';

interface OwnProps { 
    sdk: Sdk;
}

const Statistics: React.FC<OwnProps> = ({sdk}) => {

    const [dateFrom, setDateFrom] = useState(dayjs().add(-7, "day").toDate());
    const [dateTo, setDateTo] = useState(dayjs().toDate());


    useEffect(() => {
        if(sdk)
        {
            sdk.pathfinding.clearItineraries();
        }
        return () => {
            sdk.pathfinding.clearItineraries();
        }
    }, [sdk])


    if(!sdk) return <></>; 
    
    return (
        <div id='demo-picking-configuration'>
            <div className='mt-3'>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">From</InputGroup.Text>
                    <Form.Control type="date" value={dayjs(dateFrom).format("YYYY-MM-DD")} onChange={(event) => setDateFrom(dayjs(event.target.value).toDate())}/>
                    <InputGroup.Text id="basic-addon1">To</InputGroup.Text>
                    <Form.Control type="date" value={dayjs(dateTo).format("YYYY-MM-DD")} onChange={(event) => setDateTo(dayjs(event.target.value).toDate())}/>
                </InputGroup>
            </div>
            
            <ProductsPerPicker dateFrom={dateFrom} dateTo={dateTo}/>
            <NbOrders dateFrom={dateFrom} dateTo={dateTo}/>
            <AverageTime dateFrom={dateFrom} dateTo={dateTo}/>
           
        </div>
    )
}

export default Statistics
