import React, { useEffect, useState } from 'react'
import { useAppSelector } from "store/store";
import * as yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import Switch from "react-switch";
import { Badge, Col, Form, Row } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import _ from 'lodash';
import { TokenDetails } from 'backend/ApiAdminDefinition/data-contracts';

export interface ITokenFormInputs{
    id: string;
    name: string;
    active: boolean;
    maps: string[];
    permissions:{ [key: string]: boolean };
    permissionsType: string | undefined;
}

type Props = {
    /**Call when user quit form or after a successful submit */
    onClose: () => void;
    onSubmit: (token: ITokenFormInputs, setFieldError:(field: string, message: string | undefined) => void) => void;
    initialValues?: TokenDetails | undefined
  };


/**
 * Component used to create or update a token.
 * @param onClose - function called when user quit form or after a successful submit
 * @param onSubmit - function called when user submit the form, it will be called with the ITokenFormInputs and a setFieldError function to set errors
 * @param initialValues - token details to edit or undefined to create a new token
 * @returns JSX.Element
 */
const TokenForm: React.FC<Props> = ({onClose, onSubmit, initialValues = undefined}) => {
    const { t } = useTranslation(nameOf({TokenForm}), { useSuspense: false});
    const loading = useAppSelector(state => state.adminClientToken.loadingForm);
    const maps = useAppSelector(state => state.adminClientMap.maps);
    const permissions = useAppSelector(state => state.adminClientPermission.permissions);
    const isCreateForm = !initialValues?.id;
    const handleSubmit = async (values: ITokenFormInputs, {setFieldError}:FormikHelpers<ITokenFormInputs>) => {

        if(isCreateForm){
            const permissionsToAdd: { [key: string]: boolean } = {};
            if(values.permissionsType == "permissions-read")
            {
                permissions.filter(perm => _.endsWith(perm.name, "_read") || _.endsWith(perm.name, "_list")).forEach(perm => {
                    permissionsToAdd[perm.name] = true;
                })
            }
            else if(values.permissionsType == "permissions-read-write"){
                permissions.forEach(perm => {
                    permissionsToAdd[perm.name] = true;
                })
            }
            else if(values.permissionsType == "permissions-middleware-picking")
            {
                permissionsToAdd["picking_optimization_run"] = true;
            }
            values.permissions = permissionsToAdd;
        }
        onSubmit(values, setFieldError);
    };

    const schema = yup.object().shape({
        name: yup.string().required(t("Champ obligatoire") as string),
      });

      

    return  <Formik
    validationSchema={schema}
    onSubmit={handleSubmit}
    initialValues={{
        id: initialValues?.id,
        name: initialValues?.name,
        active: initialValues? initialValues.active : true,
        maps: initialValues?.maps || [],
        permissions: initialValues?.permissions || {},
        permissionsType: "permissions-read"
    } as ITokenFormInputs}>
    {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        isSubmitting,
        errors,
        setFieldValue
    }) => (
           <fieldset disabled={isSubmitting || loading}>
                <Row>
                    <Col xs="10">
                        <Form.Group className='mb-3' controlId="validationFormik03">
                            <Form.Label><b>{t("Nom du token")}</b></Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                isValid={touched.name && !errors.name}
                                isInvalid={touched.name && !!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs="2">
                        <Form.Group className='mb-3' controlId="validationFormik03">
                            <Form.Label>{t("Activé")}</Form.Label>
                            <Switch checked={values.active} onChange={(enabled) => {
                                    setFieldValue("active", enabled)
                                }}/>
                            <Form.Control.Feedback type="invalid">
                                {errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        
                        {isCreateForm ? <Form.Group className='mb-3' controlId="validationFormik03">
                            <Form.Label><b>{t("Type de permissions")}</b></Form.Label>
                            <div>
                                <Form.Check
                                    inline
                                    label="Lecture"
                                    name="permissionsType"
                                    type={"radio"}
                                    onClick={(item) => {
                                        setFieldValue("permissionsType", 'permissions-read');
                                        setFieldValue("name", _.replace(values.name, "[Middleware] ", ""));
                                    }}
                                    id={`permissions-read`}
                                />
                                <Form.Check
                                    inline
                                    label="Lecture et écriture"
                                    name="permissionsType"
                                    type={"radio"}
                                    onClick={(item) => {
                                        setFieldValue("permissionsType", 'permissions-read-write');
                                        setFieldValue("name", _.replace(values.name, "[Middleware] ", ""));
                                    }}
                                    id={`permissions-read-write`}
                                />
                                <Form.Check
                                    inline
                                    label="Middleware picking"
                                    name="permissionsType"
                                    type={"radio"}
                                    onClick={(item) => {
                                        setFieldValue("permissionsType", 'permissions-middleware-picking');
                                        setFieldValue("name", `[Middleware] ${values.name}`);
                                    }}
                                    id={`permissions-middleware-picking`}
                                />
                            </div>
                            
                        </Form.Group>:
                        <div>
                            <Form.Label><b>{t("Permissions de la clé API")}</b></Form.Label>
                            {permissions.map(perm => {
                                return <Row key={perm.name}>
                                    <Col>
                                        <Switch checked={!!values.permissions[perm.name]} onChange={(enabled) => {
                                            const permissionClone = _.cloneDeep(values.permissions);
                                            permissionClone[perm.name] = enabled;
                                            setFieldValue("permissions", permissionClone);
                                        }}/>
                                    </Col>
                                    <Col xs="10">
                                        <div><Badge className='text-uppercase'>{perm.category}</Badge> {perm.name}</div>
                                    </Col>
                                </Row>
                            })}
                        </div>}
                    </Col>
                </Row>
                <div className='mt-3'>
                    <div><b>{t('Sélectionner les lieux à associer au token:')}</b></div>
                    <div id="modal-places-list">
                        {maps.map((map) => {
                            return <Row key={map.id}>
                                <Col sm="2"><Switch checked={_.some(values.maps, (p) => p == map.id)} onChange={(enabled) => {
                                    if(enabled) setFieldValue("maps", [...values.maps, map.id])
                                    else setFieldValue("maps", values.maps.filter(mapId => mapId != map.id))
                                }}/></Col>
                                <Col sm="10">{map.name} </Col>
                            </Row>
                        })}
                    </div>
                </div>
                <div className='d-flex mt-3'>
                    <ButtonFno className="w-100 me-1" color='blue' disabled={loading} onClick={onClose}>{t('Retour')}</ButtonFno>
                    <ButtonFno className="w-100 ms-1" color='orange' loading={loading} disabled={loading}  onClick={handleSubmit}>{t('Valider')}</ButtonFno>
                </div>
            </fieldset>
        )}
    </Formik>
}

export default TokenForm;