import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store';
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import getCurrentTenant from 'backend/utils/getCurrentTenant';
import useFnoMapSdk from 'hooks/useFnoMapSdk';
import { ROUTES } from 'resources/routes-constants';
import { useNavigate } from 'react-router-dom';
import appColors from 'resources/appColors';
import MapRender from 'components/mapControls/MapRender';
import "./AuditHeatmapCompare.scss";
import dayjs from 'dayjs';
import { DisplayHeatmapResponse } from 'fno-sdk/build/models/unityEventModels/DisplayHeatmapResponse';
import GradientLegend from 'components/GradientLegend';
import computeGainPourcent from 'utility/computeGainPourcent';
import checkPermissions from 'utility/checkPermissions';
import { PermissionsEnum } from 'backend/ApiBackofficeDefinition/data-contracts';
import { toast } from 'react-toastify';
import apiBackofficeDefinition from 'backend/apiBackofficeDefinition';
import { auditActions } from 'store/reducers/Audit/auditSlice';

export interface IOrderRow{
    ref: string;
    products: {
        ean: string;
        emplacement: string;
        quantity: string;
    }[];
    searchField: string;
}



const AuditHeatmapCompare: React.FC = () => {
    const { t } = useTranslation(nameOf({AuditHeatmapCompare}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const currentMap = useAppSelector(state => state.map.currentMap);
    const loadingAuditData = useAppSelector(state => state.audit.loadingList || state.audit.loadingOptimizationResult);
    const {
        auditRawFileSelected,
        heatmapData
    } = useAppSelector(state => state.audit);
    //const optimizationResult = useAppSelector(state => state.audit.optimizationResult);
    const {
        sdk,
        loading,
        mapReady
    } = useFnoMapSdk(getCurrentUserToken(), getCurrentTenant()?.tenant ?? "", currentMap?.id ?? "");
    
    const [categoryToShow, setCategoryToShow] = useState<'Original' | "Optimized">("Original");
    const [heatmapGradient, setHeatmapGradient] = useState<string[] | undefined>(undefined);
    
    const [loadingHeatMap, setLoadingHeatMap] = useState(false);

    useEffect(() => {
        document.title = t('Heatmap de comparaison');

        if(!checkPermissions(PermissionsEnum.AuditHeatmapRead))
        {
            toast.error(t("Vous n'avez pas les permissions requises"))
            navigate(ROUTES.DASHBOARD)
        }

        return () => {
            if(sdk) sdk.hidePlayer();
        }
    }, [])

    
    useEffect(() => {
    
        if(mapReady && auditRawFileSelected && heatmapData?.state == "waitingLoad")
        {
            const dateFrom = dayjs(auditRawFileSelected.dateFrom).startOf('day').toDate();
            const dateTo = dayjs(auditRawFileSelected.dateTo).endOf('day').toDate();


            setLoadingHeatMap(true)

            const loadScaleOriginal = apiBackofficeDefinition.heatmap.generate.generateScaleFromPaths(currentMap?.id ?? "", {
                from: dateFrom.toISOString(),
                to: dateTo.toISOString(),
                category: `${auditRawFileSelected.id}_Original`
            });

            const loadScaleOptimized = apiBackofficeDefinition.heatmap.generate.generateScaleFromPaths(currentMap?.id ?? "", {
                from: dateFrom.toISOString(),
                to: dateTo.toISOString(),
                category: `${auditRawFileSelected.id}_Optimized`
            });

            const preloadHeatmap = sdk.heatmap.preloadHeatmap(dateFrom, dateTo,  
                [`${auditRawFileSelected.id}_Original`, `${auditRawFileSelected.id}_Optimized`])


            Promise.all([loadScaleOriginal, loadScaleOptimized, preloadHeatmap, ]).then((data) => {
                sdk.heatmap.displayPrelodedHeatmap( `${auditRawFileSelected.id}_${categoryToShow}`);
                
                dispatch(auditActions.setHeatmapData({
                    state: "loaded",
                    scaleOriginal: data[0].data,
                    scaleOptimized: data[1].data,
                }))
            }).finally(() => {
                setLoadingHeatMap(false)
            });

        }
        


        return () => {
            setHeatmapGradient(undefined)
            if(sdk && sdk.heatmap)
            {
                sdk.heatmap.hideHeatmap();
            }
            

        }
    }, [mapReady, auditRawFileSelected, heatmapData])

    
    useEffect(() => {
        if(heatmapData && heatmapData.state == "loaded")
        {
            sdk.heatmap.setHeatmapScale(_.take(heatmapData.scaleOriginal, heatmapData.scaleOriginal.length - 1));
            sdk.heatmap.displayPrelodedHeatmap( `${auditRawFileSelected?.id}_${categoryToShow}`)
                .then((result) => setHeatmapGradient(result.gradients.map(m => m.htmlColor)));
        }
    }, [heatmapData])

    const onChangeOrigin = (source: "Optimized" | "Original") => {
        if(!loadingHeatMap && auditRawFileSelected)
        {
            sdk.heatmap.displayPrelodedHeatmap( `${auditRawFileSelected.id}_${source}`);
            setCategoryToShow(source);
        }
        
    }

    const renderScale = (scales: number[]) => {
        return <Row>
            {scales.map((scale, i) => {
                return <Col>{scale}</Col>
            })}
        </Row>
    }


    const currentScale = categoryToShow == "Original" ? heatmapData?.scaleOriginal : heatmapData?.scaleOptimized;


    return (
        <div id="audit-heatmap-compare">
             {<PageHeader title={t('Heatmap')}/>}
             <Row style={{height: "100%"}}>
                <Col sm="8" style={{height: "100%"}}>
                    <MapRender 
                        sdk={sdk} 
                        mapReady={mapReady}
                        assetToolipConfig={{
                            highlightColor: appColors.blue
                        }}/>
                </Col>
                <Col id="col-table-container" sm="4">
                    {mapReady && !loadingAuditData && <div>
                        <h4>
                            {t("Heatmap")} 
                            <Button size='sm' variant='link' title={t('Centrer caméra')} className='ms-1 float-end' onClick={() => sdk?.user.moveCameraToUserPosition()}>
                                <FontAwesomeIcon icon={["fas", "bullseye"]} />
                            </Button>
                        </h4>
                        
                        <div>
                            {sdk && mapReady && <>

                            {!loadingHeatMap && <Row className='category-selector'>
                                <Col onClick={() => onChangeOrigin("Original")} className={'item ' + (categoryToShow === 'Original' ? 'selected orange' : '')}>{t('Original')}</Col>
                                <Col onClick={() => onChangeOrigin("Optimized")} className={'item ' + (categoryToShow === 'Optimized' ? 'selected blue' : '')}>{t('Optimisé')}</Col>
                            </Row>}

                           {!loadingHeatMap && heatmapData && <div className='mt-3'>
                                <h5>{t("Fréquence d'utilisation")} </h5>
                                <GradientLegend colors={heatmapGradient}> 
                                    {heatmapData?.scaleOriginal && renderScale(heatmapData?.scaleOriginal)}
                                </GradientLegend>
                                {/*currentScale && renderScale(currentScale)*/}
                                <div className='mt-3 text-center'>
                                    {currentScale && currentScale.length > 0 ? <div >
                                        <div><b>{t("Nombre max de passages")}: { currentScale[currentScale.length - 1]}</b></div>
                                    </div> : <div>
                                        <b>{t("Aucune donnée")}</b>
                                        </div>}
                                </div>
                                
                            </div>}
                            {loadingHeatMap && <div className='text-center'><Loading text={t("Chargement de la heatmap")}/></div>}
                            </>}


                        </div>
                    </div>}
                    {loadingAuditData && <div className='loading-map-panel'><Loading text={t("Chargement des données")}/></div>}
                </Col>
             </Row>
        </div>
    )
}

export default AuditHeatmapCompare
