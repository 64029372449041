import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { TableColumn } from 'react-data-table-component';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { useAppDispatch, useAppSelector } from 'store/store';
import { loadLabelTemplatesThunks } from 'store/reducers/Admin/ClientMapLabelTemplate/thrunks/loadLabelTemplatesThunks';
import { LabelTemplateDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import { createLabelTemplateThunk } from 'store/reducers/Admin/ClientMapLabelTemplate/thrunks/createLabelTemplateThunk';
import { updateLabelTemplateThunk } from 'store/reducers/Admin/ClientMapLabelTemplate/thrunks/updateLabelTemplateThunk';
import { deleteLabelTemplateThrunks } from 'store/reducers/Admin/ClientMapLabelTemplate/thrunks/deleteLabelTemplateThrunks';
import ColorPicker from 'components/inputs/ColorPicker';

const AdminClientMapLabelTemplates: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapLabelTemplates}), { useSuspense: false});
    const dispatch = useAppDispatch();
    
    const {
        labelTemplates,
        loadingList,
        loadingForm
    } = useAppSelector(state => state.adminClientMapLabelTemplate)
    const { clientId } = useParams();


    useEffect(() => {
        document.title = t('[Admin] Label templates du client');
        if(clientId)
        {
            dispatch(loadLabelTemplatesThunks());
        }
    }, [clientId])


    const onCreate = (labelTemplate: LabelTemplateDetails, closeModal: () => void) => {
        if(clientId)
        {
            dispatch(createLabelTemplateThunk(labelTemplate,{
                onSuccess: (asset) => {
                    closeModal();
                    toast.success(t("Le label template a bien été créé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }
    
    const onUpdate = (labelTemplate: LabelTemplateDetails, closeModal?: () => void) => {
        if(clientId)
        {
            dispatch(updateLabelTemplateThunk(labelTemplate,{
                onSuccess: (labelTemplate) => {
                    if(closeModal)closeModal();
                    toast.success(t("Le label template a bien été modifié"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))

        }
    }

    const onDelete = (labelTemplate: LabelTemplateDetails, closeModal: () => void) => {
        if(clientId)
        {
            dispatch(deleteLabelTemplateThrunks(labelTemplate.id,{
                onSuccess: (success) => {
                    closeModal();
                    toast.success(t("Le label template a bien été supprimé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))

        }
    }

    const columns: TableColumn<LabelTemplateDetails>[] = [
        {
            id:'imageColor',
            name: t('Couleur image'),
            sortable: true,
            selector: row => row.imageColor,
            cell: (row, index, column, id) =>{
                return  <ColorPicker
                value={row.imageColor}
                onChange={(color) => onUpdate({... row, imageColor: color})}
            />;
            }
        },
        {
            id:'textColor',
            name: t('Couleur texte'),
            sortable: true,
            selector: row => row.textColor,
            cell: (row, index, column, id) =>{
                return  <ColorPicker
                value={row.textColor}
                onChange={(color) => onUpdate({... row, textColor: color})}
            />;
            }
        },
        {
            id:'version',
            name: t('Version'),
            center:true,
            selector: row => row.version,
        }
    ];


    return <TableWithCrudComponent 
                    entities={labelTemplates} 
                    columns={columns}
                    loadingForm={loadingForm}
                    loadingList={loadingList}
                    onDelete={onDelete}
                    onUpdate={onUpdate}
                    onCreate={onCreate}
                    translations={{
                        tableTitle: t("Label templates du client"),
                        noEntityText: t("Aucun label template disponible"),
                        deleteText: (entity) => (t("Voulez-vous supprimer le label template ?")),
                        deleteTitle: (entity) => (t("Supprimer le label template ") + entity.id),
                        updateText: (entity) => (t("Modifier le label template : ") + entity.id),
                        createButtonText: t("Ajouter un label template"),
                        createTitle: t("Ajouter un label template"),
                    }}
                    fieldSearchable={(entity) => [
                        entity.id,
                        entity.imageColor,
                        entity.textColor
                    ]}
                    initialValueCreateForm={{
                        imageColor: "",
                        textColor: "",

                    } as LabelTemplateDetails}
                    />
}

export default AdminClientMapLabelTemplates
