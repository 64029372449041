import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientAuditOptimizationSettingActions } from "../adminClientMapOptimizationSettingSlice";
import apiAdminDefinition from "backend/apiAdminDefinition";


/**
 * Load all optimization settings from a specific map id
 * @param mapId 
 * @returns 
 */
export const loadOptimizationSettingThunks = (mapId: string): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientAuditOptimizationSettingActions.setLoadingList(true));
  
        // Fetch assets from the API
        const result = await apiAdminDefinition.optimizationSetting.mapOptimizationSettingsList(mapId);
        // Dispatch action to set the fetched entities in the store
        dispatch(adminClientAuditOptimizationSettingActions.setOptimizationSettings(result.data));

      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors du chargement des configurations de picking"));
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientAuditOptimizationSettingActions.setLoadingList(false));
      }
    };
  };