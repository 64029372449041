import { AppThunk } from "store/store"
import { mapActions } from "../mapSlice";
import { loadRelatedDataCurrentMapThrunks } from "./loadRelatedDataCurrentMapThrunks";
import { MapDetails } from "backend/ApiBackofficeDefinition/data-contracts";



/**
 * Thunk action creator for selecting the current map and loading related data
 * @param map - The current map to select
 * @returns An AppThunk function
 */
export const selectCurrentMapThrunks = (map: MapDetails | undefined): AppThunk => {
    return async (dispatch, getState) => {
        // Dispatch action to set the current map
        dispatch(mapActions.setCurrentMap(map));
        // If a map is provided, load related data
        if (map) {

            dispatch(loadRelatedDataCurrentMapThrunks(map));
        }
    };
};