import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ClientDetails, MiddlewareApiDetails } from "backend/ApiAdminDefinition/data-contracts";
import { ClientItem, MapDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { RequestClientDetails, PagedListResultRequestClientDetails } from "backend/ApiMiddlewareDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

export interface IAdminMiddlewareRequestStore {
  selectedMiddleware: MiddlewareApiDetails | undefined;
  selectedMap: MapDetails | undefined;
  requests: PagedListResultRequestClientDetails | undefined;
  selectedRequest: RequestClientDetails | undefined;
  loadingList: boolean;
  loadingForm: boolean;
}

const initialState: IAdminMiddlewareRequestStore = {
  selectedMiddleware: undefined,
  selectedMap: undefined,
  selectedRequest: undefined,
  requests: {
    sortColumn: "DateCreated",
    sortDirection: "desc",
    currentPage: 1,
    items: [],
    totalCount: 0,
    pageSize: 10,
    totalPages: 0
  } as PagedListResultRequestClientDetails,
  loadingList: false,
  loadingForm: false,
};

const middlewareRequestSlice = createSlice({
  name: "middlewareRequestSlice",
  initialState,
  reducers: {
    setSelectedMiddleware: (state, action: PayloadAction<MiddlewareApiDetails | undefined>) => {
      state.selectedMiddleware = action.payload;
    },
    setSelectedMap: (state, action: PayloadAction<MapDetails | undefined>) => {
      state.selectedMap = action.payload;
    },
    setSelectedRequest: (state, action: PayloadAction<RequestClientDetails | undefined>) => {
      state.selectedRequest = action.payload;
    },
    setRequests: (state, action: PayloadAction<PagedListResultRequestClientDetails | undefined>) => {
      state.requests = action.payload;
    },
    setLoadingList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    setLoadingForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.selectedMiddleware = undefined;
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

// Export the actions and reducer from the admin client map asset slice
export const {
  actions: adminMiddlewareRequestActions,
  reducer: adminMiddlewareRequestReducer,
} = middlewareRequestSlice;
