import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminUserActions } from "store/reducers/Admin/User/adminUserSlice";
import apiAdminDefinition from "backend/apiAdminDefinition";
import { UserCreateAdmin, UserDetailsAdminCreated } from "backend/ApiAdminDefinition/data-contracts";
import apiErrorModel from "backend/utils/apiErrorModel";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";


/**
 * Create a new user
 * @returns 
 */
export const createUserAdminThunk = (user: UserCreateAdmin, callbacks: ThrunkApiCallBacks<UserDetailsAdminCreated>): AppThunk => {
    return async (dispatch, getState) => {
        try{
            dispatch(adminUserActions.setLoadingUsers(true))
            return apiAdminDefinition.user.userPost(user)
            .then((result) => {
                dispatch(adminUserActions.addUser(result.data));
                callbacks.onSuccess(result.data);
            })
            .catch((apiResponse: apiErrorModel) => {
                callbacks.onError(apiResponse);
            })
            .finally(() => dispatch(adminUserActions.setLoadingUsers(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la modification de utilisateur"));
            dispatch(adminUserActions.setLoadingUsers(false));
        }
    }
}
