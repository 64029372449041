import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { userProfilActions } from 'store/reducers/Profil/userProfilSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import "./LanguageSelector.scss"
import { LanguageItem } from 'backend/ApiBackofficeDefinition/data-contracts';


interface OwnProps {
    languages: LanguageItem[]
}
const LanguageSelector: React.FC<OwnProps> = ({languages}) => {
    const dispatch = useAppDispatch();
    const currentCulture = useAppSelector(state => state.userProfil.culture);
    
    useEffect(() => {
        // if new languages don"t have current lang selected, change for default
        if(!_.some(languages, (lng) => lng.code == currentCulture)){
            if(languages.length > 0) selectLang(languages[0].code);
            else selectLang("fr");
        }
        
        
    }, [languages, currentCulture])


    const selectLang = (lang: string) => {
        dispatch(userProfilActions.setCulture(lang));
    }



    return (<div id='language-selector'>
            {languages.map((lang, i) => {
                return <div key={i}><img src={lang.urlFlag} onClick={() => selectLang(lang.code)}/></div>
            })}
    </div>
    )
}

export default LanguageSelector
