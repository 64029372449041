import _ from "lodash";
import { AppThunk } from "store/store";
import { auditActions } from "../auditSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";




/**
 * Thunk action creator for deleting an audit raw file
 * @param mapId - The ID of the map
 * @param fileId - The ID of the file to delete
 * @param callbacks - Callbacks for success and error handling
 * @returns An AppThunk function
 */
export const deleteAuditRawFileThunk = (
    mapId: string,
    fileId: string,
    callbacks: ThrunkApiCallBacks<boolean>
): AppThunk => {
    return async (dispatch, getState) => {
        
        // Set loading state to true
        dispatch(auditActions.setLoadingFileRawForm(true));

        // Delete audit raw file
        const result = await apiBackofficeDefinition.auditFile.auditRawFileDelete(mapId, fileId);
        if (!result.ok) {
            // Call onError callback with API error response
            callbacks.onError(result.error as apiErrorModel);
        } else {
            dispatch(auditActions.deleteRawFile(fileId));
            // Call onSuccess callback with true indicating successful deletion
            callbacks.onSuccess(true);
        }
        
        // Set loading state back to false after the operation completes
        dispatch(auditActions.setLoadingFileRawForm(false));
    };
};
