import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import IDatasetDoughnutModel from 'models/Chartjs/IDatasetDoughnutModel';
import React from 'react'
import { Doughnut } from 'react-chartjs-2';


interface OwnProps {
    dataset: IDatasetDoughnutModel;
 }


/**
 * A doughnut chart component that displays data as doughnut segments.
 * 
 * @name DoughnutChart
 * @component
 * @param {OwnProps} props - Component props
 * @param {IDatasetDoughnutModel} props.dataset - Dataset for the chart
 * @return {JSX.Element} The doughnut chart component
 */
const DoughnutChart: React.FC<OwnProps> = ({dataset}) => {

    const options = {
        tooltips: {
            position: 'nearest',
            displayColors: false
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: { 
        },
        plugins: {
          datalabels:{
            formatter: (value, ctx) => {
                
              if(!dataset.showPourcentage) return "";

              let sum = 0;
              const dataArr = ctx.chart.data.datasets[0].data as number[];
              dataArr.map(data => {
                  sum += data;
              });
              const percentage = (value*100 / sum);
              if(percentage == 0) return "";
              return percentage.toFixed(2)+"%";

          
            },
            color: '#fff',
            font:{
              size: 16
            }
          },
          legend: {
            position: 'bottom' as const,
          },
          title: {
            display: false,
            // text: 'Chart.js Line Chart',
          },
        },
      } as ChartOptions<"doughnut">;

    const data = {
        labels: dataset.labels,
        datasets: [{
            data: dataset.data,
            backgroundColor: dataset.backgroundColor
        }]
        /*labels,
        datasets: datasets.map(dataset => {
            return {
                data: dataset.data,
                label: dataset.label,
                backgroundColor: dataset.color,
                borderColor: dataset.color,
                fillColor: dataset.color,
                borderWidth: 5,
                pointRadius: 3,
                pointHoverRadius: 4,
                pointHoverBorderColor: dataset.color,
                pointHoverBackgroundColor: dataset.color,
                pointHitRadius: 20,
                fill: false,
                barPercentage: 1.0
            }
        })*/
      } as ChartData<'doughnut', number[]>;

    return <div style={{height: '300px'}}>
        <Doughnut options={options} data={data}/>
    </div>
    

}

export default DoughnutChart