import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userProfilActions } from '../Profil/userProfilSlice';
import { Floor } from 'fno-sdk/build/models/unityEventModels/Floor';
import { CameraMode } from 'fno-sdk/build/models/unityEventModels/enums/CameraMode';


export interface IMapControlsStore {
  floors: Floor[];
  cameraMode: CameraMode;
  currentFloor: Floor | undefined;
}

const initialState: IMapControlsStore = {
  floors: [],
  currentFloor: undefined,
  cameraMode: CameraMode.CAMERA_3D
};

const mapControlsSlice = createSlice({
  name: 'mapControls',
  initialState,
  reducers: {
    setFloors: (state, action: PayloadAction<Floor[]>) => {
      state.floors = action.payload;
    },
    setCurrentFloor: (state, action: PayloadAction<Floor | undefined>) => {
      state.currentFloor = action.payload
    },
    setCurrentFloorById: (state, action: PayloadAction<string>) => {
      state.currentFloor = state.floors.find(f => f.id === action.payload)
    },
    setCameraMode: (state, action: PayloadAction<CameraMode>) => {
      state.cameraMode = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userProfilActions.setLogout, (state) => {
        state.floors = initialState.floors;
        state.currentFloor = initialState.currentFloor;
        state.cameraMode = initialState.cameraMode
    })
  }
});


export const { actions: mapControlsActions, reducer: mapControlsReducer } = mapControlsSlice;