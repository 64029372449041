import apiErrorModel from "backend/utils/apiErrorModel";
import i18next from "i18next";
import { toast } from "react-toastify";


/**
 * Parses API errors and show them in a toast.
 * @param apiError - The API error model containing error details.
 */
export default function parseApiErrorToast(apiError: apiErrorModel) {
    

    const htmlErrors:JSX.Element[] = [];

    // Iterate through each field in the API error response
    for (const fieldName in apiError.errors) {
        if (Object.prototype.hasOwnProperty.call(apiError.errors, fieldName)) {
            // Get the array of errors for the current field
            const errors = apiError.errors[fieldName];
            // If there are errors for the field, convert them in html li tag
            if (errors.length > 0) {
                htmlErrors.push(<>
                    <div>{fieldName}</div>
                    {errors.map((error) => <li>{i18next.t(error, { ns: "TransverseError" })}</li>)}
                </>)
            }
        }
    }

    toast.error(<div>
        <div><b>{i18next.t("Les erreurs suivantes se sont produites", { ns: "TransverseError" })}</b></div>
        {htmlErrors}
    </div>)
}