import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/PageHeader';
import nameOf from 'utility/nameOf';
import { useAppDispatch, useAppSelector } from 'store/store'
import Loading from 'components/Loading';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import Switch from "react-switch";
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import IOnEventAdminModel from 'models/Admin/IOnEventAdminModel';
import dayjs from 'dayjs';
import { adminRealtimeLogActions } from 'store/reducers/Admin/RealtimeLog/adminRealtimeLogSlice';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditJsonRaw from 'components/Admin/EditJsonRaw';
import useSignalRHub from 'hooks/useSignalRHub';
import { SpinnerInfinity } from 'spinners-react';
import ButtonFno from 'components/inputs/ButtonFno';
import StatisticCard from 'components/StatisticCard';
import IConnectedEntityModel from 'models/Admin/IConnectedEntityModel';

const AdminRealtimeConnectedUsers: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminRealtimeConnectedUsers}), { useSuspense: false});
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    //const allClients = useAppSelector(state => state.adminClient.allClients);
    //const allMaps = useAppSelector(state => state.adminClientMap.maps);

    const logs = useAppSelector(state => state.adminRealtimeLog.logs);
    const [realtimeEnable, setRealtimeEnable] = useState(true);

    const [selectedRow, setSelectedRow] = useState<IConnectedEntityModel | undefined>();

    const connectedEntities = useAppSelector(state => state.adminRealtimeLog.connectedUsers);


    const adminHub = useSignalRHub(process.env.REACT_APP_API_URL + "/live/adminHub", {
        onConnected: (hub) => {
            hub.invoke("GetOnlineUsersForMap").then((users: IConnectedEntityModel[]) => {
                    console.log("result => ", users); 
                    dispatch(adminRealtimeLogActions.setConnectedUsers(users));
                })

            hub.on("OnAddUserToGroupMap", (user: IConnectedEntityModel) => { 
                console.log("OnAddUserToGroupMap !! ", user); 
                dispatch(adminRealtimeLogActions.addConnectedUser(user))
            });
            hub.on("OnRemoveUserToGroupMap", (user: IConnectedEntityModel) => { 
                console.log("OnRemoveUserToGroupMap !! ", user); 
                dispatch(adminRealtimeLogActions.removeConnectedUser(user))
            });
        },
        onDisconnected: () => setRealtimeEnable(false),
        onError: (err) => {console.log("onError !", err); setRealtimeEnable(false);},
        enabled: realtimeEnable,
        automaticReconnect: true,
        httpTransportTypeOrOptions: {accessTokenFactory: getCurrentUserToken}
      })



    useEffect(() => {
        document.title = t('[Admin] Utilisateurs connectés');
        return () => {
            setRealtimeEnable(false)
        }
    }, [])

    const columns: TableColumn<IConnectedEntityModel>[] = [
        {
            id:'dateRegister',
            name: t('Date'),
            sortable: true,
            selector: row => `${row.dateRegister ? dayjs(row.dateRegister).unix(): ""}`,
            cell: row => `${row.dateRegister ? dayjs(row.dateRegister).format("DD/MM/YYYY HH:mm:ss"): ""}`
        },
        {
            id:'name',
            name: t('Nom'),
            sortable: true,
            selector: row => row.name,
        },
        {
            id:'hub',
            name: t('Hub'),
            sortable: true,
            selector: row => row.hubName,
        },
        {
            id:'groups',
            name: t('Carte'),
            sortable: true,
            cell: (row, index, column, id) =>{
                return <div className='d-flex'>
                    {row.groups.map((group) => <span key={group.mapId + "-" + group.tenant}>{group.mapName}</span>)}
                </div>
            }
        },
        {
            name:<div>{t('Actions')}</div>,
            center:true,
            width:"150px",
            cell: (row, index, column, id) =>{
                return <div className='d-flex'>
                    <Button size='sm' variant='success' className='me-1' onClick={() => setSelectedRow(row)}>
                        <FontAwesomeIcon icon={["fas", "eye"]} />
                    </Button>
                </div>
            }
        }
    ];

    return (
        <div>
             <PageHeader title={t("Logs temps réel")} admin/>

            <div>
                <StatisticCard color='blue' title={t("Clients connectés")} icon='wifi' value={connectedEntities.length}/>
            </div>


            <div className='table-component'>
                <DataTable 
                    columns={columns} 
                    data={connectedEntities} 
                    defaultSortAsc={false} 
                    progressComponent={<Loading text={t("Chargement")}/>}
                    defaultSortFieldId={'dateRegister'} 
                    striped={true}
                    subHeader={true}
                    highlightOnHover={true}
                    noDataComponent={<div className='w-100 text-center p-5'>
                        {!realtimeEnable ? t("Temps réel désactivé") : t("Aucun utilisateur connecté")}
                        {!realtimeEnable && <div className='mt-3'><ButtonFno onClick={() => setRealtimeEnable(true)}>{t("Activer le temps réel")}</ButtonFno></div>}
                        </div>}
                    subHeaderComponent={<> 
                        <h5 className='text-uppercase'> {t("Les logs")} </h5>
                        <div className='search-panel'>
                            <span className='me-2'>
                                {realtimeEnable && <SpinnerInfinity size={50} thickness={180} speed={98} color="rgba(62, 172, 57, 1)" secondaryColor="rgba(172, 102, 57, 0.31)" />}
                            </span>
                            <span>  
                                <Switch checked={realtimeEnable} onChange={(enabled) => setRealtimeEnable(enabled)}/>
                            </span>
                            {/*<input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>*/}
                        </div>
                        </>}
                    sortServer={false}/>
            </div>

            {selectedRow && <Modal dialogClassName='modal-fno' show={true} onHide={() => setSelectedRow(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedRow.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditJsonRaw onCancel={() => setSelectedRow(undefined)} value={selectedRow}/>
                </Modal.Body>
            </Modal>}
        </div>
    )
}

export default AdminRealtimeConnectedUsers
