/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, Operation, TemplateCreate, TemplateDetails, TemplateUpdate } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MapTemplate<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags MapTemplate
     * @name TemplatesList
     * @summary [🤵 OR 🔑] Get all template for a map. [🔒: map_read].
     * @request GET:/map/{mapId}/templates
     * @secure
     */
    templatesList = (mapId: string, params: RequestParams = {}) =>
        this.request<TemplateDetails[], ApiErrorsResponse | void>({
            path: `/map/${mapId}/templates`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapTemplate
     * @name TemplatesPost
     * @summary [🤵 OR 🔑] Create several templates. [🔒: map_create].
     * @request POST:/map/{mapId}/templates
     * @secure
     */
    templatesPost = (mapId: string, data: TemplateCreate[], params: RequestParams = {}) =>
        this.request<TemplateDetails[], void>({
            path: `/map/${mapId}/templates`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapTemplate
     * @name PolygonTemplatesList
     * @summary [🤵 OR 🔑] Get all template for a map. [🔒: map_read].
     * @request GET:/map/{mapId}/polygon/templates
     * @secure
     */
    polygonTemplatesList = (mapId: string, params: RequestParams = {}) =>
        this.request<TemplateDetails[], ApiErrorsResponse | void>({
            path: `/map/${mapId}/polygon/templates`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapTemplate
     * @name PolygonTemplatesPost
     * @summary [🤵 OR 🔑] Create several templates. [🔒: map_create].
     * @request POST:/map/{mapId}/polygon/templates
     * @secure
     */
    polygonTemplatesPost = (mapId: string, data: TemplateCreate[], params: RequestParams = {}) =>
        this.request<TemplateDetails[], void>({
            path: `/map/${mapId}/polygon/templates`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapTemplate
     * @name TemplatePost
     * @summary [🤵 OR 🔑] Create new template. [🔒: map_create].
     * @request POST:/map/{mapId}/template
     * @secure
     */
    templatePost = (mapId: string, data: TemplateCreate, params: RequestParams = {}) =>
        this.request<TemplateDetails, void>({
            path: `/map/${mapId}/template`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapTemplate
     * @name PolygonTemplatePost
     * @summary [🤵 OR 🔑] Create new template. [🔒: map_create].
     * @request POST:/map/{mapId}/polygon/template
     * @secure
     */
    polygonTemplatePost = (mapId: string, data: TemplateCreate, params: RequestParams = {}) =>
        this.request<TemplateDetails, void>({
            path: `/map/${mapId}/polygon/template`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapTemplate
     * @name TemplatePut
     * @summary [🤵 OR 🔑] Update all fields of a template. [🔒: map_update].
     * @request PUT:/map/{mapId}/template/{templateId}
     * @secure
     */
    templatePut = (mapId: string, templateId: string, data: TemplateUpdate, params: RequestParams = {}) =>
        this.request<TemplateDetails, void>({
            path: `/map/${mapId}/template/${templateId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapTemplate
     * @name TemplatePatch
     * @summary [🤵 OR 🔑] Update specific field of a map template. [🔒: map_update].
     * @request PATCH:/map/{mapId}/template/{templateId}
     * @secure
     */
    templatePatch = (mapId: string, templateId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<TemplateDetails, void>({
            path: `/map/${mapId}/template/${templateId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapTemplate
     * @name TemplateDelete
     * @summary [🤵 OR 🔑] Delete a template. [🔒: map_update].
     * @request DELETE:/map/{mapId}/template/{templateId}
     * @secure
     */
    templateDelete = (mapId: string, templateId: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/map/${mapId}/template/${templateId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
    /**
     * No description
     *
     * @tags MapTemplate
     * @name PolygonTemplatePut
     * @summary [🤵 OR 🔑] Update all fields of a template. [🔒: map_update].
     * @request PUT:/map/{mapId}/polygon/template/{templateId}
     * @secure
     */
    polygonTemplatePut = (mapId: string, templateId: string, data: TemplateUpdate, params: RequestParams = {}) =>
        this.request<TemplateDetails, void>({
            path: `/map/${mapId}/polygon/template/${templateId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapTemplate
     * @name PolygonTemplatePatch
     * @summary [🤵 OR 🔑] Update specific field of a map template. [🔒: map_update].
     * @request PATCH:/map/{mapId}/polygon/template/{templateId}
     * @secure
     */
    polygonTemplatePatch = (mapId: string, templateId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<TemplateDetails, void>({
            path: `/map/${mapId}/polygon/template/${templateId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapTemplate
     * @name PolygonTemplateDelete
     * @summary [🤵 OR 🔑] Delete a template. [🔒: map_update].
     * @request DELETE:/map/{mapId}/polygon/template/{templateId}
     * @secure
     */
    polygonTemplateDelete = (mapId: string, templateId: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/map/${mapId}/polygon/template/${templateId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
