/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, ContainerCreate, ContainerDetails, ContainerUpdate, Operation, RunnerPreloadMatrixParams } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class PickingRunner<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Picking runner
     * @name PickingRunnersList
     * @summary [🤵] Get all runners for current client. [🔒: map_read].
     * @request GET:/admin/picking/runners
     * @secure
     */
    pickingRunnersList = (params: RequestParams = {}) =>
        this.request<ContainerDetails[], void>({
            path: `/admin/picking/runners`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Picking runner
     * @name PickingRunnerPost
     * @summary [🤵] Create new picking runner for client. [🔒: map_update].
     * @request POST:/admin/picking/runner
     * @secure
     */
    pickingRunnerPost = (data: ContainerCreate, params: RequestParams = {}) =>
        this.request<ContainerDetails, ApiErrorsResponse | void>({
            path: `/admin/picking/runner`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Picking runner
     * @name PickingRunnerPut
     * @summary [🤵] [🔒: map_update].
     * @request PUT:/admin/picking/runner/{runnerId}
     * @secure
     */
    pickingRunnerPut = (runnerId: string, data: ContainerUpdate, params: RequestParams = {}) =>
        this.request<ContainerDetails, ApiErrorsResponse | void>({
            path: `/admin/picking/runner/${runnerId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Picking runner
     * @name PickingRunnerDelete
     * @summary [🤵] [🔒: map_delete].
     * @request DELETE:/admin/picking/runner/{runnerId}
     * @secure
     */
    pickingRunnerDelete = (runnerId: string, params: RequestParams = {}) =>
        this.request<any, ApiErrorsResponse | void>({
            path: `/admin/picking/runner/${runnerId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
    /**
     * No description
     *
     * @tags Picking runner
     * @name PickingRunnerPatch
     * @summary [🤵] [🔒: map_update].
     * @request PATCH:/admin/picking/runner/{runnerId}
     * @secure
     */
    pickingRunnerPatch = (runnerId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<ContainerDetails, ApiErrorsResponse | void>({
            path: `/admin/picking/runner/${runnerId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Picking runner
     * @name PickingRunnerRestartPost
     * @summary [🤵] [🔒: map_update].
     * @request POST:/admin/picking/runner/{runnerId}/restart
     * @secure
     */
    pickingRunnerRestartPost = (runnerId: string, params: RequestParams = {}) =>
        this.request<ContainerDetails, ApiErrorsResponse | void>({
            path: `/admin/picking/runner/${runnerId}/restart`,
            method: 'POST',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Picking runner
     * @name PickingRunnerPreloadDataList
     * @summary [🤵] Get map preload data for runner API. [🔒: map_read].
     * @request GET:/admin/picking/runner/preload/data
     * @secure
     */
    pickingRunnerPreloadDataList = (
        query: {
            mapId: string
            tenant: string
            /** (optionnal) Get specific preload data */
            settingId?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<RunnerPreloadMatrixParams, void>({
            path: `/admin/picking/runner/preload/data`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
}
