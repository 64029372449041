/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, LabelTemplateCreate, LabelTemplateDetails, LabelTemplateUpdate, Operation } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class LabelTemplate<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags LabelTemplate
     * @name LabelTemplatesList
     * @summary [🤵 OR 🔑] Get all label templates for a map. [🔒: map_read].
     * @request GET:/map/label/templates
     * @secure
     */
    labelTemplatesList = (params: RequestParams = {}) =>
        this.request<LabelTemplateDetails[], ApiErrorsResponse | void>({
            path: `/map/label/templates`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags LabelTemplate
     * @name LabelTemplatesPost
     * @summary [🤵 OR 🔑] Create several label templates. [🔒: map_create].
     * @request POST:/map/label/templates
     * @secure
     */
    labelTemplatesPost = (data: LabelTemplateCreate[], params: RequestParams = {}) =>
        this.request<LabelTemplateDetails[], void>({
            path: `/map/label/templates`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags LabelTemplate
     * @name LabelTemplatePost
     * @summary [🤵 OR 🔑] Create new label template. [🔒: map_create].
     * @request POST:/map/label/template
     * @secure
     */
    labelTemplatePost = (data: LabelTemplateCreate, params: RequestParams = {}) =>
        this.request<LabelTemplateDetails, void>({
            path: `/map/label/template`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags LabelTemplate
     * @name LabelTemplatePut
     * @summary [🤵 OR 🔑] Update all fields of a label template. [🔒: map_update].
     * @request PUT:/map/label/template/{templateId}
     * @secure
     */
    labelTemplatePut = (templateId: string, data: LabelTemplateUpdate, params: RequestParams = {}) =>
        this.request<LabelTemplateDetails, void>({
            path: `/map/label/template/${templateId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags LabelTemplate
     * @name LabelTemplatePatch
     * @summary [🤵 OR 🔑] Update specific field of a label template. [🔒: map_update].
     * @request PATCH:/map/label/template/{templateId}
     * @secure
     */
    labelTemplatePatch = (templateId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<LabelTemplateDetails, void>({
            path: `/map/label/template/${templateId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags LabelTemplate
     * @name LabelTemplateDelete
     * @summary [🤵 OR 🔑] Delete a label template. [🔒: map_update].
     * @request DELETE:/map/label/template/{templateId}
     * @secure
     */
    labelTemplateDelete = (templateId: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/map/label/template/${templateId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
