import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import { ROUTES } from 'resources/routes-constants';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import getCurrentTenant from 'backend/utils/getCurrentTenant';
import Sdk from 'fno-sdk';
import useFnoMapSdk from 'hooks/useFnoMapSdk';
import FloorControls from 'components/mapControls/FloorControls';
import AssetTooltip from 'components/mapControls/AssetTooltip';

const AdminClientMapViewer3D: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation(nameOf({AdminClientMapViewer3D}), { useSuspense: false});
    const tenant = getCurrentTenant()?.tenant;
    const token = getCurrentUserToken();
    const { clientId, mapId } = useParams();
    const {
        sdk,
        loading,
        loadingEngine,
        loadingMap,
        mapReady
    } = useFnoMapSdk(token, tenant ?? "", mapId ?? "");
    const navigate = useNavigate();
    


    useEffect(() => {
        document.title = t('[Admin] Carte 3D');
        //setSdk(new Sdk(token, tenant ?? ""));   <---- TODO
        return () => {
            console.log("destroy scene ...", sdk)
            //sdk?.scene.destroyScene();
        }
    }, [])

    

    if(!clientId) {
        return <Navigate to={ROUTES.ADMIN.CLIENTS} state={{ from: location }} replace />
    }

    return (<div>
                {/*<div style={{height: "700px"}}>
                    <MapRender 
                        sdk={sdk} 
                        mapReady={mapReady}
                        assetToolipConfig={{
                            highlightColor: appColors.orange
                        }}/>

                </div>*/}
            </div>)
}

export default AdminClientMapViewer3D
