/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from './http-client'

export class RunnerManagement<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags RunnerManagement
     * @name ReconnectRunner
     * @request POST:/runner/{runnerId}/realtime/connect
     */
    reconnectRunner = (runnerId: string, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/runner/${runnerId}/realtime/connect`,
            method: 'POST',
            format: 'json',
            ...params
        })
}
