import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sdk, { Asset, MetadataGroup } from 'fno-sdk';
import { Form, Table } from 'react-bootstrap';
import Loading from 'components/Loading';
import _ from 'lodash';
import ButtonFno from 'components/inputs/ButtonFno';

interface OwnProps { 
    sdk: Sdk;
    onSelectAsset: (assetId: string) => void
}

const LocationsList: React.FC<OwnProps> = ({sdk, onSelectAsset}) => {

    const [assets, setAssets] = useState<Asset[]>([]);
    const [metadataGroups, setMetadataGroups] = useState<MetadataGroup[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if(sdk)
        {
            setLoading(true);
            sdk.asset.getAllWithLocationCode().then((assets) => {
                setAssets(assets as unknown[] as Asset[]);
                
            }).finally(() => {
                setLoading(false);
            })

            sdk.metadata.getAll().then((groups) => {
                setMetadataGroups(groups)
            })
        }
    }, [sdk])



    function onHoverIn(asset: Asset){
        sdk.asset.setHighlightAssetById(asset.id, true)
    }
    function onHoverOut(asset: Asset){
        sdk.asset.setHighlightAssetById(asset.id, false)
    }

    function zoomOnAsset(asset: Asset)
    {
       // sdk.camera.zoomOnEntity(asset.id);    <------ Pas encore fait coté unity
    }


    return ( <Table striped bordered hover className={"table-metadata mt-5"}>
                        <thead>
                            <tr>
                                <th>LOCATION</th>
                                <th>TAG</th>
                                <th>SKU</th>
                                <th className='text-center'>#</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading && assets.map((asset) => {

                                const metadata = metadataGroups.find(m => m.name == "asset_products" && asset.metadataGroupIds.includes(m.id))
                                console.log("metadata", metadata)

                                return <tr onPointerEnter={() => onHoverIn(asset)} onPointerLeave={() => onHoverOut(asset)}>
                                    <td>{asset.locationCode}</td>
                                    <td>{_.keys(metadata?.data).map((e) => <div>{e}</div>)}</td>
                                    <td>{_.values(metadata?.data).map((e) => <div>{e}</div>)}</td>
                                    <td style={{width: "100px"}} className='text-center'>
                                        <ButtonFno sm onClick={() => onSelectAsset(asset.id)}><FontAwesomeIcon icon={["fas", "edit"]} /></ButtonFno>
                                        {/*<ButtonFno sm color='orange' onClick={() => zoomOnAsset(asset)} className='ms-1'><FontAwesomeIcon icon={["fas", "eye"]} /></ButtonFno>*/}
                                    </td>
                                </tr>
                            })}
                            {loading && <tr>
                                    <td colSpan={4} className='text-center'><Loading inline size={40} /></td>
                                </tr>}
                            {assets.length == 0 && !loading && <tr>
                                    <td colSpan={4} className='text-center'>No asset with location code</td>
                                </tr>}
                        </tbody>
                    </Table>)
}

export default LocationsList
