import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LanguageItem, TranslationItemAdmin } from "backend/ApiAdminDefinition/data-contracts";
import _ from "lodash";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

// Interface representing a translation item that is waiting to be updated
export interface ILoadingTranslationItem {
  page: string;
  name: string;
  lang: string;
  translation: string;
}

// Interface representing the state of the admin translation store
export interface IAdminTranslationStore {
  translations: TranslationItemAdmin[];
  defaultLanguages: LanguageItem[];
  loadingTranslation: boolean;
  /** List of translations waiting to be updated from API. Allows managing loading state in views */
  loadingUpdateTranslations: ILoadingTranslationItem[];
}

// Initial state for the admin translation store
const initialState: IAdminTranslationStore = {
  defaultLanguages: [],
  translations: [],
  loadingTranslation: false,
  loadingUpdateTranslations: [],
};

// Create a slice for the admin translations store
const adminTranslationsSlice = createSlice({
  name: "adminTranslationsReducer",
  initialState,
  reducers: {
    // Set the loadingTranslation state
    setLoadingTranslation: (state, action: PayloadAction<boolean>) => {
      state.loadingTranslation = action.payload;
    },
    // Set the translations in the state
    setTranslations: (state, action: PayloadAction<TranslationItemAdmin[]>) => {
      state.translations = action.payload;
    },
    // Set the defaultLanguages in the state
    setDefaultLanguages: (state, action: PayloadAction<LanguageItem[]>) => {
      state.defaultLanguages = action.payload;
    },
    // Add a loadingUpdateTranslation item to the state
    addLoadingUpdateTranslation: (state, action: PayloadAction<ILoadingTranslationItem>) => {
      state.loadingUpdateTranslations.push(action.payload);
    },
    // Remove a loadingUpdateTranslation item from the state
    removeLoadingUpdateTranslation: (state, action: PayloadAction<ILoadingTranslationItem>) => {
      state.loadingUpdateTranslations = state.loadingUpdateTranslations.filter(
        (m) => !_.isEqual(m, action.payload)
      );
    },
    // Update a translation in the state
    updateTranslation: (state, action: PayloadAction<TranslationItemAdmin>) => {
      state.translations = state.translations.map((trans) =>
        trans.name === action.payload.name && trans.page === action.payload.page
          ? action.payload
          : trans
      );
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.defaultLanguages = [];
      state.translations = [];
      state.loadingTranslation = false;
      state.loadingUpdateTranslations = [];
    });
  },
});

// Export the actions and reducer from the admin translations slice
export const {
  actions: adminTranslationsActions,
  reducer: adminTranslationsReducer,
} = adminTranslationsSlice;
