import { Batching } from "./ApiMiddlewareDefinition/Batching";
import { Optimization } from "./ApiMiddlewareDefinition/Optimization";
import { OptimizationResult } from "./ApiMiddlewareDefinition/OptimizationResult";
import { Statistic } from "./ApiMiddlewareDefinition/Statistic";
import getApiMiddlewareConfig from "./utils/getApiMiddlewareConfig";




const apiMiddlewareDefinition = (baseUrl: string, tenant: string) => {
    return {
        requests: new OptimizationResult(getApiMiddlewareConfig(baseUrl, tenant)),
        statistics: new Statistic(getApiMiddlewareConfig(baseUrl, tenant)),
        OptimizationItm: new Optimization(getApiMiddlewareConfig(baseUrl, tenant)),
        batching: new Batching(getApiMiddlewareConfig(baseUrl, tenant)),
    }
}


export default apiMiddlewareDefinition;