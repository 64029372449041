import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import "./MapRender.scss";
import FloorControls from './FloorControls';
import AssetTooltip from './AssetTooltip';
import Loading from 'components/Loading';
import Sdk from 'fno-sdk';
import CameraModeControls from './CameraModeControls';


interface AssetToolipConfig{
    disableAssetToolip?: boolean;
    highlightColor?: string;
}

interface Props {
    sdk: Sdk;
    mapReady: boolean;
    assetToolipConfig?: AssetToolipConfig
}


/**
 * MapRender is a React functional component, it renders the Unity map into a container div.
 * It also provides a way to display the location code of an asset when hovering it in the map.
 * It is meant to be used in a MapView component.
 * @param sdk Sdk: The fno-sdk instance.
 * @param mapReady boolean: A boolean indicating if the map is ready to be displayed.
 * @param assetToolipConfig AssetToolipConfig: An optional configuration to disable the asset tooltip or to customize its color.
 * @returns JSX.Element
 */
const MapRender: React.FC<Props>= ({sdk, mapReady, assetToolipConfig}) => {
   const { t } = useTranslation(nameOf({MapRender}), { useSuspense: false});

   useEffect(() => {
    return () => {
        if(sdk) sdk.hidePlayer();
    }
}, [])


   useEffect(() => {
        if(sdk && mapReady)
        {
            sdk.showPlayer("fno-map-container").then(async () => {
                // TODO ?
            });
        }
        
    }, [sdk, mapReady])

   return (
       <div id="fno-map-container">
            <FloorControls sdk={sdk} loading={!mapReady}/>
            {/*<CameraModeControls sdk={sdk} loading={!mapReady}/>*/}
            {!assetToolipConfig?.disableAssetToolip && <AssetTooltip sdk={sdk} loading={!mapReady} color={assetToolipConfig?.highlightColor}/>}
            {!mapReady && <div className='loading-map-panel'><Loading text={t("Chargement de la carte 3D")}/></div>}
       </div>
   )
}
export default MapRender