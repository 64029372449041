import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import "./PageHeader.scss"



interface OwnProps {
    title: string;
    admin?: boolean;
    middlePart?: {
        to: string;
        title: string;
    }[];
 }
/**
 * The PageHeader component is a header component that displays a title and a breadcrumb.
 * It is used to display the current page title and the path to navigate to get to this page.
 * The breadcrumb is displayed as a list of NavLinks.
 * The component is used in the client application to display the current page title and path.
 * @param {OwnProps} props - The properties of the component.
 * @param {string} props.title - The title of the page.
 * @param {boolean} [props.admin=false] - Indicates if the current user is an admin.
 * @param {Array<{to: string; title: string;}>} [props.middlePart=[]] - The middle part of the breadcrumb.
 * @return {JSX.Element} The PageHeader component.
 */
const PageHeader: React.FC<OwnProps> = ({title, admin = false, middlePart = []}) => {

    useEffect(() => {
        
    }, [])

    const breadcrumbItems = [<NavLink key="home" to={admin ? ROUTES.ADMIN.DASHBOARD : ROUTES.DASHBOARD}><FontAwesomeIcon icon={["fas", "home"]} size="lg"/></NavLink>]
        
    if(admin){
        breadcrumbItems.push(<NavLink key={"admin"} to={ROUTES.ADMIN.DASHBOARD} className={"item"}>Admin</NavLink>)
    }
    
    middlePart.forEach((part, i) => {
        breadcrumbItems.push(<NavLink key={i} to={part.to} className={"item"}>{part.title}</NavLink>)
    })
    

    breadcrumbItems.push(<span key={"title"} className='item'>{title}</span>)

    return (<div className={'page-header pt-3 '}>
        <div className={'breadcrumb '  + (admin ? "admin": "no-admin")}>
             {breadcrumbItems.map((elem) => {
                return elem
             })}
        </div>
        {/*<h1 style={{ fontSize: '2em' }}>{title}</h1>*/}
    </div>)



   
}

export default PageHeader
