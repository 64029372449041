import apiErrorModel from "backend/utils/apiErrorModel";
import { store } from "store/store";
import { ApiTokenExpiredThunk } from "store/reducers/Profil/thrunks/ApiTokenExpiredThunk";

/**
 * Add interceptor for manage api error like 401 (token not valid/expired)
 */
export default function initFetchInterceptor()
{

    const { fetch: originalFetch } = window;

    window.fetch = async (...args) => {
        const [resource, config] = args;
        try{
            return originalFetch(resource, config).then(async (response) => {
                if (response.status === 401) {
                    store.dispatch(ApiTokenExpiredThunk())
                }
                if (!response.ok)
                {
                    const errorBody = await response.json() as apiErrorModel
                    if(errorBody.errors["token"].some(err => err == "not_valid"))
                    {
                        store.dispatch(ApiTokenExpiredThunk());
                    }

                    return Promise.reject(errorBody);
                }
                return response;
            }).catch((error) => {
                return Promise.reject(error);
            });
            
            
        }
        catch(error){
            console.error("window.fetch error", error);
            return Promise.reject(error);
        }

      };
}