import i18next from "i18next";
import { toast } from "react-toastify";
import { adminClientMapLabelTemplateActions } from "../adminClientMapLabelTemplateSlice";
import { AppThunk } from "store/store"
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Thunk action creator for loading LabelTemplates
 * @param mapId 
 * @returns 
 */
export const loadLabelTemplatesThunks = (): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapLabelTemplateActions.setLoadingLabelTemplateList(true));
  
        // Fetch LabelTemplates from the API
        const clientMaps = await apiBackofficeDefinition.labelTemplate.labelTemplatesList();
  
        // Dispatch action to set the fetched LabelTemplates in the store
        dispatch(adminClientMapLabelTemplateActions.setLabelTemplates(clientMaps.data));
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors du chargement des labelTemplates de la map"));
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapLabelTemplateActions.setLoadingLabelTemplateList(false));
      }
    };
  };