import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditJsonRaw from 'components/Admin/EditJsonRaw';
import { toast } from 'react-toastify';
import copyToClipboard from 'utility/copyToClipboard';
import DeleteConfirmForm from 'components/DeleteConfirmForm';
import { loadPolygonsThunks } from 'store/reducers/Admin/ClientMapPolygon/thrunks/loadPolygonsThunks';
import { useAppDispatch, useAppSelector } from 'store/store';
import { updatePolygonThunk } from 'store/reducers/Admin/ClientMapPolygon/thrunks/updatePolygonThunk';
import { deletePolygonThrunks } from 'store/reducers/Admin/ClientMapPolygon/thrunks/deletePolygonThrunks';
import {  PolygonUpdate, PolygonDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import dayjs from 'dayjs';

const AdminClientMapPolygones: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapPolygones}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const { clientId, mapId } = useParams();

    const {
        loadingList,
        loadingForm,
        polygons
    } = useAppSelector(state => state.adminMapPolygon);

    useEffect(() => {
        document.title = t('[Admin] Polygones de la map');
        if(clientId && mapId && mapId != "new")
        {
            dispatch(loadPolygonsThunks(mapId));
        }


    }, [mapId])

    

    const onUpdate = (entity: PolygonUpdate, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(updatePolygonThunk(mapId, entity as PolygonUpdate,{
                onSuccess: (marker) => {
                    toast.success(t("Le polygon a bien été modifié"));
                    closeModal();
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    /*const onCreateEntity = (entity: PolygonCreate) => {
        if(clientId && mapId)
        {
            dispatch(createPolygonThunk(mapId, entity as PolygonCreate,{
                onSuccess: (marker) => {
                    toast.success(t("Le polygon a bien été créé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }*/

    const onDelete = (entity: PolygonDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(deletePolygonThrunks(mapId, entity.id,{
                onSuccess: (success) => {
                    toast.success(t("Le polygon a bien été supprimé"))
                    closeModal();
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }
    const columns: TableColumn<PolygonDetails>[] = [
        {
            id:'category',
            name: t('Catégorie'),
            sortable: true,
            selector: row => row.category,
        },
        {
            id:'points',
            name: t('Nb points'),
            sortable: true,
            selector: row => row.points.length,
        },
        {
            id:'holes',
            name: t('Nb Trous'),
            sortable: true,
            selector: row => row.holes.length,
        },
        {
            id:'metadataGroupIds',
            name: t('Nb groupe Metadata'),
            sortable: true,
            selector: row => row.metadataGroupIds.length,
        },
        {
            id:'version',
            name: t('Version'),
            center:true,
            selector: row => row.version,
        },
        {
            id:'dateCreated',
            name: t('Créé le'),
            sortable: true,
            selector: row => `${row.dateCreated ? dayjs(row.dateCreated).unix(): ""}`,
            cell: row => `${row.dateCreated ? dayjs(row.dateCreated).format("DD/MM/YYYY HH:mm"): ""}`
        },
    ];


    return <TableWithCrudComponent 
    entities={polygons} 
    columns={columns}
    loadingForm={loadingForm}
    loadingList={loadingList}
    onDelete={onDelete}
    onUpdate={onUpdate}
    translations={{
        tableTitle: t("Polygones de la carte"),
        noEntityText: t("Aucun polygone disponible"),
        createTitle: t("Ajouter un polygone"),
        createButtonText: t("Ajouter un polygone"),
        deleteText: (entity) => (t("Voulez-vous supprimer le polygone ?")),
        deleteTitle: (entity) => (t("Supprimer le polygone ") + entity.id),
        updateText: (entity) => (t("Modifier le polygone ")  + entity.id),
    }}
    fieldSearchable={(entity) => [
        entity.category,
        entity.id
    ]}
    />;
}

export default AdminClientMapPolygones