import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectLarge from 'components/inputs/SelectLarge';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import { timcodeActions } from './store/timcodeStore';
import { SelectedTimcodeDataThunk } from './store/SelectedTimcodeDataThunk';

const SelectDayComponent: React.FC = () => {
   const { t } = useTranslation(nameOf({SelectDayComponent}), { useSuspense: false});
   const dispatch = useAppDispatch();
   const {
    allFiles,
    selectedFile
} = useAppSelector(state => state.timcodeDemo);

   useEffect(() => {
    if(!selectedFile){
        dispatch(SelectedTimcodeDataThunk(allFiles[0]));
    }
   }, [])

   return (<div className='m-auto' style={{maxWidth: "250px"}}>
    <SelectLarge options={allFiles} 
            isDisabled = {allFiles.length < 2}
            value={selectedFile} 
            onChange={(value) => dispatch(SelectedTimcodeDataThunk(value))} 
            valueRender={(file) =><div className='text-truncate text-uppercase'>
                <FontAwesomeIcon className='me-2' icon={["fas", "calendar-days"]} /> {file.name}
                </div>}
            optionRender={(file) => <div className='text-truncate text-uppercase'>
                <FontAwesomeIcon className='me-2' icon={["fas", "calendar-days"]} />  {file.name}
                </div>}/>
 </div>)
}
export default SelectDayComponent