import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ContainerDetails } from "backend/ApiAdminDefinition/data-contracts";
import IRunnerConnectedModel from "models/Admin/IRunnerConnectedModel";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";


// Interface representing the state of the admin picking store
export interface IAdminPickingStore {
  /** All picking runners */
  runners: ContainerDetails[];
  runnersConnected: IRunnerConnectedModel[];
  selectedRunner: ContainerDetails | undefined;
  realtimeEnabled: boolean;
  loadingEdit: boolean;
  loading: boolean;
}

// Initial state for the admin picking store
const initialState: IAdminPickingStore = {
  runners: [],
  selectedRunner: undefined,
  runnersConnected: [],
  realtimeEnabled: false,
  loadingEdit: false,
  loading: false,
};

// Create a slice for the admin client picking store
const adminClientPickingSlice = createSlice({
  name: "adminClientPickingSlice",
  initialState,
  reducers: {

    setRealtimeEnabled: (state, action:PayloadAction<boolean>) => {
      state.realtimeEnabled = action.payload;
    },
    clearConnectedRunner: (state) => {
      state.runnersConnected = [];
    },
    addOrUpdateConnectedRunner: (state, action:PayloadAction<IRunnerConnectedModel>) => {
      const index = state.runnersConnected.findIndex((m) => m.runnerId === action.payload.runnerId);

      if (index >= 0) {
        state.runnersConnected[index] = action.payload;
      }
      else {
        state.runnersConnected.push(action.payload);

        // if middleware exist only in connected array, add it in middlewares
        const middlewareFound = state.runnersConnected.some((m) => m.runnerId === action.payload.runnerId);
        if(!middlewareFound){
          state.runnersConnected.push(action.payload);
        }
      }
    },
    removeConnectedRunner: (state, action:PayloadAction<IRunnerConnectedModel>) => {
      state.runnersConnected = state.runnersConnected.filter(
        (m) => m.runnerId !== action.payload.runnerId
      );
    },
    setConnectedRunners: (state, action:PayloadAction<IRunnerConnectedModel[]>) => {
      state.runnersConnected = action.payload;
    },


    setSelectedRunner: (state, action: PayloadAction<ContainerDetails | undefined>) => {
      state.selectedRunner = action.payload;
    },

    // Set the picking runners in the state
    setRunners: (state, action: PayloadAction<ContainerDetails[]>) => {
      state.runners = action.payload;
    },
    // Set the loading state
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    // Set the loadingEdit state
    setLoadingEdit: (state, action: PayloadAction<boolean>) => {
      state.loadingEdit = action.payload;
    },
    // Update a specific picking runner in the state
    updateRunner: (state, action: PayloadAction<ContainerDetails>) => {
      state.runners = state.runners.map((runner) =>
        runner.id === action.payload.id ? action.payload : runner
      );
    },
    // Add a new picking runner to the state
    addRunner: (state, action: PayloadAction<ContainerDetails>) => {
      state.runners = [...state.runners, action.payload];
    },
    // Delete a picking runner from the state
    deleteRunner: (state, action: PayloadAction<string>) => {
      state.runners = state.runners.filter((runner) =>
        runner.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.runners = [];
      state.loadingEdit = false;
    });
  },
});

// Export the actions and reducer from the admin client picking slice
export const {
  actions: adminClientPickingActions,
  reducer: adminClientPickingReducer,
} = adminClientPickingSlice;