import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import PageHeader from 'components/PageHeader';
import { ROUTES } from 'resources/routes-constants';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import Section from 'components/Section';
import ClientForm, { ClientFormInputs } from 'components/Admin/forms/ClientForm';
import { FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { updateClientAdminThunk } from 'store/reducers/Admin/Client/thrunks/updateClientAdminThunk';
import { ClientUpdate } from 'backend/ApiAdminDefinition/data-contracts';
import parseApiErrorSetFieldError from 'utility/parseApiErrorSetFieldError';

const AdminClientDetails: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation(nameOf({AdminClientDetails}), { useSuspense: false});
    const selectedClient = useAppSelector(state => state.userProfil.currentTenant);
    const allClients = useAppSelector(state => state.adminClient.allClients);
    const loadingUpsert = useAppSelector(state => state.adminClient.loadingUpsert);
    const { clientId } = useParams();
    

    useEffect(() => {
        document.title = t('[Admin] Détails du client');
    }, [])



    const onSubmit = async (values: ClientFormInputs, {setFieldError}:FormikHelpers<ClientFormInputs>) => {

        dispatch(updateClientAdminThunk({
            id: values.id,
            name: values.name,
            siret: values.siret,
            tenant: values.tenant,
            type: values.type
        } as ClientUpdate,
            {
                onSuccess: (entityUpdated) => {
                    toast.success(t('Client sauvegardé'))
                },
                onError: (apiResponse) => {
                    parseApiErrorSetFieldError(apiResponse, setFieldError);
                }
            }))

    };




    if(!selectedClient)
    {
        return <Navigate to={ROUTES.ADMIN.CLIENTS} replace />
    }

    const clientDetails = allClients.find(m => m.id == selectedClient.id);


    return (
        <div>
             <PageHeader title={selectedClient.name} admin middlePart={[
                {to: ROUTES.ADMIN.CLIENTS, title: t("Client")}]}/>
                <Section>
                    <ClientForm initialValues={clientDetails} loading={loadingUpsert} onSubmit={onSubmit} onCancel={() => navigate(ROUTES.ADMIN.CLIENTS)} />
                </Section>
        </div>
    )
}

export default AdminClientDetails
