import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BuildingDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

// Interface representing the state of the admin client map building store
export interface IAdminClientMapBuildingStore {
  buildings: BuildingDetails[]; // Array of buildings
  loadingList: boolean; // Indicates if the building list is loading
  loadingForm: boolean; // Indicates if the building form is loading
}

// Initial state for the admin client map building store
const initialState: IAdminClientMapBuildingStore = {
  buildings: [],
  loadingList: false,
  loadingForm: false,
};

// Create a slice for the admin client map building store
const adminClientMapBuildingSlice = createSlice({
  name: "adminClientMapBuildingSlice",
  initialState,
  reducers: {
    // Set the buildings in the state
    setBuildings: (state, action: PayloadAction<BuildingDetails[]>) => {
      state.buildings = action.payload;
    },
    // Update a specific building in the state
    updateBuilding: (state, action: PayloadAction<BuildingDetails>) => {
      state.buildings = state.buildings.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new building to the state
    addBuilding: (state, action: PayloadAction<BuildingDetails>) => {
      state.buildings.push(action.payload);
    },
    // Remove a building
    deleteBuilding: (state, action: PayloadAction<string>) => {
      state.buildings = state.buildings.filter(m => m.id !== action.payload);
    },
    // Set the loadingList state
    setLoadingBuildingList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingForm state
    setLoadingBuildingForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.buildings = [];
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

// Export the actions and reducer from the admin client map building slice
export const {
  actions: adminClientMapBuildingActions,
  reducer: adminClientMapBuildingReducer,
} = adminClientMapBuildingSlice;