import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import IDatasetModel from 'models/Chartjs/IDatasetModel';
import React from 'react'
import { Line } from 'react-chartjs-2';




interface OwnProps {
    labels: string[];
    datasets: IDatasetModel[];
    axes?: any;
    /**Show pourcentage in the chart */
    showPourcentage?: boolean
 }


/**
 * A line chart component that displays data as line segments.
 * @name LineChart
 * @component
 * @param {OwnProps} props - Component props
 * @param {string[]} props.labels - Array of labels for the x-axis of the chart
 * @param {IDatasetModel[]} props.datasets - Array of datasets for the chart
 * @param {any} [props.axes] - Configuration options for the chart axes
 * @param {boolean} [props.showPourcentage=false] - Indicates if the chart should display the percentage of each line segment
 * @return {JSX.Element} The line chart component
 */
const LineChart: React.FC<OwnProps> = ({labels, datasets, axes, showPourcentage = false}) => {

    const options = {
        tooltips: {
            position: 'nearest',
            displayColors: false
        },
        responsive: true,
        maintainAspectRatio: false,
        /*scales: {
          products:{
          }
        }*/
        plugins: {
          legend: {
            position: 'bottom' as const,
          },
          title: {
            display: false,
            // text: 'Chart.js Line Chart',
          },
          datalabels:{
            formatter: (value, ctx) => {
                
              if(!showPourcentage) return "";

              let sum = 0;
              const dataArr = ctx.chart.data.datasets[0].data as number[];
              dataArr.map(data => {
                  sum += data;
              });
              const percentage = (value*100 / sum);
              if(percentage == 0) return "";
              return percentage.toFixed(2)+"%";

          
            },
            color: '#fff',
          },
        },
      } as ChartOptions<"line">;


    if(axes)
    {
      options.scales = axes;
    }


    const data = {
        labels,
        datasets: datasets.map(dataset => {
            return {
                data: dataset.data,
                type: dataset.type ?? "line",
                label: dataset.label,
                backgroundColor: dataset.color,
                borderColor: dataset.color,
                fillColor: dataset.color,
                borderWidth: 5,
                pointRadius: 3,
                pointHoverRadius: 4,
                pointHoverBorderColor: dataset.color,
                pointHoverBackgroundColor: dataset.color,
                pointHitRadius: 20,
                fill: false,
                YAxisID: dataset.yAxisID
            }
        })
      } as ChartData<'line', number[]>;

    return <div style={{height: '300px'}}>
        <Line options={options} data={data} />
    </div>
    

}

export default LineChart