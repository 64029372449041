import React, { useEffect } from 'react'
import { Pagination } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';




interface OwnProps {
    currentPage: number;
    pageSize: number;
    totalPages: number;
    onChangePage: (page: number) => void
 }


/**
 * The PaginationRender component is a React functional component that renders a pagination
 * component for a table. It takes the current page, page size, total number of pages,
 * and a callback function to handle page changes as props.
 *
 * @param {number} currentPage - The current page number.
 * @param {number} pageSize - The number of items per page.
 * @param {number} totalPages - The total number of pages.
 * @param {(page: number) => void} onChangePage - A callback function that is called when the page changes.
 * @return {JSX.Element} The pagination component.
 */
const PaginationRender: React.FC<OwnProps> = ({currentPage, pageSize, totalPages, onChangePage}) => {
   const { t } = useTranslation(nameOf({PaginationRender}), { useSuspense: false});

   return (totalPages > 0 ? <Pagination className='justify-content-center'>
            
            <Pagination.First disabled={currentPage == 1} onClick={() => onChangePage(1)}/>
            <Pagination.Prev disabled={currentPage == 1} onClick={() => onChangePage(currentPage - 1)}/>

            {currentPage >= 4 && <Pagination.Ellipsis />}
            
            {Array.from(Array(5).keys()).map((i) => {
                const isCurrentPage = currentPage + i - 2 == currentPage
                const pageToShow = currentPage + i - 2;
                if(pageToShow < 1) return <></>;
                if(pageToShow > totalPages) return <></>;
                return <Pagination.Item key={i} active={isCurrentPage} onClick={() => onChangePage(pageToShow)}>{pageToShow}</Pagination.Item>
            })}

            {currentPage < totalPages - 2 && <Pagination.Ellipsis />}

            <Pagination.Next disabled={currentPage == totalPages} onClick={() => onChangePage(currentPage + 1)}/>
            <Pagination.Last disabled={currentPage == totalPages} onClick={() => onChangePage(totalPages)}/>
        </Pagination>: <></>)
}
export default PaginationRender