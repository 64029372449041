/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, FileCreate, FileDetails, FileDetailsWithUploadUrl, FileUpdate } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Storage<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Storage
     * @name FilePost
     * @summary [🤵] Create new file. [🔒: storage_file_create].
     * @request POST:/storage/file
     * @secure
     */
    filePost = (data: FileCreate, params: RequestParams = {}) =>
        this.request<FileDetailsWithUploadUrl, ApiErrorsResponse | void>({
            path: `/storage/file`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Storage
     * @name FilePut
     * @summary [🤵] Update file and return temporary link for storage upload. [🔒: storage_file_update].
     * @request PUT:/storage/file/{fileId}
     * @secure
     */
    filePut = (fileId: string, data: FileUpdate, params: RequestParams = {}) =>
        this.request<FileDetailsWithUploadUrl, ApiErrorsResponse | void>({
            path: `/storage/file/${fileId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Storage
     * @name FileDelete
     * @summary [🤵] Delete a file. [🔒: storage_file_delete].
     * @request DELETE:/storage/file/{fileId}
     * @secure
     */
    fileDelete = (fileId: string, params: RequestParams = {}) =>
        this.request<void, ApiErrorsResponse | void>({
            path: `/storage/file/${fileId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
    /**
     * No description
     *
     * @tags Storage
     * @name FilesList
     * @summary [🤵 OR 🔑] Get all client's files. [🔒: storage_file_list].
     * @request GET:/storage/files
     * @secure
     */
    filesList = (params: RequestParams = {}) =>
        this.request<FileDetails[], void>({
            path: `/storage/files`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
}
