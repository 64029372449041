import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import { useAppDispatch } from 'store/store';
import Sdk from 'fno-sdk';


type Props = {
    sdk: Sdk;
  };


const LocationCodePanel: React.FC<Props> = ({sdk}) => {
   const { t } = useTranslation(nameOf({LocationCodePanel}), { useSuspense: false});
   const dispatch = useAppDispatch();

   useEffect(() => {
   }, [])
   return (
       <div>
           Page LocationCodePanel
       </div>
   )
}
export default LocationCodePanel