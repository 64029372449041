import i18next from "i18next";
import { toast } from "react-toastify";
import { adminClientMapZoneActions} from "../adminClientMapZoneSlice";
import { AppThunk } from "store/store"
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Thunk action creator for loading Zones
 * @param mapId 
 * @returns 
 */
export const loadZonesThunks = (mapId: string): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapZoneActions.setLoadingZoneList(true));
  
        // Fetch Zones from the API
        const clientMaps = await apiBackofficeDefinition.zone.zonesList(mapId);
  
        // Dispatch action to set the fetched Zones in the store
        dispatch(adminClientMapZoneActions.setZones(clientMaps.data));
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors du chargement des Zones de la map"));
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapZoneActions.setLoadingZoneList(false));
      }
    };
  };