import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientMapSettingActions } from "../adminClientMapSettingsSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Delete a setting
 * @returns 
 */
export const deleteMapSettingThrunks = (mapId: string, settingId: string, callbacks: ThrunkApiCallBacks<boolean>): AppThunk => {
    return async (dispatch, getState) => {
       try{

            dispatch(adminClientMapSettingActions.setLoadingMapSettingForm(true));
            await apiBackofficeDefinition.mapSetting.settingDelete(mapId, settingId)
                .then((result) => {
                    dispatch(adminClientMapSettingActions.deleteMapSetting(settingId))
                    callbacks.onSuccess(true);
                })
                .catch((apiResponse: apiErrorModel) => {
                    callbacks.onError(apiResponse);
                })
                .finally(() => dispatch(adminClientMapSettingActions.setLoadingMapSettingForm(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la suppression de la configuration"))
        }
    }
}