import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiMiddlewareDefinition from "backend/apiMiddlewareDefinition";
import _ from "lodash";
import { middlewareBatchingActions } from "../middlewareBatchingSlice";

/**
 * Load batching columns binding for current client
 * @param baseUrl 
 * @param mapId 
 * @returns 
 */
export const loadBatchingColumnsBindingThrunk = (
        baseUrl: string, 
        mapId: string): AppThunk => {
    return async (dispatch, getState) => {
      try {
        
        const tenant = getState().userProfil.currentTenant?.tenant


        const columns = await apiMiddlewareDefinition(baseUrl, tenant ?? "").batching.getFileColumnsBinding(mapId);

       dispatch(middlewareBatchingActions.setBatchingColumnBindings(columns.data));


      } catch (error: any) {

        if (!error.status || error.status != 401) {
          toast.error(i18next.t("Erreur lors du chargement des colonnes de regroupement"));
        }
      }
    };
  };