import _ from "lodash";
import { AppThunk } from "store/store";
import { adminClientFileManagerActions } from "store/reducers/Admin/FileManager/adminClientFileManagerSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";
import { FileCreate, FileDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import IFileUploadStatus from "models/IFileUploadStatus";
import uploadFileToStorage from "utility/uploadFileToStorage";




export const createAndUploadFilesThunk = (files: File[], callbacks: ThrunkApiCallBacks<FileDetails[]>): AppThunk => {
    return async (dispatch, getState) => {
        
        dispatch(adminClientFileManagerActions.setLoadingUpload(true));


        dispatch(adminClientFileManagerActions.setCreatingFilesStatus(files.map((f) => {return { name: f.name, status: 'waiting' } as IFileUploadStatus})));
        const filesCreated:FileDetails[] = []
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            dispatch(adminClientFileManagerActions.updateUploadingFilesStatus({ name: file.name, status: 'pending' } as IFileUploadStatus));
            
            const result = await apiBackofficeDefinition.storage.filePost({fileName: file.name} as FileCreate)

            if(!result.ok)
            {
                callbacks.onError(result.error as apiErrorModel)
            }
            else
            {
                if(result && result.data.urlUpload){
                    filesCreated.push(result.data)
                    const resultUpload = await uploadFileToStorage(result.data.urlUpload, file);
                    dispatch(adminClientFileManagerActions.updateUploadingFilesStatus({ name: file.name, status: 'success' } as IFileUploadStatus));
                }
                else{
                    dispatch(adminClientFileManagerActions.updateUploadingFilesStatus({ name: file.name, status: 'error' } as IFileUploadStatus));
                }
            }
           
            
        }
        callbacks.onSuccess(filesCreated);
        dispatch(adminClientFileManagerActions.setLoadingUpload(false));

    }
}
