import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { TableColumn } from 'react-data-table-component';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { updateLabelThunk } from 'store/reducers/Admin/ClientMapLabel/thrunks/updateLabelThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import { loadLabelsThunks } from 'store/reducers/Admin/ClientMapLabel/thrunks/loadLabelsThunks';
import { deleteLabelThrunks } from 'store/reducers/Admin/ClientMapLabel/thrunks/deleteLabelThrunks';
import { LabelDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import { createLabelThunk } from 'store/reducers/Admin/ClientMapLabel/thrunks/createLabelThunk';

const AdminClientMapLabels: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapLabels}), { useSuspense: false});
    const dispatch = useAppDispatch();
    
    const {
        labels,
        loadingList,
        loadingForm
    } = useAppSelector(state => state.adminClientMapLabel)
    const { clientId, mapId } = useParams();


    useEffect(() => {
        document.title = t('[Admin] Labels de la map');
        if(clientId && mapId && mapId != "new")
        {
            dispatch(loadLabelsThunks(mapId));
        }
    }, [clientId, mapId])


    const onCreate = (label: LabelDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(createLabelThunk(mapId, label,{
                onSuccess: (asset) => {
                    closeModal();
                    toast.success(t("Le label a bien été créé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }
    
    const onUpdate = (label: LabelDetails, closeModel: () => void) => {
        if(clientId && mapId)
        {
            dispatch(updateLabelThunk(mapId, label,{
                onSuccess: (label) => {
                    closeModel();
                    toast.success(t("Le label a bien été modifié"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))

        }
    }

    const onDelete = (label: LabelDetails, closeModel: () => void) => {
        if(clientId && mapId)
        {
            dispatch(deleteLabelThrunks(mapId, label.id,{
                onSuccess: (success) => {
                    closeModel();
                    toast.success(t("Le label a bien été supprimé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))

        }
    }

    const columns: TableColumn<LabelDetails>[] = [
        {
            id:'text',
            name: t('Texte'),
            sortable: true,
            selector: row => row.text,
        },
        {
            id:'imageId',
            name: t('Image'),
            sortable: true,
            selector: row => row.imageId,
        },
        {
            id:'version',
            name: t('Version'),
            center:true,
            selector: row => row.version,
        }
    ];


    return <TableWithCrudComponent 
                    entities={labels} 
                    columns={columns}
                    loadingForm={loadingForm}
                    loadingList={loadingList}
                    onDelete={onDelete}
                    onUpdate={onUpdate}
                    onCreate={onCreate}
                    translations={{
                        tableTitle: t("Labels de la carte"),
                        noEntityText: t("Aucun label disponible"),
                        deleteText: (entity) => (t("Voulez-vous supprimer le label ?")),
                        deleteTitle: (entity) => (t("Supprimer le label ") + entity.id),
                        updateText: (entity) => (t("Modifier le label : ") + entity.id),
                        createButtonText: t("Ajouter un label"),
                        createTitle: t("Ajouter un label"),
                    }}
                    fieldSearchable={(entity) => [
                        entity.id,
                        entity.text,
                        entity.imageId
                    ]}
                    initialValueCreateForm={{
                        imageId:"",
                        text:"Mon label"
                    } as LabelDetails}
                    />
}

export default AdminClientMapLabels
