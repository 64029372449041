import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/PageHeader';
import nameOf from 'utility/nameOf';
import { useAppDispatch, useAppSelector } from 'store/store'
import Loading from 'components/Loading';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import Switch from "react-switch";
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import IOnEventAdminModel from 'models/Admin/IOnEventAdminModel';
import dayjs from 'dayjs';
import { adminRealtimeLogActions } from 'store/reducers/Admin/RealtimeLog/adminRealtimeLogSlice';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditJsonRaw from 'components/Admin/EditJsonRaw';
import useSignalRHub from 'hooks/useSignalRHub';
import { SpinnerInfinity } from 'spinners-react';
import ButtonFno from 'components/inputs/ButtonFno';
import StatisticCard from 'components/StatisticCard';
import IConnectedEntityModel from 'models/Admin/IConnectedEntityModel';

const AdminRealtimeMapChanges: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminRealtimeLog: AdminRealtimeMapChanges}), { useSuspense: false});
    const dispatch = useAppDispatch();
    
    const logs = useAppSelector(state => state.adminRealtimeLog.logs);
    const realtimeEnable = useAppSelector(state => state.adminRealtimeLog.enabled);

    const [selectedRow, setSelectedRow] = useState<IOnEventAdminModel | undefined>();


    const adminHub = useSignalRHub(process.env.REACT_APP_API_URL + "/live/adminHub", {
        onConnected: (hub) => {
            hub.on("OnMapChanges", (metadata: any, data: string) => { 
                console.log("OnMapChanges admin:", metadata, data); 
                dispatch(adminRealtimeLogActions.addRealtimeLog({
                    ...metadata,
                    entities: JSON.parse(data)
                }as IOnEventAdminModel));
            });
        },
        onDisconnected: () => dispatch(adminRealtimeLogActions.setRealtimeEnabled(false)),
        onError: (err) => {console.log("onError !", err); dispatch(adminRealtimeLogActions.setRealtimeEnabled(false));},
        enabled: realtimeEnable,
        automaticReconnect: true,
        httpTransportTypeOrOptions: {accessTokenFactory: getCurrentUserToken}
      })


    useEffect(() => {
        document.title = t('[Admin] Log temps réel');
        return () => {
            dispatch(adminRealtimeLogActions.setRealtimeEnabled(false))
        }
    }, [])

    const columns: TableColumn<IOnEventAdminModel>[] = [
        {
            id:'date',
            name: t('Date'),
            sortable: true,
            selector: row => `${row.date ? dayjs(row.date).unix(): ""}`,
            cell: row => `${row.date ? dayjs(row.date).format("DD/MM/YYYY HH:mm:ss"): ""}`
        },
        {
            id:'entity',
            name: t('Entité'),
            sortable: true,
            selector: row => row.entityType,
        },
        {
            id:'nbEntities',
            name: t('Nb'),
            sortable: true,
            selector: row => row.entities.length,
        },
        {
            id:'opearation',
            name: t('Opération'),
            sortable: true,
            selector: row => row.operation,
        },
        {
            id:'map',
            name: t('Map'),
            sortable: true,
            selector: row => row.mapId,
        },
        {
            id:'tenant',
            name: t('Client'),
            sortable: true,
            selector: row => row.tenant,
        },
        {
            name:<div>{t('Actions')}</div>,
            center:true,
            width:"150px",
            cell: (row, index, column, id) =>{
                return <div className='d-flex'>
                    <Button size='sm' variant='success' className='me-1' onClick={() => setSelectedRow(row)}>
                        <FontAwesomeIcon icon={["fas", "eye"]} />
                    </Button>
                </div>
            }
        }
    ];

    return (
        <div>
             <PageHeader title={t("Logs temps réel")} admin/>

            <div className='table-component'>
                <DataTable 
                    columns={columns} 
                    data={logs} 
                    defaultSortAsc={false} 
                    progressComponent={<Loading text={t("Chargement")}/>}
                    defaultSortFieldId={'date'} 
                    striped={true}
                    subHeader={true}
                    highlightOnHover={true}
                    noDataComponent={<div className='w-100 text-center p-5'>
                        {!realtimeEnable ? t("Temps réel désactivé") : t("Aucun log disponible")}
                        {!realtimeEnable && <div className='mt-3'><ButtonFno onClick={() => dispatch(adminRealtimeLogActions.setRealtimeEnabled(true))}>{t("Activer le temps réel")}</ButtonFno></div>}
                        </div>}
                    subHeaderComponent={<> 
                        <h5 className='text-uppercase'> {t("Les logs")} </h5>
                        <div className='search-panel'>
                            {logs.length > 0 && <ButtonFno sm className='me-2' onClick={() => dispatch(adminRealtimeLogActions.clearRealtimeLog())}><FontAwesomeIcon className='me-2' icon={["fas", "trash-can"]} /> {t("Reset")}</ButtonFno>}
                            <span className='me-2'>
                                {realtimeEnable && <SpinnerInfinity size={50} thickness={180} speed={98} color="rgba(62, 172, 57, 1)" secondaryColor="rgba(172, 102, 57, 0.31)" />}
                            </span>
                            <span>  
                                <Switch checked={realtimeEnable} onChange={(enabled) => dispatch(adminRealtimeLogActions.setRealtimeEnabled(enabled))}/>
                            </span>
                            {/*<input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>*/}
                        </div>
                        </>}
                    sortServer={false}/>
            </div>

            {selectedRow && <Modal dialogClassName='modal-fno' show={true} onHide={() => setSelectedRow(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedRow.entityType}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditJsonRaw onCancel={() => setSelectedRow(undefined)} value={selectedRow.entities}/>
                </Modal.Body>
            </Modal>}
        </div>
    )
}

export default AdminRealtimeMapChanges
