import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AssetDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

// Interface representing the state of the admin client map asset store
export interface IAdminClientMapAssetStore {
  assets: AssetDetails[];
  loadingList: boolean;
  loadingForm: boolean;
}

// Initial state for the admin client map asset store
const initialState: IAdminClientMapAssetStore = {
  assets: [],
  loadingList: false,
  loadingForm: false,
};

// Create a slice for the admin client map asset store
const adminClientMapAssetSlice = createSlice({
  name: "adminClientMapAssetSlice",
  initialState,
  reducers: {
    // Set the assets in the state
    setAssets: (state, action: PayloadAction<AssetDetails[]>) => {
      state.assets = action.payload;
    },
    // Update a specific asset in the state
    updateAsset: (state, action: PayloadAction<AssetDetails>) => {
      state.assets = state.assets.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new asset to the state
    addAsset: (state, action: PayloadAction<AssetDetails>) => {
      state.assets.push(action.payload);
    },
    // remove an asset
    deleteAsset: (state, action: PayloadAction<string>) => {
      state.assets = state.assets.filter(m => m.id !== action.payload)
    },
    // Set the loadingList state
    setLoadingAssetList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingForm state
    setLoadingAssetForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.assets = [];
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

// Export the actions and reducer from the admin client map asset slice
export const {
  actions: adminClientMapAssetActions,
  reducer: adminClientMapAssetReducer,
} = adminClientMapAssetSlice;
