import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { TableColumn } from 'react-data-table-component';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { updateFloorThrunk } from 'store/reducers/Admin/ClientMapFloor/thrunks/updateFloorThrunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import { loadFloorsThrunks } from 'store/reducers/Admin/ClientMapFloor/thrunks/loadFloorsThrunks';
import { deleteFloorThrunk } from 'store/reducers/Admin/ClientMapFloor/thrunks/deleteFloorThrunk';
import { FloorDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';

const AdminClientMapFloors: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapFloors}), { useSuspense: false});
    const dispatch = useAppDispatch();
    
    const {
        floors,
        loadingList,
        loadingForm
    } = useAppSelector(state => state.adminMapFloor)
    const { clientId, mapId } = useParams();

    /*const cartographyHub = useSignalRHub(`${process.env.REACT_APP_API_URL}/live/cartography?token=${getCurrentUserToken()}&tenant=${getCurrentTenant()?.tenant}&mapId=${mapId}`, {
        onConnected: (hub) => {
            hub.on("MapFloor_Update", (metadata: any, data: any) => { 
                console.log("MapFloor_Update", metadata, data);
            });
        },
        onDisconnected: (err) => console.log("cartography hub disconnected", err),
        onError: (err) => {console.log("cartography hub onError !", err);},
        automaticReconnect: true,
        enabled: true
      })*/

/*
      const cartographyHub = useSignalRHub(`${process.env.REACT_APP_API_URL}/live/cartography?token=${getCurrentUserToken()}&tenant=${getCurrentTenant()?.tenant}&mapId=${mapId}`, {
        onConnected: (hub) => {
            hub.on("Floor_Update", (metadata: any, data: any) =>console.log("Floor_Update", metadata, data));},
        onDisconnected: (err) => console.log("cartography hub disconnected", err),onError: (err) => {console.log("cartography hub onError !", err);},automaticReconnect: true,enabled: true})

        const modelisationHub = useSignalRHub(`${process.env.REACT_APP_API_URL}/live/modelisation?token=${getCurrentUserToken()}&tenant=${getCurrentTenant()?.tenant}&mapId=${mapId}`, {
            onConnected: (hub) => {
                hub.on("Asset_Update", (metadata: any, data: any) =>console.log("Asset_Update", metadata, data));},
            onDisconnected: (err) => console.log("cartography hub disconnected", err),onError: (err) => {console.log("cartography hub onError !", err);},automaticReconnect: true,enabled: true})
*/

    useEffect(() => {
        document.title = t('[Admin] Etages de la map');
        if(clientId && mapId && mapId != "new")
        {
            dispatch(loadFloorsThrunks(mapId));
        }
    }, [clientId, mapId])

    
    const onUpdate = (floor: FloorDetails, closeModel: () => void) => {
        if(clientId && mapId)
        {
            dispatch(updateFloorThrunk(mapId, floor,{
                onSuccess: (floor) => {
                    closeModel();
                    toast.success(t("L'étage a bien été modifié"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onDelete = (floor: FloorDetails, closeModel: () => void) => {
        if(clientId && mapId)
        {
            dispatch(deleteFloorThrunk(mapId, floor.id,{
                onSuccess: (success) => {
                    closeModel();
                    toast.success(t("L'étage a bien été supprimé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))

        }
    }

    const columns: TableColumn<FloorDetails>[] = [
        {
            id:'name',
            name: t('Nom'),
            sortable: true,
            selector: row => row.name,
        },
        {
            id:'floorIndex',
            name: t('Niveau'),
            sortable: true,
            selector: row => row.floorIndex,
        },
        {
            id:'entities',
            name: t('Nb entités'),
            sortable: true,
            selector: row => row.entityIds.length,
        },
        {
            id:'metadataGroupIds',
            name: t('Nb groupe Metadata'),
            sortable: true,
            selector: row => row.metadataGroupIds.length,
        },
        {
            id:'version',
            name: t('Version'),
            center:true,
            selector: row => row.version,
        }
    ];


    return <TableWithCrudComponent 
                    entities={floors} 
                    columns={columns}
                    loadingForm={loadingForm}
                    loadingList={loadingList}
                    onDelete={onDelete}
                    onUpdate={onUpdate}
                    onCreate={undefined}
                    translations={{
                        tableTitle: t("Etages de la carte"),
                        noEntityText: t("Aucun étage disponible"),
                        deleteText: (entity) => (t("Voulez-vous supprimer l'étage ?")),
                        deleteTitle: (entity) => (t("Supprimer l'étage ") + entity.id),
                        updateText: (entity) => (t("Modifier l'étage : ") + entity.id)
                    }}
                    fieldSearchable={(entity) => [
                        entity.id,
                        entity.name
                    ]}
                    />
}

export default AdminClientMapFloors
