/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, MarkerTemplateCreate, MarkerTemplateDetails, MarkerTemplateUpdate, Operation } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MapMarkerTemplate<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags MapMarkerTemplate
     * @name MarkerTemplatesList
     * @summary [🤵 OR 🔑] Get all marker templates for a map. [🔒: map_read].
     * @request GET:/map/{mapId}/marker/templates
     * @secure
     */
    markerTemplatesList = (mapId: string, params: RequestParams = {}) =>
        this.request<MarkerTemplateDetails[], ApiErrorsResponse | void>({
            path: `/map/${mapId}/marker/templates`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapMarkerTemplate
     * @name MarkerTemplatesPost
     * @summary [🤵 OR 🔑] Create several marker templates. [🔒: map_create].
     * @request POST:/map/{mapId}/marker/templates
     * @secure
     */
    markerTemplatesPost = (mapId: string, data: MarkerTemplateCreate[], params: RequestParams = {}) =>
        this.request<MarkerTemplateDetails[], void>({
            path: `/map/${mapId}/marker/templates`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapMarkerTemplate
     * @name MarkerTemplatePost
     * @summary [🤵 OR 🔑] Create new marker template. [🔒: map_create].
     * @request POST:/map/{mapId}/marker/template
     * @secure
     */
    markerTemplatePost = (mapId: string, data: MarkerTemplateCreate, params: RequestParams = {}) =>
        this.request<MarkerTemplateDetails, void>({
            path: `/map/${mapId}/marker/template`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapMarkerTemplate
     * @name MarkerTemplatePut
     * @summary [🤵 OR 🔑] Update all fields of a marker template. [🔒: map_update].
     * @request PUT:/map/{mapId}/marker/template/{templateId}
     * @secure
     */
    markerTemplatePut = (mapId: string, templateId: string, data: MarkerTemplateUpdate, params: RequestParams = {}) =>
        this.request<MarkerTemplateDetails, void>({
            path: `/map/${mapId}/marker/template/${templateId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapMarkerTemplate
     * @name MarkerTemplatePatch
     * @summary [🤵 OR 🔑] Update specific field of a marker template. [🔒: map_update].
     * @request PATCH:/map/{mapId}/marker/template/{templateId}
     * @secure
     */
    markerTemplatePatch = (mapId: string, templateId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<MarkerTemplateDetails, void>({
            path: `/map/${mapId}/marker/template/${templateId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapMarkerTemplate
     * @name MarkerTemplateDelete
     * @summary [🤵 OR 🔑] Delete a marker template. [🔒: map_update].
     * @request DELETE:/map/{mapId}/marker/template/{templateId}
     * @secure
     */
    markerTemplateDelete = (mapId: string, templateId: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/map/${mapId}/marker/template/${templateId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
