import React, { useEffect, useState } from 'react'
import { useAppDispatch } from "store/store";
import * as yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import { createRoleThunk } from 'store/reducers/Admin/ClientRole/thrunks/createRoleThunk';
import { Form } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import parseApiErrorSetFieldError from 'utility/parseApiErrorSetFieldError';
import { toast } from 'react-toastify';
import { RoleClientCreate } from 'backend/ApiAdminDefinition/data-contracts';

interface IRoleFormInputs{
    name: string;
}

type Props = {
    /**Call when user quit form or after a successful submit */
    onClose: () => void
  };


const RoleForm: React.FC<Props> = ({onClose}) => {
    const { t } = useTranslation(nameOf({RoleForm}), { useSuspense: false});
    const dispatch = useAppDispatch();

    const onSubmit = async (values: IRoleFormInputs, {setFieldError}:FormikHelpers<IRoleFormInputs>) => {
        dispatch(createRoleThunk(values as RoleClientCreate, {
            onSuccess: () => onClose(),
            onError: (error) => {
                parseApiErrorSetFieldError(error, setFieldError);
                toast.error(t("Impossible de créer le rôle"))
            }
        }));

    };


    const schema = yup.object().shape({
        name: yup.string().required(t("Champ obligatoire") as string),
      });


    return  <Formik
    validationSchema={schema}
    onSubmit={onSubmit}
    initialValues={{
        name: ''
    } as IRoleFormInputs}>
    {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        isSubmitting,
        errors,
    }) => (
           <fieldset disabled={isSubmitting}>
                <Form.Group className='mb-3' controlId="validationFormik03">
                    <Form.Label>{t("Nom du rôle")}</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        isValid={touched.name && !errors.name}
                        isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.name}
                    </Form.Control.Feedback>
                </Form.Group>
                <div className='d-flex'>
                    <ButtonFno className="w-100 me-1" color='blue' onClick={onClose}>{t('Retour')}</ButtonFno>
                    <ButtonFno className="w-100 ms-1" color='orange' onClick={handleSubmit}>{t('Valider')}</ButtonFno>
                </div>
            </fieldset>
        )}
    </Formik>
}

export default RoleForm;