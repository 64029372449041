import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import * as yup from 'yup';
import { Badge, Button, Col, Form, Row } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import Switch from "react-switch";
import { Formik, FormikHelpers } from 'formik';
import { ContainerDetails, ContainerPreloadMap } from 'backend/ApiAdminDefinition/data-contracts';
import { useAppSelector } from 'store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableColumn } from 'react-data-table-component';
import _ from 'lodash';
import TableWithCrudComponent from '../TableWithCrudComponent';

interface MapClientRow{
    id:string;
    tenant: string;
    clientName: string;

    mapId: string;
    mapName: string;
    
}

interface OwnProps {
    initialValues?: ContainerDetails;
    loading: boolean;
    onSubmit: ((values: IPickingRunnerUpdateFormInputs, formikHelpers: FormikHelpers<IPickingRunnerUpdateFormInputs>) => void | Promise<any>) & ((values: IPickingRunnerUpdateFormInputs, { setFieldError }: FormikHelpers<IPickingRunnerUpdateFormInputs>) => Promise<void>);
    onCancel: () => void
 }

 export interface IPickingRunnerUpdateFormInputs{
    id: string;
    name: string;
    mapsPreloaded: ContainerPreloadMap[];
    enabled: boolean;
    isGlobalRunner:boolean;
}


/**
 * Form to update a picking runner.
 * 
 * @param onSubmit Function called when form is submitted.
 * @param onCancel Function called when form is cancelled.
 * @param loading Boolean indicating if form is loading.
 * @param initialValues Optional initial values for the form.
 * @returns The JSX Element for the form.
 */
const PickingRunnerUpdateForm: React.FC<OwnProps> = ({onSubmit, onCancel, loading, initialValues = undefined}) => {
    const { t } = useTranslation([nameOf({PickingRunnerUpdateForm}), "TransverseError"], { useSuspense: false});

    const {
        allClients
    } = useAppSelector(state => state.adminClient);

    const schema = yup.object().shape({
        name: yup.string().required(t("required", { ns:"TransverseError"}) as string),
        mapsPreloaded: yup.array().required(t("required", { ns:"TransverseError"}) as string),
        enabled: yup.bool().required(t("required", { ns:"TransverseError"}) as string)
      });


      const allMaps = _.flatten(allClients.map(c => c.maps.map(m => { return {id: m.id, tenant: c.tenant, clientName: c.name ,mapId: m.id, mapName: m.name} as MapClientRow})));


    return (
        <div>
                <Formik
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    initialValues={{
                        id: initialValues?.id ?? "",
                        name: initialValues?.name ?? '',
                        enabled: initialValues?.enabled ?? true,
                        mapsPreloaded: initialValues?.mapsPreloaded ?? [],
                        isGlobalRunner: initialValues?.isGlobalRunner ?? false
                    } as IPickingRunnerUpdateFormInputs}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        isSubmitting,
                        setFieldValue,
                        errors,
                    }) => {

                        const getColumns = () => {
                            const columns: TableColumn<MapClientRow>[] = [
                                {
                                    id:'selected',
                                    name: t('Activée'),
                                    sortable: true,
                                    width:"100px",
                                    selector: row => values.mapsPreloaded.some(m => m.tenant == row.tenant && m.mapId == row.mapId),
                                    cell: (row, index, column, id) => {
                                        const selected = values.mapsPreloaded.some(m => m.tenant == row.tenant && m.mapId == row.mapId)

                                        return <div>
                                           <Switch checked={selected} disabled={isSubmitting} onChange={(enabled) => {
                                                let preloadMaps = values.mapsPreloaded;

                                                if(enabled)  preloadMaps = [...values.mapsPreloaded, {tenant: row.tenant, mapId: row.mapId} as ContainerPreloadMap]
                                                else preloadMaps = preloadMaps.filter(m => m.tenant != row.tenant || m.mapId != row.mapId)
                                                
                                                setFieldValue("mapsPreloaded", preloadMaps)
                                            }}/>
                                        </div>
                                    }
                                },
                                {
                                    id:'client',
                                    name: t('Client'),
                                    sortable: true,
                                    selector: row => row.clientName,
                                },
                                {
                                    id:'mapName',
                                    name: t('Carte'),
                                    sortable: true,
                                    selector: row => row.mapName
                                }
                            ];
                    
                            return columns;
                        };


                        


                        return (
                            <fieldset disabled={isSubmitting}>
    
                                    <Form.Control type="hidden" name="id" value={values.id}></Form.Control>
                                    <Row>
                                        <Col xs={10}>
                                            <Form.Control type="hidden" name="id" value={values.id}></Form.Control>
                                                <Form.Group className='mb-3' controlId="validationFormik03">
                                                    <Form.Label>{t("Nom de l'instance")}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        disabled={loading}
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        isValid={touched.name && !errors.name}
                                                        isInvalid={!!errors.name}
                                                    />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik03">
                                                <Form.Label>{t("Active")}</Form.Label>
                                                <div>
                                                <Switch checked={values.enabled} disabled={isSubmitting} onChange={(enabled) => {
                                                        setFieldValue("enabled", enabled)
                                                    }}/>
                                                </div>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.enabled}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {values.isGlobalRunner && <div className='text-center mb-3'>
                                            <div><Badge color="primary">{t("Runner global")}</Badge></div>
                                            <div><span className='text-orange'>{t("Vous ne pouvez pas assigner des cartes à un runner global")}</span></div>
                                        </div>}
                                   {!values.isGlobalRunner && <>
                                        <div> <Form.Label>{t("Cartes sélectionnées")}</Form.Label></div>
                                        {values.mapsPreloaded.map(mapSelected => 
                                        {
                                            const tenant = mapSelected.tenant;
                                            const mapName = allMaps.find(m => m.tenant == mapSelected.tenant && m.mapId == mapSelected.mapId)?.mapName;
                                            return <Badge className='m-1' key={mapSelected.tenant + mapSelected.mapId} color="primary">
                                                    {`${tenant} - ${mapName}`} 
                                                    <Button size='sm' className='ms-1' variant='primary' onClick={() => setFieldValue("mapsPreloaded", values.mapsPreloaded.filter(m => m.tenant != tenant || m.mapId != mapSelected.mapId))}>
                                                        <FontAwesomeIcon icon={["fas", "xmark"]} />
                                                    </Button>
                                                </Badge>
                                        })}

                                        {values.mapsPreloaded.length == 0 && <Badge className='m-1' bg="secondary">{t("Aucune carte définie")}</Badge>}

                                        <Row>


                                        <div>
                                        <TableWithCrudComponent 
                                        entities={allMaps} 
                                        columns={getColumns()}
                                        addDefaultActionsColumn={false}
                                        hideIdColumn={true}
                                        loadingForm={false}
                                        loadingList={false}
                                        translations={{
                                            tableTitle: t("Cartes préchargées"),
                                            noEntityText: t("Aucune carte"),
                                            deleteText: (entity) => (""),
                                            deleteTitle: (entity) => (""),
                                            updateText: (entity) => (""),
                                        }}
                                        fieldSearchable={(entity) => [
                                            entity.id,
                                            entity.clientName,
                                            entity.mapName,
                                            entity.mapId,
                                            entity.tenant
                                        ]}
                                        />
                                        </div>


                                        </Row>
                                   </>}

                                    <div className='d-flex justify-content-center'>
                                        <ButtonFno disabled={loading} className="w-50 me-1" color='blue' onClick={onCancel}>{t('Retour')}</ButtonFno>
                                        <ButtonFno disabled={loading} className="w-50 ms-1" color='orange' loading={loading} onClick={handleSubmit}>{t('Valider')}</ButtonFno>
                                    </div>
                                </fieldset>
                            )
                    }}
                    </Formik> 
        </div>
    )
}

export default PickingRunnerUpdateForm
