import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ZoneDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

// Interface representing the state of the admin client map Zone store
export interface IAdminClientMapZoneStore {
  zones: ZoneDetails[];
  loadingList: boolean;
  loadingForm: boolean;
}

// Initial state for the admin client map Zone store
const initialState: IAdminClientMapZoneStore = {
  zones: [],
  loadingList: false,
  loadingForm: false,
};

// Create a slice for the admin client map Zone store
const adminClientMapZoneSlice = createSlice({
  name: "adminClientMapZoneSlice",
  initialState,
  reducers: {
    // Set the Zones in the state
    setZones: (state, action: PayloadAction<ZoneDetails[]>) => {
      state.zones = action.payload;
    },
    // Update a specific Zone in the state
    updateZone: (state, action: PayloadAction<ZoneDetails>) => {
      state.zones = state.zones.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new Zone to the state
    addZone: (state, action: PayloadAction<ZoneDetails>) => {
      state.zones.push(action.payload);
    },
    // remove an Zone
    deleteZone: (state, action: PayloadAction<string>) => {
      state.zones = state.zones.filter(m => m.id !== action.payload)
    },
    // Set the loadingList state
    setLoadingZoneList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingForm state
    setLoadingZoneForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.zones = [];
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

// Export the actions and reducer from the admin client map Zone slice
export const {
  actions: adminClientMapZoneActions,
  reducer: adminClientMapZoneReducer,
} = adminClientMapZoneSlice;
