import { useEffect, useRef } from "react";


/**
 * Hook to detect clicks outside a specified element and trigger a callback.
 * @param callback Function to execute when a click occurs outside the specified element.
 * @returns ref Reference to the specified HTMLDivElement.
 */
const useOutsideClick = (callback: () => void) => {
    // Create a reference to the specified HTMLDivElement
    const ref = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
      // Function to handle click outside the element
      const handleClickOutside = (event: MouseEvent) => {
        // Check if the element exists and if the clicked element is not within the specified element
        if (ref.current && !ref.current.contains(event.target as Node)) {
          // Execute the callback function
          callback();
        }
      };
  
      // Add event listener for mousedown event
      document.addEventListener('mousedown', handleClickOutside);
  
      // Clean up by removing event listener when component unmounts or callback changes
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [callback]); // Re-run effect if the callback function changes
  
    // Return reference to the specified HTMLDivElement
    return ref;
  };

  export default useOutsideClick;