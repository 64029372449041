import React from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import * as yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import { Formik, FormikHelpers } from 'formik';
import { ClientDetails } from 'backend/ApiAdminDefinition/data-contracts';



interface OwnProps {
    initialValues?: ClientDetails;
    loading: boolean;
    onSubmit: ((values: ClientFormInputs, formikHelpers: FormikHelpers<ClientFormInputs>) => void | Promise<any>) & ((values: ClientFormInputs, { setFieldError }: FormikHelpers<ClientFormInputs>) => Promise<void>);
    onCancel: () => void
 }

 export interface ClientFormInputs{
    id: number | undefined;
    name: string;
    siret: string;
    tenant: string;
    type: string;
}


/**
 * Form component for managing client data.
 * 
 * @param onSubmit Callback function for form submission.
 * @param onCancel Callback function for form cancellation.
 * @param loading Indicates if the form is currently loading.
 * @param initialValues Initial values for the form.
 * @returns The client form component.
 */
const ClientForm: React.FC<OwnProps> = ({onSubmit, onCancel, loading, initialValues = undefined}) => {
    const { t } = useTranslation([nameOf({ClientForm}), "TransverseError"], { useSuspense: false});

    const schema = yup.object().shape({
        name: yup.string().required(t("required", { ns:"TransverseError"}) as string),
        reference: yup.string().nullable(),
        /*languages: yup.array().required(t("required", { ns:"TransverseError"}) as string)
                            .min(1, t("required", { ns:"TransverseError"}) as string)*/
      });


    return (
        <div>
                <Formik
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    
                    initialValues={{
                        id: initialValues?.id ?? '',
                        name: initialValues?.name ?? '',
                        siret:initialValues?.siret ?? '',
                        tenant: initialValues?.tenant ?? '',
                        type: initialValues?.type ?? '',
                    } as ClientFormInputs}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        isSubmitting,
                        errors,
                    }) => {
                        return (
                            <fieldset disabled={isSubmitting}>
    
                                    <Row>
                                        <Col>
                                            <Form.Control type="hidden" name="id" value={values.id}></Form.Control>
                                            <Form.Group className='mb-3' controlId="validationFormik03">
                                                <Form.Label>{t("Nom du client")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isValid={touched.name && !errors.name}
                                                    isInvalid={!!errors.name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
    
                                            <Form.Group className='mb-3' controlId="validationFormik05">
                                                <Form.Label>{t("Tenant (Nom BDD)")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="tenant"
                                                    disabled={!!initialValues?.id}
                                                    value={values.tenant}
                                                    onChange={handleChange}
                                                    isValid={touched.tenant && !errors.tenant}
                                                    isInvalid={!!errors.tenant}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.tenant}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik04">
                                                <Form.Label>{t("Type")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="type"
                                                    value={values.type}
                                                    onChange={handleChange}
                                                    isValid={touched.type && !errors.type}
                                                    isInvalid={!!errors.type}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.type}
                                                </Form.Control.Feedback>
                                            </Form.Group>
    
                                            <Form.Group className='mb-3' controlId="validationFormik06">
                                                <Form.Label>{t("Siret")}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="siret"
                                                    value={values.siret}
                                                    onChange={handleChange}
                                                    isValid={touched.siret && !errors.siret}
                                                    isInvalid={!!errors.siret}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.siret}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                   
    
                                  
    
    
                                    <div className='d-flex justify-content-center'>
                                        <ButtonFno className="w-50 me-1" color='blue' onClick={onCancel}>{t('Retour')}</ButtonFno>
                                        <ButtonFno className="w-50 ms-1" color='orange' loading={loading} onClick={handleSubmit}>{t('Valider')}</ButtonFno>
                                    </div>
                                </fieldset>
                            )
                    }}
                    </Formik> 
        </div>
    )
}

export default ClientForm
