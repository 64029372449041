import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientPermissionActions } from "store/reducers/Admin/ClientPermission/adminClientPermissionSlice";
import apiAdminDefinition from "backend/apiAdminDefinition";


/**
 * Load current selected client's permissions
 * @returns 
 */
export const loadClientPermissionsThunk = (): AppThunk => {
    return async (dispatch, getState) => {
        const selectedClient = getState().userProfil.currentTenant;

        if(!selectedClient) {
            toast.warn(i18next.t("Impossible de récupérer les permissions car aucun client n'est sélectionné"))
            return;
        }

       try{

            dispatch(adminClientPermissionActions.setLoadingPermissionList(true));
            const permissions = await apiAdminDefinition.permission.clientPermissionsList();
            dispatch(adminClientPermissionActions.setPermissions(permissions.data ?? []));
            
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des permissions du client"))
        }
        dispatch(adminClientPermissionActions.setLoadingPermissionList(false));
    }
}