import { apiVusion } from 'backend/ApiVusion/apiVusion';
import Item from 'backend/ApiVusion/models/Item';
import ButtonFno from 'components/inputs/ButtonFno';
import Loading from 'components/Loading';
import { Asset } from 'fno-sdk';
import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import { locationCodeUsed } from './TimcodeDemoData';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


interface Props {
    asset: Asset
}


const AssetSelectedComponent: React.FC<Props> = ({asset}) => {
   const { t } = useTranslation(nameOf({AssetSelectedComponent}), { useSuspense: false});
   const [vusionItem, setVusionItem] = useState<Item[]>([]);
   const [loadingVusion, setLoadingVusion] = useState<boolean>(false);
   const [loadingFlash, setLoadingFlash] = useState<boolean>(false);
   const vusionApi = new apiVusion("44c65e513c0d48f7b8a8350ccd83e018","findnorder_fr.vlab");
   const locationCodeFound = locationCodeUsed.find(x => x.locationCode == asset.locationCode);


   useEffect(() => {
    
    if(locationCodeFound)
    {
        setLoadingVusion(true);
        vusionApi.getLabelById(locationCodeFound.vusionTagId )
            .then((data) => {
                setVusionItem(data.matching.items);
            }).catch(() => setVusionItem([]))
            .finally(() => setLoadingVusion(false));
    }


   }, [asset])

   const flashItem = (labelId: string) => {
        setLoadingFlash(true);
        vusionApi.flashItem(labelId)
        .then((data) => {})
        .catch(() => {})
        .finally(() => {
            setLoadingFlash(false);
            toast.success(<><FontAwesomeIcon icon={["fas", "lightbulb"]} /> {`L'étiquette ${labelId} clignotte`}</>)
        });
    }


   return (
       <>
           <h4 className='text-center'>{t("Informations emplacement")}</h4>
            <Row>
                <Col className='text-end'>{t("Code emplacement")}</Col>
                <Col>{asset.locationCode}</Col>
            </Row>
            {locationCodeFound && <Row>
                <Col className='text-end'>{t("Code étiquette")}</Col>
                <Col>{locationCodeFound.vusionTagId}</Col>
            </Row>}

            {loadingVusion && <div className='text-center mt-3'><Loading inline size={20}/></div>}
            {!loadingVusion && vusionItem.map((item) => {
                return <div className='mt-3'>
                 <Row>
                    <Col className='text-end'>{t("Produit")}</Col>
                    <Col>{item.name ?? "N/C"}</Col>
                </Row>

                <Row>
                    <Col className='text-end'>{t("Description")}</Col>
                    <Col>{item.description ?? "N/C"}</Col>
                </Row>
                </div>
            })}
            {!loadingVusion && vusionItem.length == 0 && <div className='text-center mt-3'>{t("Pas de produit")}</div>}
           

            {/*<Row>
                <Col className='text-end'>{t("Prix")}</Col>
                <Col>{vusionItem?.price ?? "N/C"}</Col>
            </Row>*/}


            {locationCodeFound && <Row>
                <Col><ButtonFno className='w-100 mt-3' loading={loadingFlash} onClick={() => flashItem(locationCodeFound.vusionTagId)}>{t("Faire clignoter")}</ButtonFno></Col>
            </Row>}

       </>
   )
}
export default AssetSelectedComponent