/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, MapSettingCreate, MapSettingDetails, MapSettingUpdate, Operation } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MapSetting<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags MapSetting
     * @name SettingsList
     * @summary [🤵 OR 🔑] Get all map settings for current client. [🔒: map_read].
     * @request GET:/map/{mapId}/settings
     * @secure
     */
    settingsList = (mapId: string, params: RequestParams = {}) =>
        this.request<MapSettingDetails[], void>({
            path: `/map/${mapId}/settings`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapSetting
     * @name SettingPost
     * @summary [🤵 OR 🔑] Create new map setting. [🔒: map_create].
     * @request POST:/map/{mapId}/setting
     * @secure
     */
    settingPost = (mapId: string, data: MapSettingCreate, params: RequestParams = {}) =>
        this.request<MapSettingDetails, void>({
            path: `/map/${mapId}/setting`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapSetting
     * @name SettingPut
     * @summary [🤵 OR 🔑] Update all fields of a map setting. [🔒: map_update].
     * @request PUT:/map/{mapId}/setting/{settingId}
     * @secure
     */
    settingPut = (mapId: string, settingId: string, data: MapSettingUpdate, params: RequestParams = {}) =>
        this.request<MapSettingDetails, void>({
            path: `/map/${mapId}/setting/${settingId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapSetting
     * @name SettingPatch
     * @summary [🤵 OR 🔑] Update specific field of a map setting. [🔒: map_update].
     * @request PATCH:/map/{mapId}/setting/{settingId}
     * @secure
     */
    settingPatch = (mapId: string, settingId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<MapSettingDetails, void>({
            path: `/map/${mapId}/setting/${settingId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapSetting
     * @name SettingDelete
     * @summary [🤵 OR 🔑] Delete a map setting by its ID. [🔒: map_update].
     * @request DELETE:/map/{mapId}/setting/{settingId}
     * @secure
     */
    settingDelete = (mapId: string, settingId: string, params: RequestParams = {}) =>
        this.request<void, ApiErrorsResponse | void>({
            path: `/map/${mapId}/setting/${settingId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
