import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/PageHeader';
import nameOf from 'utility/nameOf';
import { useAppSelector } from 'store/store'
import Loading from 'components/Loading';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonFno from 'components/inputs/ButtonFno';
import { ClientDetails } from 'backend/ApiAdminDefinition/data-contracts';

const AdminClients: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClients}), { useSuspense: false});
    const navigate = useNavigate();

    const loadingAllClients = useAppSelector(state => state.adminClient.loadingAllClients);
    const allClients = useAppSelector(state => state.adminClient.allClients);


    useEffect(() => {
        document.title = t('[Admin] Liste clients');
    }, [])

    const columns: TableColumn<ClientDetails>[] = [
        {
            id:'name',
            name: t('Nom'),
            sortable: true,
            selector: row => row.name.toLocaleLowerCase(),
        },
        {
            id:'tenant',
            name: t('Réf (tenant)'),
            sortable: true,
            selector: row => row.tenant,
        },
        {
            id:'maps',
            name: t('Nb cartes'),
            sortable: true,
            selector: row => row.maps.length,
        },
        {
            id:'type',
            name: t('Type'),
            sortable: true,
            selector: row => row.type ?? "",
        },
        {
            name:<div>{t('Actions')}</div>,
            center:true,
            width:"150px",
            cell: (row, index, column, id) =>{
                return <div className='d-flex'>
                    <Button variant="success" size='sm' onClick={() => navigate(ROUTES.ADMIN.CLIENT(row.id.toString()).MAPS)}>{t("Détails")} <FontAwesomeIcon icon={["fas", "arrow-right"]}/></Button>
                </div>
            }
        }
    ];




    return (
        <div>
            
             <PageHeader title={t("Clients")} admin/>
            <div className='table-component'>
                <DataTable 
                    columns={columns} 
                    data={allClients} 
                    defaultSortAsc={true} 
                    progressPending={allClients.length == 0 && loadingAllClients}
                    progressComponent={<Loading text={t("Chargement")}/>}
                    defaultSortFieldId={'name'} 
                    striped={true}
                    subHeader={true}
                    highlightOnHover={true}
                    noDataComponent={<div className='w-100 text-center p-5'>{t("Aucun client disponible")}</div>}
                    subHeaderComponent={<> 
                        <h5 className='text-uppercase'> {t("Les clients") + " ("+allClients.length+")"} {loadingAllClients && <Loading inline size={20}/>}</h5>
                        <div className='search-panel'>
                            <ButtonFno sm className='me-2' onClick={() => navigate(ROUTES.ADMIN.CLIENT_CREATE)}><FontAwesomeIcon className='me-2' icon={["fas", "plus"]} /> {t("Créer un client")}</ButtonFno>
                            {/*<input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>*/}
                        </div>
                        </>}
                    sortServer={false}/>
            </div>
        </div>
    )
}

export default AdminClients
