import React from 'react'
import { useTranslation } from 'react-i18next';
import currentUserIsAdmin from 'backend/utils/currentUserIsAdmin';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store'
import nameOf from 'utility/nameOf';
import { Navigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import AuditsList from 'components/Audit/AuditsList';
import checkPermissions from 'utility/checkPermissions';
import { PermissionsEnum } from 'backend/ApiBackofficeDefinition/data-contracts';

const AuditDashboard: React.FC = () => {
    const { t } = useTranslation(nameOf({AuditDashboard}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(state => state.userProfil.currentUser);
    const currentMap = useAppSelector(state => state.map.currentMap);
    const allMaps = useAppSelector(state => state.map.maps);
    const tenantSelected = useAppSelector(state => state.userProfil.currentTenant);

    return (
        <div>
             <PageHeader title={t("Audit Dashboard")}/>
            <div>
                {/*<Navigate to={ROUTES.AUDIT_RAW_DATA} replace={true} />*/}
                <AuditsList
                    showCheckbox 
                    allMaps={allMaps}
                    mapId={currentMap?.id ?? ""}
                    clientId={tenantSelected?.id.toString() ?? ""}/>
            </div>
        </div>
    )
}

export default AuditDashboard
