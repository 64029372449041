import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/PageHeader';
import nameOf from 'utility/nameOf';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from 'store/store'
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import { Formik, FormikHelpers } from 'formik';
import Section from 'components/Section';
import ClientForm, { ClientFormInputs } from 'components/Admin/forms/ClientForm';
import { createClientAdminThunk } from 'store/reducers/Admin/Client/thrunks/createClientAdminThunk';
import { ClientCreate } from 'backend/ApiAdminDefinition/data-contracts';
import { toast } from 'react-toastify';
import parseApiErrorSetFieldError from 'utility/parseApiErrorSetFieldError';


const AdminClientCreate: React.FC = () => {
    const { t } = useTranslation([nameOf({AdminClientCreate}), "TransverseError"], { useSuspense: false});
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const loadingUpsert = useAppSelector(state => state.adminClient.loadingUpsert);

    useEffect(() => {
        document.title = t('[Admin] Créer un client');
    }, [])

    const onSubmit = async (values: ClientFormInputs, {setFieldError}:FormikHelpers<ClientFormInputs>) => {

        dispatch(createClientAdminThunk({
            id: values.id,
            name: values.name,
            siret: values.siret,
            tenant: values.tenant,
            type: values.type
        } as ClientCreate,
            {
                onSuccess: (entityCreated) => {
                    toast.success(t('Client créé'))
                    navigate(ROUTES.ADMIN.CLIENT(entityCreated.id.toString()).DETAILS)
                },
                onError: (apiResponse) => {
                    parseApiErrorSetFieldError(apiResponse, setFieldError);
                }
            }))
    };

    const schema = yup.object().shape({
        name: yup.string().required(t("required", { ns:"TransverseError"}) as string),
        tenant: yup.string().required(t("required", { ns:"TransverseError"}) as string)
      });


    return (
        <div>
            
             <PageHeader title={t("Nouveau client")} admin/>
            <Section>
                <ClientForm loading={loadingUpsert} onSubmit={onSubmit} onCancel={() => navigate(ROUTES.ADMIN.CLIENTS)} />
            </Section>
                
        </div>
    )
}

export default AdminClientCreate
