import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientPickingActions } from "../adminClientPickingSlice";
import apiAdminDefinition from "backend/apiAdminDefinition";


/**
 * Load current selected client's runner
 * @returns 
 */
export const loadPickingRunnersThunk = (): AppThunk => {
    return async (dispatch, getState) => {
       try{

            dispatch(adminClientPickingActions.setLoading(true));
            const clientRunners = await apiAdminDefinition.pickingRunner.pickingRunnersList()
            dispatch(adminClientPickingActions.setRunners(clientRunners.data));
            
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des runners"))
        }
        dispatch(adminClientPickingActions.setLoading(false));
    }
}