import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'components/Loading';
import Sdk from 'fno-sdk';
import React, { useEffect, useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap';



interface OwnProps { 
    sdk: Sdk;
    assetId: string;
}



const LocationCodeForm: React.FC<OwnProps> = ({sdk, assetId}) => {

    const [locationCode, setLocationCode] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if(assetId){
            setLoading(true)

            sdk.asset.getAssetDataByIds([assetId])
                .then((asset) => setLocationCode(asset.assets[0]?.locationCode))
                .finally(() => setLoading(false));

        } 
    }, [assetId])


    async function saveLocationCode(){
        setLoading(true)
        sdk.asset.updateLocationCode(assetId, locationCode)
            .then(() => {
                //TODO update location code to unity
                sdk.asset.setLocalAssetLocationCode(assetId, locationCode)
            })
            .finally(() => setLoading(false));
    }


    return (
        <div className='mt-3'>
            <InputGroup className="mb-3">
                <Form.Control
                    placeholder="Location code"
                    value={locationCode}
                    disabled={loading}
                    onChange={(e) => setLocationCode(e.target.value)}
                    aria-label="Location code"
                    aria-describedby="basic-addon2"
                />
                <Button disabled={loading} variant="outline-success" id="button-addon2" onClick={saveLocationCode}>
                    {loading ? <Loading inline size={20}/> : <><FontAwesomeIcon icon={["fas", "save"]} /></>}
                </Button>
            </InputGroup>
        </div>
    )
}

export default LocationCodeForm
