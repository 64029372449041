import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from 'components/Loading';
import { ROUTES } from 'resources/routes-constants';
import { useAppDispatch, useAppSelector } from 'store/store'
import { selectTenantForAdminThunk } from 'store/reducers/Admin/thrunks/selectTenantForAdminThunk';
import nameOf from 'utility/nameOf';
import "./LayoutClient.scss"

type Props = {
    children?: React.ReactNode
  };

const LayoutClient: React.FC<Props> = ({children}) => {
    const { t } = useTranslation(nameOf({LayoutClient}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const { clientId } = useParams();
    // tenant are already loaded if we are in this layout
    const allClients = useAppSelector(state => state.adminClient.allClients);
    const selectedClient = useAppSelector(state => state.userProfil.currentTenant);
    const loadingClient = useAppSelector(state => state.adminClient.loadingAllClients);
    const navigate = useNavigate();
    
    useEffect(() => {
        
        // remove current client if user quit client section pages
        return () => {
            dispatch(selectTenantForAdminThunk(undefined));
        }
    }, [])


    useEffect(() => {
        // if client id in url change, set new current client object
        if(clientId)
        {
            const tenantFound = allClients.find((c) => c.id == parseInt(clientId));
            if(!tenantFound) {
                toast.warn(t("Le client n'existe pas"));
                navigate(ROUTES.ADMIN.CLIENTS);
            }
            else
            {
                dispatch(selectTenantForAdminThunk(tenantFound));
            }
        }
    }, [clientId])


    return (<div> 
        {selectedClient && children}
        {!selectedClient && <Loading fullScreen text={t("Chargement du client")}/>}
    </div>)
}

export default LayoutClient
