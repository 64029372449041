/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HeatMapPathCreate, HeatMapPathDetails } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class HeatMapPath<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags HeatMapPath
     * @name HeatmapPathsList
     * @summary [🤵 OR 🔑] Get heatmap paths. [🔒: map_read].
     * @request GET:/map/{mapId}/heatmap/paths
     * @secure
     */
    heatmapPathsList = (
        mapId: string,
        query: {
            /** @format date-time */
            from: string
            /** @format date-time */
            to: string
            /** Type of paths to return */
            category?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<HeatMapPathDetails[], void>({
            path: `/map/${mapId}/heatmap/paths`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags HeatMapPath
     * @name HeatmapPathsPost
     * @summary [🤵 OR 🔑] Create several heatmap paths. [🔒: map_create].
     * @request POST:/map/{mapId}/heatmap/paths
     * @secure
     */
    heatmapPathsPost = (mapId: string, data: HeatMapPathCreate[], params: RequestParams = {}) =>
        this.request<HeatMapPathDetails[], void>({
            path: `/map/${mapId}/heatmap/paths`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags HeatMapPath
     * @name HeatmapPathPost
     * @summary [🤵 OR 🔑] Create new heatmap path. [🔒: map_create].
     * @request POST:/map/{mapId}/heatmap/path
     * @secure
     */
    heatmapPathPost = (mapId: string, data: HeatMapPathCreate, params: RequestParams = {}) =>
        this.request<HeatMapPathDetails, void>({
            path: `/map/${mapId}/heatmap/path`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags HeatMapPath
     * @name HeatmapPathDelete
     * @summary [🤵 OR 🔑] Delete heatmap path. [🔒: map_update].
     * @request DELETE:/map/{mapId}/heatmap/path/{heatmapPathId}
     * @secure
     */
    heatmapPathDelete = (mapId: string, heatmapPathId: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/map/${mapId}/heatmap/path/${heatmapPathId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
