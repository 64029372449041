/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, FloorCreate, FloorDetails, FloorUpdate, Operation } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MapFloor<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags MapFloor
     * @name FloorsList
     * @summary [🤵 OR 🔑] Get all floors for a specific map. [🔒: map_read].
     * @request GET:/map/{mapId}/floors
     * @secure
     */
    floorsList = (mapId: string, params: RequestParams = {}) =>
        this.request<FloorDetails[], void>({
            path: `/map/${mapId}/floors`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapFloor
     * @name FloorsPost
     * @summary [🤵 OR 🔑] Create several map floors associated with a specific map or building. [🔒: map_create].
     * @request POST:/map/{mapId}/floors
     * @secure
     */
    floorsPost = (mapId: string, data: FloorCreate[], params: RequestParams = {}) =>
        this.request<FloorDetails[], void>({
            path: `/map/${mapId}/floors`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapFloor
     * @name FloorsDelete
     * @summary [🤵 OR 🔑] [🔒: map_update].
     * @request DELETE:/map/{mapId}/floors
     * @secure
     */
    floorsDelete = (mapId: string, data: string[], params: RequestParams = {}) =>
        this.request<any, ApiErrorsResponse | void>({
            path: `/map/${mapId}/floors`,
            method: 'DELETE',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        })
    /**
     * No description
     *
     * @tags MapFloor
     * @name FloorPost
     * @summary [🤵 OR 🔑] Create a new map floor associated with a specific map or building. [🔒: map_create].
     * @request POST:/map/{mapId}/floor
     * @secure
     */
    floorPost = (mapId: string, data: FloorCreate, params: RequestParams = {}) =>
        this.request<FloorDetails, void>({
            path: `/map/${mapId}/floor`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapFloor
     * @name FloorPut
     * @summary [🤵 OR 🔑] Update all fields of a map floor associated with a specific map or building. [🔒: map_update].
     * @request PUT:/map/{mapId}/floor/{floorId}
     * @secure
     */
    floorPut = (mapId: string, floorId: string, data: FloorUpdate, params: RequestParams = {}) =>
        this.request<FloorDetails, void>({
            path: `/map/${mapId}/floor/${floorId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapFloor
     * @name FloorPatch
     * @summary [🤵 OR 🔑] Update specific fields of a map floor associated with a specific map or building using a JSON Patch document. [🔒: map_update].
     * @request PATCH:/map/{mapId}/floor/{floorId}
     * @secure
     */
    floorPatch = (mapId: string, floorId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<FloorDetails, void>({
            path: `/map/${mapId}/floor/${floorId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapFloor
     * @name FloorDelete
     * @summary [🤵 OR 🔑] Delete a specific map floor associated with a map. [🔒: map_update].
     * @request DELETE:/map/{mapId}/floor/{floorId}
     * @secure
     */
    floorDelete = (mapId: string, floorId: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/map/${mapId}/floor/${floorId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
