import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import Switch from "react-switch";
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import { ROUTES } from 'resources/routes-constants';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { Badge, Button, Modal} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateTokenEnableThunk } from 'store/reducers/Admin/ClientToken/thrunks/updateTokenEnableThunk';
import ButtonFno from 'components/inputs/ButtonFno';
import "./AdminClientApiTokens.scss"
import TokenForm, { ITokenFormInputs } from 'components/Admin/forms/TokenForm';
import { createTokenThunk } from 'store/reducers/Admin/ClientToken/thrunks/createTokenThunk';
import { adminClientTokenActions } from 'store/reducers/Admin/ClientToken/adminClientTokenSlice';
import { updateTokenThunk } from 'store/reducers/Admin/ClientToken/thrunks/updateTokenThunk';
import { TokenCreate, TokenDetails } from 'backend/ApiAdminDefinition/data-contracts';
import parseApiErrorSetFieldError from 'utility/parseApiErrorSetFieldError';

const AdminClientApiTokens: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientApiTokens}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const selectedClient = useAppSelector(state => state.userProfil.currentTenant);
    const loading = useAppSelector(state => state.adminClientToken.loadingList);
    const tokens = useAppSelector(state => state.adminClientToken.tokens);
    const maps = useAppSelector(state => state.adminClientMap.maps);
    const selectedToken = useAppSelector(state => state.adminClientToken.selectedToken);
    const showCreateTokenModal = useAppSelector(state => state.adminClientToken.showCreateTokenModal);
    const { clientId } = useParams();
    

    useEffect(() => {
        document.title = t('[Admin] Clés API du client');
    }, [])


    if(!selectedClient)
    {
        return <Navigate to={ROUTES.ADMIN.CLIENTS} replace />
    }


    const copyToClipboard = (tokenData: TokenDetails) => {
        navigator.clipboard.writeText(tokenData.value);
        toast.success(<>
            <div>{t("Clé API copié")}</div>
            <div>{tokenData.name}</div>
        </>)
    }


    const onSaveToken = (values: ITokenFormInputs, setFieldError:(field: string, message: string | undefined) => void) => {
        if(values.id){
            dispatch(updateTokenThunk(values as unknown as TokenDetails, {
                onSuccess: (token) => {
                    toast.success(t(`Le token a bien été modifié`) + ` ("${token.name}")`)
                },
                onError: (errors) => {
                    parseApiErrorSetFieldError(errors, setFieldError);
                    toast.error(t("Impossible de modifier le token"));
                }
            }));
        }
        else {
            dispatch(createTokenThunk(values as unknown as TokenCreate, {
                onSuccess: (token) => {
                    toast.success(t("Token créé"));
                },
                onError: (errors) => {
                    parseApiErrorSetFieldError(errors, setFieldError);
                    toast.error(t("Impossible de créer le token"));
                }
            }));
        }
    }


    const onToggleEnable = (tokenId: string, enabled: boolean) => {
        dispatch(updateTokenEnableThunk(tokenId, enabled,{
            onSuccess: (token) => {
                if(token) toast.success(token.active ? t("La clé API à bien été activée") : t("La clé API à bien été désactivée"))
            },
            onError: (errors) => {
                toast.error(t("Impossible de modifier le token"))
            }
        }))
    }


    const getColumns = () => {
        const columns: TableColumn<TokenDetails>[] = [
            {
                id:'enabled',
                name: t('Active'),
                sortable: true,
                center: true,
                width:"100px",
                selector: row => row.active,
                cell:(row, index, column, id) => {
                    return <><Switch checked={row.active ? row.active : false} onChange={(enabled) => onToggleEnable(row.id, enabled)}/></>
                }
            },
            {
                id:'name',
                name: t('Nom'),
                sortable: true,
                width:"auto",
                selector: row => row.name
            },
            {
                id:'permissions',
                name: t('Permissions'),
                sortable: false,
                cell:(row, index, column, id) => {
                    let cpt = 0;
                    _.forOwn(row.permissions, function(value, key) { if(value) cpt++;} );

                    return <div>{cpt}</div>
                }
            },
            {
                id:'maps',
                name: t('Lieux associés'),
                sortable: true,
                selector: row => _.join(row.maps.map(mapId => maps.find(m => m.id == mapId)?.name), ' '),
                cell:(row, index, column, id) => {
                    return <div>
                    {row.maps.map((mapId) => {
                        return <Badge key={mapId} bg="success" className="me-2">{maps.find(m => m.id == mapId)?.name}</Badge>
                    })}

                        <Button size="sm" variant='link' className='p-0' title={t("Modifier les lieux du token")} onClick={() => dispatch(adminClientTokenActions.setSelectedToken(row))}><FontAwesomeIcon icon={['fas', 'edit']} size="sm"/></Button>
                    </div>
                }
            },
            {
                id:'token',
                name: t('Clé'),
                width:"100px",
                sortable: false,
                cell:(row, index, column, id) => {
                    return <><Button size="sm" variant='success' onClick={() => copyToClipboard(row)} title={t("Copier la clé dans le presse papier")}><FontAwesomeIcon icon={['fas', 'copy']} size="sm"/></Button></>
                }
            },
        ];

        return columns;
    };


    const onHideModal = () => {
        dispatch(adminClientTokenActions.setShowCreateTokenModal(false)); 
        dispatch(adminClientTokenActions.setSelectedToken(undefined));
    }



    return (
        <div id="admin-client-api-tokens">
             <PageHeader title={t("Clés API")} admin middlePart={[
                {to: ROUTES.ADMIN.CLIENTS, title: t("Clients")},
                {to: ROUTES.ADMIN.CLIENT(clientId).DETAILS, title: selectedClient.name}]}/>
            <div>
                <div className='table-component'>
               
                    <DataTable 
                        columns={getColumns()} 
                        data={tokens} 
                        progressPending={loading}
                        progressComponent={<Loading text={t("Chargement")}/>}
                        defaultSortFieldId={'id'} 
                        defaultSortAsc={false} 
                        striped={true}
                        subHeader={true}
                        highlightOnHover={true}
                        noDataComponent={<div className='w-100 text-center p-5'>{t("Aucun token disponible")}</div>}
                        subHeaderComponent={<> 
                            <h5 className='text-uppercase'> {t("Clés API") + " ("+tokens.length+")"} {loading && <Loading inline size={20}/>}</h5>
                            <div className='search-panel'>
                                <ButtonFno sm className='me-2' onClick={() => dispatch(adminClientTokenActions.setShowCreateTokenModal(true))}><FontAwesomeIcon className='me-2' icon={["fas", "plus"]} /> {t("Nouvelle clé API")}</ButtonFno>
                                {/*<input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>*/}
                            </div>
                            </>}
                        sortServer={false}/>
                </div>
            </div>


            {(showCreateTokenModal || selectedToken) && <Modal dialogClassName='modal-fno' show={true} onHide={onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedToken ? t("Modifier une clé API") : t("Créer une nouvelle clé API")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TokenForm onClose={onHideModal} onSubmit={onSaveToken} initialValues={selectedToken}/>
                </Modal.Body>
            </Modal>}

        </div>
    )
}

export default AdminClientApiTokens
