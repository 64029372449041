
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OptimizationBatchDetails, PathDetailsModel, ProcessedProductDetails, RequestClientDetails, RequestRawProductDetails, RequestStageDetails, KeyValuePairStringString } from 'backend/ApiMiddlewareDefinition/data-contracts';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react'
import { Accordion, Badge, Col, Row, Table } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import AceEditor from "react-ace-builds";
import "react-ace-builds/webpack-resolver-min";
import { useAppSelector } from 'store/store';
import apiMiddlewareDefinition from 'backend/apiMiddlewareDefinition';
import MiddlewareBatchTable from './MiddlewareBatchTable';
import LogsListComponent from 'components/Middleware/LogsListComponent';


interface OwnProps {
    request: RequestClientDetails
 }

 /**
  * Contains all data of a batch for view purpose
  */
interface BatchResultModel{
    batchId: string;
    batch: OptimizationBatchDetails;
    pathDetailsClient: PathDetailsModel | undefined;
    pathDetailsOptimized: PathDetailsModel | undefined;
}

/**
 * MiddlewareRequestDetails is a React functional component that displays the details of a middleware request.
 * It fetches the request data from the backend and displays it in an Accordion component.
 * 
 * @param {OwnProps} props - The props object containing the request data to display.
 * @param {RequestClientDetails} props.request - The request data to display.
 * 
 * @returns {JSX.Element} The JSX element representing the component.
 */
const MiddlewareRequestDetails: React.FC<OwnProps> = ({request}) => {
   const { t } = useTranslation(nameOf({MiddlewareRequestDetails}), { useSuspense: false});
   const {
    selectedMiddleware,
} = useAppSelector(state => state.adminMiddlewareRequest);

    const [batchResults, setBatchResults] = useState<BatchResultModel[]>([]);
    const [processedProducts, setProcessedProducts] = useState<ProcessedProductDetails[]>([]);
    const [requestRawProducts, setRequestRawProducts] = useState<RequestRawProductDetails[]>([]);

    const originalRequestRef = useRef<AceEditor | null>();
    const optimizedRequestRef = useRef<AceEditor | null>();

useEffect(() => {
    
    return () => {
        setBatchResults([]);
        setProcessedProducts([]);
    }
    
   }, [])

   useEffect(() => {
    if(selectedMiddleware)
    {
        loadPathsDetails(request);
    }
    
   }, [request, selectedMiddleware])



   async function loadPathsDetails(request: RequestClientDetails){
    if(selectedMiddleware){

        const allProducts = await apiMiddlewareDefinition(selectedMiddleware.baseUrl, selectedMiddleware.clientTenant).requests.getRequestProcessedProducts(request.mapId ,request.id);
        console.log("allProducts", allProducts)
        setProcessedProducts(allProducts.data);

        const allRawProducts = await apiMiddlewareDefinition(selectedMiddleware.baseUrl, selectedMiddleware.clientTenant).requests.getRequestRawProducts(request.mapId ,request.id);
        console.log("allRawProducts", allRawProducts)
        setRequestRawProducts(allRawProducts.data);

        const batchs = await apiMiddlewareDefinition(selectedMiddleware.baseUrl, selectedMiddleware.clientTenant).requests.getRequestBatchs(request.mapId ,request.id);
        console.log("batchs", batchs.data)

        const pathDetailsOriginalsRequests = batchs.data.map(batch => {
            return apiMiddlewareDefinition(selectedMiddleware.baseUrl, selectedMiddleware.clientTenant).requests.getRequestBatchPathsOriginal(request.mapId, batch.id)
        })
        const pathDetailsOriginalsResult = await Promise.all(pathDetailsOriginalsRequests) 
        console.log("pathDetailsOriginalsResult", pathDetailsOriginalsResult)

        const pathDetailsOptimizedRequests = batchs.data.map(batch => {
            return apiMiddlewareDefinition(selectedMiddleware.baseUrl, selectedMiddleware.clientTenant).requests.getRequestBatchPathsOptimized(request.mapId, batch.id)
        })
        const pathDetailsOptimizedResults = await Promise.all(pathDetailsOptimizedRequests) 
        console.log("pathDetailsOptimizedResults", pathDetailsOptimizedResults)

        
        const batchResults: BatchResultModel[] = [];
        batchs.data.forEach((batch, index) => {
            batchResults.push({
                batchId: batch.id,
                batch: batch,
                pathDetailsClient: pathDetailsOriginalsResult[index].data.find(m => m.optimizationBatchId == batch.id) ?? undefined,
                pathDetailsOptimized: pathDetailsOptimizedResults[index].data.find(m => m.optimizationBatchId == batch.id) ?? undefined
            })
        });
        console.log("batchResults", batchResults)
        setBatchResults(batchResults);
    }
   }

    const columnsHeader: TableColumn<KeyValuePairStringString>[] = [
        {
            id:'key',
            name: t('Key'),
            selector: row => row.key ?? "/",
        },
        {
            id:'value',
            name: t('Value'),
            cell: row => <span >{row.value ?? ""}</span>,

        }
    ];

   return (
       <div>
            <Accordion id="requestDetails" defaultActiveKey={"logs"}>
                <Accordion.Item key={"logs"} eventKey={"logs"} >
                        <Accordion.Header >
                                <FontAwesomeIcon icon={["fas", "bars"]} /> 
                                <span className='ms-2'>{t('Logs') + " (" + request.logs.length + ")"}</span>
                            </Accordion.Header>
                        <Accordion.Body>
                            <LogsListComponent logs={request.logs} />
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item key={"requests"} eventKey={"requests"} >
                        <Accordion.Header >
                                <FontAwesomeIcon icon={["fas", "file-code"]} /> 
                                <FontAwesomeIcon className='ms-2' icon={["fas", "right-to-bracket"]} /> 
                                <span className='ms-2'>{t('Requêtes JSON')}</span>
                            </Accordion.Header>
                        <Accordion.Body>
                           
                           <Row>
                            <Col>
                            <h5>{t('Requête Originale')}</h5>
                            <AceEditor
                                ref={(ref) => originalRequestRef.current = ref}
                                width='100%'
                                mode="json"
                                readOnly
                                theme="monokai"
                                name="jsonEditor"
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={JSON.stringify(request.rawData, null, "\t")}
                                onScroll={(editor) => {
                                    //@ts-ignore
                                    const currentScroll = originalRequestRef.current.editor.session.getScrollTop();
                                    //@ts-ignore
                                    optimizedRequestRef.current.editor.session.setScrollTop(currentScroll);
                                }}

                                setOptions={{
                                    enableBasicAutocompletion: true,
                                    enableLiveAutocompletion: false,
                                    enableSnippets: false,
                                    useWorker: false,
                                    showLineNumbers: true,
                                    tabSize: 2,
                                }}/>
                            </Col>
                            <Col>
                            <h5>{t('Requête Optimisé FnO')}</h5>
                            <AceEditor
                                ref={(ref) => optimizedRequestRef.current = ref}
                                width='100%'
                                mode="json"
                                readOnly
                                theme="monokai"
                                name="jsonEditor"
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={JSON.stringify(request.resultData, null, "\t")}
                                onScroll={(editor) => {
                                    //@ts-ignore
                                    const currentScroll = optimizedRequestRef.current.editor.session.getScrollTop();
                                    //@ts-ignore
                                    originalRequestRef.current.editor.session.setScrollTop(currentScroll);
                                }}
                                
                                setOptions={{
                                    enableBasicAutocompletion: true,
                                    enableLiveAutocompletion: false,
                                    enableSnippets: false,
                                    useWorker: false,
                                    showLineNumbers: true,
                                    tabSize: 2,
                                }}/>
                            </Col>
                           </Row>
                           

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item key={"products"} eventKey={"products"} >
                        <Accordion.Header >
                                <FontAwesomeIcon icon={["fas", "barcode"]} /> 
                                <span className='ms-2'>{t('Produits') + " (" + request.totalProducts + ")"}</span>
                            </Accordion.Header>
                        <Accordion.Body>
                            {batchResults.map((result, index) => 
                           {

                            return <div>
                                {result.pathDetailsClient && <MiddlewareBatchTable batch={result.batch} 
                                                    pathDetailsClient={result.pathDetailsClient} 
                                                    pathDetailsOptimized={result.pathDetailsOptimized} 
                                                    processedProducts={processedProducts}
                                                    rawProducts={requestRawProducts}/>}

                                {!result.pathDetailsClient && <div>{t("Aucun path détails client")}</div>}
                            </div>
                           })}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item key={"headers"} eventKey={"headers"} >
                        <Accordion.Header >
                                <FontAwesomeIcon icon={["fas", "heading"]} /> 
                                <span className='ms-2'>{t('Headers') + " (" + request.headers.length + ")"}</span>
                            </Accordion.Header>
                        <Accordion.Body>
                            <DataTable 
                                columns={columnsHeader} 
                                data={request.headers} 
                                striped={true}
                                subHeader={false}
                                highlightOnHover={true}
                                noDataComponent={<div className='w-100 text-center p-5'>{t("Aucun header")}</div>}
                                sortServer={false}/>
                        </Accordion.Body>
                    </Accordion.Item>
            </Accordion>
       </div>
   )
}
export default MiddlewareRequestDetails