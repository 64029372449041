import React, { useEffect, useState } from 'react'
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { Badge} from 'react-bootstrap';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import ColorPicker from 'components/inputs/ColorPicker';
import { useAppDispatch, useAppSelector } from 'store/store';
import { updateMarkerTemplateThunk } from 'store/reducers/Admin/ClientMarkerTemplate/thrunks/updateMarkerTemplateThunk';
import { MarkerTemplateCreate, MarkerTemplateDetails, MarkerTemplateUpdate } from 'backend/ApiBackofficeDefinition/data-contracts';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { createMarkerTemplateThunk } from 'store/reducers/Admin/ClientMarkerTemplate/thrunks/createMarkerTemplateThunk';
import { deleteMarkerTemplateThrunks } from 'store/reducers/Admin/ClientMarkerTemplate/thrunks/deleteMarkerTemplateThrunks';
import { loadMarkerTemplatesThunks } from 'store/reducers/Admin/ClientMarkerTemplate/thrunks/loadMarkersTemplateThunks';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';

const AdminClientMapMarkerTemplate: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapMarkerTemplate}), { useSuspense: false});
    const dispatch = useAppDispatch();

    const {
        markerTemplates,
        loadingList,
        loadingForm
    } = useAppSelector(state => state.adminMapMarkerTemplate);

    const { clientId, mapId } = useParams();

    useEffect(() => {
        document.title = t('[Admin] Les templates de marker');
        if(clientId && mapId && mapId != "new")
        {
            dispatch(loadMarkerTemplatesThunks(mapId));
        }


    }, [mapId])

    
    const onUpdate = (marker: MarkerTemplateDetails, closeModal?: () => void) => {
        if(clientId && mapId)
        {
            dispatch(updateMarkerTemplateThunk(mapId, marker as MarkerTemplateUpdate,{
                onSuccess: (marker) => {
                    if(closeModal)closeModal();
                    toast.success(t("Le template a bien été modifié"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onCreate = (marker: MarkerTemplateDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(createMarkerTemplateThunk(mapId, marker as MarkerTemplateCreate,{
                onSuccess: (marker) => {
                    if(closeModal)closeModal();
                    toast.success(t("Le template a bien été créé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onDelete = (marker: MarkerTemplateDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(deleteMarkerTemplateThrunks(mapId, marker.id,{
                onSuccess: (success) => {
                    if(closeModal)closeModal();
                    toast.success(t("Le template a bien été supprimé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const columns: TableColumn<MarkerTemplateDetails>[] = [
        {
            id:'name',
            name: t('Nom'),
            sortable: true,
            selector: row => row.name,
        },
        {
            id:'categories',
            name: t('Catégories'),
            sortable: true,
            selector: row => row.categories.join(" "),
            cell: (row, index, column, id) =>{
                return <div>
                    {row.categories.map((cat) => <Badge key={cat} className='me-1'>{cat}</Badge>)}
                </div>
            }
        },
        {
            id:'imageColor',
            name: t('Couleur image'),
            sortable: true,
            selector: row => row.imageColor,
            cell: (row, index, column, id) =>{
                return  <ColorPicker
                    value={row.imageColor}
                    onChange={(color) => onUpdate({... row, imageColor: color})}
                />;
            }
        },
        {
            id:'textColor',
            name: t('Couleur texte'),
            sortable: true,
            selector: row => row.textColor,
            cell: (row, index, column, id) =>{
                return  <ColorPicker
                    value={row.textColor}
                    onChange={(color) => onUpdate({... row, textColor: color})}
                />;
            }
        },
        {
            id:'dateUpdated',
            name: t('MAJ'),
            sortable: true,
            selector: row => `${row.dateUpdated ? dayjs(row.dateUpdated).unix(): ""}`,
            cell: row => `${row.dateUpdated ? dayjs(row.dateUpdated).format("DD/MM/YYYY HH:mm"): ""}`
        }
    ];






    return <TableWithCrudComponent 
        entities={markerTemplates} 
        columns={columns}
        loadingForm={loadingForm}
        loadingList={loadingList}
        onDelete={onDelete}
        onUpdate={onUpdate}
        onCreate={onCreate}
        translations={{
            tableTitle: t("Marker templates de la carte"),
            noEntityText: t("Aucun template disponible"),
            createTitle: t("Ajouter un template"),
            createButtonText: t("Ajouter un template"),
            deleteText: (entity) => (t("Voulez-vous supprimer le template ?")),
            deleteTitle: (entity) => (t("Supprimer le template ") + entity.name ?? entity.id),
            updateText: (entity) => (t("Modifier le template ")  + entity.name ?? entity.id),
        }}
        fieldSearchable={(entity) => [
            entity.name,
            entity.id,
            entity.categories.join(" "),
            entity.textColor,
            entity.imageColor
        ]}
        initialValueCreateForm={{
            name: "template",
            categories: ["category"],
            textColor: "#777777",
            imageColor: "#9b9b9b"
        } as MarkerTemplateDetails}
        />;
}

export default AdminClientMapMarkerTemplate
