import { PayloadAction, createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { TokenDetails } from "backend/ApiAdminDefinition/data-contracts";
//import IToken from "models/IToken";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

export interface IAdminTokenStore {
  /** All tokens for current selected client */
  tokens: TokenDetails[];

  /** Tokens list loading */
  loadingList: boolean;

  /**Loading for token form (create or update) */
  loadingForm: boolean;

  /**Hide or show create token modal */
  showCreateTokenModal: boolean;

  /** For update token modal */
  selectedToken: TokenDetails | undefined
}
  
  // Initial state for the admin token store
const initialState: IAdminTokenStore = {
  tokens: [],
  loadingList: false,
  loadingForm: false,
  showCreateTokenModal: false,
  selectedToken: undefined,
};

// Create a slice for the admin client token store
const adminClientTokenSlice = createSlice({
  name: "adminClientTokenSlice",
  initialState,
  reducers: {
    // Set the tokens in the state
    setTokens: (state, action: PayloadAction<TokenDetails[]>) => {
      state.tokens = action.payload;
    },
    // Update a specific token in the state
    updateToken: (state, action: PayloadAction<TokenDetails>) => {
      state.tokens = state.tokens.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new token to the state
    addToken: (state, action: PayloadAction<TokenDetails>) => {
      state.tokens.push(action.payload);
    },
    // Set the loadingList state
    setLoadingTokenList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingForm state
    setLoadingTokenForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
    // Set the showCreateTokenModal state
    setShowCreateTokenModal: (state, action: PayloadAction<boolean>) => {
      state.showCreateTokenModal = action.payload;
    },
    // Set the selectedToken state
    setSelectedToken: (state, action: PayloadAction<TokenDetails | undefined>) => {
      state.selectedToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.tokens = [];
      state.loadingList = false;
      state.loadingForm = false;
      state.showCreateTokenModal = false;
      state.selectedToken = undefined;
    });
  },
});

// Export the actions and reducer from the admin client token slice
export const {
  actions: adminClientTokenActions,
  reducer: adminClientTokenReducer,
} = adminClientTokenSlice;