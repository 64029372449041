import React, { useEffect, useState } from 'react'
import "./OrderListSAP.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sdk from 'fno-sdk';
import Orders, { IOrderData } from './Orders';
import { Accordion, Badge, Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import _ from 'lodash';
import productQueue from '../apiSAP/models/productQueue';
import { apiSAP } from '../apiSAP/apiSap';
import Loading from 'components/Loading';
import copyToClipboard from 'utility/copyToClipboard';
import appColors from 'resources/appColors';

interface OwnProps { 
    sdk: Sdk;
}
interface gpsCoord{
    longitude: number;
    latitude: number
}

interface OrderProducts{
    ref: string;
    picker: string;
    date: string; 
    products: productQueue[]
}

const _apiSAP =  new apiSAP();

const OrderListSAP: React.FC<OwnProps> = ({sdk}) => {

    const [orderSelected, setOrderSelected] = useState<OrderProducts | undefined>(undefined);
    const [orders, setOrders] = useState<OrderProducts[]>([]);
    const [showInitial, setShowInitial] = useState(true);
    const [showOptimzed, setShowOptimzed] = useState(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [dateFrom, setDateFrom] = useState(dayjs().add(-10, "day").toDate());
    const [dateTo, setDateTo] = useState(dayjs().toDate());
    const [searchTerm, setSearchTerm] = useState("");

    const startPosition = {
        longitude: 2.29448108396958,
        latitude: 48.9124764253126
    } as gpsCoord;

    
    useEffect(() => {
        setLoading(true);
        _apiSAP.getWarehouseResources().then((resources) => {
            _apiSAP.getProductsQueue().then((products) => {
                const orders = _.groupBy(products, "EWMResourceGroup");
                setOrders(_.map(orders, (values, key) => {
                    const pickerFound = resources.find(m => m.EWMResourceGroup == key);
                    return {
                        ref: key,
                        picker: pickerFound ? pickerFound.EWMResource : "N/C",
                        products: values.map((product) => {
                            return {
                                ...product,
                                SKU: (Math.trunc(Math.random() * (9999999999999 - 1000000000000) + 1000000000000)).toString()
                            }
                        }),
                        date: dayjs().add(-1,'day').add( Math.trunc(Math.random() * (10 - 500) + 10),'minute').toISOString()//"2023-10-29T12:19:20.61Z"
                    } as OrderProducts
                }).sort((a, b) => dayjs(a.date).isBefore(b.date) ? 1 : -1));
                setLoading(false);
            })
        });
    }, [])

    useEffect(() => {
        if(sdk)
        {
            sdk.pathfinding.clearItineraries();
            sdk.user.setUserPosition( startPosition.longitude, startPosition.latitude, 0);
        }
        return () => {
            sdk.pathfinding.clearItineraries();
            sdk.asset.clearHighlights();
        }
    }, [sdk])


    useEffect(() => {
        if(sdk)
        {
            if(orderSelected)
            {
                clearItineraries()
                    .then(() => {
                        if(showInitial)showInitialItinerary()
                    });
                
            }
            else
            {
                clearItineraries()
            }
        }
    }, [orderSelected])

    
    useEffect(() => {
        if(sdk)
        {
            if(showInitial) showInitialItinerary();
            else clearItineraries();
        }
    }, [showInitial])
    const onClickOrder = async (order: OrderProducts) => {
        if(orderSelected?.ref == order.ref){
            setOrderSelected(undefined);
            sdk.asset.clearHighlights();
        }
        else{
            setOrderSelected(order);
        }
    }

    const showInitialItinerary = async() =>{
        if(orderSelected)
        {
            const allLocationCodes = orderSelected.products.map((item) => item.WarehouseOrderQueue);
            await sdk?.pathfinding.setItineraryToAssetsByLocationCode(allLocationCodes, appColors.orange);
            await sdk.asset.clearHighlights();
            const allAsset = await sdk.asset.getAssetDataByLocationCodes(allLocationCodes)
            allAsset.assets.forEach((item) => sdk?.asset.setHighlightAssetById(item.id, true));
        }
    }

    const clearItineraries = async() =>{
        await sdk?.pathfinding.clearItineraries();
    }

    const zoomOnUser = async () => {
        await sdk?.user.moveCameraToUserPosition();
    }


    async function onHoverIn(locationCode: string){

        const assets = await sdk.asset.getAssetDataByLocationCodes([locationCode])
        assets.assets.forEach((asset) => {
            sdk.asset.setHighlightAssetById(asset.id, true)
        })
    }

    async function onHoverOut(locationCode: string){

        const assets = await sdk.asset.getAssetDataByLocationCodes([locationCode])
        assets.assets.forEach((asset) => {
            sdk.asset.setHighlightAssetById(asset.id, false)
        })
    }

    if(!sdk) return <></>; 
    

    let ordersFiltered = orders;
    if(searchTerm)
    {
        ordersFiltered = ordersFiltered.filter((order) => {
            const termsToCheck = [
                order.ref,
                order.picker
            ];
            const joinTerm = _.join(termsToCheck, ' ').toLocaleLowerCase();
            return joinTerm.includes(searchTerm.toLocaleLowerCase());
        });
    }

    if(dateFrom)
    {
        ordersFiltered = ordersFiltered.filter((order) => {
            const dateOrder = dayjs(order.date);

            return dateOrder.isAfter(dateFrom);
        });
    }

    if(dateTo)
    {
        ordersFiltered = ordersFiltered.filter((order) => {
            const dateOrder = dayjs(order.date);

            return dateOrder.isBefore(dateTo);
        });
    }

    return (
        <div id='demo-picking-order-list-sap'>
            <h2 className='text-center mt-2'>Pick list</h2>

            <div>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Search</InputGroup.Text>
                    <Form.Control type="text" value={searchTerm} placeholder='Ref, picker ...' onChange={(event) => {console.log("event.target.value",event.target.value ); setSearchTerm(event.target.value)}}/>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>From</InputGroup.Text>
                    <Form.Control type="date" value={dayjs(dateFrom).format("YYYY-MM-DD")} onChange={(event) => setDateFrom(dayjs(event.target.value).toDate())}/>
                    <InputGroup.Text>To</InputGroup.Text>
                    <Form.Control type="date" value={dayjs(dateTo).format("YYYY-MM-DD")} onChange={(event) => setDateTo(dayjs(event.target.value).toDate())}/>
                </InputGroup>
            </div>

            {loading && <div className='mt-5'><Loading text='Loading from SAP ...' /></div>}

            {!loading && <Accordion activeKey={orderSelected?.ref}>
                {ordersFiltered.map((order, i) => {
                    //const gainPourcent = Math.abs(((order.optimizedDistance - order.initialDistance) / order.initialDistance) * 100);

                    return <Accordion.Item key={i} eventKey={order.ref}>
                        <Accordion.Header onClick={() => onClickOrder(order)}>
                                <FontAwesomeIcon icon={["fas", "tag"]} /> 
                                <span className='ms-2'>{order.ref} </span>
                                <Badge className='ms-2' bg="primary"><FontAwesomeIcon icon={["fas", "user"]} /> {order.picker}</Badge>
                                <Badge className='ms-2' bg="success">{order.products.length} Products </Badge>
                            </Accordion.Header>
                        <Accordion.Body>
                            <Row className='text-center mb-3'>
                                <Col><FontAwesomeIcon icon={["fas", "user"]} /> {order.picker}</Col>
                                <Col><FontAwesomeIcon icon={["fas", "calendar"]} /> {dayjs(order.date).format("DD/MM/YYYY hh:mm")}</Col>
                            </Row>
                            <Table striped bordered hover className={'order-list-item ' + (showInitial ? "show-initial " : "") + (showOptimzed ? "show-optimzed " : "")}>
                                <thead>
                                    <tr>
                                        <th rowSpan={2}>#</th>
                                        <th colSpan={2} className='text-center initial' > <input type='checkbox' checked={showInitial} onChange={(e) => setShowInitial(e.target.checked)}/> SAP</th>
                                    </tr>
                                    <tr>
                                       
                                        <th className='initial'>PRODUCT</th>
                                        <th className='initial'>LOCATION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {order.products.map((item, i) => {
                                        return <tr key={i} >
                                            <td>{item.EWMResourceGroupQueueSqncNmbr}</td>
                                            <td className='initial'>{item.SKU} <Button size='sm' variant='link' onClick={() => copyToClipboard(item.SKU, "Copied")} title={"Copy SKU"}><FontAwesomeIcon icon={["fas", "copy"]} /></Button></td>
                                            <td className='initial'>{item.WarehouseOrderQueue} <Button size='sm' variant='link' onClick={() => copyToClipboard(item.WarehouseOrderQueue, "Copied")} title={"Copy location"}><FontAwesomeIcon icon={["fas", "copy"]} /></Button></td>
                                        </tr>
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        {/*<th className=''><FontAwesomeIcon icon={["fas", "flag-checkered"]} /> </th>
                                        <th className='text-center' colSpan={2}>{order.initialDistance} {t("mètres")}</th>
                                        <th className='text-center' colSpan={2}>{order.optimizedDistance} {t("mètres")}</th>*/}
                                   </tr>
                                   {/*<tr>
                                        <th className=''><FontAwesomeIcon icon={["fas", "percent"]} /> </th>
                                        <th className='text-center' colSpan={4}>{gainPourcent}%</th>
                                </tr>*/}
                                </tfoot>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                })}

                
            </Accordion>}
        </div>
    )
}

export default OrderListSAP
