/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, LabelCreate, LabelDetails, LabelUpdate, Operation } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MapLabel<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags MapLabel
     * @name LabelsList
     * @summary [🤵 OR 🔑] Get all Labels for a map. [🔒: map_read].
     * @request GET:/map/{mapId}/Labels
     * @secure
     */
    labelsList = (mapId: string, params: RequestParams = {}) =>
        this.request<LabelDetails[], void>({
            path: `/map/${mapId}/Labels`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapLabel
     * @name LabelsPut
     * @summary [🤵 OR 🔑] Update many labels. [🔒: map_update].
     * @request PUT:/map/{mapId}/Labels
     * @secure
     */
    labelsPut = (mapId: string, data: LabelUpdate[], params: RequestParams = {}) =>
        this.request<LabelDetails[], ApiErrorsResponse | void>({
            path: `/map/${mapId}/Labels`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapLabel
     * @name LabelPost
     * @summary [🤵 OR 🔑] Create new Label. [🔒: map_create].
     * @request POST:/map/{mapId}/label
     * @secure
     */
    labelPost = (mapId: string, data: LabelCreate, params: RequestParams = {}) =>
        this.request<LabelDetails, void>({
            path: `/map/${mapId}/label`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapLabel
     * @name LabelsPost
     * @summary [🤵 OR 🔑] Create several labels. [🔒: map_create].
     * @request POST:/map/{mapId}/labels
     * @secure
     */
    labelsPost = (mapId: string, data: LabelCreate[], params: RequestParams = {}) =>
        this.request<LabelDetails[], void>({
            path: `/map/${mapId}/labels`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapLabel
     * @name LabelsDelete
     * @summary [🤵 OR 🔑] [🔒: map_update].
     * @request DELETE:/map/{mapId}/labels
     * @secure
     */
    labelsDelete = (mapId: string, data: string[], params: RequestParams = {}) =>
        this.request<any, ApiErrorsResponse | void>({
            path: `/map/${mapId}/labels`,
            method: 'DELETE',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        })
    /**
     * No description
     *
     * @tags MapLabel
     * @name LabelPut
     * @summary [🤵 OR 🔑] Update all field of a label. [🔒: map_update].
     * @request PUT:/map/{mapId}/label/{labelId}
     * @secure
     */
    labelPut = (mapId: string, labelId: string, data: LabelUpdate, params: RequestParams = {}) =>
        this.request<LabelDetails, void>({
            path: `/map/${mapId}/label/${labelId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapLabel
     * @name LabelPatch
     * @summary [🤵 OR 🔑] Update specific field of a map label. [🔒: map_update].
     * @request PATCH:/map/{mapId}/label/{labelId}
     * @secure
     */
    labelPatch = (mapId: string, labelId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<LabelDetails, void>({
            path: `/map/${mapId}/label/${labelId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapLabel
     * @name LabelDelete
     * @summary [🤵 OR 🔑] Delete a Label. [🔒: map_update].
     * @request DELETE:/map/{mapId}/label/{labelId}
     * @secure
     */
    labelDelete = (mapId: string, labelId: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/map/${mapId}/label/${labelId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
