/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AnalysisCreate, AnalysisDetails } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class AuditOptimization<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags AuditOptimization
     * @name AuditOptimizationProcessStart
     * @summary Start asynchrone optimization process
     * @request POST:/map/{mapId}/audit/raw/file/{auditRawFileId}/optimization/run
     */
    auditOptimizationProcessStart = (mapId: string, auditRawFileId: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/map/${mapId}/audit/raw/file/${auditRawFileId}/optimization/run`,
            method: 'POST',
            ...params
        })
    /**
     * No description
     *
     * @tags AuditOptimization
     * @name AuditRawFileOptimizationResultPost
     * @summary Create a picking result associated with a specific map.
     * @request POST:/map/{mapId}/audit/raw/file/{auditRawFileId}/optimization/result
     */
    auditRawFileOptimizationResultPost = (mapId: string, auditRawFileId: string, data: AnalysisCreate, params: RequestParams = {}) =>
        this.request<AnalysisDetails, any>({
            path: `/map/${mapId}/audit/raw/file/${auditRawFileId}/optimization/result`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
}
