import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/PageHeader';
import nameOf from 'utility/nameOf';
import { useAppDispatch, useAppSelector } from 'store/store'
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonFno from 'components/inputs/ButtonFno';
import UserForm from 'components/Admin/forms/UserForm';
import { toast } from 'react-toastify';
import copyToClipboard from 'utility/copyToClipboard';
import { UserDetailsAdmin } from 'backend/ApiAdminDefinition/data-contracts';
import { createUserAdminThunk } from 'store/reducers/Admin/User/thrunks/createUserAdminThunk';
import parseApiErrorSetFieldError from 'utility/parseApiErrorSetFieldError';

const AdminUserCreate: React.FC = () => {
    const { t } = useTranslation([nameOf({AdminUserCreate}), "TransverseError"], { useSuspense: false});
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.adminUser.loading);

    useEffect(() => {
        document.title = t('[Admin] Créer un utilisateur');
    }, [])

    const onSubmit = async (userToEdit: UserDetailsAdmin, setFieldError:(field: string, message: string | undefined) => void) => {

        dispatch(createUserAdminThunk(userToEdit,{
            onSuccess: (userCreate) => {
                toast.success(<div>
                    <div>{t("L'utilisateur a bien été créé")}</div>
                    <div><ButtonFno className="p-2" color='blue' sm onClick={() => copyToClipboard(userCreate.password)}><FontAwesomeIcon icon={['fas', 'copy']} size="sm"/> {t("Copier le mot de passe")}</ButtonFno></div>
                </div>);
    
                navigate(ROUTES.ADMIN.USERS)
            },
            onError: (apiResponse) => {
                parseApiErrorSetFieldError(apiResponse, setFieldError);
            }
        }));
    };


    return (
        <div>
            
             <PageHeader title={t("Nouveau utilisateur")} admin/>
             <UserForm loading={loading} onSubmit={onSubmit} onClose={() => navigate(ROUTES.ADMIN.USERS)}/>   
        </div>
    )
}

export default AdminUserCreate
