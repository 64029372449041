/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, ClientCreate, ClientDetails, ClientUpdate } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Client<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Client
     * @name ClientsList
     * @summary [🤵] Return all clients.
     * @request GET:/admin/clients
     * @secure
     */
    clientsList = (params: RequestParams = {}) =>
        this.request<ClientDetails[], void>({
            path: `/admin/clients`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Client
     * @name ClientPost
     * @summary [🤵] Create new client.
     * @request POST:/admin/client
     * @secure
     */
    clientPost = (data: ClientCreate, params: RequestParams = {}) =>
        this.request<ClientDetails, ApiErrorsResponse | void>({
            path: `/admin/client`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Client
     * @name ClientPut
     * @summary [🤵] update client.
     * @request PUT:/admin/client/{clientId}
     * @secure
     */
    clientPut = (clientId: number, data: ClientUpdate, params: RequestParams = {}) =>
        this.request<ClientDetails, ApiErrorsResponse | void>({
            path: `/admin/client/${clientId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
}
