import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/PageHeader';
import nameOf from 'utility/nameOf';
import { useAppDispatch, useAppSelector } from 'store/store'
import Loading from 'components/Loading';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Badge, Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonFno from 'components/inputs/ButtonFno';
import { loadUsersAdminThunk } from 'store/reducers/Admin/User/thrunks/loadUsersAdminThunk';
import dayjs from 'dayjs';
import { regenerateUserPasswordAdminThunk } from 'store/reducers/Admin/User/thrunks/regenerateUserPasswordAdminThunk';
import { toast } from 'react-toastify';
import copyToClipboard from 'utility/copyToClipboard';
import { UserDetailsAdmin } from 'backend/ApiAdminDefinition/data-contracts';

const AdminUsers: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminUsers}), { useSuspense: false});
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.adminUser.loading);
    const users = useAppSelector(state => state.adminUser.users);

    useEffect(() => {
        document.title = t('[Admin] Liste des utilisateurs');
        dispatch(loadUsersAdminThunk());
    }, [])


    const resetPassword = (user: UserDetailsAdmin) => {
        dispatch(regenerateUserPasswordAdminThunk(user.email, (newPassword) => {
            toast.success(<div>
                <div>{t("Mot de passe regénéré")}</div>
                <div><ButtonFno className="p-2" color='blue' sm onClick={() => copyToClipboard(newPassword)}><FontAwesomeIcon icon={['fas', 'copy']} size="sm"/> {t("Copier le mot de passe")}</ButtonFno></div>
            </div>);
        }));
    }


    const columns: TableColumn<UserDetailsAdmin>[] = [
        {
            id:'name',
            name: t('Nom'),
            sortable: true,
            selector: row => `${row.firstName} ${row.lastName}`,
        },
        {
            id:'email',
            name: t('Mail'),
            sortable: true,
            selector: row => row.email.toLocaleLowerCase(),
        },
        {
            id:'roles',
            name: t('Roles'),
            sortable: false,
            cell: row => {
                return row.userRoles.map((role) => {
                    return <Badge key={role} bg={role.toUpperCase() == "ADMIN" ? "danger": "primary"}>{role}</Badge>
                });
            },
        },
        {
            id:'client',
            name: t('Client (Rôle)'),
            sortable: false,
            cell: row => {
                return row.clients.map((client) => {
                    return <span key={client.id}>{client.name} ({client.roleName})</span>
                });
            },
        },
        {
            id:'nbMap',
            name: t('Nb cartes'),
            sortable: false,
            cell: row => {
                return row.clients.map((client) => {
                    return <span key={client.id}>{client.maps.length}</span>
                });
            },
        },
        {
            id:'dateLastLogin',
            name: t('Dernière connexion'),
            sortable: true,
            selector: row => `${row.lastLoginDate ? dayjs(row.lastLoginDate).format("DD/MM/YYYY HH:mm"): ""}`,
        },
        {
            name:<div>{t('Actions')}</div>,
            center:true,
            width:"150px",
            cell: (row, index, column, id) =>{
                return <div className='d-flex'>
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle split variant="success" size='sm' id="dropdown-split-basic" />
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="0" onClick={() => resetPassword(row)}><FontAwesomeIcon icon={["fas", "arrows-rotate"]} size="lg"/> {t('Générer nouveau mot de passe')}</Dropdown.Item>
                        </Dropdown.Menu>
                        <Button variant="success" size='sm' onClick={() => navigate(ROUTES.ADMIN.USER(row.email.toString()).DETAILS)}>{t("Détails")} <FontAwesomeIcon icon={["fas", "edit"]}/></Button>
                    </Dropdown>
                </div>
            }
        }
    ];




    return (
        <div>
            
             <PageHeader title={t("Utilisateurs")} admin/>
            <div className='table-component'>
                <DataTable 
                    columns={columns} 
                    data={users} 
                    defaultSortAsc={true} 
                    progressPending={users.length == 0 && loading}
                    progressComponent={<Loading text={t("Chargement")}/>}
                    defaultSortFieldId={'email'} 
                    striped={true}
                    subHeader={true}
                    highlightOnHover={true}
                    noDataComponent={<div className='w-100 text-center p-5'>{t("Aucun utilisateur disponible")}</div>}
                    subHeaderComponent={<> 
                        <h5 className='text-uppercase'> {t("Les utilisateurs") + " ("+users.length+")"} {loading && <Loading inline size={20}/>}</h5>
                        <div className='search-panel'>
                            <ButtonFno sm className='me-2' onClick={() => navigate(ROUTES.ADMIN.USER().CREATE)}><FontAwesomeIcon className='me-2' icon={["fas", "plus"]} /> {t("Créer un utilisateur")}</ButtonFno>
                            {/*<input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>*/}
                        </div>
                        </>}
                    sortServer={false}/>
            </div>
        </div>
    )
}

export default AdminUsers
