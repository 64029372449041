import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiErrorModel from "backend/utils/apiErrorModel";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { FloorDetails, FloorUpdate} from "backend/ApiBackofficeDefinition/data-contracts";
import { adminClientMapFloorActions } from "../adminClientMapFloorSlice";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Thunk action creator for updating a floor
 * @param mapId 
 * @param floor 
 * @param callbacks 
 * @returns 
 */
export const updateFloorThrunk = (
    mapId: string,
    floor: FloorUpdate,
    callbacks: ThrunkApiCallBacks<FloorDetails>
  ): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapFloorActions.setLoadingFloorForm(true));
  
        // Make API call to create floor
        await apiBackofficeDefinition.floor.floorPut(mapId, floor.id ,floor)
          .then((result) => {
            // Dispatch action to add newly created floor to store
            dispatch(adminClientMapFloorActions.updateFloor(result.data));
            // Call onSuccess callback
            callbacks.onSuccess(result.data);
          })
          .catch((apiResponse: apiErrorModel) => {
            // Call onError callback with API error response
            callbacks.onError(apiResponse);
          });
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors de la modification du floor"));
          callbacks.onError({ errors:{"api":[error.message]} } as apiErrorModel)
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapFloorActions.setLoadingFloorForm(false));
      }
    };
};