import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiErrorModel from "backend/utils/apiErrorModel";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { adminMiddlewareActions } from "../adminMiddlewareSlice";
import { MiddlewareApiCreate, MiddlewareApiDetails } from "backend/ApiAdminDefinition/data-contracts";
import apiAdminDefinition from "backend/apiAdminDefinition";



/**
 * Thunk action to create a middleware.
 * @param {MiddlewareApiCreate} middleware - The middleware data to be created.
 * @param {ThrunkApiCallBacks<MiddlewareApiDetails>} callbacks - Callbacks for success and error scenarios.
 * @returns {AppThunk} Thunk function.
 */
export const createMiddlewareThunk = (
  middleware: MiddlewareApiCreate,
  callbacks: ThrunkApiCallBacks<MiddlewareApiDetails>
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      // Set loading state to true
      dispatch(adminMiddlewareActions.setLoadingForm(true));

      // Make API call to create middleware
      await apiAdminDefinition.middlewareApi.middlewarePost(middleware)
        .then((result) => {
          // Dispatch action to add newly created middleware to store
          dispatch(adminMiddlewareActions.addMiddleware(result.data));
          // Call onSuccess callback
          callbacks.onSuccess(result.data);
        })
        .catch((apiResponse: apiErrorModel) => {
          // Call onError callback with API error response
          callbacks.onError(apiResponse);
        });
    } catch (error: any) {
      // Handle any errors that occur during the async operation
      if (!error.status || error.status != 401) {
        // Display error notification if it's not an unauthorized error
        toast.error(i18next.t("Erreur lors de la création du middleware"));
        callbacks.onError({ errors: { "api": [error.message] } } as apiErrorModel);
      }
    } finally {
      // Set loading state back to false after the operation completes
      dispatch(adminMiddlewareActions.setLoadingForm(false));
    }
  };
};