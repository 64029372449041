import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PermissionDetails } from "backend/ApiAdminDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

export interface IAdminClientPermissionStore {
  permissions: PermissionDetails[];
  loadingList: boolean;
}
  
const initialState: IAdminClientPermissionStore = {
  permissions: [],
  loadingList: false
}

/**
 * Manage permission feature lirst
 */
const adminClientPermissionSlice = createSlice({
  name: "adminClientPermissionSlice",
  initialState,
  reducers: {
    setPermissions: (state, action:PayloadAction<PermissionDetails[]>) => {
      state.permissions = action.payload;
    },
    updatePermissions: (state, action:PayloadAction<PermissionDetails>) => {
      state.permissions = state.permissions.map((p) =>
        p.name === action.payload.name ? action.payload : p
      );
    },
    setLoadingPermissionList: (state, action:PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.permissions = [];
      state.loadingList = false;
    });
  },
});


export const { actions: adminClientPermissionActions, reducer: adminClientPermissionReducer } = adminClientPermissionSlice