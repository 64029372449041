import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement
} from 'chart.js/auto';

import ChartDataLabels from 'chartjs-plugin-datalabels';

export default function initChartjs(){
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );
}

ChartJS.defaults.set('plugins.datalabels', {
  color: '#000'
});