import React from 'react'
import "./StorageFileStatus.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IFileUploadStatus from 'models/IFileUploadStatus';
import { Col, Row } from 'react-bootstrap';
import { SpinnerInfinity } from 'spinners-react';
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';


type Props = {
    uploadingFiles: IFileUploadStatus[]
  };

/**
 * Component to display the status of multiple files uploading.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {IFileUploadStatus[]} props.uploadingFiles - The list of files being uploaded.
 * @return {JSX.Element} The component JSX.Element.
 */
const StorageFileStatus: React.FC<Props> = ({uploadingFiles}) => {
    const { t } = useTranslation([nameOf({StorageFileStatus})], { useSuspense: false});
    
    return <div className='storage-file-panel'>
    {uploadingFiles.map((file, i) => {
        return <Row key={i} className={"storage-file-status " +file.status  }>
            <Col>{file.name}</Col>
            <Col className='text-end'>
                {file.status == "waiting" && <span><FontAwesomeIcon className='me-2' icon={["fas", "hourglass"]} /></span>}
                {file.status == "pending" && <span><SpinnerInfinity size={30} thickness={180} speed={98} color="rgba(62, 172, 57, 1)" secondaryColor="rgba(172, 102, 57, 0.31)" /></span>}
                {file.status == "success" && <span><FontAwesomeIcon className='me-2' icon={["fas", "check"]} /></span>}
                {file.status == "error" && <span>{t('Erreur')}</span>}
            </Col>
        </Row>
    })}
</div>

}

export default StorageFileStatus