import React, { useEffect, useState } from 'react'
import DataTable, { ExpanderComponentProps, TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { Badge, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store';
import { ROUTES } from 'resources/routes-constants';
import "./TimcodeBatching.scss";
import { useNavigate } from 'react-router-dom';
import SelectDayComponent from './SelectDayComponent';
import StatisticCard from 'components/StatisticCard';


interface IRowType{
    missionRef: string;
    supports: string[];
    searchField: string;
}

const TimcodeBatching: React.FC = () => {
    const { t } = useTranslation(nameOf({PickingOrder: TimcodeBatching}), { useSuspense: false});
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    const [selectedClientRow, setSelectedClientRow] = useState<IRowType | undefined>();

    const [rowsSuperU, setRowsSuperU] = useState<IRowType[]>([]);
    const [rowsTimcod, setRowsTimcod] = useState<IRowType[]>([]);

    const [orderHovered, setOrderHovered] = useState<string | undefined>();

    const [headers, setHeaders] = useState< TableColumn<any>[]>([]);

    

    const [searchClientTerm, setSearchClientTerm] = useState('');
    const [searchFnoTerm, setSearchFnoTerm] = useState('');
    
    const {
        allFiles,
        selectedFile,
        selectedFileData,
        loadingFile
    } = useAppSelector(state => state.timcodeDemo);

    //const loadingOptimizationResult = useAppSelector(state => state.audit.loadingOptimizationResult);
    //const auditData = useAppSelector(state => state.audit.data);
    //const auditColumn = useAppSelector(state => state.audit.auditRawFileSelected?.columnsBindingData);
    //const auditOptimizationResults = useAppSelector(state => state.audit.optimizationResult);
    

    useEffect(() => {
        document.title = t("Regroupement de commandes");
    }, [])


    useEffect(() => {
        const headersData: TableColumn<IRowType>[] = []

        headersData.push({
            id:"mission",
            name: t("Ref. mission"),
            sortable: true,
            selector: row => row.missionRef
        })

        headersData.push({
            id:"products",
            name: "Nb. support",
            sortable: true,
            selector: row => {
                return row.supports.length;
            }
        })
        
        setHeaders(headersData)
    }, [])


    useEffect(() => {
        if(selectedFileData)
        {

            setRowsTimcod(
                _.map(selectedFileData.batching.timcod, (values, key) => {
                return {
                     supports: values,
                     missionRef: key,
                     searchField: _.join(values, " ").toLocaleLowerCase()
                } as IRowType
            }));

            setRowsSuperU(
                _.map(selectedFileData.batching.superU, (values, key) => {
                return {
                     supports: values,
                     missionRef: key,
                     searchField: _.join(values, " ").toLocaleLowerCase()
                } as IRowType
            }));
        }
        else
        {
            setRowsTimcod([]);
            setRowsSuperU([]);
        }
    }, [selectedFileData])

    const onSelectClientRow = (rowClient: IRowType | undefined) => {
        setSelectedClientRow(rowClient);
    }


    let rowsClientSearch = rowsSuperU
    let rowsFnoSearch = rowsTimcod

    if(selectedClientRow)
    {
        rowsFnoSearch = rowsTimcod.filter(rowFno => {
            return rowFno.supports.some(fnoSupport => _.some(selectedClientRow.supports, (SuppRef) => SuppRef ==  fnoSupport))
        })
    }

    const rowClientDetailsRender: React.FC<ExpanderComponentProps<IRowType>> = ({ data }) => {
        return <>
            {data.supports.map((supportRef, i) => {
                return <Badge 
                    key={i}
                    bg={orderHovered && supportRef == orderHovered  ? "danger" : "primary"}
                    onMouseEnter={() => setOrderHovered(supportRef)} 
                    onMouseLeave={() => setOrderHovered(undefined)} 
                    className='m-2'>
                    {supportRef}</Badge>;
                })
            }
        </>;
    };

    const rowFnoDetailsRender: React.FC<ExpanderComponentProps<IRowType>> = ({ data }) => {
        return <>
            {data.supports.map((supportFno) => {

                let badgeColor = "secondary";
                if(selectedClientRow?.supports.some((o) => o == supportFno)) badgeColor = "primary";
                if(orderHovered && orderHovered == supportFno)badgeColor = "danger";


                return <Badge key={supportFno} className='m-2' bg={badgeColor}>{supportFno}</Badge>;
            })}
        </>;
    };

    if(searchClientTerm)
    {
        rowsClientSearch = rowsClientSearch.filter((entity) => {
            return entity.searchField.includes(searchClientTerm.toLocaleLowerCase());
        });
    }

    if(searchFnoTerm)
    {
        rowsFnoSearch = rowsFnoSearch.filter((entity) => {
            return entity.searchField.includes(searchFnoTerm.toLocaleLowerCase());
        });
    }



    const totalDistancesSuperU = selectedFileData ? _.sumBy(_.map(selectedFileData.final_superU.missions), "totalDistance") : 0;
    const totalDistancesTimcod = selectedFileData ? _.sumBy(_.map(selectedFileData.final_timcod.missions), "totalDistance") : 0;
    const gainpourcent = -((totalDistancesTimcod - totalDistancesSuperU) / totalDistancesSuperU) * 100;
    const gainPourcentString = gainpourcent.toFixed(2);

    return (
        <div id="timcode-batching-page">
             <PageHeader title={t("Regroupement commandes")} middlePart={[{title:t("Timcode"), to:ROUTES.DEMO.TIMCODE.BATCHING_RESULT}]}/>
             <Row>
                <Col><SelectDayComponent /></Col>
                <Col><StatisticCard title={t("Gain période (%)")} color='green' value={`${gainPourcentString}`} icon='chart-line' loading={loadingFile}/></Col>
             </Row>
            <Row id="content-table">
                <Col>
                    <div className='table-component'>
                        <DataTable 
                            className={loadingFile ? "loading": ""}
                            dense
                            columns={headers} 
                            paginationPerPage={30}
                            data={rowsClientSearch} 
                            progressPending={loadingFile}
                            progressComponent={<Loading text={t("Chargement")}/>}
                            striped={true}
                            subHeader={true}
                            highlightOnHover={true}
                            pagination
                            expandableRows
                            expandableRowsComponent={rowClientDetailsRender}
                            expandableRowExpanded={(row: IRowType) => row.missionRef == selectedClientRow?.missionRef}
                            expandOnRowClicked={true}
                            onRowExpandToggled={(expanded, row) => onSelectClientRow(expanded ? row: undefined)}
                            noDataComponent={<div className='w-100 text-center p-5'>{t("Aucune data")}</div>}
                            subHeaderComponent={<> 
                                <h5 className='text-uppercase'> 
                                    {t("Regroupement Super U")} {loadingFile && <Loading inline size={20} />}
                                    {!loadingFile && <Badge bg="primary" className='me-2'>{rowsClientSearch.length+" missions"}</Badge>}
                                    {!loadingFile && selectedFileData && <Badge className='text-lowercase' bg="success">{totalDistancesSuperU.toLocaleString("fr-FR")} {t("mètres")}</Badge>}
                                    </h5>
                                <div className='search-panel'>
                                    
                                    <input placeholder={t("Rechercher")} onChange={(e) => setSearchClientTerm(e.target.value.toLowerCase())}/>
                                </div>
                                </>}/>
                    </div>
                </Col>
                <Col>
                    <div className='table-component'>
                        <DataTable 
                            className={loadingFile ? "loading": ""}
                            dense
                            columns={headers}
                            paginationPerPage={30}
                            data={rowsFnoSearch} 
                            progressPending={loadingFile}
                            progressComponent={<Loading text={t("Calcul de l'optimisation du regroupement")}/>}
                            striped={true}
                            subHeader={true}
                            highlightOnHover={true}
                            pagination
                            expandableRows 
                            expandableRowsComponent={rowFnoDetailsRender}
                            expandableRowExpanded={(row: IRowType) => {
                                if(selectedClientRow && orderHovered)
                                {
                                    return row.supports.some((m) => m == orderHovered)
                                }
                                
                                return !!selectedClientRow;
                            }}
                            noDataComponent={<div className='w-100 text-center p-5'>{t("Aucune data")}</div>}
                            subHeaderComponent={<> 
                                <h5 className='text-uppercase'> 
                                    {!selectedClientRow && t("Regroupement Timcode")} {loadingFile && <Loading inline size={20} />}
                                    {!loadingFile && !selectedClientRow && <Badge bg="primary" className='me-2'>{rowsFnoSearch.length+" missions"}</Badge>}
                                    {!loadingFile && !selectedClientRow && selectedFileData && <Badge className='text-lowercase' bg="success">{totalDistancesTimcod.toLocaleString("fr-FR")} {t("mètres")}</Badge>}
                                    {selectedClientRow && t("Regroupement Timcode pour") + " " + selectedClientRow.missionRef}
                                    
                                </h5>
                                <div className='search-panel'>
                                    <input placeholder={t("Rechercher")} onChange={(e) => setSearchFnoTerm(e.target.value.toLowerCase())}/>
                                </div>
                                </>}/>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default TimcodeBatching
