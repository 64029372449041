import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';


const AdminRealtimeDashboard: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminRealtimeLog: AdminRealtimeDashboard}), { useSuspense: false});
    const navigate = useNavigate();


    useEffect(() => {
        navigate(ROUTES.ADMIN.REALTIME.MAP_CHANGES)
    }, [])

    

    return (<div></div>)
}

export default AdminRealtimeDashboard
