import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { TableColumn } from 'react-data-table-component';
import { loadMarkersThunks } from 'store/reducers/Admin/ClientMapMarker/thrunks/loadMarkersThunks';
import { updateMarkerThunk } from 'store/reducers/Admin/ClientMapMarker/thrunks/updateMarkerThunk';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { createMarkerThunk } from 'store/reducers/Admin/ClientMapMarker/thrunks/createMarkerThunk';
import { deleteMarkerThrunks } from 'store/reducers/Admin/ClientMapMarker/thrunks/deleteMarkerThrunks';
import { MarkerCreate, MarkerDetails, MarkerUpdate } from 'backend/ApiBackofficeDefinition/data-contracts';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';

const AdminClientMapMarkers: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapMarkers}), { useSuspense: false});
    const dispatch = useAppDispatch();

    const {
        loadingList,
        loadingForm,
        markers
    } = useAppSelector(state => state.adminMapMarker);

    const { clientId, mapId } = useParams();

    useEffect(() => {
        document.title = t('[Admin] Markers de la map');
        if(clientId && mapId && mapId != "new")
        {
            dispatch(loadMarkersThunks(mapId));
        }
    }, [clientId, mapId])

    const onUpdate = (marker: MarkerDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(updateMarkerThunk(mapId, marker as MarkerUpdate,{
                onSuccess: (marker) => {
                    closeModal();
                    toast.success(t("Le marker a bien été modifié"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onCreate = (marker: MarkerDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(createMarkerThunk(mapId, marker as MarkerCreate,{
                onSuccess: (marker) => {
                    closeModal();
                    toast.success(t("Le marker a bien été créé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onDelete = (marker: MarkerDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(deleteMarkerThrunks(mapId, marker.id,{
                onSuccess: (success) => {
                    toast.success(t("Le marker a bien été supprimé"))
                    closeModal();
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const columns: TableColumn<MarkerDetails>[] = [
        {
            id:'imageId',
            name: t('Image Id'),
            sortable: true,
            selector: row => row.imageId,
        },
        {
            id:'labelTemplateId',
            name: t('Label template'),
            sortable: true,
            selector: row => row.labelTemplateId ?? "",
        },
        {
            id:'gpsPosition',
            name: t('Position'),
            sortable: false,
            cell: row => `${row.gpsPosition.x}, ${row.gpsPosition.y}`,
        },
        {
            id:'rotation',
            name: t('Rotation'),
            sortable: false,
            cell: row => row.rotation ? `${row.rotation.x}, ${row.rotation.y}, ${row.rotation.z}`: "",
        },
        {
            id:'metadataGroupIds',
            name: t('Nb groupe Metadata'),
            sortable: true,
            selector: row => row.metadataGroupIds.length,
        },
        {
            id:'version',
            name: t('Version'),
            center:true,
            selector: row => row.version,
        }
    ];


    return <TableWithCrudComponent 
        entities={markers} 
        columns={columns}
        loadingForm={loadingForm}
        loadingList={loadingList}
        onDelete={onDelete}
        onUpdate={onUpdate}
        onCreate={onCreate}
        translations={{
            tableTitle: t("Markers de la carte"),
            noEntityText: t("Aucun marker disponible"),
            createTitle: t("Ajouter un marker"),
            createButtonText: t("Ajouter un marker"),
            deleteText: (entity) => (t("Voulez-vous supprimer le marker ?")),
            deleteTitle: (entity) => (t("Supprimer le marker ") + entity.text ?? entity.id),
            updateText: (entity) => (t("Modifier le marker ")  + entity.text ?? entity.id),
        }}
        fieldSearchable={(entity) => [
            entity.text,
            entity.id,
            entity.imageId
        ]}
        initialValueCreateForm={{
            text: "Nouveau marker",
            imageId: "",
            mapId: mapId ?? "",
            labelTemplateId: "",
            gpsPosition: {
                x: 0,
                y: 0
            },
            rotation: undefined 
        }as MarkerDetails}
        />;
    
}

export default AdminClientMapMarkers
