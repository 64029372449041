import { AppThunk } from "store/store"
import { auditActions } from "store/reducers/Audit/auditSlice";
import { AuditRawFileDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Load optimization result from current audit setting selected
 * @returns 
 */
export const LoadOptimizationResultFromRawFileIdThunk = (auditRawFile: AuditRawFileDetails | undefined): AppThunk => {
    return async (dispatch, getState) => {

        try{

            const currentMap = getState().map.currentMap;

            if(!currentMap) {

                console.error("[LoadAuditOptimizedPathFromOriginalThunk] No map selected")
                return;
            }

            if(auditRawFile)
            {
                dispatch(auditActions.setLoadingOptimizationResult(true));
                const optimizationresult = await apiBackofficeDefinition.pickingOptimizationResult.pickingOptimizationResultsList(currentMap.id, {
                    rawFileId: auditRawFile.id
                })

                dispatch(auditActions.setOptimizationResults(optimizationresult.data[0] ?? undefined));
    
                dispatch(auditActions.setLoadingOptimizationResult(false));
            }
            else
            {
                dispatch(auditActions.setOptimizationResults(undefined));
            }

        }
        catch(error: any)
        {
            console.error("[LoadAuditOptimizationThunk] error", error)
            dispatch(auditActions.setLoadingOptimizationResult(false));
        }
    }
}