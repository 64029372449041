import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LabelDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

// Interface representing the state of the admin client map Label store
export interface IAdminClientMapLabelStore {
  labels: LabelDetails[];
  loadingList: boolean;
  loadingForm: boolean;
}

// Initial state for the admin client map Label store
const initialState: IAdminClientMapLabelStore = {
  labels: [],
  loadingList: false,
  loadingForm: false,
};

// Create a slice for the admin client map Label store
const adminClientMapLabelSlice = createSlice({
  name: "adminClientMapLabelSlice",
  initialState,
  reducers: {
    // Set the Labels in the state
    setLabels: (state, action: PayloadAction<LabelDetails[]>) => {
      state.labels = action.payload;
    },
    // Update a specific Label in the state
    updateLabel: (state, action: PayloadAction<LabelDetails>) => {
      state.labels = state.labels.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new Label to the state
    addLabel: (state, action: PayloadAction<LabelDetails>) => {
      state.labels.push(action.payload);
    },
    // remove an Label
    deleteLabel: (state, action: PayloadAction<string>) => {
      state.labels = state.labels.filter(m => m.id !== action.payload)
    },
    // Set the loadingList state
    setLoadingLabelList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingForm state
    setLoadingLabelForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.labels = [];
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

// Export the actions and reducer from the admin client map Label slice
export const {
  actions: adminClientMapLabelActions,
  reducer: adminClientMapLabelReducer,
} = adminClientMapLabelSlice;
