import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptimizationSettingDetails } from "backend/ApiAdminDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

// Interface representing the state of the admin client map setting store
export interface IAdminClientAuditSliceStore {
  optimizationSettings: OptimizationSettingDetails[];
  loadingList: boolean;
  loadingForm: boolean;
}

// Initial state for the admin client map setting store
const initialState: IAdminClientAuditSliceStore = {
  optimizationSettings: [],
  loadingList: false,
  loadingForm: false,
};

// Create a slice for the admin client map setting store
const adminClientMapOptimizationSettingSlice = createSlice({
  name: "adminClientMapOptimizationSettingSlice",
  initialState,
  reducers: {
    // Set the settings in the state
    setOptimizationSettings: (state, action: PayloadAction<OptimizationSettingDetails[]>) => {
      state.optimizationSettings = action.payload;
    },
    // Update a specific setting in the state
    updateOptimizationSetting: (state, action: PayloadAction<OptimizationSettingDetails>) => {
      state.optimizationSettings = state.optimizationSettings.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new setting to the state
    addOptimizationSetting: (state, action: PayloadAction<OptimizationSettingDetails>) => {
      state.optimizationSettings.push(action.payload);
    },
    // remove a setting
    deleteOptimizationSetting: (state, action: PayloadAction<string>) => {
      state.optimizationSettings = state.optimizationSettings.filter(m => m.id !== action.payload)
    },
    // Set the loadingList state
    setLoadingList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingForm state
    setLoadingForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.optimizationSettings = [];
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

// Export the actions and reducer from the admin client map setting slice
export const {
  actions: adminClientAuditOptimizationSettingActions,
  reducer: adminClientAuditOptimizationSettingReducer,
} = adminClientMapOptimizationSettingSlice;
