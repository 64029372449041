/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, Operation, TokenCreate, TokenDetails, TokenUpdateAdmin } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Tokens<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Tokens
     * @name ClientTokensList
     * @summary [🤵] Get all API token for current client.
     * @request GET:/admin/client/tokens
     * @secure
     */
    clientTokensList = (params: RequestParams = {}) =>
        this.request<TokenDetails[], void>({
            path: `/admin/client/tokens`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Tokens
     * @name ClientTokenPost
     * @summary [🤵] Create new app token (api key).
     * @request POST:/admin/client/token
     * @secure
     */
    clientTokenPost = (data: TokenCreate, params: RequestParams = {}) =>
        this.request<TokenDetails, ApiErrorsResponse | void>({
            path: `/admin/client/token`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Tokens
     * @name ClientTokenPut
     * @summary [🤵] Update an existing token.
     * @request PUT:/admin/client/token/{tokenId}
     * @secure
     */
    clientTokenPut = (tokenId: string, data: TokenUpdateAdmin, params: RequestParams = {}) =>
        this.request<TokenDetails, ApiErrorsResponse | void>({
            path: `/admin/client/token/${tokenId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Tokens
     * @name ClientTokenPatch
     * @summary [🤵] Update specifc field of a token.
     * @request PATCH:/admin/client/token/{tokenId}
     * @secure
     */
    clientTokenPatch = (tokenId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<TokenDetails, ApiErrorsResponse | void>({
            path: `/admin/client/token/${tokenId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
}
