import apiErrorModel from "backend/utils/apiErrorModel";
import i18next from "i18next";


/**
 * Parses API errors and sets corresponding field errors using the provided callback.
 * @param apiError - The API error model containing error details.
 * @param setFieldError - Callback function to set field-level errors.
 */
export default function parseApiErrorSetFieldError(apiError: apiErrorModel, setFieldError: (field: string, message: string | undefined) => void) {
    // Iterate through each field in the API error response
    for (const fieldName in apiError.errors) {
        if (Object.prototype.hasOwnProperty.call(apiError.errors, fieldName)) {
            // Get the array of errors for the current field
            const errors = apiError.errors[fieldName];

            // If there are errors for the field, set the first error as the field error
            if (errors.length > 0) {
                // Assuming i18next is used for translating error messages
                setFieldError(fieldName, i18next.t(errors[0], { ns: "TransverseError" }));
            }
        }
    }
}