/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, Operation, OptimizationSettingCreate, OptimizationSettingCreateResult, OptimizationSettingDetails } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class OptimizationSetting<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Optimization setting
     * @name MapOptimizationSettingPost
     * @summary [🤵] Create new optimization picking. [🔒: map_create].
     * @request POST:/admin/map/{mapId}/optimization/setting
     * @secure
     */
    mapOptimizationSettingPost = (mapId: string, data: OptimizationSettingCreate, params: RequestParams = {}) =>
        this.request<OptimizationSettingCreateResult, ApiErrorsResponse | void>({
            path: `/admin/map/${mapId}/optimization/setting`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Optimization setting
     * @name MapOptimizationSettingsList
     * @summary [🤵] Get all optimization settings for current map. [🔒: map_read].
     * @request GET:/admin/map/{mapId}/optimization/settings
     * @secure
     */
    mapOptimizationSettingsList = (mapId: string, params: RequestParams = {}) =>
        this.request<OptimizationSettingDetails[], void>({
            path: `/admin/map/${mapId}/optimization/settings`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Optimization setting
     * @name MapOptimizationSettingEnablePost
     * @summary [🤵] Enable a specific optimization setting for picking optimization (and disable all others settings). [🔒: map_read].
     * @request POST:/admin/map/{mapId}/optimization/setting/{settingId}/enable
     * @secure
     */
    mapOptimizationSettingEnablePost = (mapId: string, settingId: string, params: RequestParams = {}) =>
        this.request<OptimizationSettingDetails, ApiErrorsResponse | void>({
            path: `/admin/map/${mapId}/optimization/setting/${settingId}/enable`,
            method: 'POST',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Optimization setting
     * @name MapOptimizationSettingPatch
     * @summary [🤵] Update specific field of a setting. [🔒: map_read].
     * @request PATCH:/admin/map/{mapId}/optimization/setting/{settingId}
     * @secure
     */
    mapOptimizationSettingPatch = (mapId: string, settingId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<OptimizationSettingDetails, ApiErrorsResponse | void>({
            path: `/admin/map/${mapId}/optimization/setting/${settingId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Optimization setting
     * @name MapOptimizationSettingDelete
     * @summary [🤵] Delete an optimization setting. [🔒: map_delete].
     * @request DELETE:/admin/map/{mapId}/optimization/setting/{settingId}
     * @secure
     */
    mapOptimizationSettingDelete = (mapId: string, settingId: string, params: RequestParams = {}) =>
        this.request<void, ApiErrorsResponse | void>({
            path: `/admin/map/${mapId}/optimization/setting/${settingId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
