import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import * as yup from 'yup';
import ButtonFno from 'components/inputs/ButtonFno';
import { Formik } from 'formik';
import { DefaultTFuncReturn } from 'i18next';



interface OwnProps {
    loading: boolean;
    text: string | JSX.Element | DefaultTFuncReturn;
    onSubmit: () => void;
    onCancel: () => void
 }


/**
 * React component to display a confirmation form for deletion.
 * @param onSubmit - Function to call when the deletion is confirmed.
 * @param onCancel - Optional function to call when the deletion is cancelled.
 * @param loading - Indicates if the deletion is in progress.
 * @param text - The text to display in the confirmation form.
 * @returns A React component that displays a confirmation form for deletion.
 */
const DeleteConfirmForm: React.FC<OwnProps> = ({onSubmit, onCancel, loading, text}) => {
    const { t } = useTranslation([nameOf({DeleteConfirmForm}), "TransverseError"], { useSuspense: false});

    const schema = yup.object().shape({
      });


    return (
        <div>
                <Formik
                    validationSchema={schema}
                    initialValues={{}}
                    onSubmit={onSubmit}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        isSubmitting,
                        errors,
                    }) => {
                        return (
                            <fieldset disabled={isSubmitting}>
                                <div className='mt-5 mb-5'>
                                    {text}
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <ButtonFno className="w-50 me-1" color='blue' onClick={onCancel}>{t('Retour')}</ButtonFno>
                                    <ButtonFno className="w-50 ms-1" color='orange' loading={loading} onClick={handleSubmit}>{t('Supprimer')}</ButtonFno>
                                </div>
                            </fieldset>)
                    }}
                    </Formik> 
        </div>
    )
}

export default DeleteConfirmForm
