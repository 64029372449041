import i18next from "i18next";
import _ from "lodash";
import { toast } from "react-toastify";
import { AppThunk } from "store/store";
import apiErrorModel from "backend/utils/apiErrorModel";
import { adminClientMapActions } from "store/reducers/Admin/ClientMap/adminClientMapSlice";
import { MapDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Enable or disable maintenance mode for a map
 * @param map 
 * @returns 
 */
export const updateMapMaintenanceThunk = (map: MapDetails, enable: boolean): AppThunk => {
    return async (dispatch, getState) => {

        const selectedClient = getState().userProfil.currentTenant;

        if(!selectedClient) {
            toast.warn(i18next.t("Impossible de modifier une permission car aucun client n'est sélectionné"))
            return;
        }

        const mapToUpdate = {
            ...map,
            isUnderMaintenance: enable
        } as MapDetails;

        dispatch(adminClientMapActions.updateMap(mapToUpdate));
        apiBackofficeDefinition.map.putMap(mapToUpdate.id, mapToUpdate)

        .then((result) => {
            if(enable)toast.success(i18next.t("Mode maintenance activé pour la carte:") + " " + map.name);
            else toast.success(i18next.t("Mode maintenance désactivé pour la carte:") + " " + map.name);
            // other updateMap for refresh map version
            dispatch(adminClientMapActions.updateMap(result.data));
        })
        .catch((apiResponse: apiErrorModel) => {
            toast.error(i18next.t("Une erreur s'est produite lors de la modification du mode maintenance"));
            // restore old value
            dispatch(adminClientMapActions.updateMap(map));
        })
    }
}
