import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MarkerTemplateDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

// Interface representing the state of the admin client map marker template store
export interface IAdminClientMapMarkerStore {
  markerTemplates: MarkerTemplateDetails[];
  loadingList: boolean;
  loadingForm: boolean;
}

// Initial state for the admin client map marker template store
const initialState: IAdminClientMapMarkerStore = {
  markerTemplates: [],
  loadingList: false,
  loadingForm: false,
};

// Create a slice for the admin client map marker template store
const adminClientMapMarkerTemplateSlice = createSlice({
  name: "adminClientMapMarkerTemplateSlice",
  initialState,
  reducers: {
    // Set the markers in the state
    setMarkerTemplates: (state, action: PayloadAction<MarkerTemplateDetails[]>) => {
      state.markerTemplates = action.payload;
    },
    // Update a specific marker template in the state
    updateMarkerTemplate: (state, action: PayloadAction<MarkerTemplateDetails>) => {
      state.markerTemplates = state.markerTemplates.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new marker template to the state
    addMarkerTemplate: (state, action: PayloadAction<MarkerTemplateDetails>) => {
      state.markerTemplates.push(action.payload);
    },
    // remove an marker
    deleteMarkerTemplate: (state, action: PayloadAction<string>) => {
      state.markerTemplates = state.markerTemplates.filter(m => m.id !== action.payload)
    },
    // Set the loadingList state
    setLoadingMarkerTemplateList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingForm state
    setLoadingMarkerTemplateForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.markerTemplates = [];
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

// Export the actions and reducer from the admin client map marker template slice
export const {
  actions: adminClientMapMarkerTemplateActions,
  reducer: adminClientMapMarkerTemplateReducer,
} = adminClientMapMarkerTemplateSlice;
