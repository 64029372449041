import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import "./DemoNotion.scss";
import FloorsComponent from './Components/FloorsComponent';
import ZoomComponent from './Components/ZoomComponent';
import Sdk from 'fno-sdk';
import Loading from 'components/Loading';
import appColors from 'resources/appColors';


const Demo1: React.FC = () => {
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZW5hbnQiOiJkZW1vQ2xpZW50IiwidG9rZW4tdHlwZSI6IlByb2plY3QiLCJzZWVkIjoiYzgyMzJmMzktZGFkNy00MjM0LTg4NjQtZjE1NThmYzBhMWM3IiwiaXNzIjoiRmluZCAmIE9yZGVyIGRldiIsImF1ZCI6IkZpbmQgJiBPcmRlciBkZXYifQ.WoXqG1WpZuoiSbquUSdK4my2eegMUC-ForcPbY-kuoQ';
    const mapId = "6526b1f36e179431c7dd412f";
    const tenant = "demoClient";
    
    const [sdk, setSdk] = useState<Sdk>();
    const [mapReady, setMapReady] = useState(false);
    
    const orders: {name:string, userLat: number, userLong: number, entityId: string}[] = [
        {name: "ORDER 1", userLong:2.29513115675926, userLat:48.9124812412974, entityId: "6526b2776e179431c7dd4327"},
        {name: "ORDER 2", userLong:2.29513115675926, userLat:48.9124812412974, entityId: "6526b2776e179431c7dd431a"},
        {name: "ORDER 3", userLong:2.29513115675926, userLat:48.9124812412974, entityId: "6526b2776e179431c7dd430b"},

        {name: "ORDER 4", userLong:2.29508231621652, userLat:48.9124808640322, entityId: "6526b27b6e179431c7dd43ce"},
        {name: "ORDER 5", userLong:2.29508231621652, userLat:48.9124808640322, entityId: "6526b2776e179431c7dd4315"},
        {name: "ORDER 6", userLong:2.29508231621652, userLat:48.9124808640322, entityId: "6526b2786e179431c7dd433a"},

        {name: "ORDER 7", userLong:2.29493743067147, userLat:48.9124798314906, entityId: "6526b2776e179431c7dd4305"},
        {name: "ORDER 8", userLong:2.29493743067147, userLat:48.9124798314906, entityId: "6526b2796e179431c7dd437d"},

        {name: "ORDER 9", userLong:2.29448108396958, userLat:48.9124764253126, entityId: "6526b26c6e179431c7dd4157"},
        {name: "ORDER 10", userLong:2.29448108396958, userLat:48.9124764253126, entityId: "6526b26f6e179431c7dd41cc"},
        {name: "ORDER 11", userLong:2.29448108396958, userLat:48.9124764253126, entityId: "6526b2716e179431c7dd421f"}
    ]


    useEffect(() => {
        document.title = "Démo notion";
        //setSdk(new Sdk(token, tenant));  <----- TODO change
    }, [])

    useEffect(() => {
        if(sdk)
        {
            sdk.initEngine("MapContainer").then(() => {
                sdk.loadMap(mapId).then(async () => {
                    setMapReady(true);

                    //sdk.camera.zoomOnEntity("6526b2796e179431c7dd4378");
                    await sdk.user.setUserPosition(2.2947190911982, 48.912240768386 ,0);
                    await sdk.user.moveCameraToUserPosition()
                    await sdk.camera.setZoom(0.4);
                })
            })
        }
    }, [sdk])

    

    const onClickOrder = async (order: {name:string, userLat: number, userLong: number, entityId: string}) => {
        await sdk?.pathfinding.clearItineraries();
        await sdk?.user.setUserPosition( order.userLong, order.userLat, 0);
        await sdk?.pathfinding.setItineraryToAsset(order.entityId, appColors.blue);
        await sdk?.user.moveCameraToUserPosition()
    }


    return (
        <Container id="demo1-body" className='g-0'  fluid style={{height: "100%"}}>
            {/*<Row id='menu'>
                
                <Col sm="12" className='map'>MENU</Col>
             </Row>*/}
            <Row id='content' className='g-0'>
                <Col id="map-overlay" sm="9">
                    {mapReady && sdk && <>
                        <div className='position-absolute' style={{bottom:"20px", left: "20px"}}><FloorsComponent sdk={sdk} /></div>
                        <div className='position-absolute' style={{top:"20px", left: "20px"}}><ZoomComponent sdk={sdk} /></div>
                    </>}
                    <canvas id="MapContainer"></canvas>
                </Col>
                <Col sm="3" className='sub-menu'>
                    <h2 className='text-center mt-2'>Pick list</h2>
                    {orders.map((order, i) => {
                        return <div key={i} className='order-row' onClick={() => onClickOrder(order)}>
                            {order.name}
                        </div>
                    })}
                </Col>
            </Row>
            {!mapReady && <Loading text={"Loading ..."} fullScreen fullScreenBackgroundColor='#fff'/>}
        </Container>
    )
}

export default Demo1
