/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, AssetCreate, AssetDetails, AssetUpdate, Operation } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MapAsset<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags MapAsset
     * @name AssetsList
     * @summary [🤵 OR 🔑] Get all assets associated with a map, optionally filtered by assets with location codes. [🔒: map_read].
     * @request GET:/map/{mapId}/assets
     * @secure
     */
    assetsList = (
        mapId: string,
        query?: {
            /**
             * If true, return only assets with location codes.
             * @default false
             */
            onlyWithLocationCode?: boolean
        },
        params: RequestParams = {}
    ) =>
        this.request<AssetDetails[], ApiErrorsResponse | void>({
            path: `/map/${mapId}/assets`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapAsset
     * @name AssetsPost
     * @summary [🤵 OR 🔑] Create several map assets. [🔒: map_create].
     * @request POST:/map/{mapId}/assets
     * @secure
     */
    assetsPost = (mapId: string, data: AssetCreate[], params: RequestParams = {}) =>
        this.request<AssetDetails[], ApiErrorsResponse | void>({
            path: `/map/${mapId}/assets`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapAsset
     * @name AssetsPut
     * @summary [🤵 OR 🔑] Update all fields of several map asset. [🔒: map_update].
     * @request PUT:/map/{mapId}/assets
     * @secure
     */
    assetsPut = (mapId: string, data: AssetUpdate[], params: RequestParams = {}) =>
        this.request<AssetDetails[], ApiErrorsResponse | void>({
            path: `/map/${mapId}/assets`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapAsset
     * @name AssetsDelete
     * @summary [🤵 OR 🔑] Delete many assets. [🔒: map_update].
     * @request DELETE:/map/{mapId}/assets
     * @secure
     */
    assetsDelete = (mapId: string, data: string[], params: RequestParams = {}) =>
        this.request<any, ApiErrorsResponse | void>({
            path: `/map/${mapId}/assets`,
            method: 'DELETE',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        })
    /**
     * No description
     *
     * @tags MapAsset
     * @name AssetDetails
     * @summary [🤵 OR 🔑] Get a specific map asset by its ID. [🔒: map_read].
     * @request GET:/map/{mapId}/asset/{assetId}
     * @secure
     */
    assetDetails = (mapId: string, assetId: string, params: RequestParams = {}) =>
        this.request<AssetDetails, ApiErrorsResponse | void>({
            path: `/map/${mapId}/asset/${assetId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapAsset
     * @name AssetPut
     * @summary [🤵 OR 🔑] Update all fields of a map asset. [🔒: map_update].
     * @request PUT:/map/{mapId}/asset/{assetId}
     * @secure
     */
    assetPut = (mapId: string, assetId: string, data: AssetUpdate, params: RequestParams = {}) =>
        this.request<AssetDetails, ApiErrorsResponse | void>({
            path: `/map/${mapId}/asset/${assetId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapAsset
     * @name AssetPatch
     * @summary [🤵 OR 🔑] Update specific fields of a map asset using a JSON Patch document. [🔒: map_update].
     * @request PATCH:/map/{mapId}/asset/{assetId}
     * @secure
     */
    assetPatch = (mapId: string, assetId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<AssetDetails, ApiErrorsResponse | void>({
            path: `/map/${mapId}/asset/${assetId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapAsset
     * @name AssetDelete
     * @summary [🤵 OR 🔑] Delete a map asset by its ID. [🔒: map_update].
     * @request DELETE:/map/{mapId}/asset/{assetId}
     * @secure
     */
    assetDelete = (mapId: string, assetId: string, params: RequestParams = {}) =>
        this.request<any, ApiErrorsResponse | void>({
            path: `/map/${mapId}/asset/${assetId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
    /**
     * No description
     *
     * @tags MapAsset
     * @name FloorAssetsDetails
     * @summary [🤵 OR 🔑] Get assets associated with a specific floor within a map. [🔒: map_read].
     * @request GET:/map/{mapId}/floor/{floorId}/assets
     * @secure
     */
    floorAssetsDetails = (mapId: string, floorId: string, params: RequestParams = {}) =>
        this.request<AssetDetails[], void>({
            path: `/map/${mapId}/floor/${floorId}/assets`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapAsset
     * @name AssetPost
     * @summary [🤵 OR 🔑] Create a new map asset. [🔒: map_create].
     * @request POST:/map/{mapId}/asset
     * @secure
     */
    assetPost = (mapId: string, data: AssetCreate, params: RequestParams = {}) =>
        this.request<AssetDetails, ApiErrorsResponse | void>({
            path: `/map/${mapId}/asset`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
}
