import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import "./HelperTooltip.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlacesType, Tooltip } from 'react-tooltip'
import _ from 'lodash';



interface OwnProps {
    text: string;
    color?: 'blue';
    placement?: PlacesType;
 }


/**
 * A component for displaying a helper tooltip with a question icon and text.
 *
 * @param {string} text - the text to be displayed in the tooltip
 * @param {string} [color="blue"] - the color of the question icon
 * @param {PlacesType} [placement="top"] - the placement of the tooltip
 * @return {ReactElement} the helper tooltip element
 */
const HelperTooltip: React.FC<OwnProps> = ({text, color = "blue", placement = "top"}) => {
   const { t } = useTranslation(nameOf({HelperTooltip}), { useSuspense: false});

   const [id] = useState(_.uniqueId());

   useEffect(() => {
   }, [])
   return (
       <span className='helper-tooltip-component' data-tooltip-id={id} data-tooltip-content={text}>
            <Tooltip id={id} place={placement} style={{maxWidth: "350px"}}/>
           <FontAwesomeIcon className={'question' + ' ' + color} icon={['fas', 'question-circle']} size="sm"/>
       </span>
   )
}
export default HelperTooltip