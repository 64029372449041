import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientMapMarkerActions } from "../adminClientMapMarkerSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Delete a Marker
 * @returns 
 */
export const deleteMarkerThrunks = (mapId: string, MarkerId: string, callbacks: ThrunkApiCallBacks<boolean>): AppThunk => {
    return async (dispatch, getState) => {
       try{

            dispatch(adminClientMapMarkerActions.setLoadingMarkerForm(true));
            await apiBackofficeDefinition.marker.markerDelete(mapId, MarkerId)
                .then((result) => {
                    dispatch(adminClientMapMarkerActions.deleteMarker(MarkerId))
                    callbacks.onSuccess(true);
                })
                .catch((apiResponse: apiErrorModel) => {
                    callbacks.onError(apiResponse);
                })
                .finally(() => dispatch(adminClientMapMarkerActions.setLoadingMarkerForm(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la suppression du marker"))
        }
    }
}