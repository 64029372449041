import { UpdateTranslationItemAdmin } from "backend/ApiAdminDefinition/data-contracts";
import apiAdminDefinition from "backend/apiAdminDefinition";
import i18next from "i18next";
import { toast } from "react-toastify";
import { ILoadingTranslationItem, adminTranslationsActions} from "store/reducers/Admin/Translation/adminTranslationsSlice";
import { AppThunk } from "store/store"


/**
 * Update a translation on the API
 * @param translation 
 * @returns 
 */
export const updateTranslationThunk = (translation: ILoadingTranslationItem): AppThunk => {
    return async (dispatch, getState) => {
        try{
            dispatch(adminTranslationsActions.addLoadingUpdateTranslation(translation));
            await apiAdminDefinition.translation.translationPut({
                lang: translation.lang, 
                page: translation.page, 
                name: translation.name, 
                translation: translation.translation
            } as UpdateTranslationItemAdmin);

            //await apiAdmin.translation.update(translation.lang, translation.page, translation.name, translation.translation);
            dispatch(adminTranslationsActions.removeLoadingUpdateTranslation(translation));
        }
        catch(error:any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la modification de la traduction"));

            dispatch(adminTranslationsActions.removeLoadingUpdateTranslation(translation));
        }
       
    }
}