import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MetadataGroupDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

// Interface representing the state of the admin client map marker template store
export interface IAdminClientMapMetadataStore {
  metadataGroups: MetadataGroupDetails[];
  loadingList: boolean;
  loadingForm: boolean;
}

// Initial state for the admin client map marker template store
const initialState: IAdminClientMapMetadataStore = {
  metadataGroups: [],
  loadingList: false,
  loadingForm: false,
};

// Create a slice for the admin client map marker template store
const adminClientMapMetadataSlice = createSlice({
  name: "adminClientMapMetadataSlice",
  initialState,
  reducers: {
    // Set the markers in the state
    setMetadata: (state, action: PayloadAction<MetadataGroupDetails[]>) => {
      state.metadataGroups = action.payload;
    },
    // Update a specific marker template in the state
    updateMetadata: (state, action: PayloadAction<MetadataGroupDetails>) => {
      state.metadataGroups = state.metadataGroups.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new marker template to the state
    addMetadata: (state, action: PayloadAction<MetadataGroupDetails>) => {
      state.metadataGroups.push(action.payload);
    },
    // remove an marker
    deleteMetadata: (state, action: PayloadAction<string>) => {
      state.metadataGroups = state.metadataGroups.filter(m => m.id !== action.payload)
    },
    // Set the loadingList state
    setLoadingMetadataList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingForm state
    setLoadingMetadataForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.metadataGroups = [];
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

// Export the actions and reducer from the admin client map marker template slice
export const {
  actions: adminClientMapMetadataActions,
  reducer: adminClientMapMetadataReducer,
} = adminClientMapMetadataSlice;
