import _ from "lodash";
import { AppThunk } from "store/store";
import { adminClientFileManagerActions } from "store/reducers/Admin/FileManager/adminClientFileManagerSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";
import { FileDetails, FileUpdate } from "backend/ApiBackofficeDefinition/data-contracts";
import IFileUploadStatus from "models/IFileUploadStatus";
import uploadFileToStorage from "utility/uploadFileToStorage";




export const updateAndUploadFilesThunk = (fileData: FileUpdate, file: File, callbacks: ThrunkApiCallBacks<FileDetails>): AppThunk => {
    return async (dispatch, getState) => {
        
        dispatch(adminClientFileManagerActions.setLoadingUpload(true));
        dispatch(adminClientFileManagerActions.setUpdatingFilesStatus([{name: file.name, status: 'waiting' } as IFileUploadStatus]));

        dispatch(adminClientFileManagerActions.updateUploadingFilesStatus({ name: file.name, status: 'pending' } as IFileUploadStatus));


        const result = await apiBackofficeDefinition.storage.filePut(fileData.id, {...fileData, fileName: file.name} as FileDetails)
        if(!result.ok)
        {
            callbacks.onError(result.error as apiErrorModel)
        }
        else
        {
            if(result && result.data.urlUpload){
                dispatch(adminClientFileManagerActions.updateUploadingFilesStatus({ name: file.name, status: 'success' } as IFileUploadStatus));
            }
            else{
                dispatch(adminClientFileManagerActions.updateUploadingFilesStatus({ name: file.name, status: 'error' } as IFileUploadStatus));
            }
        }
    

        if(result && result.data.urlUpload){
            const resultUpload = await uploadFileToStorage(result.data.urlUpload, file);
            dispatch(adminClientFileManagerActions.updateUpdatingFilesStatus({ name: file.name, status: 'success' } as IFileUploadStatus));
            callbacks.onSuccess(result.data);
        }
        else{
            dispatch(adminClientFileManagerActions.updateUpdatingFilesStatus({ name: file.name, status: 'error' } as IFileUploadStatus));
        }
            
        
        dispatch(adminClientFileManagerActions.setLoadingUpload(false));

    }
}
