import i18next from "i18next";
import _ from "lodash";
import { toast } from "react-toastify";
import { adminClientRolesActions } from "store/reducers/Admin/ClientRole/adminClientRolesSlice";
import { AppThunk } from "store/store";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { RoleClientCreate, RoleClientDetails } from "backend/ApiAdminDefinition/data-contracts";
import apiAdminDefinition from "backend/apiAdminDefinition";
import apiErrorModel from "backend/utils/apiErrorModel";


/**
 * create a role
 * @param role 
 * @returns 
 */
export const createRoleThunk = (roleClient: RoleClientCreate, callbacks: ThrunkApiCallBacks<RoleClientDetails>): AppThunk => {
    return async (dispatch, getState) => {

        const selectedClient = getState().userProfil.currentTenant;

        if(!selectedClient) {
            toast.warn(i18next.t("Impossible de modifier une permission car aucun client n'est sélectionné"))
            return;
        }
        dispatch(adminClientRolesActions.setLoadingEdit(true));
        await apiAdminDefinition.roleClient.clientRolePost(roleClient)
            .then((result) => {
                dispatch(adminClientRolesActions.addRole(result.data));
                callbacks.onSuccess(result.data);
            })
            .catch((errors: apiErrorModel) =>  callbacks.onError(errors))
            .finally(() => dispatch(adminClientRolesActions.setLoadingEdit(false)));

    }
}
