import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import { Col, Row } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import AceEditor from "react-ace-builds";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import { toast } from 'react-toastify';

interface OwnProps {
    value: any;
    loading?: boolean;
    onSubmit?: (entity: any) => void;
    onCancel: () => void | undefined
 }

/**
 * React functional component that renders an editor for editing raw JSON data. 
 * The component supports both creating and editing JSON data.
 *
 * @param {OwnProps} props - The component props.
 * @param {any} [props.value] - The initial JSON value to be edited (optional).
 * @param {boolean} [props.loading] - A flag indicating if the form is in a loading state (optional).
 * @param {(entity: any) => void} [props.onSubmit] - The function called when the JSON is submitted (optional).
 * @param {() => void} [props.onCancel] - The function called when the form is canceled (optional).
 * @returns {JSX.Element} The rendered editor component.
 */
const EditJsonRaw: React.FC<OwnProps> = ({onSubmit, value, onCancel = undefined, loading = false}) => {
    const { t } = useTranslation([nameOf({PolygonForm: EditJsonRaw}), "TransverseError"], { useSuspense: false});
    const [json, setJson] = useState(JSON.stringify(value, null, "\t"));
    


    const onSave = () => {
        try{
            const object = JSON.parse(json);
            if(onSubmit) onSubmit(object);
        }
        catch(e){
            toast.error(t("Le format du JSON n'est pas valide"));
        }
    }


    return (
        <div>
            <fieldset disabled={loading}>
                <Row>
                    <Col>
                    <AceEditor
                        width='100%'
                        height='600px'
                        mode="json"
                        theme="monokai"
                        name="jsonEditor"
                        fontSize={14}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        onChange={(newJson) => setJson(newJson)}
                        value={json}
                        setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            useWorker: false,
                            showLineNumbers: true,
                            tabSize: 2,
                        }}/>
                    </Col>
                </Row>
                
                <div className='d-flex justify-content-center mt-3'>
                    <ButtonFno className={ "me-1 " + (!onSubmit ? "w-100":"w-50" )} color='blue' disabled={loading} onClick={onCancel}>{t('Retour')}</ButtonFno>
                    {onSubmit && <ButtonFno className="w-50 ms-1" color='orange' loading={loading} disabled={loading} onClick={onSave}>{t('Valider')}</ButtonFno>}
                </div>
            </fieldset>
        </div>
    )
}

export default EditJsonRaw
