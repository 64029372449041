import i18next from "i18next";
import { toast } from "react-toastify";
import { adminClientMapActions} from "store/reducers/Admin/ClientMap/adminClientMapSlice";
import { AppThunk } from "store/store"
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Load current selected client's places
 * @returns 
 */
export const loadClientPlacesThunk = (): AppThunk => {
    return async (dispatch, getState) => {
        const selectedClient = getState().userProfil.currentTenant;

        if(!selectedClient) {
            toast.warn(i18next.t("Impossible de récupérer les places car aucun client n'est sélectionné"))
            return;
        }

       try{

            dispatch(adminClientMapActions.setLoadingMapList(true));
            const clientMaps = await apiBackofficeDefinition.map.mapsList();
            dispatch(adminClientMapActions.setMaps(clientMaps.data));
            
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des lieux du client"))
        }
        dispatch(adminClientMapActions.setLoadingMapList(false));
    }
}