/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, NavmeshCreate, NavmeshDetails, NavmeshDetailsWithUploadLink, NavmeshUpdate } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MapNavMesh<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags MapNavMesh
     * @name NavmeshesList
     * @summary [🤵 OR 🔑] Get all navmesh from a mapId. [🔒: map_read].
     * @request GET:/map/{mapId}/navmeshes
     * @secure
     */
    navmeshesList = (mapId: string, params: RequestParams = {}) =>
        this.request<NavmeshDetails[], void>({
            path: `/map/${mapId}/navmeshes`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapNavMesh
     * @name NavmeshPost
     * @summary [🤵 OR 🔑] Create new navmesh. [🔒: map_create].
     * @request POST:/map/{mapId}/navmesh
     * @secure
     */
    navmeshPost = (mapId: string, data: NavmeshCreate, params: RequestParams = {}) =>
        this.request<NavmeshDetailsWithUploadLink, ApiErrorsResponse | void>({
            path: `/map/${mapId}/navmesh`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapNavMesh
     * @name NavmeshPut
     * @summary [🤵 OR 🔑] Update all fields of a navmesh. [🔒: map_update].
     * @request PUT:/map/{mapId}/navmesh/{navmeshId}
     * @secure
     */
    navmeshPut = (mapId: string, navmeshId: string, data: NavmeshUpdate, params: RequestParams = {}) =>
        this.request<NavmeshDetails, ApiErrorsResponse | void>({
            path: `/map/${mapId}/navmesh/${navmeshId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapNavMesh
     * @name NavmeshDelete
     * @summary [🤵 OR 🔑] Update all fields of a navmesh. [🔒: map_delete].
     * @request DELETE:/map/{mapId}/navmesh/{navmeshId}
     * @secure
     */
    navmeshDelete = (mapId: string, navmeshId: string, params: RequestParams = {}) =>
        this.request<any, ApiErrorsResponse | void>({
            path: `/map/${mapId}/navmesh/${navmeshId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
    /**
     * No description
     *
     * @tags MapNavMesh
     * @name NavmeshsPut
     * @summary [🤵 OR 🔑] Update all fields of several map navmesh. [🔒: map_update].
     * @request PUT:/map/{mapId}/navmeshs
     * @secure
     */
    navmeshsPut = (mapId: string, data: NavmeshUpdate[], params: RequestParams = {}) =>
        this.request<NavmeshDetails[], ApiErrorsResponse | void>({
            path: `/map/${mapId}/navmeshs`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
}
