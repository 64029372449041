/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AccountInfos } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Account<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Account
     * @name UserInfosList
     * @summary [🤵] Get current user infos.
     * @request GET:/account/user/infos
     * @secure
     */
    userInfosList = (params: RequestParams = {}) =>
        this.request<AccountInfos, void>({
            path: `/account/user/infos`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
}
