import _ from "lodash";
import { AppThunk } from "store/store";
import { adminClientTokenActions } from "store/reducers/Admin/ClientToken/adminClientTokenSlice";
import { TokenCreate, TokenDetails } from "backend/ApiAdminDefinition/data-contracts";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiAdminDefinition from "backend/apiAdminDefinition";
import apiErrorModel from "backend/utils/apiErrorModel";


/**
 * Create a token for current client
 * @param token 
 * @returns 
 */
export const createTokenThunk = (token: TokenCreate, callbacks: ThrunkApiCallBacks<TokenDetails>): AppThunk => {
    return async (dispatch, getState) => {

        dispatch(adminClientTokenActions.setLoadingTokenForm(true));
        apiAdminDefinition.token.clientTokenPost(token)
            .then((result) => {
                dispatch(adminClientTokenActions.addToken(result.data));
                // close form modal
                dispatch(adminClientTokenActions.setShowCreateTokenModal(false));
                callbacks.onSuccess(result.data);
            })
            .catch((errors: apiErrorModel) =>  callbacks.onError(errors))
            .finally(() => dispatch(adminClientTokenActions.setLoadingTokenForm(false)));

    }
}
