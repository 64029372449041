import _ from "lodash";
import { AppThunk } from "store/store";
import { adminClientFileManagerActions } from "store/reducers/Admin/FileManager/adminClientFileManagerSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";
import IFileUploadStatus from "models/IFileUploadStatus";



// TODO a finir !!!! et a tester !
export const deleteFilesThunk = (deletingFiles: IFileUploadStatus[], callbacks: ThrunkApiCallBacks<boolean>): AppThunk => {
    return async (dispatch, getState) => {
        
        dispatch(adminClientFileManagerActions.setLoadingUpload(true));


        dispatch(adminClientFileManagerActions.setDeletingFilesStatus(deletingFiles.map((f) => {return { name: f.name, status: 'waiting' } as IFileUploadStatus})));
        for (let i = 0; i < deletingFiles.length; i++) {
            const file = deletingFiles[i];
            dispatch(adminClientFileManagerActions.updateDeletingFilesStatus({ name: file.name, status: 'pending' } as IFileUploadStatus));
            const result = await apiBackofficeDefinition.storage.fileDelete(file.id ?? "")
            .then((result) => {
                dispatch(adminClientFileManagerActions.updateDeletingFilesStatus({ name: file.name, status: 'success' } as IFileUploadStatus));
            })
            .catch((apiResponse: apiErrorModel) => {
                
                dispatch(adminClientFileManagerActions.updateDeletingFilesStatus({ name: file.name, status: 'error' } as IFileUploadStatus));
                callbacks.onError(apiResponse);
            });  
        }
        dispatch(adminClientFileManagerActions.setLoadingUpload(false));
        callbacks.onSuccess(true)
    }
}
