import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store';
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import getCurrentTenant from 'backend/utils/getCurrentTenant';
import useFnoMapSdk from 'hooks/useFnoMapSdk';
import { useNavigate } from 'react-router-dom';
import appColors from 'resources/appColors';
import TimcodeFinalCompareRowRender from './TimcodeFinalCompareRowRender';
import MapRender from 'components/mapControls/MapRender';
import SelectDayComponent from './SelectDayComponent';
import StatisticCard from 'components/StatisticCard';

export interface IOrderRow{
    ref: string;
    distance: number,
    products: {
        ean: string;
        emplacement: string;
        //quantity: string;
    }[];
    searchField: string;
}



const TimcodeFinalResultTimcode: React.FC = () => {
    const { t } = useTranslation(nameOf({TimcodeFinalResultTimcode}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const currentMap = useAppSelector(state => state.map.currentMap);

    const {
        sdk,
        loading,
        mapReady
    } = useFnoMapSdk(getCurrentUserToken(), getCurrentTenant()?.tenant ?? "", currentMap?.id ?? "");

    const {
        allFiles,
        selectedFile,
        selectedFileData,
        loadingFile
    } = useAppSelector(state => state.timcodeDemo);

    const [rowSelected, setRowSelected] = useState<IOrderRow | undefined>(undefined);
    const [rows, setRows] = useState<IOrderRow[]>([]);

    //  const rawData = useAppSelector(state => state.audit.data);
    //  const optimizationResult = useAppSelector(state => state.audit.optimizationResult);
    //  const loadingAuditData = useAppSelector(state => state.audit.loadingRawFile || state.audit.loadingOptimizationResult);
    //  const auditRawFileSelected = useAppSelector(state => state.audit.auditRawFileSelected);

    const [searchTerm, setSearchTerm] = useState('');
    

    useEffect(() => {
        document.title = t('Optimisation de chemin');
        return () => {
            sdk.asset.clearHighlights();
            sdk.pathfinding.clearItineraries();
        }
    }, [])
    
    useEffect(() => {
        
        if(selectedFileData)
        {
            const group: IOrderRow[] = _.map(selectedFileData.final_timcod.missions, (items, key) => {
                return {
                    ref: key,
                    distance: items.totalDistance,
                    products: [
                        ...items.emplacement.map((product, index) => {
                            return {
                                ean: selectedFileData.final_timcod.missions[key].supports[index],
                                emplacement: product,
                                //quantity: product[rawData.fields[auditRawFileSelected?.columnsBindingData.fieldQuantity]] as string
                            }
                        })
                    ],
                    // merge all fields in one for search purpose
                    searchField: "",//_.join(_.values(items), " ").toLocaleLowerCase() 
                }  as IOrderRow
            });

            setRows(group);
        
        }
        else{
            setRows([]);
        }
    }, [selectedFileData])


    useEffect(() => {
        if(!rowSelected)
        {
            clearItineraries();
        }
        else
        {
            showItinerary(rowSelected);
        }
    }, [rowSelected])


    const showItinerary = async (order: IOrderRow) => {
      
        // optimized
        await sdk?.pathfinding.setItineraryToAssetsByLocationCode(order.products.map(item => item.emplacement), appColors.orange);
        // original = "#cd5c5c"
    }

    const clearItineraries = async () => {
        await sdk?.pathfinding?.clearItineraries();
    }


    const onHoverEnter = (order: IOrderRow) => {
        if(!rowSelected)
        {
            showItinerary(order)
        }
    }   
    const onHoverLeave = (order: IOrderRow) => {
        if(!rowSelected)
        {
            clearItineraries()
        }
    }

    const oldCost = selectedFileData ? _.sumBy(_.map(selectedFileData.final_superU.missions), "totalDistance") : 0;
    const finalCost = selectedFileData ?  _.sumBy(_.map(selectedFileData.final_timcod.missions), "totalDistance"): 0;
    const gainpourcent = -((finalCost - oldCost) / oldCost) * 100;
    const gainPourcentString = gainpourcent.toFixed(2);


    return (
        <div id="statistic-picking-optimization-path">
             {<PageHeader title={t('Résultat Super U')}/>}
             <Row style={{height: "100%"}}>
                <Col sm="8">
                    <MapRender 
                        sdk={sdk} 
                        mapReady={mapReady}
                        assetToolipConfig={{
                            highlightColor: appColors.orange
                        }}/>
                </Col>
                <Col id="col-table-container" sm="4">
                    {mapReady && !loadingFile && <div>
                        <h4>
                            {t("Missions optimisées")} 
                            {/*<Badge  className='ms-2 fs-6' bg="orange">{`${t("Gain moyen final")} ${gainPourcentString}%`}</Badge>*/}
                            <Button size='sm' variant='link' title={t('Centrer caméra')} className='ms-1 float-end' onClick={() => sdk?.user.moveCameraToUserPosition()}>
                                <FontAwesomeIcon icon={["fas", "bullseye"]} />
                            </Button>
                        </h4>
                        <Row className='align-items-center'>
                            <Col><SelectDayComponent /></Col>
                            <Col><StatisticCard sm title={t("Gain moyen final")} color='green' value={`${gainPourcentString}%`} icon='chart-line' loading={loadingFile}/></Col>
                        </Row>
                        

                        <div>
                            {sdk && <>
                            <div>
                                <input className='w-100 search-input mb-2' placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>
                            </div>
                            <TimcodeFinalCompareRowRender 
                                        sdk={sdk} 
                                        onClick={setRowSelected} 
                                        orders={_.filter(rows, (o) => {return searchTerm ? o.ref.includes(searchTerm) : true})} 
                                        onHoverEnter={onHoverEnter} 
                                        onHoverLeave={onHoverLeave} />
                            </>}
                        </div>
                    </div>}
                    {loadingFile && <div className='loading-map-panel'><Loading text={t("Chargement des données")}/></div>}
                </Col>
             </Row>
        </div>
    )
}

export default TimcodeFinalResultTimcode
