import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientMapFloorActions } from "../adminClientMapFloorSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Delete a floor
 * @returns 
 */
export const deleteFloorThrunk = (mapId: string, floorId: string, callbacks: ThrunkApiCallBacks<boolean>): AppThunk => {
    return async (dispatch, getState) => {
       try{

            dispatch(adminClientMapFloorActions.setLoadingFloorForm(true));
            await apiBackofficeDefinition.floor.floorDelete(mapId, floorId)
                .then((result) => {
                    dispatch(adminClientMapFloorActions.deleteFloor(floorId))
                    callbacks.onSuccess(true);
                })
                .catch((apiResponse: apiErrorModel) => {
                    callbacks.onError(apiResponse);
                })
                .finally(() => dispatch(adminClientMapFloorActions.setLoadingFloorForm(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la suppression du floor"))
        }
    }
}