import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MiddlewareApiDetails } from "backend/ApiAdminDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

export interface IMiddlewarePickingStore {
  middlewares: MiddlewareApiDetails[];
  middlewareSelected: MiddlewareApiDetails | undefined;
}

const initialState: IMiddlewarePickingStore = {
  middlewareSelected: undefined,
  middlewares:[],
};

/**
 * Manage missleware apis available for current tenant
 */
const middlewareApisSlice = createSlice({
  name: "middlewareApisSlice",
  initialState,
  reducers: {
    setMiddlewares: (state, action: PayloadAction<MiddlewareApiDetails[]>) => {
      state.middlewares = action.payload;
    },
    setMiddlewareSelected: (state, action: PayloadAction<MiddlewareApiDetails | undefined>) => {
      state.middlewareSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state = initialState;
    });
  },
});

// Export the actions and reducer from the admin client map asset slice
export const {
  actions: middlewareApisActions,
  reducer: middlewareApisReducer,
} = middlewareApisSlice;
