import { AppThunk } from "store/store";
import { loadClientPermissionsThunk } from "../ClientPermission/thrunks/loadClientPermissionsThunk";
import { loadClientPlacesThunk } from "../ClientMap/thrunks/loadClientPlacesThunk";
import { loadClientRolesThunk } from "../ClientRole/thrunks/loadClientRolesThunk";
import { loadClientTokensThunk } from "../ClientToken/thrunks/loadClientTokensThunk";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";
import { loadClientFilesThunk } from "../FileManager/thrunks/loadClientFilesThunk";
import { adminClientFileManagerActions } from "store/reducers/Admin/FileManager/adminClientFileManagerSlice";
import { ClientItem } from "backend/ApiBackofficeDefinition/data-contracts";


/**
 * Select current client and load additional content for admin pages (permissions, roles, places etc ...)
 * @param tenant 
 * @returns 
 */
export const selectTenantForAdminThunk = (tenant: ClientItem  | undefined): AppThunk => {
    return async (dispatch, getState) => {
        if(tenant)
        {
            dispatch(userProfilActions.setCurrentTenant(tenant));
            dispatch(loadClientPlacesThunk());
            dispatch(loadClientRolesThunk());
            dispatch(loadClientPermissionsThunk());
            dispatch(loadClientTokensThunk());
            // clean storage file of old client
            dispatch(adminClientFileManagerActions.setFiles([]))
            // load storage file for new client
            dispatch(loadClientFilesThunk());
        }
        else
        {
            dispatch(userProfilActions.setCurrentTenant(undefined));
            // TODO clean le store quand on déselectionne un client
        }
    }
}