import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import Switch from "react-switch";
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import { ROUTES } from 'resources/routes-constants';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import { updateRoleThunk } from 'store/reducers/Admin/ClientRole/thrunks/updateRoleThunk';
import { Badge, Button, Modal } from 'react-bootstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonFno from 'components/inputs/ButtonFno';
import RoleForm from './RoleForm';
import { PermissionDetails, RoleClientDetails } from 'backend/ApiAdminDefinition/data-contracts';
import { toast } from 'react-toastify';

const AdminClientPermissions: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientPermissions}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const selectedClient = useAppSelector(state => state.userProfil.currentTenant);
    const loading = useAppSelector(state => state.adminClientPermission.loadingList);
    const permissions = useAppSelector(state => state.adminClientPermission.permissions);
    const roles = useAppSelector(state => state.adminClientRole.roles);

    const [showCreateRoleForm, setShowCreateRoleForm] = useState(false);
    const { clientId } = useParams();
    
    useEffect(() => {
        document.title = t('[Admin] Permissions du client');
    }, [])

    if(!selectedClient)
    {
        return <Navigate to={ROUTES.ADMIN.CLIENTS} replace />
    }


    const selectAll = (role: RoleClientDetails) => {
        const roleUpdate = _.clone(role);
        const newPermissions: {[key:string]: boolean} ={}
        permissions.forEach((p) => newPermissions[p.name] = true);
        roleUpdate.permissions = newPermissions;
        dispatch(updateRoleThunk(roleUpdate,{
            onSuccess: () => {},
            onError: () => {}
        }));
    }

    const deselectAll = (role: RoleClientDetails) => {
        const roleUpdate = _.clone(role);
        const newPermissions: {[key:string]: boolean} ={}
        permissions.forEach((p) => newPermissions[p.name] = false);
        roleUpdate.permissions = newPermissions;
        dispatch(updateRoleThunk(roleUpdate,{
            onSuccess: () => {},
            onError: () => {}
        }));
    }

    const getColumns = () => {
        const columns: TableColumn<PermissionDetails>[] = [
            {
                id:'category',
                name: t('Catégorie'),
                sortable: true,
                width:"120px",
                selector: row => row.category,
                cell: row => <Badge className='text-uppercase'>{row.category}</Badge>
            },
            {
                id:'permission',
                name: t('Permission'),
                sortable: true,
                width:"300px",
                selector: row => row.name
            },
            {
                id:'description',
                name: t('Description'),
                sortable: true,
                selector: row => t(row.name).toString(),
                cell: row => t(row.name)
            }
        ];


        roles.forEach((role) => {

            const allChecked = _.every(permissions, (p) => role.permissions[p.name])
            columns.push({
                id:`role_${role.id}`,
                name: <div>
                    {role.name} 
                    <Button size="sm" variant='link' className='text-orange' onClick={() => allChecked ? deselectAll(role) : selectAll(role)} title={allChecked ? t("Tout désélectionner"):  t("Tout sélectionner")}>
                        <FontAwesomeIcon icon={['fas', allChecked ? 'square': 'square-check']} size="sm"/>
                    </Button>
                </div>,
                sortable: false,
                width:"150px",
                selector: row => row.name,
                cell: (row, index, column, id)  => {

                    const enable = role.permissions[row.name];

                    return <div> <Switch checked={enable ? enable : false} onChange={(enabled) => {
                        const roleUpdate = _.clone(role);
                        roleUpdate.permissions = {
                            ...roleUpdate.permissions,
                            [row.name]: enabled
                        }
                        dispatch(updateRoleThunk(roleUpdate,{
                            onSuccess: () => {

                            },
                            onError: (errors) => {
                                toast.error(t("Impossible de modifier la permission"))
                            }
                        }));
                    }}/></div>
                }
            })
        })


        return columns;
    };




    return (
        <div>
             <PageHeader title={t("Permissions")} admin middlePart={[
                {to: ROUTES.ADMIN.CLIENTS, title: t("Clients")},
                {to: ROUTES.ADMIN.CLIENT(clientId).DETAILS, title: selectedClient.name}]}/>
            <div>
                <div className='table-component'>
               
                    <DataTable 
                        columns={getColumns()} 
                        data={permissions} 
                        
                        progressPending={loading}
                        progressComponent={<Loading text={t("Chargement")}/>}
                        defaultSortFieldId={'category'} 
                        defaultSortAsc={false} 
                        striped={true}
                        subHeader={true}
                        highlightOnHover={true}
                        noDataComponent={<div className='w-100 text-center p-5'>{t("Aucune permission disponible")}</div>}
                        subHeaderComponent={<> 
                            <h5 className='text-uppercase'> {t("Permissions") + " ("+permissions.length+")"} {loading && <Loading inline size={20}/>}</h5>
                            <div className='search-panel'>
                                <ButtonFno sm className='me-2' onClick={() => setShowCreateRoleForm(true)}><FontAwesomeIcon className='me-2' icon={["fas", "plus"]} /> {t("Créer un rôle")}</ButtonFno>
                            </div>
                            </>}
                        sortServer={false}/>
                </div>
            </div>


            {showCreateRoleForm && <Modal dialogClassName='modal-fno' show={true} onHide={() => setShowCreateRoleForm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Créer un nouveau rôle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RoleForm onClose={() => setShowCreateRoleForm(false)}/>
                </Modal.Body>
            </Modal>}


        </div>
    )
}

export default AdminClientPermissions
