import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/store';
import SelectLarge from '../inputs/SelectLarge';
import { MiddlewareApiDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import { middlewareApisActions } from 'store/reducers/MiddlewareApis/middlewareApisSlice';

/**
 * Middleware selector component
 * @returns 
 */
const MiddlewareSelector: React.FC = () => {

    const {
        middlewareSelected,
        middlewares
    } = useAppSelector(state => state.middlewareApis);

    const dispatch = useAppDispatch();


    const onMiddlewareChange = (middleware: MiddlewareApiDetails) => {
        dispatch(middlewareApisActions.setMiddlewareSelected(middleware));
    }


    if(middlewares && middlewares.length > 1)
    {
        return <SelectLarge options={middlewares} 
                        isDisabled = {false}
                        value={middlewareSelected} 
                        onChange={onMiddlewareChange} 
                        valueRender={(middleware) =>{
                            const url = middleware.baseUrl.replaceAll("https://", "").replaceAll("http://", "");
                            return <div className='text-truncate text-uppercase'>
                            <div><FontAwesomeIcon className='me-2' icon={["fas", "network-wired"]} /> {url.length > 25 ? url.slice(0, 18) + "...": url}</div>
                            </div>
                        }}
                        optionRender={(middleware) => <div className='text-truncate text-uppercase'><FontAwesomeIcon className='me-2' icon={["fas", "network-wired"]} />  {middleware.baseUrl.replaceAll("https://", "")}</div>}/>
    }
    else 
    {
        return <></>
    }
}

export default MiddlewareSelector