import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { userProfilActions } from "../../Profil/userProfilSlice";
import { RoleAppDetails } from "backend/ApiAdminDefinition/data-contracts";

export interface IAdminClientStore {
  appRoles: RoleAppDetails[];
}
  
const initialState: IAdminClientStore = {
  appRoles: []
}

const adminGlobalSlice = createSlice({
  name: "adminGlobalReducer",
  initialState,
  reducers: {
    setAppRoles: (state, action: PayloadAction<RoleAppDetails[]>) => {
      state.appRoles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.appRoles = [];
    });
  },
});

export const { actions: adminGlobalActions, reducer: adminGlobalReducer } = adminGlobalSlice