import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiErrorModel from "backend/utils/apiErrorModel";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import {  NavmeshDetails, NavmeshUpdate} from "backend/ApiBackofficeDefinition/data-contracts";
import { adminClientMapNavmeshActions } from "../adminClientMapNavmeshSlice";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Thunk action creator for updating an Navmesh
 * @param mapId 
 * @param Navmesh 
 * @param callbacks 
 * @returns 
 */
export const updateNavmeshThunk = (
    mapId: string,
    Navmesh: NavmeshUpdate,
    callbacks: ThrunkApiCallBacks<NavmeshDetails>
  ): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapNavmeshActions.setLoadingNavmeshForm(true));
  
        // Make API call to create Navmesh
        await apiBackofficeDefinition.navmesh.navmeshPut(mapId, Navmesh.id ,Navmesh)
          .then((result) => {
            // Dispatch action to add newly created Navmesh to store
            dispatch(adminClientMapNavmeshActions.updateNavmesh(result.data));
            // Call onSuccess callback
            callbacks.onSuccess(result.data);
          })
          .catch((apiResponse: apiErrorModel) => {
            // Call onError callback with API error response
            callbacks.onError(apiResponse);
          });
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors de la modification de la Navmesh"));
          callbacks.onError({ errors:{"api":[error.message]} } as apiErrorModel)
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapNavmeshActions.setLoadingNavmeshForm(false));
      }
    };
  };