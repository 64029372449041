import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import MenuAdmin from 'components/Admin/MenuAdmin';
import LanguageSelector from 'components/selectors/LanguageSelector';
import Loading from 'components/Loading';
import { ROUTES } from 'resources/routes-constants';
import { useAppDispatch, useAppSelector } from 'store/store'
import { loadDefaultLanguagesThunk } from 'store/reducers/Admin/Translation/thrunks/loadDefaultLanguagesThunk';
import { loadClientsThunk } from 'store/reducers/Admin/Client/thrunks/loadClientsThunk';
import nameOf from 'utility/nameOf';
import LayoutClient from './client/LayoutClient';
import "./LayoutAdmin.scss"
import { loadAppRolesThunk } from 'store/reducers/Admin/Global/thrunks/loadAppRolesThunk';
import dayjs from 'dayjs';
import { selectTenantForAdminThunk } from 'store/reducers/Admin/thrunks/selectTenantForAdminThunk';
import { loadMiddlewaresThrunk } from 'store/reducers/Admin/RealtimeMiddleware/thrunks/loadMiddlewaresThrunk';
import GlobalSearchForm from 'components/Admin/forms/GlobalSearchForm';

type Props = {
    children?: React.ReactNode
  };

const LayoutAdmin: React.FC<Props> = ({children}) => {
    const { t } = useTranslation(nameOf({LayoutAdmin}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const defaultLanguages = useAppSelector(state => state.adminTranslations.defaultLanguages);
    const loadingAllClients = useAppSelector(state => state.adminClient.loadingAllClients);
    const location = useLocation();
    const isClientPages = location.pathname.startsWith(ROUTES.ADMIN.CLIENT().BASE_PATH + "/");

    useEffect(() => {
        
        if(!isClientPages)
        {
            // Remove old selected tenant
            dispatch(selectTenantForAdminThunk(undefined));
        }
        
        // preload tenant when admin section is shown
        dispatch(loadClientsThunk());
        // preload all defaults lanaguages availables
        dispatch(loadDefaultLanguagesThunk());
        // preload app roles (client or admin)
        dispatch(loadAppRolesThunk());
        // preload middleware list
        dispatch(loadMiddlewaresThrunk());
    }, [])



    // check if we need to insert a sub-layout (example: for client pages)
    let subLayout = children
    if(isClientPages)
    {
        subLayout = <LayoutClient>{children}</LayoutClient>
    }
    

    const dateCommit = process.env.REACT_APP_COMMIT_DATE ? dayjs(process.env.REACT_APP_COMMIT_DATE).format("DD/MM/YYYY HH:mm") : "N/C";

    return (<div style={{height: "100vh"}}>
        <Row id="top-pane-admin" className='mx-0'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </Row>
            <Row className='mx-0'>
                <Col id="menu-panel-admin" sm="2" className='px-0'>
                    <MenuAdmin/>
                    <div id="menu-footer">
                        {defaultLanguages && <LanguageSelector languages={defaultLanguages}/>}
                        <small>Commit date: {dateCommit}</small>
                    </div>
                </Col>
                <Col id="content-panel-admin">
                    {loadingAllClients && <Loading text={t('Chargement')} fullScreen/>}
                    {subLayout}
                </Col>
            </Row>
    </div>)
}

export default LayoutAdmin
