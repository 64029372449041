/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, BuildingCreate, BuildingDetails, BuildingUpdate, Operation } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MapBuilding<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags MapBuilding
     * @name BuildingsList
     * @summary [🤵 OR 🔑] Get all map buildings associated with a specific map. [🔒: map_read].
     * @request GET:/map/{mapId}/buildings
     * @secure
     */
    buildingsList = (mapId: string, params: RequestParams = {}) =>
        this.request<BuildingDetails[], void>({
            path: `/map/${mapId}/buildings`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapBuilding
     * @name BuildingsDelete
     * @summary [🤵 OR 🔑] [🔒: map_update].
     * @request DELETE:/map/{mapId}/buildings
     * @secure
     */
    buildingsDelete = (mapId: string, data: string[], params: RequestParams = {}) =>
        this.request<any, ApiErrorsResponse | void>({
            path: `/map/${mapId}/buildings`,
            method: 'DELETE',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        })
    /**
     * No description
     *
     * @tags MapBuilding
     * @name BuildingPost
     * @summary [🤵 OR 🔑] Create a new map building associated with a specific map. [🔒: map_create].
     * @request POST:/map/{mapId}/building
     * @secure
     */
    buildingPost = (mapId: string, data: BuildingCreate, params: RequestParams = {}) =>
        this.request<BuildingDetails, void>({
            path: `/map/${mapId}/building`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapBuilding
     * @name BuildingPut
     * @summary [🤵 OR 🔑] Update all fields of a map building associated with a specific map. [🔒: map_update].
     * @request PUT:/map/{mapId}/building/{buildingId}
     * @secure
     */
    buildingPut = (mapId: string, buildingId: string, data: BuildingUpdate, params: RequestParams = {}) =>
        this.request<BuildingDetails, void>({
            path: `/map/${mapId}/building/${buildingId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapBuilding
     * @name BuildingPatch
     * @summary [🤵 OR 🔑] Update specific fields of a map building associated with a specific map using a JSON Patch document. [🔒: map_update].
     * @request PATCH:/map/{mapId}/building/{buildingId}
     * @secure
     */
    buildingPatch = (mapId: string, buildingId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<BuildingDetails, void>({
            path: `/map/${mapId}/building/${buildingId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags MapBuilding
     * @name BuildingDelete
     * @summary [🤵 OR 🔑] Delete a building by its ID. [🔒: map_update].
     * @request DELETE:/map/{mapId}/building/{buildingId}
     * @secure
     */
    buildingDelete = (mapId: string, buildingId: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/map/${mapId}/building/${buildingId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
