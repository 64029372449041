import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { Badge, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditJsonRaw from 'components/Admin/EditJsonRaw';
import ButtonFno from 'components/inputs/ButtonFno';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store';
import { ROUTES } from 'resources/routes-constants';
import currentUserIsAdmin from 'backend/utils/currentUserIsAdmin';
import StatisticCard from 'components/StatisticCard';
import checkPermissions from 'utility/checkPermissions';
import { PermissionsEnum } from 'backend/ApiBackofficeDefinition/data-contracts';


const AuditRawDataList: React.FC = () => {
    const { t } = useTranslation(nameOf({AuditRawDataList}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const [selectedRow, setSelectedRow] = useState<any>();
    const [rows, setRows] = useState<any[]>([]);
    const [headers, setHeaders] = useState< TableColumn<any>[]>([]);
    const navigate = useNavigate();
    const loadingRawFile = useAppSelector(state => state.audit.loadingList);
    const loadingOptimizationResult = useAppSelector(state => state.audit.loadingOptimizationResult);
    const [searchTerm, setSearchTerm] = useState('');
    
    const currentMap = useAppSelector(state => state.map.currentMap);
    const tenant = useAppSelector(state => state.userProfil.currentTenant);
    const rawData = useAppSelector(state => state.audit.data);
    const auditRawFileSelected = useAppSelector(state => state.audit.auditRawFileSelected);
    const optimizationResult = useAppSelector(state => state.audit.optimizationResult);

    useEffect(() => {
        document.title = t("Données de l'audit");
    }, [])


    useEffect(() => {
        if(!checkPermissions(PermissionsEnum.AuditList))
        {
            navigate(ROUTES.DASHBOARD)
        }
    }, [currentMap])


    useEffect(() => {
        if(rawData)
        {
            const headersData: TableColumn<any>[] = []
            for (let index = 0; index < rawData.fields.length; index++) {
                const col = rawData.fields[index];
                if(col)
                headersData.push({
                    id:col,
                    name: col,
                    sortable: true,
                    selector: row => row[col]
                })
            }

            setHeaders(headersData);
            setRows(rawData.rows.map((row) => {
                return {
                    ...row,
                    // merge all fields in one for search purpose
                    searchField: _.join(_.values(row), " ").toLocaleLowerCase()
                }
            }));
        
        }
        else{
            setHeaders([]);
            setRows([]);
        }
    }, [rawData])


    let rowData = rows
    if(searchTerm)
    {
        rowData = rows.filter((entity) => {
            return entity.searchField.includes(searchTerm.toLocaleLowerCase());
        });
    }

    
    let nbMission: number | undefined = undefined;
    let nbOrders: number | undefined = undefined;
    let nbProducts: number | undefined = undefined;
    let nbDeplacement: number | undefined = undefined;
    let moneyWin: number | undefined = undefined;

    if(auditRawFileSelected && rawData)
    {
        nbMission = _.size(_.unionBy(rawData.rows, rawData.fields[auditRawFileSelected.columnsBindingData.fieldMission] ));
        nbOrders = _.size(_.unionBy(rawData.rows, rawData.fields[auditRawFileSelected.columnsBindingData.fieldSupport]));
        if(auditRawFileSelected.columnsBindingData.fieldMission)
        {
            nbProducts = _.sumBy(rawData.rows, (item) => {
                const fieldQuantity = rawData.fields[auditRawFileSelected.columnsBindingData.fieldQuantity];
                const quantity = parseInt(item[fieldQuantity]);
                if(quantity) return quantity;
                else return 0;
                
            });
        }
        
        nbDeplacement = rawData.rows.length;
    }


    
    if(optimizationResult)
    {
        const newDistance = _.sumBy(_.map(optimizationResult.final.missions, (value, key) => {
            return value.totalDistance
        }));
        const oldDistance = _.sumBy(_.map(optimizationResult.path.paths, (value, key) => {
            return value.original.totalDistance
        }));


        moneyWin = Math.abs(Math.trunc(((oldDistance - newDistance) / 3000) * 25));

    } 

    return (
        <div>
             <PageHeader title={t("Données client")} middlePart={[{title:t("Audit"), to:ROUTES.AUDIT_RAW_DATA}]}/>
             <Row>
                {/*moneyWin && <Col><StatisticCard title={t("Economisé/période")} color='orange' value={moneyWin.toLocaleString("fr-FR")} icon='euro' loading={loadingOptimizationResult}/></Col>*/}
                {nbMission && <Col><StatisticCard title={t("Missions/période")} color='blue' value={nbMission.toLocaleString("fr-FR")} icon='boxes-stacked' loading={loadingRawFile}/></Col>}
                {nbOrders && <Col><StatisticCard title={t("Commandes/période")} color='orange' value={nbOrders.toLocaleString("fr-FR")} icon='box' loading={loadingRawFile}/></Col>}
                {nbProducts && <Col><StatisticCard title={t("Produits/période")} color='blue' value={nbProducts.toLocaleString("fr-FR")} icon='barcode' loading={loadingRawFile}/></Col>}
            </Row>
            <div>
                <div className='table-component'>

                <DataTable 
                        className={loadingRawFile ? "loading": ""}
                        dense
                        columns={headers} 
                        data={rowData} 
                        progressPending={loadingRawFile}
                        progressComponent={<Loading text={t("Chargement")}/>}
                        striped={true}
                        subHeader={true}
                        paginationPerPage={15}
                        highlightOnHover={true}
                        pagination
                        noDataComponent={<div className='w-100 text-center p-5'>{t("Aucun fichier d'audit disponible")}</div>}
                        subHeaderComponent={<> 
                            <h5 className='text-uppercase'> {t("Données brutes") + " ("+rows.length+")"} {loadingRawFile && <Loading inline size={20} />}</h5>
                            <div className='search-panel'>
                                {checkPermissions(PermissionsEnum.AuditEdit) && <ButtonFno sm className='me-2' onClick={() => navigate(ROUTES.AUDIT_DASHBOARD)}><FontAwesomeIcon className='me-2' icon={["fas", "plus"]} /> {t("Importer un fichier")}</ButtonFno>}
                                <input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>
                            </div>
                            </>}/>
                </div>
            </div>

            {selectedRow && <Modal dialogClassName='modal-fno' show={true} onHide={() => setSelectedRow(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Modifier la commande")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditJsonRaw onCancel={() => setSelectedRow(undefined)} onSubmit={() => setSelectedRow(undefined)} value={selectedRow} loading={false}/>
                </Modal.Body>
            </Modal>}
        </div>
    )
}

export default AuditRawDataList
