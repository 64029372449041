import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { userProfilActions} from "store/reducers/Profil/userProfilSlice";

export interface ILoginStore {
  errors: Record<string, string[]> | undefined;
  loading: boolean;
  redirectUrl?: string;
  showSessionExpiredModal: boolean;
}

const initialState: ILoginStore = {
  errors: undefined,
  loading: false,
  redirectUrl: undefined,
  showSessionExpiredModal: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setErrors: (state, action: PayloadAction<Record<string, string[]> | undefined>) => {
      state.errors = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setRedirectUrl: (state, action: PayloadAction<string | undefined>) => {
      state.redirectUrl = action.payload;
    },
    setShowSessionExpiredModal: (state, action: PayloadAction<boolean>) => {
      state.showSessionExpiredModal = action.payload;
    }
  },
  
  extraReducers: (builder) => {
    builder.addCase(userProfilActions.setLogout, (state) => {
      state.loading = false;
      state.errors = undefined;
    })
  }
});

export const { actions: loginActions, reducer: loginReducer } = loginSlice;