import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { useAppSelector } from 'store/store';
import AuditsList from 'components/Audit/AuditsList';

const AdminClientMapAudits: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientAudits: AdminClientMapAudits}), { useSuspense: false});
    const allMaps = useAppSelector(state => state.adminClientMap.maps);
    const { clientId, mapId } = useParams();


    return <AuditsList  
        allMaps={allMaps}
        clientId={clientId ?? ""}
        mapId={mapId  ?? ""}
        />
}

export default AdminClientMapAudits
