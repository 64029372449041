import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientPickingActions } from "../adminClientPickingSlice";
import apiAdminDefinition from "backend/apiAdminDefinition";
import { Operation, ContainerDetails } from "backend/ApiAdminDefinition/data-contracts";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";


/**
 * Enable or not a runner
 * @returns 
 */
export const toggleEnableRunnerThrunks = (runnerID: string, enable:boolean, callbacks: ThrunkApiCallBacks<ContainerDetails>): AppThunk => {
    return async (dispatch, getState) => {
       try{

            dispatch(adminClientPickingActions.setLoadingEdit(true));
            await apiAdminDefinition.pickingRunner.pickingRunnerPatch(runnerID, 
                [{op:"replace", path: "/enabled", value: enable.toString()} as Operation])
                .then((result) => {
                    dispatch(adminClientPickingActions.updateRunner(result.data))
                    callbacks.onSuccess(result.data);
                })
                .catch((apiResponse: apiErrorModel) => {
                    callbacks.onError(apiResponse);
                })
                .finally(() => dispatch(adminClientPickingActions.setLoadingEdit(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors du chargement des runners"))
        }
    }
}