import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiAdminDefinition from "backend/apiAdminDefinition";
import { ClientDetails, ClientUpdate } from "backend/ApiAdminDefinition/data-contracts";
import apiErrorModel from "backend/utils/apiErrorModel";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { adminClientActions } from "../adminClientSlice";


/**
 * Update a client
 * @returns 
 */
export const updateClientAdminThunk = (client: ClientUpdate, callbacks: ThrunkApiCallBacks<ClientDetails>): AppThunk => {
    return async (dispatch, getState) => {
        try{
            dispatch(adminClientActions.setloadingUpsert(true))

            return apiAdminDefinition.client.clientPut(client.id, client)
            .then((result) => {
                dispatch(adminClientActions.updateClient(result.data));
                callbacks.onSuccess(result.data);
            })
            .catch((apiResponse: apiErrorModel) => {
                callbacks.onError(apiResponse);
            })
            .finally(() => dispatch(adminClientActions.setloadingUpsert(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la modification de utilisateur"));
            dispatch(adminClientActions.setloadingUpsert(false));
        }
    }
}
