import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import "./DemoHeatmapSAP.scss";
import FloorsComponent from '../Components/FloorsComponent';
import ZoomComponent from '../Components/ZoomComponent';
import Loading from 'components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useFnoMapSdk from 'hooks/useFnoMapSdk';
import MapRender from 'components/mapControls/MapRender';
import appColors from 'resources/appColors';
import getApiConfig from 'backend/utils/getApiConfig';
import { PickingOptimizationResult } from 'backend/ApiBackofficeDefinition/PickingOptimizationResult';
import { AuditFile } from 'backend/ApiBackofficeDefinition/AuditFile';
import HeatMapPanel from './Components/HeatMapPanel';
import LocationCodePanel from './Components/LocationCodePanel';
import PathPanel from './Components/PathPanel';
import { AnalysisDetails, AuditRawFileDetails } from 'backend/ApiBackofficeDefinition/data-contracts';


/**
 * All menu selectable
 */
enum menuItems{
    PATHS = "PATHS",
    HEATMAP = "HEATMAP",
    LOCATION_CODE = "LOCATION_CODE",
}


export type SapDemoData = {
    rawFiles: AuditRawFileDetails;
    optimizationResult: AnalysisDetails;
  };

const DemoHeatmapSAP: React.FC = () => {
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZW5hbnQiOiJjYXJyZWZvdXIiLCJ0b2tlbi10eXBlIjoiUHJvamVjdCIsInNlZWQiOiI3ZWM5MTEwYi1lZmJjLTQ0YjMtOGVmNy1lZjEwNjQwNTRjYjciLCJpc3MiOiJmaW5kYW5kb3JkZXIiLCJhdWQiOiJmaW5kYW5kb3JkZXIifQ.L4iuRrL6FjsCPyjtiZPrLdSWds6S_BMHF4XUH3INxGg';
    const mapId = "66851f93ea5a4d2191aca1da";
    const tenant = "carrefour";
    
    const [menuSelected, setMenuSelected] = useState<menuItems>(menuItems.PATHS);
    const [data, setData] = useState<SapDemoData | undefined>(undefined);

    const {
        sdk,
        loading,
        mapReady
    } = useFnoMapSdk(token, tenant, mapId);


    useEffect(() => {

        const apiConfig = getApiConfig(tenant, token);
        const apiAuditRaw = new AuditFile(apiConfig);
        const apiAuditResult = new PickingOptimizationResult(apiConfig);

        Promise.all([
            apiAuditRaw.auditRawFilesList(mapId),
            apiAuditResult.pickingOptimizationResultsList(mapId, {rawFileId: "66978875dd418dab48605456"})
        ]).then((result) => {
            setData({rawFiles: result[0].data[0], optimizationResult: result[1].data[0]} as SapDemoData);
        })

        /*apiAuditRaw.auditRawFilesList(mapId).then((result) => {
            console.log("AAA auditRawFilesList", result.data);
        })
        apiAuditResult.pickingOptimizationResultsList(mapId, {rawFileId: "66978875dd418dab48605456"}).then((result) => {
            console.log("AAA pickingOptimizationResultsList", result.data);
        })*/

    }, [])






    return (<div id="demo-picking-body">
        <Container fluid>
            {<Row id='menu'>
                <Col sm="4" onClick={() => setMenuSelected(menuItems.PATHS)} className={'g-0 item ' + (menuSelected == menuItems.PATHS ? "selected" : "")}><FontAwesomeIcon icon={["fas", "route"]} /> Paths</Col>
                <Col sm="4" onClick={() => setMenuSelected(menuItems.HEATMAP)} className={'g-0 item ' + (menuSelected == menuItems.HEATMAP ? "selected" : "")}><FontAwesomeIcon icon={["fas", "table-cells"]} /> Heatmap</Col>
                <Col sm="4" onClick={() => setMenuSelected(menuItems.LOCATION_CODE)} className={'g-0 item ' + (menuSelected == menuItems.LOCATION_CODE ? "selected" : "")}><FontAwesomeIcon icon={["fas", "barcode"]} /> Locations code</Col>
            </Row>}
        </Container>
        <Container  className='g-0'  fluid style={{height: "100%"}}>
            <Row id='content' className='g-0'>
                <Col id="map-overlay" sm="8">
                    {<MapRender 
                        sdk={sdk} 
                        mapReady={mapReady}
                        assetToolipConfig={{
                            highlightColor: appColors.blue
                        }}/>}
                </Col>
                <Col sm="4" className='sub-menu'>
                   {sdk && mapReady && menuSelected == menuItems.HEATMAP && <HeatMapPanel sdk={sdk} />}
                   {sdk && mapReady && menuSelected == menuItems.PATHS && <PathPanel sdk={sdk} data={data}/>}
                   {sdk && mapReady && menuSelected == menuItems.LOCATION_CODE && <LocationCodePanel sdk={sdk} />}
                </Col>
            </Row>
        </Container>
        {!mapReady && <div id='fullscreen-loading'><Loading text={"Loading ..."} fullScreen fullScreenBackgroundColor='#fff'/></div>}
        </div>)
}

export default DemoHeatmapSAP
