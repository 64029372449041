import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientMapLabelTemplateActions } from "../adminClientMapLabelTemplateSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Delete a labelTemplate
 * @returns 
 */
export const deleteLabelTemplateThrunks = (labelTemplateId: string, callbacks: ThrunkApiCallBacks<boolean>): AppThunk => {
    return async (dispatch, getState) => {
       try{

            dispatch(adminClientMapLabelTemplateActions.setLoadingLabelTemplateForm(true));
            await apiBackofficeDefinition.labelTemplate.labelTemplateDelete(labelTemplateId)
                .then((result) => {
                    dispatch(adminClientMapLabelTemplateActions.deleteLabelTemplate(labelTemplateId))
                    callbacks.onSuccess(true);
                })
                .catch((apiResponse: apiErrorModel) => {
                    callbacks.onError(apiResponse);
                })
                .finally(() => dispatch(adminClientMapLabelTemplateActions.setLoadingLabelTemplateForm(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la suppression du labelTemplate"))
        }
    }
}