import { encode } from "js-base64";

/**
 * Uploads a file to Azure Storage using a Shared Access Signature (SAS) token.
 * @param urlWithSasToken - The URL with the SAS token for the Azure Storage container.
 * @param file - The Blob or File object representing the file to be uploaded.
 * @param chunkSize - The size of each chunk in bytes (default is 100MB).
 * @returns A Promise that resolves to a boolean indicating whether the upload was successful.
 */
export default function uploadFileToStorage(urlWithSasToken: string, file: Blob, chunkSize: number = 100 * 1024 * 1024) {
    // Calcul du nombre total de chunks nécessaires pour télécharger le fichier
    const totalChunks = Math.ceil(file.size / chunkSize);
    const blockIds: string[] = []; // Tableau pour stocker les IDs des blocs

    // Fonction pour télécharger un seul chunk
    const uploadChunk = (chunkNumber: number): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            // Calcul de l'indice de début et de fin pour le chunk
            const start = chunkNumber * chunkSize;
            const end = Math.min(start + chunkSize, file.size);

            // Découpe du fichier pour obtenir le chunk
            const chunk = file.slice(start, end);

            const fileName = urlWithSasToken.split('?')[0].split('/').pop();
            // Génération d'un ID de bloc aléatoire
            const blockId = encode(`${fileName}-chunk-${(chunkNumber + 1).toString().padStart(6, '0')}`);

            // Stockage de l'ID du bloc
            blockIds.push(blockId);
            
            //@ts-ignore
            console.log(` ${fileName} - chunk: ${chunkNumber}/${totalChunks} - chunk size : ${chunk.size} - Block ID: ${blockId}`);
            // Configuration des options pour la requête PUT
            const requestOptions = {
                method: 'PUT',
                headers: {
                    "x-ms-blob-content-type": file.type // Spécification du type de contenu
                },
                body: chunk, // Corps de la requête contenant le chunk
            };

            // Envoi de la requête pour télécharger le chunk
            fetch(`${urlWithSasToken}&comp=block&blockid=${blockId}`, {
                ...requestOptions, // Propagation de l'objet requestOptions
            })
            .then(response => {
                if (response.ok) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch(error => {
                resolve(false);
            });
        });
    };

    // Fonction pour télécharger tous les chunks séquentiellement
    const uploadChunksSequentially = (): Promise<boolean> => {
        let index = 0;

        // Fonction récursive pour télécharger les chunks séquentiellement
        const uploadNextChunk = (): Promise<boolean> => {
            if (index >= totalChunks) {
                // Tous les chunks ont été téléchargés avec succès
                return Promise.resolve(true);
            }

            // Téléchargement du prochain chunk
            return uploadChunk(index)
                .then(success => {
                    if (!success) {
                        // Si le téléchargement du chunk a échoué, arrêt du processus
                        return false;
                    }

                    // Passage au chunk suivant
                    index++;
                    return uploadNextChunk(); // Appel récursif pour télécharger le chunk suivant
                });
        };

        // Démarrage du processus de téléchargement séquentiel
        return uploadNextChunk();
    };

    // Lancement du téléchargement des chunks séquentiellement
    return uploadChunksSequentially()
        .then(success => {
            if (success) {
                // Si tous les chunks ont été téléchargés avec succès, exécution de l'opération Put Block List
                const requestBody = blockIds.map(blockId => `<Latest>${blockId}</Latest>`).join('');
                return fetch(`${urlWithSasToken}&comp=blocklist`, {
                    method: 'PUT',
                    body: `<?xml version="1.0" encoding="utf-8"?><BlockList>${requestBody}</BlockList>`,
                })
                .then(response => response.ok);
            } else {
                return false; // Si le téléchargement d'un chunk a échoué, retour de false
            }
        })
        .catch(error => false);
}