import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sdk from 'fno-sdk';
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Orders from '../Orders';
import dayjs from 'dayjs';


interface OwnProps { 
    dateFrom: Date;
    dateTo: Date;
}


interface IStatsData{
    name: string;
    nbProducts: number;
}

const ProductsPerPicker: React.FC<OwnProps> = ({dateFrom, dateTo}) => {

    const [data, setData] = useState<IStatsData[]>([]);


    useEffect(() => {
        if(dayjs(dateFrom).isBefore(dateTo)){
            const tmpData:IStatsData[] = []
            let currentDate = dayjs(dateFrom);

            while(dayjs(currentDate).isBefore(dateTo)){ 
                tmpData.push({
                    name: currentDate.format("DD/MM"), 
                    nbProducts: Math.trunc(Math.random() * (600 - 300) + 300)
            });
                currentDate = currentDate.add(1, "day");   
            }
            setData(tmpData);
        }
        else
        {
            setData([]);
        }
    }, [dateFrom, dateTo])


    return (
        <div className='mt-3'>
            <h4 className=' mt-2'>Products per picker</h4>
            <ResponsiveContainer width={"100%"} height={200}>
                <LineChart data={data}>
                    <Line type="monotone" dataKey="nbProducts" stroke="#e7b525" strokeWidth={4}/>
                    <CartesianGrid stroke="#ccc" />
                    <XAxis dataKey="name" angle={45} tickSize={18} height={40} interval={0}/>
                    <YAxis interval={0}/>
                    <Tooltip formatter={(value, name, props) => [value + "s", "Nb seconds"]}/>
                    <Legend/>
                </LineChart>
            </ResponsiveContainer>
            
        </div>
    )
}

export default ProductsPerPicker
