/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, AuditRawFileCreate, AuditRawFileCreateResult, AuditRawFileDetails, Operation } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class AuditFile<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags AuditFile
     * @name AuditRawFilesList
     * @summary Get all audit raw file. [🔒: audit_list]
     * @request GET:/map/{mapId}/audit/raw/files
     * @secure
     */
    auditRawFilesList = (mapId: string, params: RequestParams = {}) =>
        this.request<AuditRawFileDetails[], void>({
            path: `/map/${mapId}/audit/raw/files`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags AuditFile
     * @name AuditRawFilePost
     * @summary Create new audit raw file. [🔒: audit_edit]
     * @request POST:/map/{mapId}/audit/raw/file
     * @secure
     */
    auditRawFilePost = (mapId: string, data: AuditRawFileCreate, params: RequestParams = {}) =>
        this.request<AuditRawFileCreateResult, ApiErrorsResponse | void>({
            path: `/map/${mapId}/audit/raw/file`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags AuditFile
     * @name AuditRawFilePatch
     * @summary Update specific field of an audit raw file. [🔒: audit_edit]
     * @request PATCH:/map/{mapId}/audit/raw/file/{fileId}
     * @secure
     */
    auditRawFilePatch = (mapId: string, fileId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<AuditRawFileDetails, ApiErrorsResponse | void>({
            path: `/map/${mapId}/audit/raw/file/${fileId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags AuditFile
     * @name AuditRawFileDelete
     * @summary Delete an audit raw file. [🔒: audit_edit]
     * @request DELETE:/map/{mapId}/audit/raw/file/{fileId}
     * @secure
     */
    auditRawFileDelete = (mapId: string, fileId: string, params: RequestParams = {}) =>
        this.request<void, ApiErrorsResponse | void>({
            path: `/map/${mapId}/audit/raw/file/${fileId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
