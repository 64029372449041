import { AppThunk } from "store/store"
import _ from "lodash";
import { menuDatesSelectorActions } from "../menuDatesSelectorSlice";


/**
 * Toggle display or hide dates selector component in the menu
 * @param show 
 * @returns 
 */
export const showDatesSelectorThrunks = (show: boolean): AppThunk => {
    return async (dispatch, getState) => {
       dispatch(menuDatesSelectorActions.setShowInput(show));
    };
  };