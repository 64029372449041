import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import * as yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import { Formik, FormikHelpers } from 'formik';
import Switch from "react-switch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContainerDetails } from 'backend/ApiAdminDefinition/data-contracts';
import HelperTooltip from 'components/HelperTooltip';



interface OwnProps {
    initialValues?: ContainerDetails;
    loading: boolean;
    onSubmit: ((values: IPickingRunnerCreateFormInputs, formikHelpers: FormikHelpers<IPickingRunnerCreateFormInputs>) => void | Promise<any>) & ((values: IPickingRunnerCreateFormInputs, { setFieldError }: FormikHelpers<IPickingRunnerCreateFormInputs>) => Promise<void>);
    onCancel: () => void
 }

 export interface IPickingRunnerCreateFormInputs{
    id: string | undefined;
    name: string;
    image: string;
    cpu: number;
    memoryInGB: number;
    enabled: boolean;
    isGlobalRunner:boolean;
}


/**
 * Form component for creating a picking runner.
 * 
 * @param onSubmit Callback function for form submission.
 * @param onCancel Callback function for form cancellation.
 * @param loading Indicates if the form is currently loading.
 * @param initialValues Initial values for the form.
 * @returns The picking runner creation form component.
 */
const PickingRunnerCreateForm: React.FC<OwnProps> = ({onSubmit, onCancel, loading, initialValues = undefined}) => {
    const { t } = useTranslation([nameOf({PickingRunnerCreateForm}), "TransverseError"], { useSuspense: false});

    const schema = yup.object().shape({
        name: yup.string().required(t("required", { ns:"TransverseError"}) as string),
        image: yup.string().required(t("required", { ns:"TransverseError"}) as string),
        cpu: yup.number().required(t("required", { ns:"TransverseError"}) as string),
        memoryInGB: yup.number().required(t("required", { ns:"TransverseError"}) as string),
      });


    return (
        <div>
                <Formik
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    initialValues={{
                        id: initialValues?.id ?? "",
                        name: initialValues?.name ?? '',
                        cpu: initialValues?.cpu ?? 2,
                        memoryInGB: initialValues?.memoryInGB ?? 2,
                        enabled: initialValues?.enabled ?? true,
                        image: initialValues?.image ?? 'pickingfnodev.azurecr.io/apirunner:latest',
                        isGlobalRunner: initialValues?.isGlobalRunner ?? false
                    } as IPickingRunnerCreateFormInputs}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        isSubmitting,
                        setFieldValue,
                        errors,
                    }) => {
                        return (
                            <fieldset disabled={isSubmitting}>
    

                                    <Row>
                                        <Col xs="8">
                                            <Form.Control type="hidden" name="id" value={values.id}></Form.Control>
                                                <Form.Group className='mb-3' controlId="validationFormik03">
                                                    <Form.Label>{t("Nom de l'instance")}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        disabled={loading}
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        isValid={touched.name && !errors.name}
                                                        isInvalid={!!errors.name}
                                                    />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col className='text-center'>
                                            <Form.Group className='mb-3' controlId="validationFormik03">
                                                <Form.Label>{t("Global")} <HelperTooltip text={t("Si global, le runner sera utilisé pour l'optimisation des audits. Les matrices seront envoyées au runner au moment de l'optimisation.")} /></Form.Label>
                                                <div>
                                                    <Switch checked={values.isGlobalRunner} onChange={(enabled) => {
                                                        setFieldValue("isGlobalRunner", enabled)
                                                    }}/>
                                                </div>
                                               
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Control type="hidden" name="id" value={values.image}></Form.Control>
                                                <Form.Group className='mb-3' controlId="validationFormik03">
                                                    <Form.Label>{t("Image")}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="image"
                                                        value={values.image}
                                                        onChange={handleChange}
                                                        isValid={touched.image && !errors.image}
                                                        isInvalid={!!errors.image}
                                                    />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.image}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik07">
                                                <Form.Label>{t("CPU")}</Form.Label>
                                                <Form.Select
                                                    name="cpu"
                                                    value={values.cpu}
                                                    disabled={loading}
                                                    onChange={async (val) => {setFieldValue("cpu", parseInt(val.target.value))}}
                                                    isValid={touched.cpu && !errors.cpu}
                                                    isInvalid={!!errors.cpu}>
                                                    {Array.from(Array(4).keys()).map((i) => {
                                                            return <option value={i + 1}>{i + 1}</option>;
                                                        })}
                                                    
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.cpu}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            
                                        </Col>
                                        <Col>
                                            <Form.Group className='mb-3' controlId="validationFormik07">
                                                <Form.Label>{t("Mémoire")}</Form.Label>
                                                <Form.Select
                                                    name="memoryInGB"
                                                    value={values.memoryInGB}
                                                    disabled={loading}
                                                    onChange={async (val) => {setFieldValue("memoryInGB", parseInt(val.target.value))}}
                                                    isValid={touched.memoryInGB && !errors.memoryInGB}
                                                    isInvalid={!!errors.memoryInGB}>
                                                        {Array.from(Array(16).keys()).map((i) => {
                                                            return <option value={i + 1}>{i + 1}</option>;
                                                        })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.memoryInGB}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                   
    
                                  
    
    
                                    <div className='d-flex justify-content-center'>
                                        <ButtonFno disabled={loading} className="w-50 me-1" color='blue' onClick={onCancel}>{t('Retour')}</ButtonFno>
                                        <ButtonFno disabled={loading} className="w-50 ms-1" color='orange' loading={loading} onClick={handleSubmit}>{t('Valider')}</ButtonFno>
                                    </div>

                                    {loading && <div className='d-flex justify-content-center mt-4'>
                                        <h5 className='text-orange'><FontAwesomeIcon icon={['fas', 'warning']} size="sm"/> {t('Cette opération peut prendre plusieurs minutes')}</h5>
                                    </div>}
                                </fieldset>
                            )
                    }}
                    </Formik> 
        </div>
    )
}

export default PickingRunnerCreateForm
