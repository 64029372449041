
/**
 * Compute gain in pourcent and return as number.
 * @param oldCost 
 * @param newCost 
 * @returns 
 */
export default function computeGainPourcent(oldCost: number, newCost: number) {

    const gainpourcent = -((newCost - oldCost) / oldCost) * 100;
    return isNaN(gainpourcent) ? 0 : parseFloat(gainpourcent.toFixed(2));
}