import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiErrorModel from "backend/utils/apiErrorModel";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { PolygonDetails, PolygonUpdate} from "backend/ApiBackofficeDefinition/data-contracts";
import { adminClientMapPolygonActions } from "../adminClientMapPolygonSlice";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Thunk action creator for updating an Polygon
 * @param mapId 
 * @param Polygon 
 * @param callbacks 
 * @returns 
 */
export const updatePolygonThunk = (
    mapId: string,
    Polygon: PolygonUpdate,
    callbacks: ThrunkApiCallBacks<PolygonDetails>
  ): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapPolygonActions.setLoadingPolygonForm(true));
  
        // Make API call to create Polygon
        await apiBackofficeDefinition.polygon.polygonPut(mapId, Polygon.id ,Polygon)
          .then((result) => {
            // Dispatch action to add newly created Polygon to store
            dispatch(adminClientMapPolygonActions.updatePolygon(result.data));
            // Call onSuccess callback
            callbacks.onSuccess(result.data);
          })
          .catch((apiResponse: apiErrorModel) => {
            // Call onError callback with API error response
            callbacks.onError(apiResponse);
          });
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors de la modification du Polygon"));
          callbacks.onError({ errors:{"api":[error.message]} } as apiErrorModel)
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapPolygonActions.setLoadingPolygonForm(false));
      }
    };
  };