import React from 'react'
import "./Section.scss"


type Props = {
    children?: React.ReactNode
  };


/**
 * Component for displaying a section.
 *
 * @param {Object} props - The properties for the component.
 * @param {React.ReactNode} props.children - The children to render inside the section.
 * @return {React.ReactElement} The rendered section component.
 */
const Section: React.FC<Props> = ({children}) => {
    return <div className='fno-section'>
        {children}
    </div>

}

export default Section