/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, RoleClientCreate, RoleClientDetails, RoleClientUpdate } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Roles<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Roles
     * @name ClientRolesList
     * @summary [🤵] Get all roles for current client.
     * @request GET:/admin/client/roles
     * @secure
     */
    clientRolesList = (params: RequestParams = {}) =>
        this.request<RoleClientDetails[], void>({
            path: `/admin/client/roles`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Roles
     * @name ClientRolePost
     * @summary [🤵] Create new role for current client.
     * @request POST:/admin/client/role
     * @secure
     */
    clientRolePost = (data: RoleClientCreate, params: RequestParams = {}) =>
        this.request<RoleClientDetails, ApiErrorsResponse | void>({
            path: `/admin/client/role`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Roles
     * @name ClientRolePut
     * @summary [🤵]
     * @request PUT:/admin/client/role/{roleId}
     * @secure
     */
    clientRolePut = (roleId: string, data: RoleClientUpdate, params: RequestParams = {}) =>
        this.request<RoleClientDetails, ApiErrorsResponse | void>({
            path: `/admin/client/role/${roleId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
}
