import React, { useEffect, useState } from 'react'
import Chrome, { ChromeInputType } from '@uiw/react-color-chrome';
import { GithubPlacement } from '@uiw/react-color';
import "./ColorPicker.scss";
import useOutsideClick from 'hooks/useOutsideClick ';

type Props = {
    value: string;
    onChange: (colorHexa: string) => void;
  };



/**
 * React functional component that renders a color picker button with optional color scheme modal.
 *
 * @param {string} props.value - The initial color value.
 * @param {function} props.onChange - The function to call when the color value changes.
 * @return {JSX.Element} The color picker component.
 */
const ColorPicker: React.FC<Props> = ({value, onChange}) => {
    const [showColorScheme, setShowColorScheme] = useState(false);
    const [currentColor, setCurrentColor] = useState(value);

    const ref = useOutsideClick(() => {
        setShowColorScheme(false);
      });
      
    useEffect(() => {
        if(!showColorScheme && value != currentColor){
            onChange(currentColor);
        }
    }, [showColorScheme])

    useEffect(() => {
        setCurrentColor(value);
    }, [value])


    return <div ref={ref} className='color-picker-component'>
        <div className='preview' onClick={() => setShowColorScheme(!showColorScheme)}>
            <div className='color' style={{backgroundColor: currentColor}}></div>
        </div>
        {showColorScheme && <div className='modal-color'>
            <Chrome 
                inputType={ChromeInputType.HEXA}
                color={currentColor} 
                onChange={(color) => setCurrentColor(color.hexa)} 
                placement= {GithubPlacement.Right} />
            </div>}
         </div>

}

export default ColorPicker