/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { LanguageItem } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class LanguageAdmin<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Language admin
     * @name LanguagesList
     * @summary [🤵] Return all default language availables.
     * @request GET:/admin/languages
     * @secure
     */
    languagesList = (params: RequestParams = {}) =>
        this.request<LanguageItem[], void>({
            path: `/admin/languages`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
}
