import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientAuditOptimizationSettingActions } from "../adminClientMapOptimizationSettingSlice";
import apiAdminDefinition from "backend/apiAdminDefinition";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import { OptimizationSettingDetails } from "backend/ApiAdminDefinition/data-contracts";



export const enableOptimizationSettingThrunks = (mapId: string, settingId: string, callbacks: ThrunkApiCallBacks<OptimizationSettingDetails>): AppThunk => {
    return async (dispatch, getState) => {
      try {

        const settings = getState().adminOptimizationSetting.optimizationSettings.map((setting) => ({
          ...setting,
          enabled: setting.id === settingId
        }));
         
        dispatch(adminClientAuditOptimizationSettingActions.setOptimizationSettings(settings));

        // Fetch assets from the API
        const result = await apiAdminDefinition.optimizationSetting.mapOptimizationSettingEnablePost(mapId, settingId);
        
        if(!result.ok){
          callbacks.onError(result.error as apiErrorModel);
        } 
        else
        {
          callbacks.onSuccess(result.data);
        }



      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors de l'activationde la configuration picking"));
        }
      }
    };
  };