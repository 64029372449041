import { ContainerCreate ,  ContainerDetails} from "backend/ApiAdminDefinition/data-contracts";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import { AppThunk } from "store/store";
import { adminClientPickingActions } from "../adminClientPickingSlice";
import apiAdminDefinition from "backend/apiAdminDefinition";
import apiErrorModel from "backend/utils/apiErrorModel";
import { toast } from "react-toastify";
import i18next from "i18next";

/**
 * Create a nex runner for current client
 * @param runner 
 * @param callbacks 
 * @returns 
 */
export const createPickingRunnerThrunks = (runner: ContainerCreate , callbacks: ThrunkApiCallBacks<ContainerDetails>): AppThunk => {
    return async (dispatch, getState) => {
        try{
            dispatch(adminClientPickingActions.setLoadingEdit(true))

            return apiAdminDefinition.pickingRunner.pickingRunnerPost(runner)
            .then((result) => {
                dispatch(adminClientPickingActions.addRunner(result.data))
                callbacks.onSuccess(result.data);
            })
            .catch((apiResponse: apiErrorModel) => {
                callbacks.onError(apiResponse);
            })
            .finally(() => dispatch(adminClientPickingActions.setLoadingEdit(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la modification de la carte"));
            dispatch(adminClientPickingActions.setLoadingEdit(false));
        }
    }
}