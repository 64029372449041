import productQueue from "./models/productQueue";
import warehouseModel from "./models/warehouseResourceModel";


export class apiSAP {
    private _apiKey = "9dGiNACAP5UrvbGLMeNCdA8AgJ9vJYbP";
    private _baseUrl = "https://sandbox.api.sap.com";
    private _warehouseId = "1750";
    private _ressource = "SALL-1"

    private getHeaderHelper()
    {
        const headers: {[key: string]: any} = {
            'Content-Type': 'application/json',
            'APIKey': this._apiKey,
        }
        return headers;
    }

    public getWarehouseResources(): Promise<warehouseModel[]>{
        const requestOptions = {
            method: 'GET',
            headers: this.getHeaderHelper(),
        };
    
        return fetch((`${this._baseUrl}/s4hanacloud/sap/opu/odata4/sap/api_warehouse_resource_2/srvd_a2x/sap/warehouseresource/0001/WarehouseResource?$top=50`), {
            ...requestOptions
        })
        .then(response => response.json())
        .then((data) => data.value as warehouseModel[]);
    }


    public getProductsQueue():  Promise<productQueue[]>{
        const requestOptions = {
            method: 'GET',
            headers: this.getHeaderHelper(),
        };
    
        return fetch((`${this._baseUrl}/s4hanacloud/sap/opu/odata4/sap/api_warehouse_resource_2/srvd_a2x/sap/warehouseresource/0001/WhseResourceGroupQueueSqnc?$top=50`), {
            ...requestOptions
        })
        .then(response => response.json())
        .then((data) => data.value as productQueue[]);
    }
    // TODO: /WhseResourceGroupQueueSqnc 
    // EWMResourceGroupQueueSqncNmbr => ordre du produit
    // WarehouseOrderQueue => location code
    //EWMResourceGroup => groupe d'une liste de picker

}